<template>
  <div class="wrapper">
    <ControlArea
        :controlAreaObj="control"
        :editMode="editMode"
        :changeMode="changeMode"
        v-if="hasAuth"
    />
    <h2 class="main-title">{{targetObj.label}}記事</h2>
    <div class="heading">
      <p class="lead" v-html="nl2br(targetObj.body)" v-if="targetObj.body"/>
      <router-link :to="targetObj.link" class="link" v-if="targetObj.link">{{targetObj.linkText}}</router-link>
      <div class="l-wrapper p-action_block" v-if="route.query.target">
        <div class="p-action_item red">
          <a class="p-action_link" :href="'/articles/create/?type=' + targetObj.target + '&temple_id=' + id">
            <span class="p-action_link-text">新規{{targetObj.label}}を作成する</span>
          </a>
        </div>
      </div>
    </div>
    <template v-if="hasAuth">
      <FlowList :limit="limit" :conditions="conditions" :decorate="false" :editMode="editMode" :types="targetObj.type" />
    </template>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {useTemples} from "../../composables/useTemples";
import {computed, inject, ref, watch} from "vue";
import FlowList from "../../components/FlowList";
import {nl2br} from "../../utils/nl2br";
import ControlArea from "../../components/ControlArea";

export default {
  name: 'ArticleAdmin',
  setup(){
    const route = useRoute();
    console.log("route----", route);
    const { keyword } = route.query;
    const { id } = route.params;

    const { control, setControlSimple } = useTemples(id);
    setControlSimple(id, route.name)
    const { hasTempleAuth } = inject('auth');
    const hasAuth = computed(()=>{
      return hasTempleAuth(id)
    })

    const targetObj = computed(()=>{
      const object = {
        target: route.query.target
      };
      switch (route.query.target){
        case "project":
          object.label = "プロジェクト"
          object.type = 1
          object.body = "「お寺のソーシャルプロジェクト」の理念を示すのがプロジェクト記事です。\n" +
              "プロジェクトを始めたきっかけや、目指していること、活動の様子などを書きましょう。\n"
          object.link = "/about#social-project"
          object.linkText = "お寺のソーシャルプロジェクトとは？"
          break;
        case "event":
          object.label = "イベント"
          object.type = 2
          object.body = "参加者募集をする記事が、イベント記事です。\n" +
              "プロジェクトの記事とグルーピングができるので、理念はプロジェクトページに書き込み\n" +
              "イベントページでは具体的な参加募集要項を記載するという使い方ができます。"
          break;
        case "report":
          object.label = "レポート"
          object.type = 3
          object.body = "実施しているプロジェクトやイベントの途中経過や結果報告を書くのがレポート記事です。\n" +
              "レポート記事が充実するとお寺のフォロワーが増えることに繋がります。"
          break;
        default:
          object.label = ""
          object.type = [1,2,3]
          break;
      }

      return object
    })

    const conditions = ref({
      keyword: keyword ? keyword : null,
      templeId: id,
      editable: true
    });

   const editMode = ref(false);
    const limit = ref(12);

    const changeMode = () => {
      editMode.value = !editMode.value
    }
    watch(
        () => route.query,
        (to, from) => {
          if(to !== from){
            const { keyword } = route.query;
            const { id } = route.params;
            conditions.value = {
              keyword: keyword ? keyword : null,
              templeId: id,
              editable: true
            }
          }
        }
    );

    return {targetObj, conditions, limit, hasAuth, nl2br, control, editMode, changeMode, route, id}
  },
  components: {
    FlowList,
    ControlArea
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.heading {
	padding-bottom: 0;
	text-align: center;
	.link {
		font-size: 1.8rem;
		letter-spacing: .08rem;
		line-height: 1.8;
		margin: 5rem 0 0;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
.lead {
	font-size: 1.8rem;
	letter-spacing: .08rem;
	line-height: 1.8;
	margin: 5rem 0 0;
  @include sp {
    font-size: 1.4rem;
    letter-spacing: .04rem;
    line-height: 1.8;
    padding: 0 1.5rem;
    margin: 2.5rem 0 0;
  }
}
.p-action_block {
	background-image: initial;
	padding: 4rem;
}
.p-action_item.red {
	background-color: #b24b5b;
	border: none;
	color: #fff;
}
.main-img {
	height: 97rem;
	padding-top: 12rem;
	position: relative;
}
.base-point {
	height: 0;
	margin: auto;
	position: relative;
	width: 0;
}
.title {
	left: 8.5rem;
	max-width: initial;
	position: absolute;
	top: 5rem;
	width: 42rem;
	z-index: 99;
}
.copy {
	font-family: "iroha-23kaede-stdn", Avenir, Helvetica, Arial, sans-serif;
	font-size: 2.8rem;
	left: 24rem;
	letter-spacing: 1.1rem;
	line-height: 5rem;
	position: absolute;
	top: 33rem;
	width: 39rem;
	z-index: 30;
}
.bg {
	max-width: initial;
	position: absolute;
	z-index: 1;
}
.bg-shippo-l {
	right: 8rem;
	top: 3rem;
	width: 18.4rem;
}
.bg-hishigata01 {
	left: 17rem;
	top: 1rem;
	width: 4rem;
}
.bg-hishigata02 {
	right: 16rem;
	top: 67rem;
	width: 4rem;
}
.bg-shippo-s01 {
	right: 18rem;
	top: 74rem;
	width: 3.9rem;
}
.bg-shippo-s02 {
	left: 58rem;
	top: 41rem;
}
.bg-uzu {
	background-image: url("~@/assets/image/top/bg-uzu.svg");
	background-position: 50% 100%;
	background-repeat: no-repeat;
	content: "";
	height: 11rem;
	left: 31rem;
	top: 0;
	width: 24rem;
}
.bg-nami01 {
	left: 18rem;
	top: 58rem;
	width: 11rem;
}
.bg-icho-left {
	left: 35rem;
	top: 46rem;
	width: 6rem;
}
.bg-soroban01 {
	left: 70rem;
	top: 24rem;
	width: 10.7rem;
}
.bg-icho-right {
	right: 33rem;
	top: 16rem;
	width: 6rem;
}
.bg-hamon {
	right: 29rem;
	top: 21rem;
	width: 18.4rem;
}
.bg-nami02 {
	right: 46rem;
	top: 34rem;
	width: 11rem;
}
.bg-soroban02 {
	right: 66rem;
	top: 58rem;
	width: 10.7rem;
}
.center-img {
	left: 48%;
	position: absolute;
	top: 51%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index: 20;
}
.center-img img {
	border-radius: 35% 0 35% 0;
	height: 47.6rem;
	object-fit: cover;
	width: 47.6rem;
}
.clip {
	max-width: initial;
	position: absolute;
}
.clip img {
	border-radius: 35% 0 35% 0;
	object-fit: cover;
}
.clip-right01 {
	left: 55rem;
	top: 10rem;
}
.clip-right01, .clip-right01 img {
	height: 15.3rem;
	width: 15.3rem;
}
.clip-right02 {
	left: 41rem;
	top: 49rem;
}
.clip-right02, .clip-right02 img {
	height: 24.5rem;
	width: 24.5rem;
}
.clip-left01 {
	right: 40rem;
	top: 3rem;
}
.clip-left01, .clip-left01 img {
	height: 15.3rem;
	width: 15.3rem;
}
.clip-left02 {
	right: 66rem;
	top: 14rem;
}
.clip-left02, .clip-left02 img {
	height: 22.1rem;
	width: 22.1rem;
}
.clip-left03 {
	right: 34rem;
	top: 49rem;
}
.clip-left03, .clip-left03 img {
	height: 29.7rem;
	width: 29.7rem;
}
.social {
	align-items: center;
	display: flex;
	flex-direction: column;
	left: 2.5rem;
	position: fixed;
	top: 42rem;
}
.social > p {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.3rem;
	letter-spacing: .5rem;
	margin-bottom: 3rem;
	writing-mode: vertical-rl;
}
.social > div {
	display: flex;
	flex-direction: column;
}
.social > div a {
	margin-bottom: 2rem;
}
.scroll {
	align-items: center;
	bottom: -12%;
	display: flex;
	flex-direction: column;
	left: 49%;
	position: absolute;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index: 20;
}
.scroll p {
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
	font-size: 1.3rem;
	letter-spacing: .5rem;
	margin-bottom: 1.5rem;
}
.tag01, .tag02, .tag03, .tag04 {
	position: relative;
}
.tag01 {
	bottom: 80px;
	left: 370px;
}
.tag02 {
	bottom: 10rem;
	left: 20rem;
}
.tag03 {
	bottom: 7rem;
	left: 18rem;
}
.tag04 {
	bottom: 10rem;
	left: 23rem;
}
.wrapper {
	padding-top: 0;
}
.main-title {
	font-family: $contents_fonts_jp_iroha;
	font-size: 4.3rem;
	@include sp {
		font-size: 2.4rem;
		padding-top: 5.2rem;
	}
	letter-spacing: 1.2rem;
	margin-bottom: 5.2rem;
	padding-top: 12rem;
	text-align: center;
}
</style>

<template>
  <div>
    <vue-flatpickr
        v-model="date"
      :config="config"
    ></vue-flatpickr>
    <span v-if="errorState">
      <div
        v-for="(key, index) in Object.keys(errorState)"
        :key="index"
        class="err-message"
      >
        {{ errorState[key] }}
      </div>
    </span>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import moment from "moment";
import 'flatpickr/dist/flatpickr.css';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import {ref, watch} from 'vue';
export default {
  name: 'FlatPickr',
  props: {
    modelValue: {
      type: [String, Array] ,
      required: true,
    },
    configEnableTime: {
      type: Boolean,
      required: true,
    },
    configNoCalendar: {
      type: Boolean,
      required: true,
    },
    configMaxDate: {
      type: String,
      required: false,
    },
    configMinDate: {
      type: String,
      required: false,
    },
    errorState: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const date = ref(null);

    const onChange = (selectedDates, dateStr, instance) => {
      console.log('FlatPickr-selectedDates', selectedDates);
      console.log('FlatPickr-dateStr', dateStr);
      console.log('FlatPickr-instance', instance);
      emit('update:modelValue', moment(selectedDates[0]).format('YYYY-MM-DD HH:mm:ss'));
    };

    const onReady = (dObj, dStr, fp, dayElem) => {
      console.log('FlatPickr-dObj', dObj);
      console.log('FlatPickr-dStr', dStr);
      console.log('FlatPickr-fp', fp);
      console.log('FlatPickr-dayElem', dayElem);
      emit('update:modelValue', moment(dObj[0]).format('YYYY-MM-DD HH:mm:ss'));
    }
    const createInitialConfig = () => {
      const initialConfig = {};
      initialConfig.defaultDate = props.modelValue;
      initialConfig.iti = props.configMaxDate;

      if (props.configEnableTime) {
        //カレンダー＆時刻
        initialConfig.locale = Japanese
        initialConfig.dateFormat = 'Y/m/d H:i'
        initialConfig.enableTime = true;
      } else {
        //カレンダーのみ
        initialConfig.locale = Japanese
        initialConfig.dateFormat = 'Y/m/d'
        initialConfig.enableTime = false;
      }

      if (props.configNoCalendar) {
        //時刻のみ
        const defaultDate = moment(props.modelValue).format("HH:mm")
        delete initialConfig.locale
        initialConfig.dateFormat = 'H:i'
        initialConfig.defaultDate = defaultDate
        initialConfig.noCalendar = true;
        initialConfig.enableTime = true;
        initialConfig.time_24hr = true;
      }

      if (props.configMaxDate) {
        //終了日の設定あり
        initialConfig.maxDate = props.configMaxDate;
      }

      if (props.configMinDate) {
        //開始日の設定あり
        initialConfig.minDate = props.configMinDate;
      }
      initialConfig.onChange = onChange
      initialConfig.onReady = onReady

      return initialConfig
    }

    const config = ref(createInitialConfig())

    watch(()=>[props.configMaxDate, props.configMinDate], () => {
      const initialConfig = createInitialConfig()
      initialConfig.onChange = onChange
      config.value = initialConfig
    });

    // const onChange = (event) => {
    //       console.log('FlatPickr-selected-event', event);

    //     };

    return {
      onChange,
      config,
      date,
      // defaultDate,
    };
  },
  components: {
    'vue-flatpickr': flatPickr,
  },
};
</script>

<style>
.vue__time-picker {
	vertical-align: initial;
	width: 6em;
}

.vue__time-picker input.vue__time-picker-input {
	border: 1px dotted rgb(51, 51, 51);
	width: 6em;
}

.vue__time-picker .dropdown {
	width: 6em;
}

.vue__time-picker .dropdown .select-list {
	width: 6em;
}

.flatpickr-input {
	border: 1px dotted rgb(51, 51, 51);
	padding: 8px;
}
</style>

<template>
  <div class="tag-input">
    <ul class="tags" v-if="tags.length > 0">
      <li v-for="(tag, index) in tags" :key="tag.id">
        <div class="p-tag__item" :class="key === index ? `err` : ``">
          <router-link :to="`/search/?tag=${tag.name}`">
            #{{ tag.name }}
          </router-link>
          <button v-if="editMode" class="delete" @click="removeTag(index)">
            x
          </button>
        </div>
      </li>
    </ul>
    <template v-if="errorState">
      <div v-for="(key, keyIndex) in Object.keys(errorState)" :key="keyIndex">
      <span class="err-message"
        >{{ outputErrMessage(errorState[key].name) }}</span
      >
    </div>
    </template>


    <span v-if="editMode" class="add-btn" @click="addTag(newTag)"
      >タグを追加</span
    >
    <input
      v-if="editMode"
      id="input"
      v-model="newTag"
      type="text"
      :list="id"
      autocomplete="off"
      @input="addTagIfDelem(newTag)"
      placeholder="タグを入力してください"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { outputErrMessage } from '@/utils/validation';
export default {
  name: 'TagInput',
  props: {
    name: { type: String, default: '' },
    modelValue: { type: Array, default: () => [] },
    tagTextColor: { type: String, default: 'white' },
    tagBgColor: { type: String, default: 'rgb(250, 104, 104)' },
    tagClass: { type: String, default: '' },
    editMode: { type: Boolean },
    customDelimiter: {
      type: [String, Array],
      default: () => [],
      validator: (val) => {
        if (typeof val == 'string') return val.length == 1;
        for (let i = 0; i < val.length; i++) {
          if (typeof val[i] != 'string' || val[i].length != 1) return false;
        }
        return true;
      },
    },
    errorState: {
      type: [String, Number, Object],
      required: false,
    },
  },
  setup(props, { emit }) {
    // Tags
    // console.log('props.modelValue', props.modelValue);
    let tagInputShow = ref(false);
    const tags = ref(props.modelValue);
    const tagsClass = ref(props.tagClass);
    const newTag = ref('');
		let updateFlg = ref(false);
    const id = Math.random()
      .toString(36)
      .substring(7);
    const customDelimiter = [
      ...new Set(
        (typeof props.customDelimiter == 'string'
          ? [props.customDelimiter]
          : props.customDelimiter
        ).filter((it) => it.length == 1)
      ),
    ];
    // handling duplicates
    const duplicate = ref(null);
    const handleDuplicate = (tag) => {
      duplicate.value = tag;
      setTimeout(() => (duplicate.value = null), 1000);
      newTag.value = '';
    };
    // const noMatchingTag = ref(false);
    // function handleNoMatchingTag() {
    //   noMatchingTag.value = true;
    //   setTimeout(() => (noMatchingTag.value = false), 500);
    //   let v = newTag.value;
    //   if (customDelimiter.includes(v.charAt(v.length - 1)))
    //     newTag.value = v.substr(0, v.length - 1);
    // }

    const addTag = (tag) => {
      tag = tag.trim();

      if (!tag) return; // prevent empty tag
      // only allow predefined tags when allowCustom is false
      // if (!props.allowCustom && !props.options.includes(tag)) {
      //   //   display not a valid tag
      //   handleNoMatchingTag();
      //   return;
      // }
      // return early if duplicate
      if (tags.value.includes(tag)) {
        handleDuplicate(tag);
        return;
      }
      // console.log('tags.value.length', tags.value.length);
      emit('update:modelValue', tags.value);
      tags.value.push({ name: tag });
      newTag.value = ''; // reset newTag
    };

    const addTagIfDelem = (tag) => {
      if (!customDelimiter || customDelimiter.length == 0) return;
      if (customDelimiter.includes(tag.charAt(tag.length - 1)))
        addTag(tag.substr(0, tag.length - 1));
    };

    const removeTag = (index) => {
      tags.value.splice(index, 1);
			updateFlg.value = true;
    };

    return {
      tags,
      tagsClass,
      newTag,
      addTag,
      addTagIfDelem,
      removeTag,
      id,
      duplicate,
      // noMatchingTag,
      tagInputShow,
      outputErrMessage,
			updateFlg,
    };
  },
  methods: {},
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.tag-input {
  padding: 8px;
  position: relative;
}
ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  list-style: none;
  margin: 0;
  padding: 0;
  --tagBgColor: rgb(250, 104, 104);
  --tagTextColor: white;
}

.delete {
  background-color: #333;
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  outline: none;
  position: absolute;
  right: -6px;
  text-align: center;
  top: -4px;
  width: 20px;
}
.tag.duplicate {
  animation: shake 1s;
  background: rgb(235, 27, 27);
}
.duplicate input {
  animation: shake1 0.5s;
  border: 1px solid rgb(235, 27, 27);
  outline: rgb(235, 27, 27);
}
input {
  border: 1px dotted rgb(51, 51, 51);
  outline: none;
  padding: 15px;
}
.count {
  display: block;
  font-size: 0.8rem;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}
.count span {
  background: #eee;
  border-radius: 2px;
  padding: 2px;
}
.with-count input {
  padding-right: 60px;
}
.with-count ul {
  max-width: 60%;
}
.err {
  color: #c84d5f;
}
@keyframes shake {
  10%,
  90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
@keyframes shake1 {
  10%,
  90% {
    transform: scale(0.99) translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: scale(0.98) translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: scale(1) translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: scale(0.98) translate3d(4px, 0, 0);
  }
}
</style>

<template>
  <div class="planning-proposal">
    <section class="mv">
      <img
        class="mv__image mv__image-01 u-use--pc"
        src="@/assets/image/about/mv05.png"
        alt=""
      />
      <div class="mv__title--wrap">
        <h1>
          <span class="mv__title--sub">まち寺プラットフォームの</span
          ><span class="mv__title--main">寺院会員になる</span>
        </h1>
        <span class="mv__title--btn u-use--pc">寺院会員向け</span>
      </div>
      <img
        class="mv__image-02 u-use--pc"
        src="@/assets/image/about/mv04.png"
        alt=""
      />
      <div class="mv__sp u-use--sp">
        <img
          class="mv__image mv__image-01 u-use--sp"
          src="@/assets/image/about/mv05.png"
          alt=""
        />
        <button class="mv__title--btn u-use--sp">寺院会員向け</button>
        <img
          class="mv__image-02 u-use--sp"
          src="@/assets/image/about/mv04.png"
          alt=""
        />
      </div>
    </section>
    <section class="project">
      <h2 class="project__title title">
        まちも元気、お寺も元気<br />お寺のソーシャルプロジェクトをはじめよう
      </h2>
      <p class="project__text">
        職場や学校でもなく、家庭でもない、誰もが集える「サードプレイス」としてのお寺は、<br
          class="u-use--pc"
        />「まちづくりの拠点」として、たくさんの方々に求められています。
      </p>
      <p class="project__text">
        心と体を調える場・学びの場・つながりの場・イノベーションの場として、<br
          class="u-use--pc"
        />
        数々のお寺のソーシャルプロジェクトが人々に笑顔を提供しています。
      </p>
      <p class="project__text">
        この「まち」をより良くしたいと願う地域の人々は、お寺を改めて必要としているのです。
      </p>
      <img
        class="project__image u-use--pc"
        src="@/assets/image/about/project.png"
        alt=""
      />
      <img
        class="project__image u-use--sp"
        src="@/assets/image/about/sp_project.png"
        alt=""
      />
      <p class="project__text">
        お寺に人々が集うことで、ひとも、まちも、お寺も、共に魅力を高めてゆけるはずです。
      </p>
      <p class="project__text">
        まち寺プラットフォームには、既に「お寺のソーシャルプロジェクト」に取り組む僧侶の仲間、お寺の仲間がいます。<br
          class="u-use--pc"
        />苦悩や成果を語り合いながら、ご一緒に活動をはじめませんか？
      </p>
    </section>
    <section class="about__social-project">
      <div class="about__social-project--inner">
        <h3 class="about__social-project--title">
          お寺のソーシャルプロジェクト例
        </h3>
        <div class="about__social-project--wrap">
          <ul class="about__social-project--list">
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">ココロとカラダの健康</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=ヘルシーテンプル" class=""
                    >#ヘルシーテンプル</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=寺ヨガ" class=""
                    >#寺ヨガ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=人生会議" class=""
                    >#人生会議</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">学びの場づくり</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=伝統を学ぶ" class=""
                    >#伝統を学ぶ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=書を学ぶ" class=""
                    >#書を学ぶ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=まちのお寺の学校" class=""
                    >#まちのお寺の学校</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">ほっとする場づくり</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=寺カフェ" class=""
                    >#寺カフェ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=お寺ベンチ" class=""
                    >#お寺ベンチ</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">まちの支え合い</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=こども食堂" class=""
                    >#こども食堂</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=フードパントリー" class=""
                    >#フードパントリー</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=コミュニティカフェ" class=""
                    >#コミュニティカフェ</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">まちの魅力化</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=地域イベント" class=""
                    >#地域イベント</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=民泊・宿坊・寺泊" class=""
                    >#民泊・宿坊・寺泊</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=縁結び" class=""
                    >#縁結び</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">防災</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=防災キャンプ" class=""
                    >#防災キャンプ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=お寺の防災基地計画" class=""
                    >#お寺の防災基地計画</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="l-wrapper p-action_block long">
        <div class="p-action_item long">
          <router-link
            :to="{
              name: 'About',
              hash: '#social-project',
            }"
            class="p-action_link"
          >
            <span class="p-action_link-text"
              >お寺のソーシャルプロジェクトについて詳しく知る</span
            >
          </router-link>
        </div>
      </div>
    </section>
    <section class="arrange">
      <h2 class="arrange__title title long">
        まち寺プラットフォームの使い方
      </h2>
      <ul class="arrange__list">
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;1&nbsp;</span>
              <span class="arrange__title-text">寺院会員として登録する</span>
            </dt>
            <dd class="arrange__body">
              トップページのヘッダー（スマホの場合は画面下部）にある「登録ログイン」ボタンから会員登録を行い、<br />寺院会員申請を行ってください。（事務局による簡易審査があります）
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;2&nbsp;</span>
              <span class="arrange__title-text">寺院ページを作成する</span>
            </dt>
            <dd class="arrange__body">
              「まち寺」の寺院ページ機能を使って、自坊の紹介ページを作りましょう。目指しているお寺像や、ご担当の方のプロフィールをお書きください。<br />
              サブドメインの設定もでき、「まち寺」外でも利用できるので、ホームページとして利用も可能です。
              <br />
              <div class="arrange__body--tit second">
                作成手順
							</div>
                ①ログイン後、ヘッダー（スマホの場合は画面下部）の「お寺マーク」アイコンを選択<br />
                ②「寺院会員メニュー」から「編集モード」を選択<br />
                ③「編集モード」から以下のページ作成が可能です。
              <span class="arrange__body-text">
                「基本情報」（寺院ページのトップページ）<br />
                「プロジェクト」（活動の理念を示すページ）<br />
                「イベント」（参加募集をするページ）<br />
                「レポート」（事後報告）の編集も可能です。
              </span>
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;3&nbsp;</span>
              <span class="arrange__title-text"
                >お寺のソーシャルプロジェクトを企画する</span
              >
            </dt>
            <dd class="arrange__body">
              貴寺院の付近で活動する「まち寺パートナー」（各種専門家・講師・団体・学校・企業などまちづくりに関わる人）から、新たな企画提案があるかもしれません。提案内容は寺院編集メニューの「お寺への提案」から確認できます。興味をひく提案を見つけたら気軽にメーッセージし相談してみましょう。できる限り面会してプロジェクトの内容をよく確認してください。
              <span class="arrange__body-text">
                また、活動の中で「まち寺パートナー」にふさわしい方と出会ったら、まち寺への登録をおすすめください。共に「お寺のソーシャルプロジェクト」を盛り上げる仲間を作っていきましょう。
              </span>
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;4&nbsp;</span>
              <span class="arrange__title-text long"
                >寺院ページにプロジェクト/イベント ページを作成する</span
              >
            </dt>
            <dd class="arrange__body">
              <div class="arrange__body--tit">
                提案されたソーシャルプロジェクトに協力する場合
              </div>
              <div>
                まち寺にログインして、「お寺への提案」情報を閲覧し内容や目的を吟味します。興味を持ったプロジェクトがあれば、メッセージ機能でまち寺パートナー（専門家、講師、まちづくりに関わる人など）とやりとりしながら、プロジェクトページを作成します。<br>まち寺パートナーに共同編集権限をお渡しして、一緒にページを管理することが可能です。
              </div>
              <div class="arrange__body--tit second">
                自坊のソーシャルプロジェクトを発信する場合
              </div>
              <div>
              寺院ページにログインして、寺院会員メニューからプロジェクトページを新規作成することが可能です。
              </div>
              <div class="arrange__body--tit second">
                イベントを開催する場合
              </div>
              <div>
               イベントや講座を開催する場合は「イベントページ」を作成して参加者を募ります。オンラインイベント（無料開催の場合）にも対応しています。
              </div>
            </dd>
          </dl>
        </li>

        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;5&nbsp;</span>
              <span class="arrange__title-text">レポートを書く</span>
            </dt>
            <dd class="arrange__body">
            イベントを実施したらできる限りレポートを書きましょう。<br>しっかりしたレポートを書くことで、継続参加の意欲や未参加者の興味を湧き立てることができます。<br>まち寺パートナーに共同編集権限を渡すことも可能です。
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;6&nbsp;</span>
              <span class="arrange__title-text">支援へのお礼をする</span>
            </dt>
            <dd class="arrange__body">
              プロジェクトに何らかの形で協力してくれた方には必ずお礼のメッセージを送りましょう。<br>イベント参加者には、まち寺プラットフォームからメール送信することが可能です。
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;7&nbsp;</span>
              <span class="arrange__title-text">寄付を募る</span>
            </dt>
            <dd class="arrange__body">
              まち寺には寄付を募る機能があります（クレジット決済）寄付機能を寺院ページに導入したい場合は、事務局までお問い合わせください。<br>※寄付機能は2022年春に実装予定です
            </dd>
          </dl>
        </li>
      </ul>
    </section>
    <section class="feature">
      <h2 class="feature__title title">
        無料で利用できる<br class="u-use--sp" />寺院会員向け機能
      </h2>
      <ul class="feature__list">
        <li class="feature__item">
          <div class="feature__tit--box">
            <span class="feature__num">01</span>
            <h3 class="feature__tit">お寺の魅力を発信できる<br class="u-use--pc">寺院ページ<span class="feature__tit--add">（ホームページとして利用可）</span></h3>

          </div>
          <div class="feature__contents">
            <img
              class="feature__image u-use--pc"
              :src="image01"
              alt=""
            />
            <img
              class="feature__image u-use--sp"
              :src="image01"
              alt=""
            />
            <dl class="feature__second">
              <dt class="feature__second-tit">利用のメリット</dt>
              <dd>
                <div class="feature__second-list">
                  お寺のソーシャルプロジェクトが集積した「まち寺」からの情報発信でメディアなどからの注目度がUP
                </div>
                <div class="feature__second-list">
                  直感的な使用感で更新作業がカンタン
                </div>
                <div class="feature__second-list">
                  イベントの予約機能が使える。オンラインイベントにも対応（無料の場合）
                </div>
                <div class="feature__second-list">
                  サブドメイン設定が可能 （※※※ji.machitera.jpが使えます）
                </div>
                <div class="feature__second-tit second">オプション機能</div>
                <div class="feature__second-list">
                  ソーシャルプロジェクトへの寄付を募集できます
                </div>
              </dd>
            </dl>
          </div>
        </li>
        <li class="feature__item second">
          <div class="feature__tit--box">
            <span class="feature__num">02</span>
            <h3 class="feature__tit">参加者やフォロワーを統合管理できるデータベース機能</h3>
          </div>
          <div class="feature__contents">
            <img
              class="feature__image u-use--pc"
              :src="image02"
              alt=""
            />
            <img
              class="feature__image u-use--sp"
              :src="image02"
              alt=""
            />
            <dl class="feature__second">
              <dt class="feature__second-tit">利用のメリット</dt>
              <dd>
                <div class="feature__second-list">
                  複数のイベントの参加者情報を統合して一元管理が可能
                </div>
                <div class="feature__second-list">
                  参加者ごとに過去のイベント参加状況を確認したり、備考メモを付加することが可能
                </div>
                <div class="feature__second-list">
                  一般会員がお気に入り寺院をフォローすることができるので、関心層の可視化が可能
                </div>
                <div class="feature__second-list">
                  予約なしの当日参加の方もリストに手入力で追加可能
                </div>
                <div class="feature__second-list">
                  メルマガ配信が可能なので、手軽にメール版寺報が配信可能
                </div>
                <div class="feature__second-list">
                  まち寺パートナーと出会えるマッチング機能
                </div>
              </dd>
            </dl>
          </div>
        </li>
        <li class="feature__item large">
          <div class="feature__tit--box">
            <span class="feature__num">03</span>
            <h3 class="feature__tit">
              まち寺パートナーと寺院会員のマッチング機能
            </h3>
          </div>
          <div class="feature__image">
            <div class="system__temple--item-wrap left">
              <img
                class="system__temple--image"
                src="@/assets/image/about/how_to_use03_img.png"
                alt=""
              />
              <div class="system__temple--item temple-text">
                まち寺パートナー<br /><span class="temple-text-small"
                  >（専門家・講師・団体・学校・企業など）</span
                >
              </div>
            </div>
            <ul class="feature__image--list">
              <li class="feature__image--item poject">
                <h4 class="feature__image--title">
                  <span class="feature__image--title-long"
                    >まち寺パートナー</span
                  ><br />登録データ
                </h4>
                <ul class="feature__data--list">
                  <li class="feature__data--item">提案企画の内容</li>
                  <li class="feature__data--item">プロフィール</li>
                  <li class="feature__data--item">過去の活動実績</li>
                  <li class="feature__data--item">対応可能エリア</li>
                </ul>
                <div class="feature__data--point">登録</div>
              </li>
              <li class="feature__image--item temple">
                <h4 class="feature__image--title">寺院<br />登録データ</h4>
                <ul class="feature__data--list">
                  <li class="feature__data--item">寺院の基本情報</li>
                  <li class="feature__data--item">僧侶プロフィール</li>
                  <li class="feature__data--item">目指すお寺像</li>
                  <li class="feature__data--item">過去の活動実績</li>
                </ul>
                <div class="feature__data--point">登録</div>
              </li>
            </ul>
            <div class="system__temple--item-wrap right">
              <img
                class="system__temple--image"
                src="@/assets/image/about/temple.png"
                alt=""
              />
              <div class="temple-text">寺院会員</div>
            </div>
            <div class="feature__database">まち寺総合<br />データベース</div>
            <div class="feature__second-tit">
              利用のメリット
            </div>
            <ul>
              <li class="feature__second-list">
                ショーシャルプロジェクトに知見のある各種専門家、講師、NPO団体、企業など「まち寺パートナー」から様々な提案を受けることが出来ます
              </li>
            </ul>
          </div>
          <div class="l-wrapper p-action_block" data-v-3baee648="">
            <div class="p-action_item red" data-v-3baee648="">
              <a class="p-action_link" href="/users/login" data-v-3baee648="">
                <span class="p-action_link-text templ" data-v-3baee648=""
                  >寺院会員申請はこちら</span
                >
              </a>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <section class="voice">
      <h2 class="voice__title title">寺院会員の声</h2>
      <ul class="voice__list">
        <li class="voice__item">
          <div class="voice__info">
            <img
              class="voice__image"
              src="@/assets/image/about/jyusyoku_sasaki.png"
              alt=""
            />
            <h3 class="voice__sub-title">
              千葉勝浦 妙海寺<br />住職 佐々木教道
            </h3>
            <div class="voice__en">Priesthood Kyodo Sasaki</div>
            <p class="voice__text">
              千葉県勝浦市にある正榮山妙海寺と同時に、仏教の教えをもっとわかりやすく伝えたいと音楽活動を開始。「人づくり、まちづくり」をお寺の使命として活躍している。<br
                class="u-use--pc"
              />フルアルバム
              「しんが万象」「波歌綴り」、マキシシングル「境界線」等
            </p>
            <div class="voice__link--wrap">
              <router-link to=""
                ><span class="voice__link--mark">＠</span
                >妙海寺寺院ページ</router-link
              >
            </div>
          </div>
          <div class="voice__contents">
            <h3 class="voice__sub-title">少子高齢化の進む港町にて</h3>
            <p class="voice__contents--text">
              勝浦市は人口が1万9000人の小さな小さな港町です。最も人口の多い時期と比べると3分の２になってしまいました。主要産業は漁業ですが、漁師の高齢化も進んで平均年齢70歳を超えるイメージです。妙海寺の付近でも、独居の方が増えています。その中で、お寺はどんな役割を果たしていくべきかを地域の方と相談しながら活動してきました。
            </p>
            <button class="u-use--sp voice__accordion" @click="active('0')">
              <span class="voice__accordion--text" v-if="isActive[0]"
                >Close</span
              >
              <span class="voice__accordion--text" v-else>Open</span>
            </button>
            <div class="voice__accordion--contents" v-if="isActive[0]">
              <p class="voice__contents--text">
                2011年に心と体を調えることを目的に定期開催を始めた「寺ヨガ」を筆頭に、地域の魅力を住民自身が再発見する地域文化祭「寺市」、独居の方も含めて地域住民が集って楽しい食事の時間を持つ「お寺でランチ」、医師から生活習慣の改善アドバイスを受ける「メディカルカフェ」など、妙海寺の寺子屋活動を介して得た人のご縁ががさらなるご縁を産み出すことでイベント・講座が途絶えることなく定期的に開催されてきました。お寺の中で、笑顔を振りまく人が圧倒的に増えたことはとてもありがたいことです。
              </p>
              <h3 class="voice__sub-title second">
                寺子屋活動でまちを元気にしたい！
              </h3>
              <p class="voice__contents--text">
                まち寺のマッチング機能や会員管理機能を利用しながら、妙海寺の寺子屋活動に会員制度を作り、地域の皆さんが妙海寺を舞台により楽しくより有意義な時間を過ごせるよう工夫をしようと考えています。
              </p>
              <p class="voice__contents--text">
                そのために、自坊のホームページとして寺院ページを作成しました。まち寺プラットフォームに訪れる人たちは多様ですが、特にお寺で行われるイベントに参加したいという方や、お寺が行なう社会的活動に協力したいという方が多いと感じています。そういった方々が寺院ページを見てくれて、イベントに参加したりプロジェクトに協力してくださるので、寺院活動がとても楽しくなっています。寺院ページはどなたでも無料で作れるので、おすすめです。
              </p>
              <p class="voice__contents--text">
                勝浦のような人口減少が進む町では、すべての人を活かし、どんな地域資源でも活かして元気なまちをつくっていくことが何よりも大切です。お寺だけが元気になることなどありえません。これからも、寺子屋活動を起点に様々な、街を元気にする活動を行っていきたいと考えています。
              </p>
            </div>
          </div>
        </li>
        <li class="voice__item second">
          <div class="voice__info">
            <img
              class="voice__image"
              src="@/assets/image/about/jyusyoku_urakami.png"
              alt=""
            />
            <h3 class="voice__sub-title">
              横浜倶生山なごみ庵<br />住職 浦上哲也
            </h3>
            <div class="voice__en">Priesthood Tetsuya Urakami</div>
            <p class="voice__text">
              一般家庭に生まれ、縁あって僧侶となる。<br />
              「自分らしい方法で仏教をひろめたい」と発願し、平成18年に浄土真宗
              俱生山なごみ庵を開所。山号の「俱生山」には、「俱（とも）にこの世を生き、俱に浄土に生まれる」という願いが込められている。
            </p>
            <div class="voice__link--wrap">
              <router-link to=""
                ><span class="voice__link--mark">＠</span
                >なごみ庵寺院ページ</router-link
              >
            </div>
          </div>
          <div class="voice__contents">
            <h3 class="voice__sub-title">あたらしいお寺のチャレンジ</h3>
            <p class="voice__contents--text">
              私は、お寺をあたらしく開きました。<br />
              お寺って、何百年も前からその土地にあるもの。そんなイメージがあると思いますが、どんなに歴史のあるお寺にも１年目がありました。現代では珍しいかもしれませんが、そんな活動をしています。
            </p>
            <button class="u-use--sp voice__accordion" @click="active('1')">
              <span class="voice__accordion--text" v-if="isActive[1]"
                >Close</span
              >
              <span class="voice__accordion--text" v-else>Open</span>
            </button>
            <div class="voice__accordion--contents" v-if="isActive[1]">
              <p class="voice__contents--text">
                立派な本堂も広い境内も無い、檀家さんもお墓も無い、まったくのゼロからのスタート。民家を改装しただけのちいさなお寺で待っていても、誰もやってはきません。だから、とにかく色々なことにチャレンジしています。<br />
                主に外部での活動としては、舞台役者の妻と仏教演劇の公演で全国を巡り、また元は医療系のワークショップである「死の体験旅行」で多くの方に受けて頂いています。
              </p>
              <h3 class="voice__sub-title second">
                はじめての出会いは「まち寺」から
              </h3>
              <p class="voice__contents--text">
                そして本拠地であるなごみ庵では、毎週金曜日に何かしよう！
                とにかく人に興味を持って来て頂こう！
                そう考えて徐々に内容を増やしていきました。<br />
                第１金曜は呼吸法・発声法、第２金曜は法話会、第３金曜は写経会、第４金曜は笑いヨガ。この情報を広げるため、寺報やブログ、Facebookなど色々やっていますが、効果的だったのは「まち寺」でした。
              </p>
              <p class="voice__contents--text">
                もともとお檀家さんがいないので、なごみ庵の行事はどれも「誰でも参加ＯＫ」です。敷居の低さもあってか、どの行事も毎回のように問い合わせがあったり、新しい参加者がやってきたりします。<br />
                その方たちに「何をご覧になったんですか？」と尋ねると、「まち寺を見ました」という方がとても多くいらっしゃいます。<br />
                新しい出逢いをたくさん作ってくれる「まち寺」に感謝です。
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div class="l-wrapper p-action_block" data-v-3baee648="">
        <div class="p-action_item red" data-v-3baee648="">
          <a class="p-action_link" href="/users/login" data-v-3baee648="">
            <span class="p-action_link-text templ" data-v-3baee648=""
              >寺院会員申請はこちら</span
            >
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
export default {
  name: 'PlanningProposal',
  setup() {
    let isActive = ref([false, false]);
    // windowのwidth
    const windowWidth = ref(0);

    const active = (num) => {
      isActive.value[num] = !isActive.value[num];
    };

    // windowのwidthでモバイル/PC切替
    const calculateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
      if (!(windowWidth.value < 768)) {
        isActive.value[0] = true;
        isActive.value[1] = true;
      }
    };

    calculateWindowWidth();
    // resizeでウィンドウサイズ変更を検知
    onMounted(() => {
      console.log('onMounted!!isActive');
      window.addEventListener('resize', calculateWindowWidth);
    });

		const image01 = ref(require('@/assets/image/about/image1.png'));
		const image02 = ref(require('@/assets/image/about/image2.png'));


    return {
      active,
      isActive,
			image01,
			image02
    };
  },
};
</script>

<!-- Add "sdcope" attribute to limit CSS to this component only -->
<style lang="scss">
.title {
	color: #6877ac;
	font-size: 5rem;
	@include fz_vw(50);
	@include font_iroha_600;
	@include sp {
		font-size: 2.3rem;
		letter-spacing: 1px;
		margin-right: -4px;
	}
	letter-spacing: 10px;
	line-height: 1.6;
	margin-right: -10px;
	text-align: center;
	&.long {
		@include sp {
			letter-spacing: -2px;
		}
	}
}

.mv {
	.planning-proposal & {
		display: flex;
		@include sp {
			display: block;
			// padding-top: 77px;
			padding-top: 120px;
			width: 100vw;
		}
		justify-content: center;
		padding: 210px 25px 0;
	}

	&__image {
		&-01 {
			height: 13.777vw;
			@include sp {
				display: block;
				height: 18.637vw;
				object-fit: contain;
				width: 22.8vw;
			}
			width: 11.11vw;
		}

		&-02 {
			height: 12.222vw;
			@include sp {
				display: block;
				height: 18.637vw;
				object-fit: contain;
				width: 18.8vw;
			}
			width: 10.555vw;
		}
	}

	&__title {
		&--wrap {
			@include font_iroha_600;
			@include sp {
				margin: 0 0 1.5rem 0;
			}
			letter-spacing: 40px;
			margin: 0 3.33vw;
			text-align: center;
		}

		&--sub {
			color: #412814;
			display: block;
			font-size: 3rem;
			@include fz_vw(30);
			@include sp {
				font-size: 2.3rem;
				letter-spacing: 3.2px;
				line-height: 1.5;
				margin-right: -3.2px;
			}
			letter-spacing: 12px;
			line-height: 2;
			margin-right: -12px;
		}

		&--main {
			color: #6877ac;
			display: block;
			font-size: 9rem;
			@include fz_vw(75);
			@include sp {
				font-size: 2.3rem !important;
				letter-spacing: 3.2px;
				line-height: 1.2;
				margin-right: -3.2px;
			}
			letter-spacing: 37px;
			line-height: 2;
			margin-right: -45px;
		}

		&--btn {
			background-color: #6877ac;
			border-radius: 30px;
			color: #fff;
			cursor: initial;
			display: inline-block;
			font-size: 2.5rem;
			height: 60px;
			@include fz_vw(25);
			@include font_iroha_600;
			@include sp {
				font-size: 1.2rem;
				height: 30px;
				line-height: 30px;
				margin-top: 13px;
				width: 38.666vw;
			}
			letter-spacing: 2px;
			line-height: 60px;
			text-align: center;
			width: 300px;
		}
	}

	&__sp {
		display: flex;
		justify-content: space-between;
	}
}

.arrange {
	@include sp {
		margin-top: 1.2rem;
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	}
	margin-top: 11rem;
	padding: 0 2.5rem;

	.for-temples & {
		margin: 11rem 0;
	}

	&__list {
		@include sp {
			margin-top: 25px;
			width: 86.666vw;
		}
		margin: 0 auto;
		margin-top: 45px;
		max-width: 1000px;
		width: 100%;
	}

	&__item {
		border-top: 1px solid #000;
		@include sp {
			padding: 25px 13px;
		}
		padding: 45px;
		width: 100%;

		&:last-child {
			border-bottom: 1px solid #000;
		}
	}

	&__title {
		&-num {
			color: #6877ac;
			font-size: 5rem;
			@include font_conqueror_400;
			@include sp {
        font-weight: bold;
				font-size: 2.2rem;
				letter-spacing: 2px;
				line-height: 1.36;
				margin-right: 0;
			}
			letter-spacing: 5px;
			line-height: 2.4;
			margin-right: -5px;
			text-transform: capitalize;
		}

		&-text {
			color: #412814;
			font-size: 3.5rem;
			@include font_shuei_400;
			@include sp {
        font-weight: bold;
        font-family: $contents_fonts_jp_Gothic;
				font-size: 1.7rem;
				letter-spacing: 1.7px;
			}
			letter-spacing: 2.5px;
			line-height: 1.43;
			margin-right: -2.5px;
			text-align: left;

			&.long {
				display: block;
				letter-spacing: 1px;
			}
		}
	}

	&__body {
		color: #000;
    margin-top: 1rem;
		font-size: 1.6rem;
		@include sp {
			font-size: 1.3rem;
			letter-spacing: 1.1px;
		}
		letter-spacing: 1.6px;
		line-height: 1.88;
		text-align: left;

		&--tit {
			font-size: 2.5rem;
			@include font_shuei_400;
			@include sp {
				font-size: 1.3rem;
				letter-spacing: 1.3px;
				margin-top: 15px;
			}
			&::before {
				background-color: #6877ac;
				border-radius: 50%;
				content: "";
				display: inline-block;
				height: 16px;
				@include sp {
					height: 13px;
					width: 13px;
				}
				margin-right: 2px;
				width: 16px;
			}

			&.second {
				@include sp {
					margin-top: 15px;
				}
				margin-top: 40px;
			}
		}

		&-text {
			display: block;
			margin-top: 30px;
		}
	}
}

.feature {
	@include sp {
		margin-top: 35px;
	}
	margin-top: 167px;
	padding: 0 25px;

	&__list {
		display: flex;
		flex-wrap: wrap;
		@include sp {
			display: block;
			margin-top: 33px;
			width: 86.666vw;
		}
		justify-content: space-between;
		margin: 86px auto 0;
		max-width: 1300px;
		width: 100%;
	}

	&__item {
		@include sp {
			width: 100%;

			&.second {
				@include pc {
					margin-left: 60px;
				}
				margin-top: 60px;
			}
		}
		width: 47.692%;

		&.large {
			@include sp {
				margin-top: 60px;
			}
			margin-top: 87px;
			width: 100%;

			& .feature {
				&__tit--box {
					align-items: center;
				}
				&__image {
					display: block;
					@include sp {
						margin: 30px auto 0;
						width: 100%;
					}
					margin: 35px auto 0;
					width: 38.333vw;
				}
			}
		}
	}

	&__num {
		color: #6877ac;
		display: block;
		font-size: 11rem;
		@include fz_vw(110);
		@include font_conqueror_400;
		@include sp {
			font-size: 5.5rem;
			letter-spacing: normal;
		}
		// line-height: 0.55;
		letter-spacing: 11px;
		margin-right: -11px;
		text-align: left;
	}

	&__tit {
		color: #000;
		font-size: 3rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.5rem;
			letter-spacing: 2px;
			margin-left: 18px;
		}
		letter-spacing: 2px;
		line-height: 1.5;
		margin-bottom: 10px;
		margin-left: 53px;
		text-align: left;
		&--box {
			display: flex;
			@include pc {
				min-height: 130px;
			}
			@include sp {
				align-items: center;
				justify-content: center;
			}
		}

		&--add {
			display: block;
			font-size: 2rem;
			@include sp {
				font-size: 1.4rem;
			}
		}
	}

	&__contents {
		@include sp {
			text-align: center;
		}
	}

	&__image {
		@include font_iroha_600;
		@include sp {
			margin-top: 20px;
			width: 100%;
		}

		&--list {
			display: flex;
			@include sp {
				margin: 0 auto;
				padding-bottom: 7.5rem;
				width: 32.5rem;
			}
			justify-content: space-between;
			padding-bottom: 9.333vw; //168px
			width: 100%;
		}

		&--item {
			border-radius: 45px;
			height: 100%;
			@include sp {
				border-radius: 25px;
				padding: 3.222vw 1vw;
			}
			padding: 2.222vw 1vw;
			position: relative;
			width: 48.299%;

			&.poject {
				border: Solid #719630 5px;
				@include sp {
					border: Solid #719630 3px;
				}
				& .feature__image--title {
					color: #719630;
				}
			}

			&.temple {
				border: Solid #6877ac 5px;
				@include sp {
					border: Solid #6877ac 3px;
				}
				& .feature__image--title {
					color: #6877ac;
				}
			}
		}

		&--title {
			// font-size: 2rem;
			@include fz_vw(28);
			@include sp {
				font-size: 1.4rem;
			}
			line-height: 1.5;
			position: relative;
			text-align: center;

			&-long {
				letter-spacing: -2px;
			}

			&::after {
				background: url(../../assets/image/about/wave.svg) no-repeat;
				background-size: contain;
				bottom: -1.666vw; //-30px
				content: "";
				display: inline-block;
				height: 10px;
				@include sp {
					bottom: -3vw;
				}
				left: 50%;
				position: absolute;
				transform: translateX(-50%);
				width: 100px;
			}
		}
	}

	&__data {
		&--list {
			margin-left: 10px;
			margin-top: 3.888vw;
		}

		&--item {
			// font-size: 1.6rem;
			@include fz_vw(22);
			@include sp {
				font-size: 1rem;
			}
			line-height: 1.5;
			list-style: disc;
			list-style-position: inside;
			margin-bottom: 5px;
		}

		&--point {
			align-items: center;
			bottom: -9.33vw;
			color: #fff;
			display: flex;
			flex-direction: row;
			height: 9.33vw; // 168px
			@include sp {
				bottom: -7.5rem;
				font-size: 1.8rem;
				height: 7.5rem;
				width: 4.8rem;
			}
			// font-size: 3rem;
			@include fz_vw(36);
			justify-content: center;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			width: 5.777vw; //104px
			writing-mode: vertical-rl;
			.poject & {
				background: url(../../assets/image/about/green_arrow.png) no-repeat
				center center;
				background-size: contain;
			}

			.temple & {
				background: url(../../assets/image/about/blue_arrow.png) no-repeat
				center center;
				background-size: contain;
			}
		}
	}

	&__database {
		background: url(../../assets/image/about/database.png) no-repeat center
		center;
		background-size: contain;
		color: #fff;
		// font-size: 2.4rem;
		height: 12.222vw; //220px
		@include fz_vw(30);
		@include sp {
			font-size: 1.4rem;
			height: 9.8rem;
			padding-top: 1rem;
			width: 23.5rem;
		}
		line-height: 1.2;
		margin: 0 auto;
		padding-top: 1.3vw;
		position: relative;
		position: relative;
		text-align: center;
		width: 30.555vw; //550px

		&::before {
			background: url(../../assets/image/about/hands.svg) no-repeat;
			content: "";
			display: inline-block;
			height: 7.5vw;
			@include sp {
				height: 6rem;
				top: -6.75rem;
				width: 8.3rem;
			}
			left: 50%;
			position: absolute;
			top: -8.333vw;
			transform: translateX(-50%);
			width: 10.333vw;
		}
	}

	&__second {
		@include sp {
			display: inline-block;
			margin-top: 24px;
			text-align: left;
		}
		letter-spacing: 1.5px;
		margin-top: 48px;

		&-tit {
			font-size: 2rem;
			@include sp {
				font-size: 1.3rem;
				letter-spacing: 1.3px;
			}
			letter-spacing: 2px;
			line-height: 2.33;

			&.second {
				margin-top: 20px;
			}
		}

		&-list {
			font-size: 1.5rem;
			@include sp {
				font-size: 1.1rem;
				line-height: 2;
			}
			line-height: 2.33;

			&::before {
				background-color: #6877ac;
				border-radius: 50%;
				content: "";
				display: inline-block;
				height: 15px;
				@include sp {
					height: 11px;
					width: 11px;
				}
				margin-right: 2px;
				width: 15px;
			}
		}
	}
}

.p-action_block {
	& .p-action_item {
		@include sp {
			.planning-proposal & {
				width: 56.6vw;
			}
		}
	}
}

.p-action {
	&_block {
		& .p-action_item {
			& .p-action_link {
				&::before {
					content: none;
				}

				&-text {
					font-size: 1.2rem;
					position: relative;
					&.templ {
						@include sp {
							&::before {
								left: -12px;
							}
						}
						padding: 0 0 0 20px;
					}
					// &::before {
					// 	background: url(../../assets/image/about/add_temple.svg) no-repeat;
					// 	content: "";
					// 	display: inline-block;
					// 	height: 39px;
					// 	@include sp {
					// 		height: 23px;
					// 		left: -33px;
					// 		width: 27px;
					// 	}
					// 	left: -46px;
					// 	position: absolute;
					// 	top: 50%;
					// 	transform: translateY(-50%);
					// 	width: 45px;
					// }
				}
			}
		}
	}
}
.p-action_block .p-action_item.red {
	background-color: #b24b5b;
	border: none;
	color: #fff;
	font-weight: bold;
	margin: 0 auto;

	&::before {
		border-bottom: 1px dashed #b24b5b;
	}
	&::after {
		border-bottom: 1px dashed #b24b5b;
	}

	& .p-action_link-text::before {
		background: url(../../assets/image/about/add_temple_white.svg) no-repeat;
		content: "";
		display: inline-block;
		height: 39px;
		@include sp {
			height: 23px;
			left: -33px;
			width: 27px;
		}
		left: -46px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 45px;
	}
}



.project {
	@include sp {
    margin: 30px auto 0;
		padding: 0 25px;
		width: 86.666vw;
	}
	margin: 157px auto 0;
	max-width: 1300px;
	width: 100%;

	&__title {
		@include sp {
			margin-bottom: 30px;
		}
		margin-bottom: 75px;
	}

	&__text {
		font-size: 2rem;
		@include font_shuei_400;
		@include sp {
      font-family: $contents_fonts_jp_Gothic;
			font-size: 1.55rem;
      line-height:3.1rem;
			margin-top: 20px;
			text-align: left;
		}
		letter-spacing: 2px;
		line-height: 2;
		margin-top: 40px;
		text-align: center;
		&.bold {
      font-weight: bold;
      @include sp{
        font-family: $contents_fonts_jp_Gothic;
      }
		}
	}

	&__image {
		display: block;
		@include sp {
			margin: 25px auto 10px;
			max-width: initial;
			width: 100%;
		}
		margin: 50px auto 10px;
	}
}

.about__social-project {
	@include sp {
		margin: 30px auto 0;
		width: 86.666vw;
	}
	padding: 0 25px;
	.planning-proposal & {
		margin: 88px auto 0;
		max-width: 1300px;
		width: 100%;
	}

	&--inner {
		background-color: #f5f4f2;
		@include sp {
			padding: 27px;
		}
		padding: 3.666vw 2.777vw;
	}

	&--title {
		color: #412814;
		font-size: 3.5rem;
		@include font_shuei_400;
		@include sp {
      font-family: $contents_fonts_jp_Gothic;
			font-size: 1.7rem;
			letter-spacing: 0;
		}
		letter-spacing: 8px;
		margin-right: -8px;
		text-align: center;
	}

	&--wrap {
		@include sp {
			margin-top: 17px;
		}
		margin-top: 50px;
	}

	&--text {
		color: #000;
		font-size: 2rem;
		@include font_shuei_400;
		line-height: 2;
		margin: 44px auto 0;
		text-align: center;
		width: 75.5rem;
	}

	&--list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 10px;
	}

	&--item {
		border-top: 1px solid #000;
		display: inline-block;
		@include sp {
			padding: 20px 0;
			width: 100%;
		}
		padding: 30px 0;
		width: 43.846%;

		// &:nth-child(-n + 2) {
		// 	border-top: 1px solid #000;
		// 	@include sp {
		// 		border-top: none;
		// 	}
		// }

		&:first-child {
			@include sp {
				border-top: 1px solid #000;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		& .tags {
			@include sp {
				margin-top: 14px;
			}
			margin-top: 15px;
		}

		& .p-tag__item {
			background-color: #fff;
			@include sp {
				font-size: 1rem;
				margin-top: 8px;
			}
			margin-bottom: 0;
			margin-right: 10px;
			margin-top: 10px;
			padding: 0 15px;
		}
	}

	&--cat {
		font-size: 2.5rem;
		@include font_shuei_400;
		@include sp {
      font-family: $contents_fonts_jp_Gothic;
			font-size: 1.5rem;
			letter-spacing: 2px;
		}
		letter-spacing: 5px;
	}

	&--image {
		display: flex;

		&-01 {
			width: 50%;
		}

		&-02 {
			width: 50%;
		}
	}

	& .p-action_block {
		& .p-action_item {
			@include sp {
				width: 86.4vw;

				&::before {
					content: none;
				}

				&::after {
					content: none;
				}
			}
			width: 54rem;

			& .p-action_link {
				@include sp {
					font-size: .9rem;
					margin-left: 1rem;
					padding: 2rem;


					// .planning-proposal & {
					// 	margin-left: 0;
					// 	padding: 2rem 0;

					// 	& .p-action_link-text {
					// 		display: inline-block;
					// 		line-height: 1.2;
					// 		padding: 0;
					// 		width: 70%;
					// 	}
					// }
				}
				position: relative;
				&::before {
					background: url(../../assets/image/about/line-arrow-right.svg)
					no-repeat;
					content: "";
					display: inline-block;
					height: 8px;
					@include sp {
						right: 1.6rem;
					}
					// @include sp {
					// 	background-size: contain;
					// 	height: 5px !important;
					// 	right: 20px !important;
					// 	width: 17px !important;
					// }
					left: auto;
					position: absolute;
					right: 34px;
					top: 50%;
					transform: translateY(-50%);
					width: 29px;
				}
			}
		}
	}
}

.voice {
	@include sp {
		margin: 28px auto 65px;
		width: 86.666vw;
	}
	@include sp {
		&__accordion {
			border-top: 1px solid #000;
			display: block;
			font-size: 1.3rem;
			@include font_conqueror_400;
			margin-top: 15px;
			padding-top: 19px;
			text-align: center;
			width: 100%;

			&--text {
				letter-spacing: 3px;
				padding-right: 10px;
				position: relative;
				&::after {
					background: url(../../assets/image/about/plus.png) no-repeat;
					background-size: contain;
					content: "";
					display: inline-block;
					height: 15px;
					position: absolute;
					right: -15px;
					top: 0;
					width: 15px;
				}
			}
		}
	}
	margin: 150px auto 165px;
	max-width: 1300px;
	width: 100%;

	&__list {
		@include sp {
			margin-top: 26px;
		}
		margin-top: 124px;
	}

	&__item {
		display: flex;
		@include sp {
			display: block;
		}
		justify-content: center;

		&.second {
			margin-top: 110px;
		}
	}

	&__info {
		@include font_shuei_400;
		@include sp {
			width: 100%;
		}
		width: 22.222vw;
	}

	&__contents {
		background-color: #f7f6f4;
		@include sp {
			padding: 8vw;
			width: 100%;

			& .voice__sub-title {
				font-size: 1.5rem;
				text-align: left;
			}
		}
		@include pc {
			margin-left: 3.888vw;
		}
		padding: 3.333vw 4.166vw;
		width: 46.111vw;

		&--text {
			font-size: 1.6rem;
			@include sp {
				font-size: 1.1rem;
				letter-spacing: 2px;
				margin-top: 30px;
			}
			letter-spacing: 3.2px;
			line-height: 1.88;
			margin-top: 40px;
			text-align: left;
		}
	}

	&__image {
		@include sp {
			margin-bottom: 24px;
		}
		margin-bottom: 45px;
	}

	&__sub-title {
		font-size: 3rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.7rem;
			letter-spacing: 3px;
			text-align: center;
		}
		letter-spacing: 6px;
		line-height: 1.67;

		&.second {
			@include sp {
				margin-top: 40px;
			}
			margin-top: 60px;
		}
	}

	&__en {
		color: #6877ac;
		font-size: 13px;
		@include font_conqueror_400;
		@include sp {
			font-size: .9rem;
			text-align: center;
		}
		letter-spacing: 2.6px;
		margin-top: 10px;
	}

	&__text {
		@include sp {
			font-size: 1.1rem;
			letter-spacing: 2px;
			line-height: 1.5;
		}
		letter-spacing: 3.2px;
		line-height: 1.88;
		margin-top: 30px;
		text-align: left;
	}

	&__link {
		&--mark {
			color: #6877ac;
			font-family: $contents_fonts_jp_Gothic;
			font-size: 2.3rem;
			@include sp {
				font-size: 1.5rem;
			}
			letter-spacing: 4.6px;
			line-height: 1.52;
		}

		&--wrap {
			font-size: 23px;
			@include sp {
				font-size: 1.5rem;
				letter-spacing: 3px;
				margin-top: 10px;
			}
			letter-spacing: 4.6px;
			line-height: 2.61;
			margin-top: 20px;
		}
	}
}

.planning-proposal
  .feature
  .p-action_block
  .p-action_item
  .p-action_link::before {
	content: none;
}

.about__social-project
  .p-action_block
  .p-action_item
  .p-action_link-text::before {
	content: none;
}

.about__social-project .p-action_block .p-action_item {
	@include sp {
		width: 86.4vw;
	}
}

.about__social-project .p-action_block .p-action_item::before {
	@include sp {
		content: none;
	}
}

.about__social-project .p-action_block .p-action_item::after {
	@include sp {
		content: none;
	}
}

.voice .p-action_block .p-action_item .p-action_link::before {
	content: none;
}

.about__social-project .p-action_item .p-action_link::before {
	background: url(/img/line-arrow-right.4197684e.svg) no-repeat;
}

.feature__image {
	position: relative;

	& .system__temple--item-wrap {
		align-items: center;
		display: flex;
		flex-direction: column;
		@include sp {
			display: none;
		}
		justify-content: center;
		position: absolute;
		width: 48.299%;

		&.left {
			left: -48.299%;
			top: 0;

			& .temple-text {
				color: #6877ac;
				font-size: 1.8rem;
				@include font_iroha_600;
				line-height: 1.5;
				margin-top: 20px;
				text-align: center;
			}

			& .temple-text-small {
				font-size: 1.4rem;
			}
		}

		&.right {
			right: -48.299%;
			top: 0;

			& .temple-text {
				color: #6877ac;
				font-size: 2.1rem;
				letter-spacing: 4px;
				margin-top: 25px;
				text-align: center;
			}

			& .system__temple--image {
				max-height: 275px;
			}
		}
	}

	& .feature__second-tit {
		margin-top: 20px;
	}
}
</style>
<style lang="scss" scoped>
.p-action_block {
	background-image: initial;
	padding: 20px 0 0;
	position: relative;
}
</style>
<template>
  <div class="wrap">
    <router-link :to="link">
      <template v-if="item.thumbnail">
        <div
          class="thumbnail"
          :style="'background-image: url(' + item.thumbnail.thumbnail_url + ')'"
        >
          <Label
            v-if="item.category_id"
            :id="item.category_id"
            :category="item.category.name"
            color="green"
          />
          <Like :temple-id="item.temple_id" />
        </div>
      </template>
      <template v-else>
        <div class="thumbnail">
          <Label
            v-if="item.category_id"
            :id="item.category_id"
            :category="item.category.name"
            color="green"
          />
          <Like :temple-id="item.temple_id" />
        </div>
      </template>
    </router-link>
    <div>
      <template v-if="reverseGroup && reverseGroup.length > 1 && merged">
        <router-link
          :to="
            '/search?target=event&event_group_id=' +
              item.event_detail.event_group_id
          "
        >
          <p class="caption">{{ item.subtitle }}</p>
          <h1 v-html="item.title" />
          <p class="date">開催日時（シリーズ）</p>
          <time
            :class="date.stop_flg === 1 && 'stopped'"
            :datetime="moment(date.date, 'YYYY/MM/DD(ddd)')"
            :key="index"
            class="series"
            v-for="(date, index) in reverseGroup"
          >
            {{ moment(date.date, 'YYYY/MM/DD(ddd)') }}
            {{ moment(date.start_time, 'HH:mm') }}〜{{
              moment(date.end_time, 'HH:mm')
            }}{{ date.stop_flg === 1 ? ' (中止)' : '' }}
          </time>
        </router-link>
      </template>
      <template v-else>
        <router-link :to="'/articles/event/' + item.id">
          <p class="caption">{{ item.subtitle }}</p>
          <h1 v-html="nl2br(item.title)" />
          <p class="date">開催日時</p>
          <time :datetime="moment(item.event_detail.date, 'YYYY/MM/DD(ddd)')">
            {{ moment(item.event_detail.date, 'YYYY/MM/DD(ddd)') }}
            {{ moment(item.event_detail.start_time, 'HH:mm') }}〜{{
              moment(item.event_detail.end_time, 'HH:mm')
            }}
            <StatusLabel :item="item" />
          </time>
        </router-link>
      </template>
      <div class="icon-box">
        <IconBox
          v-if="getProfile(item.profile_content)"
          :imageSrc="
            getThumbnailUrl(getProfile(item.profile_content).thumbnail)
          "
          role="企画・講師・出演"
          :name="getProfile(item.profile_content).name"
          :detail="getProfile(item.profile_content).title"
          :isNonClip="false"
          color="orange"
          :link="true"
          :path="'/articles/event/' + item.id + '/#person'"
        />
        <IconBox
          v-if="item.event_detail.online_flg === 0"
          :id="item.temple_id"
          :imageSrc="require('@/assets/image/event/icon-locate-o.svg')"
          role="会場"
          :name="item.temple.sub_name + item.temple.name"
          :detail="item.temple.prefectures + item.temple.municipality"
          :isNonClip="true"
          color="orange"
          :link="true"
          :path="'/temples/' + item.temple_id"
        />
        <IconBox
          v-else
          :id="item.temple_id"
          :imageSrc="require('@/assets/image/event/icon-locate-o.svg')"
          role="会場"
          name="オンライン"
          :isNonClip="true"
          color="orange"
        />
      </div>

      <ul class="tags">
        <li v-for="tag in item.tags" :key="tag.id" class="orange">
          <Tag :tagName="tag.name" :id="tag.id" />
        </li>
      </ul>
      <ActionButton
          v-if="editMode"
          label="編集・管理"
          className="edit-link"
          :path="'/articles/event/' + item.id + '/edit'"
      />
    </div>
  </div>
</template>
<script>
import IconBox from '@/components/IconBox.vue';
import Tag from '@/components/Tag';
import Label from '@/components/Label';
import Like from '@/components/Like';
import moment from 'moment';
import { getProfile } from '@/utils/profile';
import { getThumbnailUrl } from '@/utils/helper';
import { computed } from 'vue';
import { nl2br } from '../utils/nl2br';
import StatusLabel from '@/components/StatusLabel';
import ActionButton from "@/components/ActionButton";

export default {
  name: 'EventItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    merged: {
      type: Boolean,
      default: false,
    },
    timeDirection: {
      type: String,
      default: 'future',
    },
    editMode: {
      type: Boolean
    }
  },
  components: {
    IconBox,
    Tag,
    Label,
    Like,
    StatusLabel,
    ActionButton
  },
  setup(props) {
    const reverseGroup = computed(() => {
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);

      const list =
        props.item.event_detail.event_group &&
        props.item.event_detail.event_group.event_details.slice();
      return (
        list &&
        list.filter((item) => {
          if (
            item.articles &&
            item.articles.length > 0 &&
            item.articles[0].status === 0
          ) {
            return false;
          }

          const target = new Date(item.date);
          target.setHours(0);
          target.setMinutes(0);
          target.setSeconds(0);
          target.setMilliseconds(0);
          if (props.timeDirection === 'future') {
            return target >= now.getTime();
          } else if (props.timeDirection === 'past') {
            return target.getTime() < now.getTime();
          } else {
            return true;
          }
        })
      );
    });

    const link = computed(()=>{
      if(reverseGroup.value && reverseGroup.value.length > 1 && props.merged){
        return '/search?target=event&event_group_id=' + props.item.event_detail.event_group_id
      }else{
        return '/articles/event/' + props.item.id
      }
    });

    return {
      getProfile,
      getThumbnailUrl,
      reverseGroup,
      link,
      nl2br
    };
  },
  methods: {
    moment(date, format) {
      moment.locale('ja');
      if (date) {
        return moment(date).format(format);
      }
    },
  },
  data() {
    return {
      tags: [
        { id: 1, tagName: 'WITHコロナ' },
        { id: 2, tagName: 'ヘルシーテンプル' },
      ],
    };
  },
};
</script>
<style scoped>
.wrap {
  margin-bottom: 3rem;
  width: 35rem;
}

.thumbnail {
  background-color: #a79280;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 22rem;
  position: relative;
  width: 35rem;
}

.caption,
h1 {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
}

.caption {
  font-size: 1.8rem;
  letter-spacing: 0.18rem;
  margin-bottom: 1.6rem;
  margin-top: 2.2rem;
}

h1 {
  font-size: 2.8rem;
  letter-spacing: 0.28rem;
  margin-bottom: 2rem;
  width: 35rem;
  line-height: 1.2;
}

.icon-box {
  margin-bottom: 2.6rem;
  margin-top: 2rem;
}

.icon-box > a:first-child {
  margin-bottom: 1rem;
}

.tags li {
  display: inline-block;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
}

.date {
  color: #be6c4c;
  font-size: 1.3rem;
  letter-spacing: 0.13rem;
  margin-bottom: 1.2rem;
}

time {
  font-size: 1.8rem;
  letter-spacing: 0.18rem;
}

time.series {
  display: block;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
}

time.stopped {
  color: #777777;
}

/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
  .thumbnail,
  .right {
    height: 17.5rem;
    width: 25rem;
  }

  h1 {
    font-size: 1.9rem;
    line-height: 1.3;
    margin-bottom: 1rem;
    width: auto;
  }

  .caption {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    margin-top: 1.8rem;
  }

  .date {
    font-size: 1rem;
  }

  time {
    font-size: 1.3rem;
  }

  time.series {
    font-size: 1.2rem;
  }

  .icon-box {
    display: flex;
  }

  .icon-box > a {
    flex-wrap: nowrap;
  }

  .wrap {
    margin-left: 1rem;
    margin-right: 3rem;
  }
}
</style>

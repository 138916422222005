<template>
  <div class="hello">
    <h1>Articles</h1>
  </div>
</template>

<script>
export default {
  name: 'Articles',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.l-wrapper {
	@include sp {
		padding: 0 25px;
		width: 100%;
	}
	@include pc {
		margin: 0 auto;
		max-width: 1000px;
		width: 70vw;
	}
}
.p-pagetype {
	color: #fff;
	display: flex;
	font-size: 1.8rem;
	height: 50px;
	@include sp {
		display: block;
		height: auto;
		line-height: 30px;
	}
	line-height: 50px;
	width: 100%;

	& .p-pagetype__jp {
		display: block;
		@include font_iroha_600;
		@include sp {
			font-size: 1.3rem;
			padding-right: 0;
			text-align: center;
			width: 100%;
		}
		padding-right: 40px;
		text-align: right;
		width: 50%;
	}

	& .p-pagetype__en {
		display: block;
		@include font_conqueror_400;
		@include sp {
			font-size: 1rem;
			padding-left: 0;
			text-align: center;
			width: 100%;
		}
		padding-left: 40px;
		text-align: left;
		width: 50%;
	}
}
.p-mv {
	background: url(../../assets/image/bg.jpg) no-repeat;
	background-position-x: center;
	// background-size: 100% 80%;
	@include sp {
		background: transparent;
		background-repeat: repeat;
		margin-bottom: 0;
		padding-top: 0;
	}
	margin-bottom: 45px;
	padding-top: 143px;
	position: relative;
	width: 100%;
	& img {
		border-radius: 50% 0 50% 0;
		height: 100%;
		width: 100vw;
	}

	&::after {
		background: url(../../assets/image/bow.png) no-repeat center;
		background-size: cover;
		content: "";
		display: inline-block;
		height: 60px;
		@include sp {
			height: 30px;
			top: 10px;
		}
		left: 50%;
		position: absolute;
		top: 45px;
		transform: translateX(-50%);
		width: 100%;
	}
	&__block {
		@include sp {
			background: url(../../assets/image/bg_sp.jpg) repeat;
			background-size: contain;
			padding-top: 52px;
		}
		position: relative;
	}
}
.p-cat {
	border: none;
	color: #fff;
	display: inline-block;
	font-size: 3rem;
	@include font_iroha_600;
	@include sp {
		font-size: 1.5rem;
		height: 21px;
		line-height: 21px;
		margin: 0;
		padding: 0 6px;
	}
	padding: 10px;
}
.p-date {
	color: #749e1d;
	font-size: 1.8rem;
	@include sp {
		font-size: .9rem;
		line-height: 2.2;
		margin-top: 15px;
	}
	margin-top: 35px;
	&__event {
		font-size: 2rem;
		@include sp {
			font-size: 1rem;
		}
		line-height: 2.5;
		&-place {
			color: #be6c4c;
			margin-right: 20px;
		}
	}
}
.p-title {
	@include sp {
		margin-top: 0;
	}
	@include font_shuei_400;
	margin-top: 20px;
	&__sub {
		display: block;
		font-size: 3rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.3rem;
			line-height: 2;
		}
		line-height: 1.67;
	}
	&__main {
		display: block;
		font-size: 5rem;
		@include sp {
			font-size: 2.3rem;
			line-height: 1.5;
		}
		line-height: 1.4;
	}
}
.p-author {
	align-items: center;
	display: flex;
	@include sp {
		margin-top: 45px;
	}
	margin: 85px 0 24px 0;
	position: relative;
	&::before {
		background: url(../../assets/image/wave.png) no-repeat;
		content: "";
		display: inline-block;
		height: 4px;
		@include sp {
			top: -20px;
		}
		left: 0;
		position: absolute;
		top: -28px;
		width: 77px;
	}
	&__img {
		border-radius: 50%;
		height: 70px;
		@include sp {
			height: 42px;
			width: 42px;
		}
		width: 70px;
	}
	&__block {
		@include sp {
			margin-left: 10px;
			width: 20.93vw;
		}
		margin-left: 13px;
		margin-right: 38px;
	}
	&__tit {
		font-size: 1.3rem;
		@include sp {
			font-size: 1rem;
		}
		line-height: 2;
	}
	&__name {
		font-size: 1.8rem;
		@include sp {
			font-size: 1.3rem;
		}
		line-height: 1.5;
	}
	&__company {
		font-size: 1.1rem;
		@include sp {
			font-size: .8rem;
			line-height: 1.25;
		}
		line-height: 2.2;
	}
}
.p-location {
	display: flex;
	@include sp {
		margin-left: 8vw;
		position: relative;
	}
	&__block {
		@include sp {
			margin-left: 6px;
			width: 17vw;
		}
		margin-left: 16px;
	}
	&__img {
		height: 60px;
		@include sp {
			height: 37px;
			width: 28px;
		}
		width: 44px;
	}
	&__tit {
		font-size: 1.3rem;
		@include sp {
			font-size: 1rem;
		}
		line-height: 2;
	}
	&__name {
		font-size: 1.6rem;
		@include sp {
			font-size: 1.3rem;
		}
		line-height: 1.5;
	}
	&__address {
		font-size: 1.1rem;
		@include sp {
			font-size: .9rem;
			line-height: 1.25;
		}
		line-height: 2.2;
	}
	&__btn {
		border-radius: 12.5px;
		color: #fff;
		display: inline-block;
		height: 25px;
		@include font_conqueror_400;
		@include sp {
			height: 17.5px;
			line-height: 17.5px;
			margin: 0;
			padding: 0 13px;
			position: absolute;
			right: -15.4666vw;
			top: 20px;
		}
		line-height: 25px;
		margin-left: 22px;
		margin-top: 25px;
		padding: 0 18px;
	}
}
.p-tag {
	display: inline-block;
	font-size: 1.1rem;
	@include sp {
		font-size: 1rem;
		margin-top: 20px;
	}
	margin-top: 40px;

	&__item {
		border-radius: 20px;
		display: inline-block;
		@include sp {
			line-height: 27px;
			margin-bottom: 10px;
			padding: 0 16px;
		}
		line-height: 40px;
		margin-bottom: 10px;
		margin-right: 8px;
		padding: 0 24px;
		position: relative;

		&.err {
			border: #c84d5f dotted 1px;
		}
	}
}
.p-contents {
	background-color: #fafafa;
	@include font_shuei_400;
	@include sp {
		margin-top: 23px;
	}
	margin-top: 60px;
	&__block {
		@include sp {
			padding-top: 30px;
		}
		padding-top: 60px;
		text-align: center;
		&:last-child {
			@include sp {
				padding-bottom: 58px;
			}
			padding-bottom: 115px;
		}
		&:nth-of-type(n + 2) {
			padding-top: 0;
		}
	}
	&__summary {
		font-size: 2.5rem;
		@include sp {
			font-size: 1.3rem;
		}
		line-height: 1.8;
		text-align: left;
	}
	&__title {
		font-size: 4rem;
		@include sp {
			font-family: $contents_fonts_jp_Gothic;
			font-size: 2.3rem;
			font-weight: bold;
			margin-top: 47px;
		}
		margin-bottom: 50px;
		margin-top: 85px;
		min-width: 150px;
	}
	&__catch {
		font-size: 3rem;
		@include sp {
			font-family: $contents_fonts_jp_Gothic;
			font-size: 2rem;
			font-weight: bold;
			margin-top: 47px;
		}
		line-height: 1.6;
		margin-top: 60px;
		text-align: left;
	}
	&__text {
		font-size: 1.8rem;
		@include sp {
			font-family: $contents_fonts_jp_Gothic;
			font-size: 1.5rem;
			line-height: 2;
			margin-top: 25px;
		}
		line-height: 2.2;
		margin-bottom: 25px;
		min-width: 200px;
		text-align: left;
	}
	&__name {
		font-size: 1.8rem;
		@include sp {
			font-size: 1.1rem;
			margin-top: 30px;
		}
		margin-top: 60px;
		text-align: right;
	}
	&__img {
		@include sp {
			margin-top: 27px;
		}
		margin-top: 40px;
	}
}
.p-action {
	background: url(../../assets/image/bg_s.png) no-repeat center center;
	background-size: cover;
	width: 100%;

	.p-access & {
		background: transparent;
	}
	&_wrap {
		background: url(../../assets/image/dots_line.png) no-repeat center center;
		width: 100%;
	}
	&_block {
		display: flex;
		@include sp {
			display: block;
			padding: 37px 45px;
		}
		justify-content: center;
		padding: 77px 0;
	}
	&_item {
		background-color: #fff;
		border: solid 1px #412814;
		border-radius: 41rem;
		color: #412814;
		font-size: 1.5rem;
		@include sp {
			width: 100%;
		}
		text-align: center;
		width: 41%;
		&:nth-child(1) {
			margin-right: 15px;
		}
		&:nth-child(2) {
			@include sp {
				margin-left: 0;
				margin-top: 14px;
			}
			margin-left: 15px;
			position: relative;
			& .p-action_link-text {
				padding-left: 54px;
				position: relative;
				&::before {
					background: url(../../assets/image/hands.png) no-repeat;
					background-size: contain;
					content: "";
					display: inline-block;
					height: 26px;
					left: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 35px;
				}
			}
		}
	}
	&_link {
		display: block;
		height: 100%;
		@include sp {
			padding: 23px 0;
		}
		padding: 33px 0;
		width: 100%;



		&-book {
			margin-left: 3.8rem;
			position: relative;
			&::before {
				background: url(../../assets/image/about/add_temple.svg) no-repeat center;
				content: "";
				display: inline-block;
				height: 3.1vw;
				@include sp {
					height: 23px;
					left: -33px;
					width: 27px;
				}
				left: -3.555vw;
				max-width: 4.5rem;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 3.5vw;
			}
		}
	}
}
.p-article-group {
	margin: 45px auto;
	text-align: center;

	& .p-select {
		border: none;
		color: #fff;
		display: inline-block;
		font-family: iroha-23kaede-stdn, sans-serif;
		font-size: 3rem;
		font-style: normal;
		font-weight: 400;
		padding: 10px;
	}
}

.required {
	color: #c84d5f;
	display: inline-block;
	font-size: 30px;
	left: -15px;
	position: absolute;
	top: 0;
	&_wrap {
		position: relative;
	}
}
</style>

<template>
  <dl v-if="editMode || modelComputed.name" class="p-event__outline p-event__teacher">
    <dt class="p-event__outline-title">
      <Upload
        ref="file"
        v-model="modelComputed.thumbnail"
        :initial-img="initialItem && initialItem.thumbnail"
        :editMode="editMode"
      />
      <!-- <Upload :editMode="editMode" /> -->
    </dt>
    <dd class="p-event__outline-contents">
      <Editable
        ref="text"
        :initialText="initialItem && initialItem.title"
        :editMode="editMode"
        v-model="modelComputed.title"
        :initial-class="
          'p-event__outline-contents p-event__teacher p-event__teacher-title'
        "
        tagName="p"
        placeholder="肩書を入力してください"
      />
      <Editable
        ref="text"
        :initialText="initialItem && initialItem.name"
        :editMode="editMode"
        v-model="modelComputed.name"
        :initial-class="
          'p-event__outline-contents p-event__teacher p-event__teacher-name'
        "
        tagName="p"
        :placeholder="`${placeholderType}を入力してください`"
      />
      <Editable
        ref="text"
        :initialText="initialItem && initialItem.body"
        :editMode="editMode"
        v-model="modelComputed.body"
        :initial-class="
          'p-event__outline-contents p-event__teacher p-event__teacher-body'
        "
        tagName="p"
        placeholder="プロフィールを入力してください"
      />
    </dd>
  </dl>

  <button v-if="editMode" class="delete-btn" @click="onClickDelete">
    削除
  </button>
</template>
<script>
import Editable from './Editable.vue';
import Upload from './Upload';
import { computed,ref } from 'vue';
// import { useArticles } from "@/composables/useArticles.js";
export default {
  name: 'ProfileContentBlock',
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    initialItem: {
      type: Object,
      required: false,
    },
    indexKey: {
      required: true,
    },
    handleClickDelete: {
      type: Function,
    },
    editMode: {
      type: Boolean,
    },
    articleType: {
      type: String,
    },
  },
  setup(props, { emit }) {
    let placeholderType = ref('');

    const modelComputed = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const onClickDelete = () => {
      console.log('props.key', props.indexKey);
      props.handleClickDelete(props.indexKey);
    };

    if (props.articleType === 'project' || props.articleType ===  'event') {
      placeholderType.value = '企画者、講師、出演者';
    } else if (props.articleType === 'report') {
      placeholderType.value = '執筆者';
    }

    // console.log("modelComputed", modelComputed);
    return { modelComputed, onClickDelete, placeholderType };
  },
  methods: {
    //  onClickDelete() {
    //   this.$emit("update:modelValue", null);
    //   texts.Value = null;
    //   // this.$refs.file.modelValue.url = null;
    // }
  },
  components: {
    Editable,
    Upload,
  },
};
</script>

<style lang="scss" scoped>
.p-event__teacher {
	// align-items: center;
	@include font_shuei_400;
	@include sp {
		font-size: 1.3rem;
	}

	&.p-event__outline {
		padding: 40px 0;
	}

	& .p-event__outline-title {
		// width: initial;
		width: 200px;
	}

	&-img {
		@include sp {
			width: 35px;
		}
		width: 70px;
	}

	&-title {
		font-size: 1.8rem;

		.edit & {
			margin-bottom: 15px;
		}
	}

	&-name {
		font-size: 2.5rem;
		.edit & {
			margin-bottom: 15px;
		}
	}

	&-body {
		font-size: 1.5rem;
		min-height: 5rem;
	}
}

.p-event__outline-title {
	width: 20%;
}
.p-event__outline-contents {
	@include sp {
		margin-left: 0;
	}
	margin-left: 2%;
	width: 78%;
}

.edit .l-event .p-outline .p-event__outline {
	padding: 20px 0 0 0;
}

.delete-btn {
	cursor: pointer;
	display: inline-block;
	font-size: 1.5rem;
	padding: 20px;
	position: relative;
}
.delete-btn::before {
	background-color: #000;
	border-radius: 50%;
	color: #fff;
	content: "-";
	display: inline-block;
	font-size: 16px;
	height: 17px;
	left: 0;
	line-height: 14px;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	width: 17px;
}
</style>

<style lang="scss">
.p-event__outline {
	border-top: 1px solid #000;
	display: flex;
	font-size: 1.5rem;
	@include sp {
		font-size: 1.1rem;
		justify-content: space-between;
		padding: 15px 0;
		&.p-event__teacher {
			justify-content: start;
		}
		&.p-outline__ph {
			display: block;

			& .p-event__outline-contents {
				width: 100%;
			}
		}
	}
	line-height: 2;
	padding: 20px 0;

	&-title {
		@include sp {
			width: 20vw;
		}
		width: 20%;

		& .uploaded {
			& .fit {
				border-radius: 50%;
				height: 160px;
				margin: 0 20px;
				width: 160px;
			}

			& .delete-button {
				font-size: 1.2rem;
				line-height: 24px;
				margin-top: 8px;
				padding: 0 7px;
				position: static;
			}
		}
	}

	.drop_area {
		margin: 0;
		padding: 0;

		& .user-photo.default {
			font-size: 1.2rem;
			line-height: 1.2;
			padding: 55px 10px 10px;
			text-align: center;
		}
	}

	&-contents {
		@include sp {
			width: 59.733vw;
		}
		width: 80%;

		& img {
			@include sp {
				margin: 15px 0;
				width: 100%;
			}
			// margin: 35px 0;
			width: 100%;

			&:first-child {
				margin-top: 15px;
			}

			&:last-child {
				margin-bottom: 15px;
			}
		}

		& .p-event__teacher {
			font-family: dnp-shuei-mincho-pr6, sans-serif;
			font-style: normal;
			font-weight: 400;
			margin-bottom: 15px;
			width: 100%;

			.edit & {
				margin-bottom: 10px;
			}

			&-title {
				font-size: 1.5rem;
				margin-bottom: 0;
			}
			&-name {
				font-size: 3rem;
				margin-bottom: 0;
			}
			&-body {
				font-size: 1.5rem;
				margin-bottom: 0;
				.edit & {
					min-height: 20rem;
				}
			}
		}
	}

	&-block {
		display: block;
	}

	&-cation {
		display: block;
		font-size: 1.1rem;
	}

	&-btn {
		background-color: #be6c4c;
		color: #fff;
		display: inline-block;
		@include sp {
			font-size: 1.3rem;
		}
		text-align: center;

		&.btn_1 {
			border-radius: 12.5px;
			@include font_conqueror_400;
			@include sp {
				line-height: 17px;
				margin-left: 0;
				padding: 0 18px;
			}
			line-height: 25px;
			margin-left: 30px;
			padding: 0 26px;
		}

		&.btn_2 {
			border-radius: 20px;
			display: inline-block;
			@include font_shuei_400;
			@include sp {
				margin-top: 13px;
				padding: 0 26px;
			}
			line-height: 40px;
			margin-top: 20px;
			padding: 0 30px;
		}
	}

	&-upload {
		width: auto;
	}
}
</style>

<template>
  <div class="outer">
    <div class="inner">
      <router-link :to="defaultLink">
        <template v-if="item.thumbnail">
          <div
            class="thumbnail"
            :style="
              'background-image: url(' + getThumbnailUrl(item.thumbnail) + ')'
            "
          >
            <Label
                v-if="item.category_id"
                :id="item.category_id"
                :category="item.category.name"
                color="green"
            />
            <div class="wrap">
              <div class="article-type" :class="articleType.value + '-color'">
                【{{ articleType.label }}】
              </div>
              <StatusLabel
                  v-if="item"
                  :item="item"
                  :handle-end-flg-set="endFlgSet"
                  class="status"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="thumbnail">
            <Label
                v-if="item.category_id"
                :id="item.category_id"
                :category="item.category.name"
                color="green"
            />
            <div class="wrap">
              <div class="article-type" :class="articleType.value + '-color'">
                【{{ articleType.label }}】
              </div>
              <StatusLabel
                  v-if="item"
                  :item="item"
                  :handle-end-flg-set="endFlgSet"
                  class="status"
              />
            </div>
          </div>
        </template>
      </router-link>
      <div class="right">
        <div class="time-stamp" :class="articleType.value + '-color'">
          <template v-if="articleType.value !== 'event'">
            公開日: <time :datetime="item.created_at">{{
              moment(item.created_at, 'YYYY/MM/DD(ddd)')
            }}</time>
          </template>
          <template v-else>
            開催日時: <time :datetime="item.event_detail.date">
              {{ moment(item.event_detail.date, 'YYYY/MM/DD(ddd)') }}
              {{ moment(item.event_detail.start_time, 'HH:mm') }} 〜
              {{ moment(item.event_detail.end_time, 'HH:mm') }}
            </time>
          </template>
        </div>
        <template v-if="articleType.value === 'event'">
          <p class="reserve-member">申込人数: {{reserveNumber}}/{{item.event_detail.recruit_member}}人</p>
        </template>
        <p class="caption" v-if="item.subtitle">{{ item.subtitle }}</p>
        <h1 v-html="item.title" />
        <div class="icon-box" v-if="getProfile(item.profile_content)">
          <IconBox
            :imageSrc="
              getThumbnailUrl(getProfile(item.profile_content).thumbnail)
            "
            :name="getProfile(item.profile_content).name"
            :role="role"
            :detail="getProfile(item.profile_content).title"
            :isNonClip="false"
          />
        </div>
        <ul class="buttons">
          <li v-for="(action, index) in actions" :key="index">
            <ActionButton
              :label="action.label"
              :path="action.path"
              :link="action.link"
              :className="action.className"
              :count="action.count"
              :on-click="action.function"
              :args="action.args"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import IconBox from '@/components/IconBox.vue';
import ActionButton from '@/components/ActionButton';
import Label from '@/components/Label';
import StatusLabel from '@/components/StatusLabel';
import moment from 'moment';
import { getProfile } from '@/utils/profile';
import { getThumbnailUrl } from '@/utils/helper';
import { computed, ref } from 'vue';

export default {
  name: 'FlowItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    location: {
      type: String,
    },
    onClickDelete: {
      type: Function,
    },
    onClickStopEvent: {
      type: Function,
    },
  },
  setup(props) {
    const articleType = computed(() => {
      if (props.item.article_type === 1) {
        return {
          label: 'プロジェクト',
          value: 'project',
        };
      } else if (props.item.article_type === 2) {
        return {
          label: 'イベント',
          value: 'event',
        };
      } else {
        return {
          label: 'レポート',
          value: 'report',
        };
      }
    });

    const reserveNumber = computed(() => {
      if (props.item.article_type === 2) {
        let count = 0;
        props.item.event_detail.entry_events.forEach(entry => {
          count += !entry.cancel_flg ? entry.reserve_number : 0;
        });
        return count;
      }else{
        return false
      }
    });

    const role = computed(() => {
      if (props.item.article_type === 1) {
        return '提案者';
      } else if (props.item.article_type === 2) {
        return '講師・出演者';
      } else {
        return '著者';
      }
    });

    const temporaryEvent = props.item.temporary_event_id
      ? props.item.temporary_event
      : {};

    let endFlg = ref(false);
    const endFlgSet = () => {
      endFlg.value = true;
    };

    const defaultLink = computed(()=>{
      return '/articles/' + articleType.value.value + '/' + props.item.id
    });

    const actions = computed(() => {
      const actionsFull = [
        {
          label: 'プレビュー',
          path: '/articles/' + articleType.value.value + '/' + props.item.id,
          link: 'router-link',
        },
        {
          label: '編集',
          path:
            '/articles/' +
            articleType.value.value +
            '/' +
            props.item.id +
            '/edit',
          link: 'router-link',
        },
        {
          label: '基本情報',
          path:
            '/articles/create?type=' +
            articleType.value.value +
            '&article_id=' +
            props.item.id +
            '&temple_id=' +
            props.item.temple_id,
        },
        {
          label: '提案者と連絡',
          path:
            '/temples/message_detail?temple_id=' +
            props.item.temple_id +
            '&temporary_event_id=' +
            temporaryEvent.id +
            '&partner_id=' +
            temporaryEvent.partner_id,
          type: 'proposed',
        },
        {
          label: '参加者の確認',
          path:
            '/partners/contact_participant/?partner_id=' +
            temporaryEvent.partner_id +
            '&event_detail_id=' +
            props.item.event_detail_id,
          type: 'event',
        },
        {
          label: '複製',
          path:
            '/articles/create?type=' +
            articleType.value.value +
            '&article_id=' +
            props.item.id +
            '&copy=true&temple_id=' +
            props.item.temple_id,
          className: 'defuse',
          type: 'all',
        },
        {
          label: '中止',
          function: props.onClickStopEvent,
          args: {
            eventDetailId: props.item.event_detail_id,
          },
          type: 'stop',
        },
        {
          label: '削除',
          className: 'defuse',
          type: 'delete',
          function: props.onClickDelete,
          args: {
            articleId: props.item.id,
            redirectPath: props.location,
          },
        },
      ];

      return actionsFull.filter((action) => {
        if (action.type === 'proposed') {
          return (
            !!props.item.temporary_event_id &&
            props.location !== '/partners/flow'
          );
        }

        if (action.type === 'event') {
          return articleType.value.value === 'event';
        }

        if (action.type === 'stop') {
            return (
              articleType.value.value === 'event' &&
              props.item.event_detail.stop_flg === 0 &&
              props.item.status === 1 && !endFlg.value
            );
        }

        if (action.type === 'delete') {
          if (articleType.value.value === 'event') {
            return props.item.status === 0;
          } else {
            return true;
          }
        }

        return true;
      });
    });
    return {
      role,
      actions,
      articleType,
      getProfile,
      getThumbnailUrl,
      temporaryEvent,
      endFlgSet,
			endFlg,
      reserveNumber,
      defaultLink
    };
  },
  components: {
    ActionButton,
    IconBox,
    Label,
    StatusLabel,
  },
  methods: {
    moment(date, format) {
      moment.locale('ja');
      if (date) {
        return moment(date).format(format);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer {
	padding-bottom: 8rem;
	position: relative;
}
.outer::after {
	border-bottom: 1px solid #000;
	bottom: 4rem;
	content: "";
	left: calc(50% - 5rem);
	position: absolute;
	width: 10rem;
}
.inner {
	display: flex;
	justify-content: space-between;
	margin: auto;
	max-width: 120rem;
}
.wrap{
  display: flex;
  position:absolute;
  width: 100%;
  bottom:0;
  justify-content: space-between;
}
::v-deep .status-text {
  font-family: $contents_fonts_jp_Gothic;
  font-size: 1.5rem;
  letter-spacing: .08rem;
  background-color: #f7f6f4;
  width: 50%;
  border-radius: 0;
  margin: 0;
  padding:1rem 0;
  text-align: center;
  color: #000;
}
::v-deep .status-text.public {
  background-color: #f2f2f2;
  color: #000;
}
::v-deep .status-text.scheduled {
  background-color: #f2f2f2;
  color: #000;
}
::v-deep .status-text.draft {
  background-color: #f2f2f2;
  color: #000;
}
::v-deep .status-text.stopped {
  background-color: #f2f2f2;
  color: #000;
}
::v-deep .status-text.end {
  background-color: #999;
  color: #fff;
}
.article-type {
	color: #fff;
  width: 50%;
  padding:1rem 0;
  text-align: center;
  font-weight: bold;
}

.project-color {
  background-color: #6a7bb7;
}
.event-color {
  background-color: #be6c4c;
}
.report-color {
  background-color: #749e1d;
}

.thumbnail {
	background-color: #f0f0f0;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 24.5rem;
	margin-right: 5rem;
	position: relative;
	width: 37.1rem;
}
.label {
	color: #fff;
	display: inline-block;
	font-family: "iroha-23kaede-stdn", sans-serif;
	font-size: 1.5rem;
	left: auto;
	letter-spacing: .03rem;
	padding: .7rem .4rem;
	position: relative;
	text-align: center;
	bottom: auto;
  top: auto;
  margin-bottom: 1rem;
}
.right {
	max-width: 60rem;
	width: 33.333vw;
}
.time-stamp {
	color: #000;
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
	font-size: 1.8rem;
	letter-spacing: .11rem;
	margin-bottom: .8rem;
}
.time-stamp.project-color {
	color: #6a7bb7;
  background-color: #FFF;
}
.time-stamp.event-color {
	color: #be6c4c;
  background-color: #FFF;
}
.time-stamp.report-color {
	color: #749e1d;
  background-color: #FFF;
}
.caption, h1 {
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
}
.reserve-member{
  font-size: 1.7rem;
  margin: 1rem 0 1.5rem;
}
.caption {
  font-size: 1.5rem;
  letter-spacing: .15rem;
  margin-bottom: 1.2rem;
}
h1 {
  font-size: 3rem;
  letter-spacing: .3rem;
  line-height: 4.5rem;
  margin-bottom: 2rem;
}
.icon-box {
	display: flex;
	margin-bottom: .8rem;
}
.buttons li {
	display: inline-block;
	margin-bottom: .8rem;
	margin-right: .8rem;
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
	.inner {
		flex-direction: column;
	}
	.outer {
		margin-left: 0;
		margin-right: 0;
	}
	.thumbnail {
		height: 17rem;
	}
	.thumbnail, .right {
		margin-right: 0;
		margin-top: 2rem;
		width: auto;
	}
  .caption {
    font-size: 1.1rem;
  }
  h1 {
    font-size: 1.9rem;
    line-height: 1.3;
  }
}
</style>

<template>
  <div class="tag-input">
    <ul class="tags" v-if="newIds.length > 0">
      <li v-for="(newId, index) in newIds" :key="newId.index">
        <div class="p-tag__item" :class="key === index ? `err` : ``">
            {{ newId }}
          <button v-if="editMode" class="delete" @click="removeId(index)">
            x
          </button>
        </div>
      </li>
    </ul>
    <template v-if="errorState">
      <div v-for="(key, keyIndex) in Object.keys(errorState)" :key="keyIndex">
      <span class="err-message"
        >{{ outputErrMessage(errorState[key].name) }}</span
      >
    </div>
    </template>
    <template v-if="errorMess">
      <div class="err-message">{{ errorMess }}</div>
    </template>
    <span v-if="editMode" class="add-btn" @click="addId(newId)"
      >寺院IDを追加</span
    >
    <input
      v-if="editMode"
      id="input"
      v-model="newId"
      type="number"
      :list="id"
      placeholder="寺院Idを入力してください"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { outputErrMessage } from '@/utils/validation';
export default {
  name: 'GroupTempleIdInput',
  props: {
    name: { type: String, default: '' },
    modelValue: { type: Array, default: () => [] },
    tagTextColor: { type: String, default: 'white' },
    tagBgColor: { type: String, default: 'rgb(250, 104, 104)' },
    tagClass: { type: String, default: '' },
    editMode: { type: Boolean },

    errorState: {
      type: [String, Number, Object],
      required: false,
    },
  },
  setup(props, { emit }) {
    // console.log('props.modelValue', props.modelValue);
    let tagInputShow = ref(false);
    const newIds = ref(props.modelValue);
    console.log('newIds',newIds)
    const tagsClass = ref(props.tagClass);
    const newId = ref('');
    let errorMess = ref('');
		let updateFlg = ref(false);
    const id = Math.random()
      .toString(36)
      .substring(7);

    // handling duplicates
    const duplicate = ref(null);
    const handleDuplicate = (newId) => {
      duplicate.value = newId;
      setTimeout(() => (duplicate.value = null), 1000);
      newId.value = '';
    };

    const addId = (id) => {

      if (!id) return; // prevent empty tag

      if (newIds.value.includes(id)) {
        handleDuplicate(id);
        return;
      }

      // console.log('tags.value.length', tags.value.length);
      emit('update:modelValue', newIds.value);
      newIds.value.push(id);
      newId.value = '';
       console.log('newId.value',newId.value)
    };

    // const addIdIfDelem = (id) => {
    //   if (!customDelimiter || customDelimiter.length == 0) return;
    //   if (customDelimiter.includes(id.charAt(id.length - 1)))
    //     addId(id.substr(0, id.length - 1));
    // };

    const removeId = (index) => {
      newIds.value.splice(index, 1);
			updateFlg.value = true;
    };

    return {
      newIds,
      newId,
      tagsClass,
      addId,
      removeId,
      id,
      errorMess,
      duplicate,
      // addIdIfDelem,
      // noMatchingTag,
      tagInputShow,
      outputErrMessage,
			updateFlg,
    };
  },
  methods: {},
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.tag-input {
  padding: 8px;
  position: relative;
}
ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  list-style: none;
  margin: 0;
  padding: 0;
  --tagBgColor: rgb(250, 104, 104);
  --tagTextColor: white;
}

.delete {
  background-color: #333;
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  outline: none;
  position: absolute;
  right: -6px;
  text-align: center;
  top: -4px;
  width: 20px;
}
.tag.duplicate {
  animation: shake 1s;
  background: rgb(235, 27, 27);
}
.duplicate input {
  animation: shake1 0.5s;
  border: 1px solid rgb(235, 27, 27);
  outline: rgb(235, 27, 27);
}
input {
  border: 1px dotted rgb(51, 51, 51);
  outline: none;
  padding: 15px;
}
.count {
  display: block;
  font-size: 0.8rem;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}
.count span {
  background: #eee;
  border-radius: 2px;
  padding: 2px;
}
.with-count input {
  padding-right: 60px;
}
.with-count ul {
  max-width: 60%;
}
.err {
  color: #c84d5f;
}
.p-tag__item{
background-color: #fff;
}
</style>

/**
 * オブジェクト配列の中から指定のhash値を持つオブジェクトを取得
 *
 * @param {Array} items hashプロパティを含むオブジェクトの配列
 * @param {String} hash 検索対象のhashの値
 */
export const getItemByHash = (items, hash) => {
  return items.find(item => item.hash === hash);
}

/**
 * オブジェクトにhashを付与
 *
 * @param {Object} object 任意のオブジェクト
 */
export const withHash = (object) => {
  object.hash = Math.random().toString(32).substring(2);
  return object
}
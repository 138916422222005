<template>
<router-link :to="`/search?tag=${tagName}`">#{{ tagName }}</router-link>
</template>
<script>
export default {
  name: 'SlideTag',
  props: {
    tagName: String,
    id: Number
  }
}
</script>
<style scoped>
a {
	background-color: #fff;
	border-radius: 4rem;
	box-shadow: 0 0 15px #0001a;
	color: #000;
	display: inline-block;
	font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
	font-size: 1.2rem;
	letter-spacing: .24rem;
	opacity: .9;
	padding: 1.9rem 2.1rem;
	position: absolute;
	white-space: nowrap;
	z-index: 50;
}
@media only screen and (max-width: 768px) {
	a {
		border-radius: 2rem;
		font-size: .8rem;
		letter-spacing: .1rem;
		padding: .9rem 1.4rem;
	}
}
</style>
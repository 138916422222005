<template>

  <div class="p-article-group l-wrapper">
    <div v-if="articlesGroups.length > 0">
      <select
        class="p-select"
        v-if="editMode && !editBox"
        :value="articleGroupId"
        id="selectArticlesGroup"
        @change="$emit('update:articleGroupId', Number($event.target.value))"
      >
        <option
          v-for="option in articlesGroups"
          :key="option.id"
          :value="option.id"
          :selected="option.id === Number(articleGroupId)"
        >
          {{ option.name }}
        </option>
      </select>
    </div>

    <div v-if="!editMode && articleGroup" class="view-mode p-select">
      {{ articleGroup.name }}
    </div>

    <div
      class="add-group"
      v-if="(editMode && editBox) || (editMode && articlesGroups.length === 0)"
    >
      新しい記事グループ追加
    </div>
    <p class="add-group__text" v-if="editMode">プロジェクト記事、イベント記事、レポート記事をひとまとめにしてグループ表示させることが可能です。<br>新たなグループ名を入力するか、過去作ったグループを選択してください。</p>
    <input
      v-if="(editMode && editBox) || (editMode && articlesGroups.length === 0)"
      :value="newArticleGroup.name"
      type="text"
      autocomplete="off"
      @input="updateValue"
      placeholder="新しい記事グループ名を入力してください"
      :class="errorState ? `err p-articles-group__input` : `p-articles-group__input`"
    />
    <button
      class="add-btn"
      v-if="editMode && !editBox && articlesGroups.length > 0"
      @click="openTextArea"
    >
      記事グループを追加
    </button>
    <span v-if="errorState">
      <div
        v-for="(key, index) in Object.keys(errorState)"
        :key="index"
        class="err-message"
      >
        {{ errorState[key] }}
      </div>
    </span>
  </div>
</template>

<script>
import { ref, watch, onUpdated } from 'vue';
export default {
  name: 'ArticleGroup',
  props: {
    editMode: {
      type: Boolean,
    },
    articlesGroups: {
      type: Object,
    },
    articleGroup: {
      type: Object,
    },
    articleGroupId: {
      type: Number,
    },
    templeId: {
      type: Number,
    },
    errorState: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    onUpdated(() => {
      console.log('**********articlesGroups*********', props.articlesGroups);
    });

    let editBox = ref(false);
    const openTextArea = () => {
      editBox.value = true;
    };

    const newArticleGroup = ref({
      temple_id: props.templeId,
      name: '',
    });

    const updateValue = (event) => {
      newArticleGroup.value = {
        temple_id: props.templeId,
        name: event.target.value,
      };

      emit('update:articleGroup', newArticleGroup.value);
      console.log(':::newArticleGroup::::', newArticleGroup.value);
    };

    const onChangeSelect = (event) => {
      console.log('*******$event.target.value', event.target.value);
      console.log(
        '*******onChangeSelect内ーーarticlesGroups',
        props.articlesGroups
      );
    };

    watch(
      () => props.editMode,
      (newEditMode, oldEditMode) => {
        console.log(`${oldEditMode} -> ${newEditMode}`);
        editBox.value = false;
      }
    );

    return {
      openTextArea,
      editBox,
      updateValue,
      onChangeSelect,
      newArticleGroup,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
	display: block;
	margin: 15px auto 0;
}

.p-articles-group {
	&__input {
		border: #333 dotted 1px;
		display: block;
		line-height: 2;
		margin: 30px auto 0;
		padding: 8px;
		width: 70%;
		&.err {
			border: #c84d5f dotted 1px;
		}
	}
}

.add-group {
	display: inline-block;
	font-family: $contents_fonts_jp_Gothic;
	font-size: 1.5rem;

	&__text {
		line-height: 1.5;
		margin-top: 20px;
	}
}

.l-project .p-select {
	background-color: #6a7bb7;
}

.l-report .p-select {
	background-color: #749e1d;
}

.err-message {
	margin: 10px auto 0;
	width: 70%;
}
</style>

<template>
    <router-link :class="[color, 'box', isSmall && 'compact', isSingle && 'single']" :to="path" v-if="link">
        <div :class="isNonClip && 'non-clip'">
            <img :src="imageSrc" v-if="imageSrc">
            <div v-else class="no-image"></div>
        </div>
        <div class="vertical-flex">
            <p :class="['role']" v-if="role">{{ role }}</p>
            <p :class="[isNonClip && 'small', 'name']">{{ name }}</p>
            <p class="detail" v-if="detail">{{ detail }}</p>
        </div>
    </router-link>
  <div v-else :class="[color, 'box', isSmall && 'compact', isSingle && 'single']" >
    <div :class="isNonClip && 'non-clip'">
      <img :src="imageSrc" v-if="imageSrc">
      <div v-else class="no-image"></div>
    </div>
    <div class="vertical-flex">
      <p :class="['role']" v-if="role">{{ role }}</p>
      <p :class="[isNonClip && 'small', 'name']">{{ name }}</p>
      <p class="detail" v-if="detail">{{ detail }}</p>
    </div>
  </div>

</template>
<script>
export default {
  name: 'IconBox',
  props: {
    id: Number,
    imageSrc: String,
    role: String,
    name: String,
    detail: String,
    isNonClip: Boolean,
    color: String,
    link: Boolean,
    isSmall: Boolean,
    isSingle: Boolean,
    path: String
  }
}
</script>
<style scoped lang="scss">
.box {
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 8px;
	margin-right: 3.6rem;
    z-index: 9;
    position: relative;
}
.vertical-flex {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.box > div:not(.vertical-flex) {
	margin-right: 1.3rem;
}
.box > div:first-child {
	flex: none;
}
img {
	border-radius: 50%;
	height: 7rem;
	object-fit: cover;
	width: 7rem;
}
.non-clip img {
	border-radius: 0;
	height: 5.8rem;
	object-fit: contain;
	width: 4rem;
}
.compact img{
  height: 5rem;
  width: 5rem;
}
.name {
	font-size: 1.8rem;
  line-height: 1.2;
}
.detail {
	font-size: 1.1rem;
	line-height: 1.8rem;
}
.small {
	font-size: 1.6rem;
	letter-spacing: .16rem;
}
.compact .role{
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

.compact .name{
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
  letter-spacing: 0.1rem;
}
.single{
  .role {
    font-size: 1.3rem;
    @include sp {
      font-size: 1rem;
    }
    line-height: 2;
  }
  .name {
    font-size: 1.8rem;
    @include sp {
      font-size: 1.3rem;
    }
    line-height: 1.5;
  }
  .detail {
    font-size: 1.1rem;
    @include sp {
      font-size: .8rem;
      line-height: 1.25;
    }
    line-height: 2.2;
  }
}

.blue .role {
	color: #6a7bb7;
}
.orange .role {
	color: #be6c4c;
}
.green .role {
  color: #749e1d;
}

.orange .non-clip, .blue .non-clip {
	text-align: center;
	width: 7rem;
}
.black .role {
	font-size: 1.1rem;
}
.black .name {
	font-size: 1.6rem;
}
.no-image {
	background-color: #ddd;
	border-radius: 50%;
	height: 7rem;
	width: 7rem;
}
.compact .no-image{
  height: 5rem;
  width: 5rem;
}
@media only screen and (max-width: 768px) {
    .box{
        margin-right: 0.8rem;
        flex: 1 1 50%;
    }
    .box > div{
        margin-right: 0.8rem;
    }
    .box > div:not(.vertical-flex) {
       margin-right: 0.8rem;
    }
    .vertical-flex {
        justify-content: flex-start;
    }
    img, .no-image {
        height: 4.2rem;
        width: 4.2rem;
    }
    .role{
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    .name, .small{
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
    }
    .detail{
        font-size: 0.8rem;
        line-height: 1.2rem;
    }
    .non-clip img{
        width: 2.6rem;
        height: 4rem;
    }
    .orange .non-clip, .blue .non-clip {
        width: 2.6rem;
    }
    .black .name {
        font-size: 1.1rem;
    }
    .black .role{
        font-size: 0.9rem;
    }
}
</style>
<template>
  <div :class="['container', decorate && 'decorate']">
    <template v-if="!fetching">
      <div v-if="state.proposedList.length > 0" class="project-cover flex">
        <ProposedItem
          v-for="(item, index) in state.proposedList"
          :key="index"
          :item="item"
          :templeId="conditions.temple_id"
        />
      </div>
      <p v-else class="no-list">該当企画はありません</p>

    </template>
    <Fetching v-else />

    <Pagination
      :on-click-page="handleClickPage"
      :total="pagination.total"
      :current="pagination.current"
      :perPage="pagination.perPage"
    />
  </div>
</template>

<script>
import { useArticles } from '@/composables/useArticles';
import ProposedItem from "@/components/ProposedItem";
import Pagination from '@/components/Pagination';
import Fetching from '@/components/Fetching';
import { watch } from 'vue';

export default {
  name: 'ProposedList',
  props: {
    type:{
      type: String
    },
    limit: {
      type: Number,
    },
    conditions: {
      type: Object,
    },
    decorate: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
    },
  },
  components: {
    ProposedItem,
    Pagination,
    Fetching,
  },
  setup(props) {
    const { getProposedList, state, pagination, fetching } = useArticles();
    getProposedList(props.type, props.limit, props.conditions);

    watch(()=>props.conditions, (to, from) => {
      if(to !== from){
        getProposedList(props.type, props.limit, props.conditions);
      }
    });
    const handleClickPage = (number) => {
      getProposedList(
          props.type,
          props.limit,
          Object.assign({}, props.conditions, { page: number })
      );
    };

    return {
      state,
      handleClickPage,
      pagination,
      fetching,
    };
  },
};
</script>
<style lang="scss" scoped>
.sp-only {
  display: none;
}
.relative {
  position: relative;
}
.sp-only.relative {
  overflow-x: hidden;
}
.container {
  width: 100%;
  padding: 2rem 2rem 5rem;
}
.decorate {
  padding: 18.8rem 5rem 12rem 5rem;
  background-color: #fafafa;
  overflow-x: hidden;
}
.outer {
  position: relative;
  margin: auto;
}
.bg-pattern {
  height: 0;
  width: 0;
  margin: auto;
  position: relative;
}
img.bg01,
img.bg02 {
  position: absolute;
  width: 19.5rem;
  max-width: initial;
}
.bg01 {
  bottom: -9rem;
  right: 58rem;
}
.bg02 {
  top: 24rem;
  left: 52rem;
}
.vertical-index {
  font-family: 'aw-conqueror-didot', serif;
  writing-mode: vertical-rl;
  font-size: 1.5rem;
  letter-spacing: 0.6rem;
  position: absolute;
  top: -4rem;
  right: 4rem;
}
.vertical-index:before {
  content: '';
  height: 0.2rem;
  width: 1.5rem;
  background-color: #000;
  position: absolute;
  top: -1.7rem;
  right: -0.3rem;
}
h1 {
  text-align: center;
  max-width: 120rem;
  margin: auto;
  z-index: 30;
  position: relative;
}
h1 > img {
  max-width: 100%;
  margin: auto;
  display: block;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
}
.logo {
  width: 31rem;
  height: 12rem;
  padding-left: 5rem;
}
.sub01 {
  font-family: 'iroha-23kaede-stdn', sans-serif;
  font-size: 5.2rem;
  letter-spacing: 1.62rem;
  color: #412814;
}
.sub01 .red {
  color: #c84d5f;
}
.sub01 .blue {
  color: #6a7bb7;
}
.sub01 .green {
  color: #749e1d;
}
.sub02 {
  font-family: 'aw-conqueror-didot', serif;
  font-size: 2.7rem;
  letter-spacing: 1.89rem;
  margin-top: 3rem;
}
.sub03 {
  text-align: left;
  font-family: 'aw-conqueror-didot', serif;
  font-size: 2.7rem;
  letter-spacing: 0.89rem;
  margin-top: 3rem;
}
.sub04 {
  text-align: left;
  font-family: 'aw-conqueror-didot', serif;
  font-size: 1.7rem;
  letter-spacing: 0.89rem;
}
.lead {
  font-family: dnp-shuei-mincho-pr6, sans-serif;
  font-size: 2.3rem;
  text-align: center;
  letter-spacing: 0.458rem;
  line-height: 5rem;
  margin: 6rem auto;
}
.no-list {
  text-align: center;
  letter-spacing: 0.2rem;
}
.add-btn__wrapper {
  text-align: center;
}

.flex {
  display: flex;
  max-width: 120rem;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;

  @include sp{
    justify-content: center;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 35.5rem;
    height: 100%;
  }
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
  .sp-none {
    display: none;
  }
  .sp-only {
    display: block;
  }
  .container {
    padding: 0 3rem 6rem 3rem;
  }
  .title-line {
    background-color: #fafafa;
    padding-top: 7rem;
  }
  .title {
    flex-flow: column;
  }
  .logo {
    padding-left: 0;
    width: 21.4rem;
    height: 8.2rem;
  }
  .sub01 {
    font-size: 4.6rem;
    letter-spacing: 1.1rem;
    margin-top: 4.5rem;
  }
  .sub02 {
    font-size: 2.3rem;
    letter-spacing: 1.5rem;
  }
  .lead {
    text-align: left;
  }
  .lead br {
    display: none;
  }
  .bg01 {
    bottom: -11rem;
    right: 20rem;
  }
  .bg02 {
    top: 0;
    left: 57rem;
  }
}
</style>

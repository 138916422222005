import { ref, reactive, readonly, computed } from 'vue';
import { useCommons } from './useCommons';
import { withHash } from '@/utils/hash';
import { deleteNullProperties } from '@/utils/helper';
import InputSubdomain from '@/components/InputSubdomain.vue';

const { loading, postLoading, openModal } = useCommons();

export const useTemples = (id, domain) => {
  // 管理したいステートを定義
  const state = reactive({
    currentTemple: {},
    temples: [],
  });

  const modalCheck = reactive({
    isDisable: true,
    message: '',
    loading: false
  })

  const origin = {
    currentTemple: {},
    temples: [],
  };

  const pagination = reactive({
    total: null,
    current: null,
    perPage: null,
  });


  const control = ref({});

  const error = ref(null);

  const fetching = ref(false);

  const createModalItems = (temple) => {
    return [
      {
        title: '変更を取消',
        text:
          'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を取消しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '取消する',
        class: 'delete',
        function: resetCurrentTempleSubmit,
        condition: true,
      },
      {
        title: '変更を保存',
        text:
          'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を保存しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '保存する',
        class: 'update',
        function: postTemple,
        condition: temple.temple_website.release_flg === 1,
      },
      {
        title: '下書き保存',
        text:
          'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を保存しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '保存する',
        class: 'submit',
        function: postTemple,
        condition: temple.temple_website.release_flg === 0,
      },
      {
        title: '公開する',
        text:
          '変更を保存して公開します。<br class="u-use--sp">よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '公開する',
        class: 'publish',
        function: publishTemple,
        condition: temple.temple_website.release_flg === 0 && (temple.domain !== null && temple.domain !== ""),
      },
      {
        title: '公開する',
        component: InputSubdomain,
        props: checkSubdomain,
        state: modalCheck,
        cancel: resetSubdomain,
        btnType: 'cancel&submit',
        submitBtnText: '公開する',
        class: 'publish',
        function: publishTemple,
        condition: temple.temple_website.release_flg === 0 && !temple.domain,
      },
    ].filter((item) => item.condition);
  };

  const createNav = (id) => {
    return [
      {
        path: '/temples/' + id,
        pathType: 'router-link',
        imagePath: require('@/assets/image/common/icon-toppage.svg'),
        text: 'トップページ',
      },
      {
        imagePath: require('@/assets/image/icon_new.png'),
        text: '記事作成',
        children: [
          {
            path: '/temples/' + id + '/articles?target=project',
            pathType: 'router-link',
            imagePath: require('@/assets/image/common/icon-project.svg'),
            text: 'プロジェクト（活動理念を書くページ）',
          },
          {
            path: '/temples/' + id + '/articles?target=event',
            pathType: 'router-link',
            imagePath: require('@/assets/image/common/icon-event.svg'),
            text: 'イベント（参加者募集をするページ）',
          },
          {
            path: '/temples/' + id + '/articles?target=report',
            pathType: 'router-link',
            imagePath: require('@/assets/image/common/icon-report.svg'),
            text: 'レポート（活動の様子を書くページ）',
          },
        ],
      },
      {
        path: '/temples/' + id + '/flow',
        pathType: 'router-link',
        imagePath: require('@/assets/image/common/icon-flag.svg'),
        text: '進行中の案件',
      },
      {
        path: "/temples/" + id + "/proposed",
        pathType: "router-link",
        imagePath: require("@/assets/image/common/icon-comment.svg"),
        text: "お寺への提案",
      },
      {
        path: '/temples/temple_members/?temple_id=' + id,
        pathType: 'a',
        imagePath: require('@/assets/image/common/icon-follower.svg'),
        text: 'ご縁リスト',
      },
      {
        path: "/temples/add_monk/?temple_id=" + id,
        pathType: "a",
        imagePath: require("@/assets/image/common/icon-coeditor.svg"),
        text: "共同編集者",
      },
      {
        path: "/temples/edit_web/?temple_id=" + id,
        pathType: "a",
        imagePath: require("@/assets/image/common/icon-information.svg"),
        text: "基本情報",
      },
    ];
  };

  const setControlSimple = (id, path) => {
    control.value = {
      type: '寺院ページ編集',
      id: id,
      routePath: path,
      simple: true,
      statusText: null,
      nav: createNav(id),
      modalItems: [],
    };
  };

  const setControl = (temple) => {
    const status =
      temple.temple_website.release_flg === 0 ? '未公開' : '公開中';
    control.value = {
      type: '寺院ページ編集',
      id: temple.id,
      routePath: 'Temple',
      statusText: temple.name + ' 寺院ページは現在【' + status + '】です。',
      nav: createNav(temple.id),
      modalItems: createModalItems(temple),
    };
  };

  const resetCurrentTemple = () => {
    state.currentTemple = JSON.parse(JSON.stringify(origin.currentTemple));
  };

  const resetCurrentTempleSubmit = () => {
    postLoading.value = true;
    resetCurrentTemple();
    const resetModal = {
      title: '変更を取消しました',
      text: '',
      btnType: 'close',
      submitBtnText: '閉じる',
    };
    postLoading.value = false;
    openModal(resetModal);
  };

  //寺院一覧取得
  const getTemples = async (limit = 12, conditions = {}) => {
    fetching.value = true;
    const params = {
      limit: limit,
      sort: 'created_at',
      direction: 'DESC',
    };
    Object.assign(params, conditions);
    deleteNullProperties(params);
    if (params.keyword) {
      params.name = params.keyword;
    }

    if (params.templeIds) {
      params.templeIds = params.templeIds.join(',');
    }

    const queryParams = new URLSearchParams(params);
    try {
      const data = await fetch(`/api/temples?${queryParams}`);

      if (!data.ok) {
        throw Error('No data available');
      }
      const temples = await data.json();

      state.temples = JSON.parse(JSON.stringify(temples)).data;
      origin.temples = JSON.parse(JSON.stringify(temples)).data;

      pagination.total = temples.total;
      pagination.perPage = temples.per_page;
      pagination.current = temples.current_page;

      fetching.value = false;
      // console.log(temples);
    } catch (err) {
      error.value = err.message;
      fetching.value = false;
      // console.log(error.value);
    }
  };

  // 寺院取得
  const getCurrentTemple = async (templeId, templeDomain) => {
    const idForFetch = templeId ? templeId : id;
    const domainForFetch = templeDomain ? templeDomain : domain;
    loading.value = true;
    const params = {
      temple_id: idForFetch,
      domain: domainForFetch,
    };

    const queryParams = new URLSearchParams(params);
    try {
      const data = await fetch(`/api/temples/common?${queryParams}`);

      if (!data.ok) {
        error.value = {
          code: 404,
        };

        throw Error('No data available');
      }
      const currentTemple = await data.json();

      if (!currentTemple.data.temple_website) {
        error.value = {
          code: 404,
        };

        throw Error('No data available');
      }
      // Editableでの初期値対応
      if (!currentTemple.data.temple_website.content) {
        currentTemple.data.temple_website.content = {
          thumbnail: '',
          items: [],
        };
      }
      currentTemple.data.temple_website.content.items.forEach((item) => {
        withHash(item);
      });

      state.currentTemple = JSON.parse(JSON.stringify(currentTemple)).data;
      origin.currentTemple = JSON.parse(JSON.stringify(currentTemple)).data;
      setControl(state.currentTemple);
      // console.log('======state.currentTemple===', state.currentTemple);
    } catch (err) {
      console.log(err);
    }

    loading.value = false;
  };

  // 寺院ページ更新
  const postTemple = async (publish = false) => {
    const publishPath = publish ? '/publish' : '';
    postLoading.value = true;
    fetch(`/api/temples/${state.currentTemple.id}${publishPath}`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state.currentTemple),
    })
      .then((response) => response.json())
      .then((data) => {
        postLoading.value = false;
        // console.log('postArticle===data', data);

        state.currentTemple = JSON.parse(JSON.stringify(data.data));
        origin.currentTemple = JSON.parse(JSON.stringify(data.data));
        const successModal = {
          title: publish ? '寺院ページを公開しました' : '寺院ページを更新しました',
          text: '',
          btnType: 'close',
          submitBtnText: '閉じる',
        };
        openModal(successModal);
        publish && setControl(state.currentTemple);
      })
      .catch((error) => {
        console.log(error);
        postLoading.value = false;
      });
  };

  // 寺院ページ公開
  const publishTemple = async () => {
    await postTemple(true);
    // location.href = '/temples/edit_web/?temple_id=' + state.currentTemple.id;
  };

  const deleteContentBlock = (index) => {
    state.currentTemple.temple_website.content.items.splice(index, 1);
  };

  const addContentBlock = (newListItem) => {
    state.currentTemple.temple_website.content.items.push(
      withHash(newListItem)
    );
  };

  const isCurrentTempleSet = computed(
    () => !!Object.keys(state.currentTemple).length
  );

  // サブドメイン チェック
  const checkSubdomain = async (str) => {
    modalCheck.loading = true

    try {
      const data = await fetch(`/api/temples/${str}/check-subdomain`);

      if (!data.ok) {
        modalCheck.errFlg = true
        modalCheck.message = data
        // console.log('!data.ok', data)
        modalCheck.message = '指定のドメインは使用不可です'
        loading.value = false;
        return;
      }
      // console.log('data.ok', data)
    state.currentTemple.domain = str;
    modalCheck.message = 'このドメインで公開可能です'
    modalCheck.isDisable = false;
    // console.log('後checkSubdomain===modalCheck.subDomainCheckFlg', modalCheck.subDomainCheckFlg);
    } catch (err) {
      console.log(err);
    }
    modalCheck.loading = false
  };

  const resetSubdomain = async () => {
    modalCheck.isDisable = true;
    modalCheck.message = "";
    modalCheck.errFlg = false;
    modalCheck.loading = false;
    state.currentTemple.domain = null;
  }

  return {
    state: readonly(state),
    setState: state,
    initialState: origin,
    isCurrentTempleSet,
    error,
    getCurrentTemple,
    getTemples,
    postTemple,
    resetCurrentTemple,
    addContentBlock,
    deleteContentBlock,
    control,
    setControlSimple,
    resetCurrentTempleSubmit,
    fetching,
    checkSubdomain,
    modalCheck,
    pagination: readonly(pagination),
  };
};

<template>
  <div class="main-img-wrap">
    <div class="main-img">
      <div class="base-point">
        <div class="title">
          <img src="@/assets/image/top/title-cc.svg" class="title-cc">
          <img src="@/assets/image/top/title-body.svg" class="title-main">
        </div>
        <p class="copy">まち寺は、まちの人々と<br>お寺のソーシャルグッドが<br>出会い繋がる場です。</p>
        <img src="@/assets/image/top/bg-shippo-l.svg" class="bg bg-shippo-l">
        <img src="@/assets/image/top/bg-hishigata.svg" class="bg bg-hishigata01">
        <img src="@/assets/image/top/bg-hishigata.svg" class="bg bg-hishigata02 sp-none">
        <img src="@/assets/image/top/bg-shippo-s.svg" class="bg bg-shippo-s01">
        <img src="@/assets/image/top/bg-shippo-s.svg" class="bg bg-shippo-s02">
        <img src="@/assets/image/top/bg-nami.png" class="bg bg-nami01">
        <img src="@/assets/image/top/bg-icho-left.svg" class="bg bg-icho-left sp-none">
        <img src="@/assets/image/top/bg-soroban.svg" class="bg bg-soroban01">
        <img src="@/assets/image/top/bg-icho-right.svg" class="bg bg-icho-right">
        <img src="@/assets/image/top/bg-hamon.svg" class="bg bg-hamon">
        <img src="@/assets/image/top/bg-nami.png" class="bg bg-nami02">
        <img src="@/assets/image/top/bg-soroban.svg" class="bg bg-soroban02">
        <div class="bg bg-uzu"></div>
        <a class="clip clip-right01">
          <img src="@/assets/image/top/05.jpg">
        </a>
        <router-link class="clip clip-right02" to="/search?category=2">
          <img src="@/assets/image/top/02.jpg">
          <div class="tag02">
            <SlideTag
            :id="2"
            tagName="学びの時間"
            />
          </div>
        </router-link>
        <a class="clip clip-left01">
          <img src="@/assets/image/top/04.jpg">
        </a>
        <router-link class="clip clip-left02" to="/search?category=3">
          <img src="@/assets/image/top/06.jpg">
          <div class="tag03">
            <SlideTag
            :id="3"
            tagName="ココロとカラダの健康"
            />
          </div>
        </router-link>
        <router-link class="clip clip-left03" to="/search?category=2">
          <img src="@/assets/image/top/03.jpg">
          <div class="tag04">
            <SlideTag
            :id="4"
            tagName="学びの時間"
            />
          </div>
        </router-link>
      </div>
      <router-link class="center-img" to="/search?category=4">
        <img src="@/assets/image/top/01.jpg">
        <div class="tag01">
          <SlideTag
          :id="1"
          tagName="ほっとする場所"
          />
        </div>
      </router-link>
      <div class="social sp-none">
        <p>Follow us</p>
        <div>
          <a href="https://www.facebook.com/TerakoyaBuddha/" target="_blank" rel="noopener noreferrer"><img src="@/assets/image/top/icon-fb.svg" class="fb"></a>
          <!-- <a><img src="@/assets/image/top/icon-tw.svg" class="tw"></a>
          <a><img src="@/assets/image/top/icon-insta.svg" class="insta"></a> -->
        </div>
      </div>
      <div class="scroll">
        <p>プロジェクトを探す</p>
        <a><img src="@/assets/image/top/scrollbar.svg"></a>
      </div>
    </div>
  </div>
  <SpecialFeature />
  <ProjectList lead="全国の“まち寺”が展開するソーシャル・プロジェクトは実に多種多様。<br>興味のわくプロジェクトと出会って、楽しんで、応援しよう！" :limit="3" :link-all="true"/>
  <EventList :limit="6" :conditions="eventConditions" :link-all="true"/>
  <ReportList :limit="6" :link-all="true"/>
  <TempleList :limit="9" :link-all="true"/>
</template>

<script>
import SlideTag from '@/components/SlideTag.vue'
import SpecialFeature from '@/components/SpecialFeature.vue'
import ProjectList from '@/components/ProjectList.vue'
import EventList from '@/components/EventList.vue'
import ReportList from '@/components/ReportList.vue'
import TempleList from '@/components/TempleList.vue'
import {useArticles} from "@/composables/useArticles";
export default {
  name: 'Top',
  props: {

  },
  setup(){
    const {getArticles, state} = useArticles();
    getArticles(null, 6, {sort: "created_at", direction: "DESC", feature: true})
    const eventConditions = {
      merge: true
    }
    return {
      state,
      eventConditions
    }

  },
  components: {
    SlideTag,
    SpecialFeature,
    ProjectList,
    EventList,
    ReportList,
    TempleList
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.sp-only {
	display: none;
}
.main-img-wrap {
	height: 90rem;
	overflow: hidden;
	width: 100%;
}
.main-img {
	height: 97rem;
	@include pc {
		left: -10%;
		top: -4rem;
		transform: scale(.9);
		width: calc(100vw / .81);
	}
	overflow-x: hidden;
	overflow-y: visible;
	padding-top: 12rem;
	position: relative;
}
.base-point {
	height: 0;
	margin: auto;
	position: relative;
	width: 0;
}
.title {
	left: 8.5rem;
	max-width: initial;
	position: absolute;
	top: 5rem;
	width: 42rem;
	z-index: 99;
}
.title-cc {
	bottom: 1rem;
	left: 14rem;
	position: relative;
}
.copy {
	font-family: "iroha-23kaede-stdn", Avenir, Helvetica, Arial, sans-serif;
	font-size: 2.5rem;
	left: 24rem;
	letter-spacing: 0.7rem;
	line-height: 4.2rem;
	position: absolute;
	top: 33rem;
	width: 50rem;
	z-index: 30;
}
.bg {
	max-width: initial;
	position: absolute;
	z-index: 1;
}
.bg-shippo-l {
	right: 8rem;
	top: 3rem;
	width: 18.4rem;
}
.bg-hishigata01 {
	left: 17rem;
	top: 1rem;
	width: 4rem;
}
.bg-hishigata02 {
	right: 16rem;
	top: 67rem;
	width: 4rem;
}
.bg-shippo-s01 {
	right: 18rem;
	top: 74rem;
	width: 3.9rem;
}
.bg-shippo-s02 {
	left: 58rem;
	top: 41rem;
}
.bg-uzu {
	background-image: url("~@/assets/image/top/bg-uzu.svg");
	background-position: 50% 100%;
	background-repeat: no-repeat;
	content: "";
	height: 11rem;
	left: 31rem;
	top: 0;
	width: 24rem;
}
.bg-nami01 {
	left: 18rem;
	top: 58rem;
	width: 11rem;
}
.bg-icho-left {
	left: 35rem;
	top: 46rem;
	width: 6rem;
}
.bg-soroban01 {
	left: 70rem;
	top: 24rem;
	width: 10.7rem;
}
.bg-icho-right {
	right: 33rem;
	top: 16rem;
	width: 6rem;
}
.bg-hamon {
	right: 29rem;
	top: 21rem;
	width: 18.4rem;
}
.bg-nami02 {
	right: 46rem;
	top: 34rem;
	width: 11rem;
}
.bg-soroban02 {
	right: 66rem;
	top: 58rem;
	width: 10.7rem;
}
.bg-icho-right, .bg-icho-left {
	-webkit-animation: move-left 5s infinite ease-in-out;
	animation: move-left 5s infinite ease-in-out;
}
.bg-hishigata01, .bg-hishigata02, .bg-soroban01, .bg-soroban02 {
	-webkit-animation: move-right 5s infinite ease-in-out;
	animation: move-right 5s infinite ease-in-out;
}
@keyframes move-left {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(-15px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}
@keyframes move-right {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(15px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}
.center-img {
	/* left: 33%; */
	left: calc(50% - 29.7rem);
	position: absolute;
	top: 28%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index: 20;
}
.center-img img {
	border-radius: 35% 0 35% 0;
	height: 47.6rem;
	object-fit: cover;
	width: 47.6rem;
}
.clip {
	max-width: initial;
	position: absolute;
}
.clip img {
	border-radius: 35% 0 35% 0;
	object-fit: cover;
}
.clip-right01 {
	left: 55rem;
	top: 10rem;
}
.clip-right01, .clip-right01 img {
	height: 15.3rem;
	width: 15.3rem;
}
.clip-right02 {
	left: 41rem;
	top: 49rem;
}
.clip-right02, .clip-right02 img {
	height: 24.5rem;
	width: 24.5rem;
}
.clip-left01 {
	right: 40rem;
	top: 3rem;
}
.clip-left01, .clip-left01 img {
	height: 15.3rem;
	width: 15.3rem;
}
.clip-left02 {
	right: 66rem;
	top: 14rem;
}
.clip-left02, .clip-left02 img {
	height: 22.1rem;
	width: 22.1rem;
}
.clip-left03 {
	right: 34rem;
	top: 49rem;
}
.clip-left03, .clip-left03 img {
	height: 29.7rem;
	width: 29.7rem;
}
.center-img, .clip-right02, .clip-left02 {
	-webkit-animation: fuwafuwa-up 4s infinite ease-in-out;
	animation: fuwafuwa-up 4s infinite ease-in-out;
}
.clip-right01, .clip-left01, .clip-left03 {
	-webkit-animation: fuwafuwa-down 4s infinite ease-in-out;
	animation: fuwafuwa-down 4s infinite ease-in-out;
}

@keyframes fuwafuwa-up {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0, -15px);
	}
	100% {
		transform: translate(0, 0);
	}
}
@keyframes fuwafuwa-down {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0, 15px);
	}
	100% {
		transform: translate(0, 0);
	}
}
.social {
	align-items: center;
	display: flex;
	flex-direction: column;
	left: 4%;
	position: fixed;
	top: 42rem;
	z-index: 99;
}
.social > p {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.3rem;
	letter-spacing: .5rem;
	margin-bottom: 3rem;
	writing-mode: vertical-rl;
}
.social > div {
	display: flex;
	flex-direction: column;
}
.social > div a {
	margin-bottom: 2rem;
}
.scroll {
	align-items: center;
	bottom: 0;
	display: flex;
	flex-direction: column;
	left: 49%;
	position: absolute;
	-webkit-transform: translate(-50%,0);
	transform: translate(-50%,0);
	z-index: 20;
}
.scroll p {
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
	font-size: 1.3rem;
	letter-spacing: .5rem;
	margin-bottom: 1.5rem;
}
.tag01, .tag02, .tag03, .tag04 {
	position: relative;
}
.tag01 {
	bottom: 80px;
	left: 370px;
}
.tag02 {
	bottom: 10rem;
	left: 20rem;
}
.tag03 {
	bottom: 7rem;
	left: 18rem;
}
.tag04 {
	bottom: 10rem;
	left: 23rem;
}

/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width:768px) {
	.sp-none {
		display: none;
	}
	.sp-only {
		display: block;
	}
	.main-img-wrap {
		height: 68.5rem;
	}
	.main-img {
		height: 68.5rem;
		padding-top: 72px;
	}
	.center-img {
		// left: 15%;
		left: calc(50% - 14rem);
		top: 42%;
	}
	.center-img img {
		height: 14rem;
		max-width: none;
		width: 14rem;
	}
	.tag01 {
		bottom: 9rem;
		left: auto;
		right: 4rem;
	}
	.title {
		left: -3.5rem;
		top: 18rem;
	}
	.title-main {
		position: relative;
		right: 22.5rem;
		top: 4rem;
		width: 14rem;
	}
	.title-cc {
		bottom: 5rem;
		left: -6.5rem;
		position: relative;
		width: 17rem;
	}
	.copy {
		font-size: 1.4rem;
		left: -6rem;
		letter-spacing: .3rem;
		line-height: 2.5rem;
		top: 35rem;
	}
	.clip-left01 {
		right: 15rem;
		top: 11.5rem;
	}
	.clip-left01, .clip-left01 img {
		height: 4.2rem;
		width: 4.2rem;
	}
	.clip-left02 {
		right: 4rem;
		top: .5rem;
		z-index: 9;
	}
	.clip-left02, .clip-left02 img {
		height: 7.5rem;
		width: 7.5rem;
	}
	.tag03 {
		bottom: 4rem;
		left: auto;
		right: 8rem;
	}
	.clip-left03 {
		right: 11rem;
		top: 38rem;
		z-index: 9;
	}
	.clip-left03, .clip-left03 img {
		height: 7.5rem;
		width: 7.5rem;
	}
	.tag04 {
		bottom: 7rem;
		left: 4rem;
	}
	.clip-right01 {
		left: 5rem;
		z-index: 9;
	}
	.clip-right01, .clip-right01 img {
		height: 5.8rem;
		left: 10rem;
		top: 6rem;
		width: 5.8rem;
	}
	.clip-right02 {
		left: 10rem;
		top: 42rem;
		z-index: 9;
	}
	.clip-right02, .clip-right02 img {
		height: 8.7rem;
		width: 8.7rem;
	}
	.tag02 {
		bottom: 4rem;
		left: auto;
		right: 2.5rem;
	}
	.bg-soroban01 {
		left: 14rem;
		top: 51rem;
		width: 5.3rem;
	}
	.bg-hishigata01 {
		left: 10rem;
		top: 3rem;
		width: 2rem;
	}
	.bg-shippo-s02 {
		left: 11rem;
		top: 30.5rem;
		width: 2rem;
	}
	.bg-uzu {
		background-position: 10% 50%;
		background-size: cover;
		height: 14.3rem;
		left: 9rem;
		top: 11rem;
		width: 11.8rem;
	}
	.bg-shippo-l {
		right: 6rem;
		top: 31rem;
		width: 9.2rem;
		z-index: 1;
	}
	.bg-nami01 {
		left: 4rem;
		top: 4rem;
		width: 5.8rem;
		z-index: 1;
	}
	.bg-icho-right {
		right: 6.5rem;
		top: 47rem;
		width: 3rem;
	}
	.bg-soroban02 {
		right: 10rem;
		top: 8rem;
		width: 5.3rem;
	}
	.bg-hamon {
		right: -13rem;
		top: 39rem;
		width: 9.2rem;
		z-index: 1;
	}
	.bg-shippo-s01 {
		right: 13rem;
		top: 47.5rem;
		width: 2rem;
	}
	.scroll p {
		font-size: .9rem;
	}
	.scroll img {
		height: 6rem;
	}
}
</style>

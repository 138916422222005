import { ref, reactive, readonly, computed } from 'vue';
import { useCommons } from './useCommons';
import { withHash } from "../utils/hash";

const { loading, postLoading, openModal } = useCommons();

export const usePages = () => {
  // 管理したいステートを定義
  const state = reactive({
    currentPage: {},
    pages: [],
  });

  const origin = {
    currentPage: {},
    pages: [],
  };

  const control = ref({});

  const error = ref(null);

  const createModalItems = () => {
    return [
      {
        title: '変更を取消',
        text:
            'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を取消しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '取消する',
        class: 'delete',
        function: resetCurrentPageSubmit,
        condition: true
      },
      {
        title: '変更を保存',
        text:
            'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を保存しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '保存する',
        class: 'update',
        function: postPage,
        condition: true
      },
    ].filter(item => item.condition);
  }

  const createNav = () => {
    return [
      {
        path: "/",
        pathType: "router-link",
        imagePath: require("@/assets/image/common/icon-toppage.svg"),
        text: "トップページ",
      }
    ]
}

  const setControl = (slug) => {
    control.value = {
      type: "ページ編集メニュー",
      id: "",
      routePath: capitalize(slug),
      statusText: null,
      nav: createNav(slug),
      modalItems: createModalItems(slug)
    }
  }

  const resetCurrentPage = () => {
    state.currentTemple = JSON.parse(JSON.stringify(origin.currentTemple));
  };

  const resetCurrentPageSubmit = () => {
    postLoading.value = true;
    resetCurrentPage();
    const resetModal = {
      title: "変更を取消しました",
      text: '',
      btnType: 'close',
      submitBtnText: "閉じる",
    }
    postLoading.value = false;
    openModal(resetModal);
  };

  const capitalize = (str) => {
    if (typeof str !== 'string' || !str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  // 寺院取得
  const getCurrentPage = async (slug) => {
    loading.value = true;
    try {
      const data = await fetch(`/api/pages/${slug}`);
      if (!data.ok) {
        error.value = {
          code: 404
        };
      }
      const currentPage = await data.json();

      if(!currentPage.data){
        error.value = {
          code: 404
        };

        throw Error('No data available');
      }
      // Editableでの初期値対応
      if(!currentPage.data.content){
        currentPage.data.content = {
          thumbnail: "",
          items: []
        }
      }
      currentPage.data.content.items.forEach(item=>{
        withHash(item)
      })

      state.currentPage = JSON.parse(JSON.stringify(currentPage)).data;
      origin.currentPage = JSON.parse(JSON.stringify(currentPage)).data;
      setControl(state.currentPage.slug);
    } catch (err) {
      console.log(err);
      const initialData = {
        slug: slug,
        content: {
          items: []
        }
      }
      state.currentPage = JSON.parse(JSON.stringify(initialData));
      origin.currentPage = JSON.parse(JSON.stringify(initialData));
      setControl(state.currentPage.slug);
    }

    loading.value = false;
  };

  // 寺院ページ更新
  const postPage = async () => {
    postLoading.value = true;
    fetch(`/api/pages/${state.currentPage.slug}`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state.currentPage),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        postLoading.value = false;
        const successModal = {
          title: "コンテンツを更新しました",
          text: '',
          btnType: 'close',
          submitBtnText: "閉じる",
        }

        openModal(successModal);

      })
      .catch((error) => {
        console.log(error);
        postLoading.value = false;
      });
  };

  const deleteContentBlock = (index) => {
    state.currentPage.content.items.splice(index, 1);
  };

  const addContentBlock = (newListItem) => {
    state.currentPage.content.items.push(withHash(newListItem));
  };

  const isCurrentPageSet = computed(
    () => !!Object.keys(state.currentPage).length
  );

  return {
    state: readonly(state),
    setState: state,
    initialState: origin,
    isCurrentPageSet,
    error,
    getCurrentPage,
    postPage,
    resetCurrentPage,
    addContentBlock,
    deleteContentBlock,
    control,
    resetCurrentPageSubmit
  };
};

<template>
  <footer>
    <section class="donation">
      <div class="donation__wrap">
        <h2 class="donation__title">ご支援・ご寄付のお願い</h2>
        <p class="donation__text">
          まち寺プラットフォームは、活動にご賛同いただいているみなさまからのご支援・ご寄付で運営されています。<br />お寺のソーシャルプロジェクト発展のためにお力をお貸しください。
        </p>
        <router-link to="/donation" class="donation__btn"
          ><span class="donation__btn__inner">まち寺に寄付する</span>
        </router-link>
      </div>
    </section>
    <section class="sitemap">
      <div class="inner">
        <div class="links">
          <div class="bordered sp-none">
            <h1>分野から探す</h1>
            <div class="flex">
              <div class="field">
                <ul class="categories-list">
                  <li
                    class="categories-item"
                    v-for="category in catState.categories"
                    :key="category.id"
                  >
                    <Category
                      :categoryName="category.name"
                      :id="category.id"
                      :type="'textOnly'"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- bordered -->
          <div class="flex sp-none">
            <div class="index">
              <ul>
                <li>
                  <router-link to="/search?target=project&limit=12"
                    >プロジェクト
                  </router-link>
                </li>
                <li>
                  <router-link to="/search?target=event&limit=12"
                    >イベント
                  </router-link>
                </li>
                <li>
                  <router-link to="/search?target=report&limit=12"
                    >レポート
                  </router-link>
                </li>
                <li>
                  <router-link to="/search?target=temple"
                    >参加寺院一覧
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="index">
              <ul>
                <li>
                  <router-link to="/about"
                    >まち寺プラットフォームとは
                  </router-link>
                </li>
                <li>
                  <router-link to="/about/planning-proposal"
                    >寺院会員になる
                  </router-link>
                </li>
                <li>
                  <router-link to="/about/for-temples"
                    >お寺に企画を提案する
                  </router-link>
                </li>
                <li>
                  <router-link to="/donation">ご寄付のお願い</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="note">
            <ul>
              <li><a href="/events/user_guide">まち寺 利用規約</a></li>
              <li><a href="/events/privacy_policy">プライバシーポリシー</a></li>
              <li>
                <a href="/events/contract_partner">まち寺パートナー規約</a>
              </li>
              <li><a href="/events/contract_temple">寺院会員規約</a></li>
              <li><a href="/events/company/">運営団体</a></li>
            </ul>
          </div>
        </div>
        <ul class="tags sp-none">
          <li v-for="tag in catState.tags" :key="tag.id">
            <Tag :tagName="tag.name" :id="tag.id" className="white" />
          </li>
        </ul>
      </div>
    </section>
    <section class="bottom-block">
      <div class="inner">
        <div class="logo">
          <img src="" />
          <img src="" />
        </div>
        <div class="sns">
          <a
            href="https://www.facebook.com/TerakoyaBuddha/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="@/assets/image/common/icon-fb-brown.svg"
              width="28"
              height="28"
          /></a>
          <!-- <a href="" target="_blank" rel="noopener noreferrer">
            <img
              src="@/assets/image/common/icon-twitter-brown.svg"
              width="28"
              height="23"
          /></a> -->
          <!-- <a href="" target="_blank" rel="noopener noreferrer">
            <img
              src="@/assets/image/common/icon-insta-brown.svg"
              width="26"
              height="26"
          /></a> -->
        </div>
        <div class="copyright">
          <p>Copyright © 寺子屋ブッダ All Right Reserved.</p>
        </div>
      </div>
    </section>
    <router-link :to="{ hash: '#app' }" class="pagetop" @click="onClickFlg">
      <img src="@/assets/image/common/icon-totop.svg" />
      <span>Pagetop</span>
    </router-link>
  </footer>
</template>

<script>
import Tag from '@/components/Tag';
import { inject } from 'vue';
import Category from './Category.vue';

export default {
  name: 'Footer',
  components: {
    Tag,
    Category,
  },
  setup() {
    const { catState } = inject('commons');
    let { pageTopFlg } = inject('commons');

    const onClickFlg = () => {
       pageTopFlg.value = true
    }

    return {
      catState,pageTopFlg,onClickFlg
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.donation {
	background-image: url("~@/assets/image/bg.jpg");
	background-repeat: repeat;
	@include sp {
		// margin-top: 50px;
		padding: 9.3333vw 0;
	}
	padding: 7rem 0;

	&__wrap {
		@include sp {
			margin: 0 auto;
			padding: 0 2.5rem;
			width: 100%;
		}
		margin: 0 auto;
		padding: 0 13.888vw;
	}

	&__title {
		color: #412814;
		font-size: 3rem;
		@include font_iroha_600;
		@include sp {
			font-size: 1.8rem;
		}
		letter-spacing: 6px;
		line-height: 1.83;
		text-align: center;
	}

	&__text {
		color: #412814;
		font-size: 2rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.3rem;
			margin-top: 20px;
		}
		line-height: 1.74;
		margin-top: 25px;
		text-align: center;
	}

	&__btn {
		align-items: center;
		background-color: #b24b5b;
		border-radius: 50px;
		color: #fff;
		display: flex;
		font-size: 2.5rem;
		height: 8rem;
		@include font_iroha_600;
		@include sp {
			font-size: 1.6rem;
			height: 4rem;
			margin: 18px auto 0;
			width: 65.6vw;
		}
		justify-content: center;
		margin: 4rem auto 0;
		padding-left: 3.4rem;
		position: relative;
		width: 41rem;

		&__inner {
			position: relative;

			&::before {
				background: url("~@/assets/image/about/hands_white.svg") no-repeat;
				background-size: contain;
				content: "";
				display: inline-block;
				height: 3.5rem;
				@include sp {
					height: 2.1rem;
					left: -3.6rem;
					width: 2.8rem;
				}
				left: -5.5rem;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 4.7rem;
			}
		}
	}

	&__caution {
		color: #000;
		font-size: 1.3rem;
		@include sp {
			font-size: .9rem;
			margin-top: 26px;
		}
		letter-spacing: 1.3px;
		line-height: 1.92;
		margin-top: 43px;
		text-align: left;
	}
}

.flex {
	display: flex;
}

.inner {
	@include sp {
		padding: 0 25px;
		width: 100%;
	}
	@include pc {
		margin: 0 auto;
		max-width: 1300px;
		width: 85vw;
	}
}

.sitemap {
	background-image: url("~@/assets/image/bg.jpg");
	background-repeat: repeat;
	padding: 7.3rem 0 9rem;
}

h1 {
	font-family: "iroha-23kaede-stdn", Avenir, Helvetica, Arial, sans-serif;
	font-size: 2.5rem;
	margin-bottom: 2.5rem;
}

.links {
	align-items: start;
	display: flex;
	justify-content: space-between;
	margin-bottom: 6rem;

	.bordered {
		border-right: dotted 1px #000;
	}
}

.sns {
	align-items: center;
	display: flex;
	justify-content: right;
	margin-bottom: 6rem;

	> * {
		margin-left: 3rem;
	}
}

.copyright {
	border-top: solid 1px #412814;
	padding: 5rem 0 10.5rem;

	p {
		color: #000;
		font-size: 1.1rem;
		letter-spacing: .22rem;
		line-height: 1.36;
		text-align: center;
	}
}

.pagetop {
	align-items: center;
	bottom: 12rem;
	display: flex;
	justify-content: right;
	position: fixed;
	right: 3rem;
	z-index: 100;

	span {
		color: #000;
		display: inline-block;
		font-family: AWConquerorStdDidot;
		margin-left: 1.8rem;
		text-align: center;
		-webkit-writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		writing-mode: vertical-rl;
	}
}

.field {
	width: 40rem;
	& .categories-list {
		display: flex;
		flex-wrap: wrap;
	}
	& .categories-item {
		font-size: 1.5rem;
		margin-bottom: 2.5rem;
		width: 20rem;

		& .category_link {
			font-size: 1.5rem;
		}
	}
}

.index li {
	font-family: "iroha-23kaede-stdn", Avenir, Helvetica, Arial, sans-serif;
	font-size: 2rem;
	@include tablet {
		font-size: 1.4rem;
		margin-left: 5.11vw;
	}
	margin-bottom: 4rem;
	margin-left: 6.11vw;
	position: relative;
}

.index li::before {
	background: url("~@/assets/image/projects/line-arrow-right.svg") no-repeat;
	background-size: contain;
	content: "";
	display: inline-block;
	height: .555vw;
	left: -4rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 1.777vw;
}

.tags a {
	background-color: #fff;
}

.tags li {
	display: inline-block;
	margin-bottom: .8rem;
	margin-right: .8rem;
}

.note {
	@include sp {
		margin-left: 0;
	}
	@include tablet {
		margin-left: 3.44vw;
	}
	margin-left: 4.44vw;
}

.note li {
	font-size: 1.3rem;
	margin-bottom: 1.6rem;
}

/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
	.sp-none {
		display: none;
	}
	.sitemap {
		padding: 6rem 0 1rem;
	}
	.links {
		margin-bottom: 3rem;
	}
	.note ul {
		display: flex;
		flex-wrap: wrap;
	}
	.note li {
		width: 50%;
	}
	.pagetop {
		display: none;
	}
}
</style>

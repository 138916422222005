<template>
  <div class="search-area">
    <div class="search-area__inner">
      <div class="input_wrap">
        <div class="selectbox_wrap" v-if="searchConditions.prefecture">
          <select class="selectbox" name="area" v-model="area">
            <option value="" selected>エリアを選択する</option>
            <option
              :value="prefecture.prefName"
              v-for="prefecture in prefectures"
              :key="prefecture.prefCode"
            >
              {{ prefecture.prefName }}
            </option>
          </select>
        </div>
        <input
          v-if="searchConditions.keyword"
          type="text"
          class="searchTerm"
          placeholder="エリア・寺院名・キーワード"
          v-model="keyword"
        />
      </div>
      <ul class="searchItems">
        <li v-for="(target, index) in searchConditions.targets" :key="index">
          <input
            type="checkbox"
            :id="`search-area-${target.id}`"
            v-model="selectedTarget"
            :value="target.id"
          />
          <label :for="`search-area-${target.id}`">{{ target.name }}</label>
        </li>
      </ul>
      <button type="submit" class="searchButton" @click="onClickSearch">
        <img src="@/assets/image/header/icon-search_white.svg" />
        <span class="searchButton__text">検索する</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'SearchArea',
  props: {
    searchConditions: {
      type: Object,
    },
  },
  setup() {
    const router = useRouter();

    //都道府県取得
    let prefectures = ref([]);

    const fetchArea = () => {
      const api = {
        url: 'https://opendata.resas-portal.go.jp/api/v1/prefectures',
        key: 'q8yfxZ0o5WpA2pw81aQemjvI0RtY8aAVWWUkZWOR',
      };
      fetch(api.url, {
        method: 'GET',
        headers: {
          'X-API-KEY': api.key,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return (prefectures.value = data.result);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    onMounted(() => {
      fetchArea();
    });

    const selectedTarget = ref([]);
    const keyword = ref('');
    const area = ref('');

    const onClickSearch = () => {
      router.push({
        path: '/search',
        query: {
          target: selectedTarget.value.join(','),
          keyword: keyword.value,
          prefecture: area.value,
        },
      });
    };

    return {
      prefectures,
      // targets,
      selectedTarget,
      onClickSearch,
      keyword,
      area,
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.search-area {
	background-color: #f7f6f4;
	@include sp {
		display: none;
	}
	padding: 35px 0;

	&__inner {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		max-width: 120rem;
	}

	& .input_wrap {
		background: #fff;
		border: #000 1px solid;
		border-radius: 5rem;
		display: flex;
		padding: 0 20px;
		width: 48.333%;

		& .selectbox {
			-moz-appearance: none;
			appearance: none;
			border: none;
			box-sizing: border-box;
			display: block;
			outline: none;
			webkit-appearance: none;
			width: 100%;
			&_wrap {
				font-size: 1.3rem;
				@include tablet {
					width: 48%;
				}
				padding: 1.5rem 1rem;
				position: relative;
				width: 34.482%;
				&::after {
					border-bottom: #000 1px solid;
					border-right: #000 1px solid;
					content: "";
					display: block;
					height: .7rem;
					pointer-events: none;
					position: absolute;
					right: 2.5rem;
					top: 42%;
					transform: rotate(45deg) translateY(-30%);
					width: .7rem;
				}
			}
		}

		& .searchTerm {
			border: none;
			outline: none;
			padding: 1.5rem 1rem;
			width: 60.344%;
		}
	}
	& .searchItems {
		display: flex;
		margin-left: 1.666vw;

		& input[type="checkbox"] {
			display: none;
		}
		/* チェックボックスの代わりを成すラベル */
		& input[type="checkbox"] + label {
			cursor: pointer;
			display: none;
			display: inline-block;
			font-size: 1.3rem;
			padding-left: 2.5rem;
			padding-right: 1rem;
			position: relative;
		}
		/* ラベルの左に表示させる正方形のボックス□ */
		& input[type="checkbox"] + label::before {
			background-color: #fff;
			border: 1px solid;
			border-color: #000;
			box-sizing: border-box;
			content: "";
			display: block;
			height: 14px;
			left: 0;
			margin-top: -.7rem;
			position: absolute;
			top: 50%;
			width: 14px;
		}
		/* チェックが入った時の×点 */
		& input[type="checkbox"]:checked + label::after {
			background-image: url("~@/assets/image/header/icon-checked.svg");
			background-position: center center;
			background-repeat: no-repeat;
			content: "";
			height: 12px;
			left: 1.5px;
			position: absolute;
			top: 0;
			width: 12px;
		}
	}

	& .searchButton {
		align-items: center;
		background-color: #412814;
		border-radius: 25px;
		color: #fff;
		display: flex;
		font-size: 1.4rem;
		margin-left: 1.666vw;
		padding: .555vw 1.11vw;

		& img {
			height: 1.722vw;
			width: 1.722vw;
		}

		&__text {
			margin-left: 10px;
		}
	}
}
</style>

<template>
  <router-link :class="[isNew && 'new', 'element']" :to="'/temples/' + id">
    <div class="data">
      <p class="copy" :class="longTextFlg && 'long'" v-html="excerpt"></p>
      <p class="locate">{{ locate }}</p>
      <h2 class="name">{{ name }}</h2>
    </div>
    <div class="left">
      <img :src="imageSrc" class="photo" v-if="imageSrc">
      <div class="photo" v-else></div>

      <IconBox
          :id="id"
          :imageSrc="iconImageSrc"
          :role="role"
          :name="iconName ? iconName : name"
          :isNonClip="false"
          color="black"
      />
       <Like :temple-id="id"/>
    </div>
    <div class="bg-blue"></div>
  </router-link>
</template>
<script>
import IconBox from '@/components/IconBox.vue'
import Like from '@/components/Like.vue'
import { ref } from 'vue';
export default {
  name: 'TempleItem',
  props: {
    id: Number,
    copy: {
      type: String,
      default: ""
    },
    locate: String,
    name: String,
    imageSrc: String,
    isNew: Boolean,
    iconImageSrc: String,
		role:String,
    iconName: String
  },
  components: {
    IconBox,
    Like
  },
  setup(props) {
		let longTextFlg = ref(false);
		let copy =ref(props.copy);
		let excerpt = ref('');

		if (props.copy && props.copy.length < 13){

			excerpt.value = copy.value

		} else{
			longTextFlg.value = true;
			excerpt.value = copy.value ? copy.value.substr( 0, 25 ) : " ";
      excerpt.value = `${excerpt.value.slice(0, 12)}<br>${excerpt.value.slice(12)}`

		}
			return {
		longTextFlg, excerpt
	}
	}

}
</script>
<style lang="scss" scoped>
.element {
	display: flex;
	flex-direction: row-reverse;
	height: 27rem;
	position: relative;
	width: 37.5rem;
  @include tablet {
    width: 42rem;
  }
  @include between {
    width: 42rem;
  }

}
.data {
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
	padding-top: 1.5rem;
	writing-mode: vertical-rl;
	z-index: 5;
}
::v-deep .box{
  margin-right: 0 !important;
}
.copy, .locate {
	color: #6078a9;
}
.copy {
	font-size: 1.8rem;
	letter-spacing: .18rem;
	line-height: 3.5rem;
	margin-left: 4rem;
	min-width: 3.5rem;
	position: relative;
	white-space: nowrap;
}
.copy::after {
	background-image: url(~@/assets/image/temples/nami-tate.svg);
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: inline-block;
	height: 9.17rem;
	position: absolute;
	right: 5rem;
	top: 0;
	width: .29rem;
}
.copy.long::after {
	content: none;
}

.locate {
	font-size: 1.5rem;
	letter-spacing: .15rem;
	line-height: 3rem;
	margin-left: 1.5rem;
}
.name {
	font-size: 3.5rem;
	letter-spacing: .35rem;
	line-height: 3rem;
	margin-left: 2.6rem;
}
.photo {
	background-color: #412814;
	border-radius: 6rem 0 6rem 0;
	height: 17rem;
	margin-bottom: 1.7rem;
	object-fit: cover;
	position: relative;
	width: 21rem;
	z-index: 5;
}
.bg-blue {
	background-color: #edeff2;
	border-radius: 0 6rem 0 6rem;
	bottom: 0;
	height: 20rem;
	position: absolute;
	width: 22.5rem;
	z-index: 1;
}
.left {
	position: relative;
}
.left a {
	position: relative;
	z-index: 5;
}
.new::before {
	content: url("~@/assets/image/temples/icon-new.svg");
	height: 4rem;
	left: -.5rem;
	position: absolute;
	top: -.5rem;
	width: 4rem;
	z-index: 6;
}
.like_btn {
	bottom: initial;
	@include sp {
		bottom: initial;
		height: 2rem;
		left: 1rem;
		top: 9rem;
		width: 2rem;
	}
	top: 13rem;
}
@media only screen and (max-width: 768px) {
	.photo {
		border-radius: 4rem 0 4rem 0;
		height: 12rem;
		width: 14.7rem;
	}
	.bg-blue {
		border-radius: 0 4rem 0 4rem;
		bottom: 3.5rem;
		height: 14rem;
		width: 16rem;
	}
	.copy {
		font-size: 1.25rem;
		letter-spacing: .1rem;
		line-height: 1.6rem;
		margin-left: 2rem;
	}
	.copy::after {
		height: 6.4rem;
		right: 2.5rem;
		top: 0;
		width: .2rem;
	}
	.locate {
		font-size: 1rem;
		letter-spacing: .1rem;
		line-height: 1.5rem;
		margin-left: 1.5rem;
	}
	.name {
		font-size: 2.6rem;
		letter-spacing: .2rem;
		line-height: 1.5rem;
		margin-left: 2rem;
	}
	.element {
		height: 22rem;
		margin-bottom: 5rem;
		width: auto;
	}
}
</style>
<template>
  <div class="upload-content-space user-photo upload-loading">
    <div class="loader">Loading...</div>
  </div>
</template>

<script>
export default {
  name: "Fetching",
};
</script>

<style lang="scss" scoped>
.upload-loading {
	height: auto;
	padding: 10rem 0;
	width: 100%;
	z-index: 10;
}
.upload-loading .loader:before, .upload-loading .loader:after, .upload-loading .loader {
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	border-radius: 50%;
	height: 1.5em;
	width: 1.5em;
}
.upload-loading .loader {
	-webkit-animation-delay: .16s;
	animation-delay: .16s;
	font-size: 10px;
	left: 50%;
	position: absolute;
	text-indent: -9999em;
}
.upload-loading .loader:before {
	left: -2.8em;
}
.upload-loading .loader:after {
	-webkit-animation-delay: .32s;
	animation-delay: .32s;
	left: 2.8em;
}
.upload-loading .loader:before, .upload-loading .loader:after {
	content: "";
	position: absolute;
	top: 0;
}
@-webkit-keyframes load7 {
	0%, 80%, 100% {
		box-shadow: 0 1.5em 0 -1.3em #ccc;
	}
	40% {
		box-shadow: 0 1.5em 0 0 #ccc;
	}
}
@keyframes load7 {
	0%, 80%, 100% {
		box-shadow: 0 1.5em 0 -1.3em #ccc;
	}
	40% {
		box-shadow: 0 1.5em 0 0 #ccc;
	}
}
</style>

<template>
  <div class="outer">
    <img src="@/assets/image/projects/line-nami.png" class="nami" />
    <div class="inner">
      <router-link :to="'/articles/project/' + item.id">
        <template v-if="item.thumbnail">
          <div
              class="thumbnail"
              :style="`background-image: url(${item.thumbnail && item.thumbnail.thumbnail_url})`"
          >
            <Label
                v-if="item.category_id"
                :id="item.category_id"
                :category="item.category.name"
                color="green"
            />
            <Like :temple-id="item.temple_id"/>
          </div>
      </template>
      <template v-else>
        <div class="thumbnail">
          <Label
              v-if="item.category_id"
              :id="item.category_id"
              :category="item.category.name"
              color="green"
          />
          <Like :temple-id="item.temple_id"/>
        </div>
      </template>
      </router-link>
      <div class="right">
        <router-link :to="'/articles/project/' + item.id">
          <div class="time-stamp">
            <time :datetime="item.created_at">{{
              moment(item.created_at, 'YYYY/MM/DD(ddd)')
            }}</time>
          </div>
          <p class="caption" v-if="item.subtitle">{{ item.subtitle }}</p>
          <h1 v-html="nl2br(item.title)" />
        </router-link>
        <div class="icon-box">
          <IconBox
              v-if="getProfile(item.profile_content)"
              :imageSrc="getThumbnailUrl(getProfile(item.profile_content).thumbnail)"
              :name="getProfile(item.profile_content).name"
              role="企画・講師・出演"
              :detail="getProfile(item.profile_content).title"
              :isNonClip="false"
              color="blue"
              :link="true"
              :path="'/articles/project/'+item.id+'/#person'"
          />
          <IconBox
              v-if="item.temple.group_flg === 0"
              :id="item.temple_id"
              :imageSrc="require('@/assets/image/projects/icon-locate.svg')"
              role="会場"
              :name="item.temple.name"
              :detail="item.temple.prefectures + item.temple.municipality"
              :isNonClip="true"
              color="blue"
              :link="true"
              :path="'/temples/'+item.temple_id"
          />
        </div>
        <ul class="tags">
          <li v-for="tag in item.tags" :key="tag.id" class="blue">
            <Tag :tagName="tag.name" :id="tag.id" />
          </li>
        </ul>
        <ActionButton
            v-if="editMode"
            label="編集・管理"
            className="edit-link"
            :path="'/articles/project/' + item.id + '/edit'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IconBox from '@/components/IconBox.vue';
import Tag from '@/components/Tag';
import Label from '@/components/Label';
import Like from '@/components/Like';
import ActionButton from "./ActionButton";
import moment from 'moment';
import {getProfile} from "@/utils/profile";
import {getThumbnailUrl} from "@/utils/helper";
import {nl2br} from "../utils/nl2br";

export default {
  name: 'ProjectItemFull',
  props: {
    item: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean
    }
  },
  setup() {
    return {
      getProfile,
      getThumbnailUrl,
      nl2br
    };
  },
  components: {
    IconBox,
    Tag,
    Label,
    Like,
    ActionButton
  },
  methods: {
    moment(date, format) {
      moment.locale('ja');
      if (date) {
        return moment(date).format(format);
      }
    },
  },
  data() {
    return {
      tags: [
        { id: 1, tagName: 'WITHコロナ' },
        { id: 2, tagName: 'オンラインコミュニケーション' },
        { id: 3, tagName: 'ヘルシーテンプル' },
        { id: 4, tagName: '佐々木教道' },
      ],
    };
  },
};
</script>
<style scoped>
.nami {
	display: block;
	height: .34rem;
	margin: 6rem auto;
	width: 7.65rem;
}
.inner {
	display: flex;
	justify-content: space-between;
	margin: auto;
	max-width: 120rem;
}
.thumbnail {
	background-color: #e5e7ee;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 22vw;
	margin-right: 5rem;
	max-height: 32rem;
	max-width: 53rem;
	position: relative;
	width: 37vw;
}
.label {
	background-color: #7ba960;
	color: #fff;
	display: inline-block;
	font-family: "iroha-23kaede-stdn", sans-serif;
	font-size: 1.5rem;
	left: -1rem;
	letter-spacing: .03rem;
	padding: .7rem .4rem;
	position: absolute;
	text-align: center;
	top: 1rem;
}
.right {
	max-width: 60rem;
	width: 33.333vw;
}
.time-stamp {
	color: #6a7bb7;
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.1rem;
	letter-spacing: .11rem;
	margin-bottom: 1.4rem;
}
.caption, h1 {
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
}
.caption {
	font-size: 1.5rem;
	letter-spacing: .15rem;
	margin-bottom: 1.2rem;
}
h1 {
	font-size: 3rem;
	letter-spacing: .3rem;
	line-height: 4.5rem;
	margin-bottom: 2rem;
}
.icon-box {
	display: flex;
	margin-bottom: 2.6rem;
}
.tags li {
	display: inline-block;
	margin-bottom: .8rem;
	margin-right: .8rem;
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
	.inner {
		flex-direction: column;
	}
	.outer {
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}
	.thumbnail, .right {
		margin-top: 2rem;
		width: 25rem;
	}
	.nami {
		display: none;
	}
	.thumbnail {
		height: 16.5rem;
		margin-right: 2rem;
	}
	.time-stamp {
		font-size: .9rem;
		margin-bottom: .8rem;
	}
	.caption {
		font-size: 1.1rem;
	}
	h1 {
		font-size: 1.9rem;
		line-height: 1.3;
	}
	.icon-box {
		margin-bottom: 1.3rem;
	}
}
</style>

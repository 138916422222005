<template>
  <button class="like_btn" :class="size" @click="onClickFollowTemple" :style="`background-image: url(${isLike ? onFollowImage : offFollowImage})`">
    <!-- <img :src="isLike ? onFollowImage : offFollowImage" alt="like"> -->
  </button>
</template>
<script>
import {useAuth} from "@/composables/useAuth";
import {computed} from "vue";
export default {
  name: 'Like',
  props: {
    size: {
      type: String,
      required: false
    },
    templeId:{
        type: Number,
      required: true
    },
  },
  setup(props) {
      const {followTemple, isFollowing} = useAuth();
      const onFollowImage = require('@/assets/image/icon_heart_on.svg');
      const offFollowImage = require('@/assets/image/icon_heart_off.svg');

      const isLike = computed(() => {
        return isFollowing(props.templeId);
      });


    const onClickFollowTemple = (event) => {
          event.preventDefault();
          followTemple(props.templeId)
      }

      return {onFollowImage,offFollowImage,isLike,onClickFollowTemple}
  }
}
</script>
<style lang="scss" scoped>
.like_btn {
	backface-visibility: hidden;
	background-repeat: no-repeat;
	background-size: contain;
	bottom: 1.8rem;
	cursor: pointer;
	height: 3rem;
	left: 1.8rem;
	position: absolute;
	width: 3rem;
	will-change: transform;
	z-index: 5;

	&:hover {
		animation: heartAnimation .2s;
	}

	&.large {
		bottom: 2rem;
		left: 2rem;
		width: 5rem;
	}
}
@keyframes heartAnimation {
	0% {
		transform: scale(.5);
	}
	10% {
		transform: scale(.7);
	}
	30% {
		transform: scale(.9);
	}
	50% {
		transform: scale(1.2);
	}
	80% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1.0);
	}
}
</style>
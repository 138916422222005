<template>
  <div :class="['container', decorate && 'decorate']">
    <template v-if="!fetching">
      <template v-if="state.articles.length > 0">
        <h2 class="sub-title" v-if="title">{{title}}</h2>
        <div class="project-cover flex">
          <FlowItem
              v-for="(item, index) in state.articles"
              :key="index"
              :item="item"
              :location="location"
              :on-click-delete="handleClickDelete"
              :on-click-stop-event="handleClickStopEvent"
          />
        </div>
      </template>
    </template>
    <Fetching v-else />

    <Pagination
      :on-click-page="handleClickPage"
      :total="pagination.total"
      :current="pagination.current"
      :perPage="pagination.perPage"
    />
  </div>
</template>

<script>
import {useArticles} from '@/composables/useArticles';
import {useCommons} from '@/composables/useCommons';
import FlowItem from "@/components/FlowItem";
import Pagination from '@/components/Pagination';
import Fetching from '@/components/Fetching';
import {watch} from 'vue';

export default {
  name: 'FlowList',
  props: {
    lead: {
      type: String,
    },
    limit: {
      type: Number,
    },
    conditions: {
      type: Object,
    },
    decorate: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
    },
    types: {
      type: Array,
      required:false
    },
    location: {
      type: String
    },
    title: {
      type: String
    }
  },
  components: {
    FlowItem,
    Pagination,
    Fetching,
  },
  setup(props) {
    const { getArticles, state, pagination, fetching, deleteArticle, stopEvent } = useArticles();
    const { openModal } = useCommons();
    getArticles(props.types, props.limit, props.conditions);

    watch(()=>props.conditions, (to, from) => {
      if(to !== from){
        getArticles(props.types, props.limit, props.conditions);
      }
    });
    const handleClickPage = (number) => {
      getArticles(
        props.types,
        props.limit,
        Object.assign({}, props.conditions, { page: number })
      );
    };

    const handleClickDelete = ({articleId, redirectPath}) => {
      const modal = {
            title: '記事を削除',
            text: 'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に削除しますか？',
            btnType: 'cancel&submit',
            submitBtnText: '削除する',
            function: ()=>{
              deleteArticle(articleId, redirectPath)
            }
          }
      openModal(modal)
    }

    const handleClickStopEvent = ({eventDetailId}) => {
      const modal = {
        title: 'イベントを中止',
        text: 'イベントを中止します。<br class="u-use--sp">よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '中止する',
        function: ()=>{
          stopEvent(eventDetailId)
        }
      }
      openModal(modal)
    }

    return {
      state,
      handleClickPage,
      handleClickStopEvent,
      pagination,
      fetching,
      handleClickDelete
    };
  },
};
</script>
<style lang="scss" scoped>
.sp-only {
	display: none;
}
.relative {
	position: relative;
}
.sp-only.relative {
	overflow-x: hidden;
}
.container {
	padding: 2rem 0 5rem;
	width: 100%;
}
.decorate {
	background-color: #fafafa;
	overflow-x: hidden;
	padding: 18.8rem 5rem 12rem 5rem;
}
.outer {
	margin: auto;
	position: relative;
}
.bg-pattern {
	height: 0;
	margin: auto;
	position: relative;
	width: 0;
}
img.bg01, img.bg02 {
	max-width: initial;
	position: absolute;
	width: 19.5rem;
}
.bg01 {
	bottom: -9rem;
	right: 58rem;
}
.bg02 {
	left: 52rem;
	top: 24rem;
}
.vertical-index {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.5rem;
	letter-spacing: .6rem;
	position: absolute;
	right: 4rem;
	top: -4rem;
	writing-mode: vertical-rl;
}
.vertical-index:before {
	background-color: #000;
	content: "";
	height: .2rem;
	position: absolute;
	right: -.3rem;
	top: -1.7rem;
	width: 1.5rem;
}
h1 {
	margin: auto;
	max-width: 120rem;
	position: relative;
	text-align: center;
	z-index: 30;
}
h1 > img {
	display: block;
	margin: auto;
	max-width: 100%;
}
.title {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: auto;
	margin-top: 2rem;
}
.sub-title {
  font-family: $contents_fonts_jp_iroha;
  font-size: 3.3rem;
  @include sp {
    font-size: 1.7rem;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  letter-spacing: 1.2rem;
  margin-bottom: 3.2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
}
.logo {
	height: 12rem;
	padding-left: 5rem;
	width: 31rem;
}
.sub01 {
	color: #412814;
	font-family: "iroha-23kaede-stdn", sans-serif;
	font-size: 5.2rem;
	letter-spacing: 1.62rem;
}
.sub01 .red {
	color: #c84d5f;
}
.sub01 .blue {
	color: #6a7bb7;
}
.sub01 .green {
	color: #749e1d;
}
.sub02 {
	font-family: "aw-conqueror-didot", serif;
	font-size: 2.7rem;
	letter-spacing: 1.89rem;
	margin-top: 3rem;
}
.sub03 {
	font-family: "aw-conqueror-didot", serif;
	font-size: 2.7rem;
	letter-spacing: .89rem;
	margin-top: 3rem;
	text-align: left;
}
.sub04 {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.7rem;
	letter-spacing: .89rem;
	text-align: left;
}
.lead {
	font-family: dnp-shuei-mincho-pr6, sans-serif;
	font-size: 2.3rem;
	letter-spacing: .458rem;
	line-height: 5rem;
	margin: 6rem auto;
	text-align: center;
}
.no-list {
	letter-spacing: .2rem;
	text-align: center;
}
.add-btn__wrapper {
	text-align: center;
}

.flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 120rem;

	&::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 35.5rem;
	}
}

.project-cover.flex{
&::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 74.5rem;
	}
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
	.sp-none {
		display: none;
	}
	.sp-only {
		display: block;
	}
	.container {
		padding: 0 3rem 6rem 3rem;
	}
	.title-line {
		background-color: #fafafa;
		padding-top: 7rem;
	}
	.title {
		flex-flow: column;
	}
	.logo {
		height: 8.2rem;
		padding-left: 0;
		width: 21.4rem;
	}
	.sub01 {
		font-size: 4.6rem;
		letter-spacing: 1.1rem;
		margin-top: 4.5rem;
	}
	.sub02 {
		font-size: 2.3rem;
		letter-spacing: 1.5rem;
	}
	.lead {
		text-align: left;
	}
	.lead br {
		display: none;
	}
	.bg01 {
		bottom: -11rem;
		right: 20rem;
	}
	.bg02 {
		left: 57rem;
		top: 0;
	}
}
</style>

<template>
  <div class="about">
    <section class="mv">
      <figure class="mv__logo--wrap">
        <img class="mv__logo" src="@/assets/image/about/logo.png" alt="" />
      </figure>
      <h1 class="mv__title">
        <span class="mv__title--inner title"
          >まち寺<br class="u-use--sp" />プラットフォームとは？</span
        >
      </h1>
      <h2 class="mv__text--title">まち寺は、<br>まちの人々とお寺のソーシャルグッドが<br>出会い繋がる場です。</h2>
      <div class="mv__img--wrap">
        <div class="mv__img mv__img01">
          <div class="mv__img--title">
            <span class="mv__img--title-ruby" data-ruby="き">気</span>づく
          </div>
          <div class="mv__img--content mv__img--content-01">
            喧騒から一歩離れるだけで
          </div>
          <br />
          <div class="mv__img--content mv__img--content-02">
            気づくことってあるよね
          </div>
        </div>
        <div class="mv__img mv__img02">
          <div class="mv__img--title">
            <span class="mv__img--title-ruby" data-ruby="い">癒</span>やす
          </div>
          <div class="mv__img--content mv__img--content-01">
            たまには、ゆっくり休もうよ
          </div>
          <br />
          <div class="mv__img--content mv__img--content-02">
            自分を労る時間も大事だよ
          </div>
        </div>
        <div class="mv__img mv__img03">
          <div class="mv__img--title">
            <span class="mv__img--title-ruby" data-ruby="つな">繋</span>がる
          </div>
          <div class="mv__img--content mv__img--content-01">
            繋がって変化することを
          </div>
          <br />
          <div class="mv__img--content mv__img--content-02">いつまでも</div>
          <br />
          <div class="mv__img--content mv__img--content-02">
            楽しんでいたいよね
          </div>
        </div>
      </div>
      <h2 class="mv__text--title">「まち寺」はまちを元気にするお寺</h2>
      <p class="mv__text">
        まちの人々に「気づき」「癒やし」「繋がり」を提供することで、<br
          class="u-use--pc"
        />ひとやまちを元気にしているお寺を私たちは「まち寺」と呼んでいます。<br
          class="u-use--pc"
        />
        各地の「まち寺」では、ひとやまちを元気にする多種多様な活動<br
          class="u-use--pc"
        />「お寺のソーシャルプロジェクト」が展開されています。
      </p>
      <p class="mv__text">
        都会にも田舎にも、全国津々浦々にあるお寺が、<br
          class="u-use--pc"
        />それぞれのまちの課題を一歩前進させる場になれば、<br
          class="u-use--pc"
        />社会を支える大きな力になるはずです。
      </p>
      <p class="mv__text">
        人と人の出会いから「お寺のソーシャルプロジェクト」をサポートするのが<br
          class="u-use--pc"
        />「まち寺プラットフォーム」です
      </p>
    </section>
    <section id="social-project" class="about__social-project">
      <h2 class="about__social-project--title title">
        お寺のソーシャルプロジェクトとは？
      </h2>
      <p class="about__social-project--text">
        お寺のソーシャルプロジェクトは「お寺ならでは」を活かして<br
          class="u-use--pc"
        />「まちの課題」を一歩前進させる活動です。<br
          class="u-use--pc"
        />お寺と市民の共創・協働で他種多様な活動が展開されています。
      </p>
      <div class="about__social-project--wrap">
        <ul class="about__social-project--list">
          <li class="about__social-project--item">
            <h3 class="about__social-project--cat">ココロとカラダの健康</h3>
            <ul class="tags">
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=ヘルシーテンプル" class=""
                  >#ヘルシーテンプル</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=寺ヨガ" class=""
                  >#寺ヨガ</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=人生会議" class=""
                  >#人生会議</router-link
                >
              </li>
            </ul>
          </li>
          <li class="about__social-project--item">
            <h3 class="about__social-project--cat">学びの場づくり</h3>
            <ul class="tags">
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=伝統を学ぶ" class=""
                  >#伝統を学ぶ</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=書を学ぶ" class=""
                  >#書を学ぶ</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=まちのお寺の学校" class=""
                  >#まちのお寺の学校</router-link
                >
              </li>
            </ul>
          </li>
          <li class="about__social-project--item">
            <h3 class="about__social-project--cat">ほっとする場づくり</h3>
            <ul class="tags">
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=寺カフェ" class=""
                  >#寺カフェ</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=お寺ベンチ" class=""
                  >#お寺ベンチ</router-link
                >
              </li>
            </ul>
          </li>
          <li class="about__social-project--item">
            <h3 class="about__social-project--cat">まちの支え合い</h3>
            <ul class="tags">
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=こども食堂" class=""
                  >#こども食堂</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=フードパントリー" class=""
                  >#フードパントリー</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=コミュニティカフェ" class=""
                  >#コミュニティカフェ</router-link
                >
              </li>
            </ul>
          </li>
          <li class="about__social-project--item">
            <h3 class="about__social-project--cat">まちの魅力化</h3>
            <ul class="tags">
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=地域イベント" class=""
                  >#地域イベント</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=民泊・宿坊・寺泊" class=""
                  >#民泊・宿坊・寺泊</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=縁結び" class=""
                  >#縁結び</router-link
                >
              </li>
            </ul>
          </li>
          <li class="about__social-project--item">
            <h3 class="about__social-project--cat">防災・防犯</h3>
            <ul class="tags">
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=防災キャンプ" class=""
                  >#防災キャンプ</router-link
                >
              </li>
              <li class="tag p-tag__item">
                <router-link to="/articles/?tag=お寺の防災基地計画" class=""
                  >#お寺の防災基地計画</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
        <div class="about__social-project--image">
          <img
            class="about__social-project--image-01 u-use--pc"
            src="@/assets/image/about/ph01.jpg"
            alt=""
          />
          <img
            class="about__social-project--image-02"
            src="@/assets/image/about/ph02.jpg"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="about__system">
      <h2 class="about__system--title title">
        「まち寺プラットフォーム」の仕組み
      </h2>
      <p class="about__system--text">
        「まち寺プラットフォーム」は、まちの人々と「お寺のソーシャルプロジェクト」が出会い繋がる場です。<br
          class="u-use--pc"
        />イベントに参加したり、お手伝いしたり、寄付をしたり、関わり方はさまざま。<br
          class="u-use--pc"
        />また、すでに地域を元気にする活動をされている各種専門家や、講師・団体・企業など<br
          class="u-use--pc"
        />
        は、「まち寺パートナー」としてお寺に企画提案することも可能です。
      </p>
      <div class="about__system--image">
        <div class="system__list">
          <div class="system__peaple">
            <ul class="system__peaple--point">
              <li class="system__peaple--item">お手伝い</li>
              <li class="system__peaple--item">寄付</li>
              <li class="system__peaple--item">参加</li>
              <li class="system__peaple--item">受講</li>
            </ul>
            <div class="system__peaple--image-wrap">
              <div class="system__peaple--title">一般会員</div>
              <img
                class="system__peaple--image u-use--pc"
                src="@/assets/image/about/peaple.png"
                alt=""
              />
              <img
                class="system__peaple--image u-use--sp"
                src="@/assets/image/about/sp_peaple.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="system__list project-list">
          <div class="system__project">
            <div class="system__project--title">
              お寺の<br class="u-use--pc" />ソーシャルプロジェクト
            </div>
            <div class="system__project--text">
              例：子ども食堂、寺ヨガ、ヘルシーテンプル、フードパントリー、がんカフェ等
            </div>
            <ul class="system__project--list">
              <li class="system__project--item">お手伝い依頼</li>
              <li class="system__project--item">寄付依頼</li>
              <li class="system__project--item">イベント</li>
              <li class="system__project--item">講座</li>
            </ul>
          </div>
        </div>
        <div class="system__temple system__list">
          <ul class="system__temple--list">
            <li class="system__temple--item-image">
              <div class="system__temple--title">寺院会員</div>
              <img
                class="system__temple--image"
                src="@/assets/image/about/temple.png"
                alt=""
              />
            </li>
            <li class="system__temple--item-plus">協力</li>
            <li class="system__temple--item-wrap">
              <img
                class="system__temple--image"
                src="@/assets/image/about/how_to_use03_img.png"
                alt=""
              />
              <div class="system__temple--item temple-text">
                ソーシャルプロジェクト<br class="u-use--pc">パートナー<br /><span class="temple-text-small"
                  >（NPO団体・講師など）</span
                >
              </div>
            </li>
          </ul>
          <div class="system__temple--point">主催・運営</div>
        </div>
      </div>
    </section>
    <section class="about__howtouse">
      <h2 class="about__howtouse--title title">
        まち寺プラットフォームの使い方
      </h2>
      <div class="about__howtouse--image">
        <div class="about__howtouse--image-box">
          <a href="/users/login">
            <div class="image-01">
              <div class="about__howtouse--image-content">
                <img
                  class="about__howtouse--image-img"
                  src="@/assets/image/about/how_to_use01_img.png"
                  alt=""
                />
                <div class="about__howtouse--image-title">参加する</div>
                <div class="about__howtouse--image-btn">一般会員向け</div>
              </div>
            </div>
            <div class="about__howtouse--image-text">check!</div>
          </a>
        </div>
        <div class="about__howtouse--image-box">
          <router-link to="/about/planning-proposal">
            <div class="image-02">
              <div class="about__howtouse--image-content">
                <img
                  class="about__howtouse--image-img"
                  src="@/assets/image/about/how_to_use02_img.png"
                  alt=""
                />
                <div class="about__howtouse--image-title">寺院会員になる</div>
                <div class="about__howtouse--image-btn">寺院会員向け</div>
              </div>
            </div>
            <div class="about__howtouse--image-text">check!</div>
          </router-link>
        </div>
        <div class="about__howtouse--image-box">
          <router-link to="/about/for-temples">
            <div class="image-03">
              <div class="about__howtouse--image-content">
                <img
                  class="about__howtouse--image-img"
                  src="@/assets/image/about/how_to_use03_img.png"
                  alt=""
                />
                <div class="about__howtouse--image-title">お寺に提案する</div>
                <div class="about__howtouse--image-btn">
                  まち寺パートナー向け
                </div>
              </div>
            </div>
            <div class="about__howtouse--image-text">check!</div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {},
};
</script>

<!-- Add "sdcope" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.about {
	& .title {
		color: #412814;
		font-size: 4rem;
		@include font_iroha_600;
		@include sp {
			font-size: 2rem;
			letter-spacing: 0;
		}
		text-align: center;
		width: 100%;
	}
}
.mv {
	padding-top: 120px;

	&__logo {
		@include sp {
			width: 22.1vw;
		}
		width: 24.7rem;

		&--wrap {
			@include sp {
				margin: 21px auto 0;
			}
			margin: 43px auto 0;
			text-align: center;
		}
	}

	&__title {
		@include sp {
			margin-top: 28px;
			padding: 0 25px;
		}
		margin-top: 62px;
		position: relative;

		&--inner {
			display: block;
		}

		&::after {
			background: url(../../assets/image/common/scrollbar.svg) no-repeat;
			background-size: contain;
			content: "";
			display: block;
			height: 121px;
			@include sp {
				height: 60px;
				top: 50px;
				width: 8px;
			}
			left: 50%;
			margin: 0 auto;
			transform: translateX(-50%);
			width: 17px;
		}
	}

	&__img {
		writing-mode: vertical-rl;
		&--wrap {
			display: flex;
			flex-wrap: wrap;
			@include sp {
				display: block;
				width: 100%;
			}
			justify-content: center;
			margin: 0 auto;
			max-width: 1518px;
			width: 100%;
		}

		&--title {
			color: #412814;
			@include fz_vw(63);
			// @include maru-maru-gothic;
			@include font_iroha_600;
			@include sp {
				font-size: 3.25rem;
				letter-spacing: 15px;
			}
			letter-spacing: 25px;
			line-height: .5;
			// font-size: 6.3rem;
			writing-mode: vertical-lr;

			.mv__img01 & {
				@include sp {
					margin-right: 8.379%;
					padding-top: 3.586%;
				}
				margin-right: 8.379%; //60px
				padding-top: 9.586%; //40px
			}

			.mv__img02 & {
				@include sp {
					padding-top: 11.58%;
				}
				margin-right: 40.185%; //285px
				padding-top: 13.58%; //110px
			}

			.mv__img03 & {
				@include sp {
					margin-right: 30.97%;
					padding-top: 6.56%;
				}
				margin-right: 74.97%; //628px
				padding-top: 17.44%;
			}

			&-ruby {
				.mv__img01 & {
					color: #6a7bb7;
				}

				.mv__img02 & {
					color: #749e1d;
				}

				.mv__img03 & {
					color: #c84d5f;
				}

				&[data-ruby] {
					position: relative;
					text-align: center;
					writing-mode: vertical-lr;
				}
				&[data-ruby]::before {
					color: #412814;
					content: attr(data-ruby);
					font-size: 1.6rem;
					line-height: 1;
					margin: auto;
					position: absolute;
					right: 0;
					right: -25px;
					top: 50%;
					transform: translateY(-50%);
					white-space: nowrap;
				}
			}
		}

		&--content {
			background-color: #fff;
			display: inline-block;
			// font-size: 1.6rem;
			@include fz_vw(16);
			@include font_iroha_600;
			@include sp {
				font-size: 1.3rem;
				letter-spacing: 2.5px;
			}
			letter-spacing: 4px;
			line-height: 1.8;
			padding: 10px 0;

			&-01 {
				.mv__img01 & {
					@include sp {
						margin-right: 8.402%;
					}
					margin-right: 7.402%;
				}
				.mv__img02 & {
					@include sp {
						margin-right: 15.148%;
					}
					margin-right: 8.148%;
				}
				.mv__img03 & {
					@include sp {
						margin-right: 8.631%;
					}
					margin-right: 5.631%;
				}
			}

			&-02 {
				@include sp {
					margin-right: 6px;
				}
				margin-right: 10px;
			}

			.mv__img01 & {
				@include sp {
					margin-top: 7.759%;
				}
				margin-top: 16.759%; //120px
			}

			.mv__img02 & {
				margin-top: 16.049%; //130px
			}

			.mv__img03 & {
				@include sp {
					margin-top: 10.446%;
				}
				margin-top: 19.446%;
			}
		}
	}

	&__img01 {
		background: url(../../assets/image/about/mv01_init.png) no-repeat;
		background-size: contain;
		display: block;
		height: 44.888vw;
		@include sp {
			background: url(../../assets/image/about/sp_mv01_init.png) no-repeat;
			background-size: contain;
			height: 100vw;
			margin-left: 4vw;
			margin-top: 67px;
			width: 85.333vw;
		}
		margin-top: 9.611vw; //173px
		max-width: 710px;
		width: 39.777vw; //716px
	}

	&__img02 {
		background: url(../../assets/image/about/mv02_init.png) no-repeat;
		background-size: contain;
		display: block;
		height: 47.611vw;
		@include sp {
			background: url(../../assets/image/about/sp_mv02_init.png) no-repeat;
			background-size: contain;
			height: 100vw;
			margin: 18px auto 0;
			width: 84.533vw;
		}
		margin-top: 28.555vw; //514px
		max-width: 807px;
		width: 45vw; //810px
	}

	&__img03 {
		background: url(../../assets/image/about/mv03_init.png) no-repeat;
		background-size: contain;
		display: block;
		height: 52.777vw;
		@include sp {
			background: url(../../assets/image/about/sp_mv03_init.png) no-repeat
			center;
			background-size: contain;
			height: 100vw;
			margin: 40px auto 0;
			width: 92vw;
		}
		margin-top: -10.555vw; //-190px
		max-width: 1030px;
		width: 57.1666vw; //1030px
	}

	&__text {
		font-size: 2.5rem;
		@include font_iroha_600;
		@include sp {
			font-size: 1.4rem;
			margin-top: 27px;
			padding: 0 25px;
			text-align: left;
			width: 100%;
		}
		letter-spacing: 2px;
		line-height: 2.2;
		margin: 0 auto;
		margin-top: 50px;
		text-align: center;
		width: 92.5rem;

		&--title {
			font-size: 3.5rem;
			@include font_iroha_600;
			@include sp {
				font-size: 1.8rem;
				margin-top: 37px;
			}
			letter-spacing: 5px;
			line-height: 2.2;
			margin-top: 104px;
			text-align: center;
		}
	}
}

.p-action_block {
	@include sp {
		margin: 35px auto 0;
	}
	margin: 67px auto 0;
	padding: 0;
	width: 100%;

	& .p-action_item {
		@include sp {
			margin: 0 auto;
			width: 48vw;
		}
		margin-right: 0;
		position: relative;

		&::before {
			border-bottom: 1px dashed #412814;
			content: "";
			display: inline-block;
			height: 1px;
			@include sp {
				left: -63px;
				width: 55px;
			}
			left: -105px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 95px;
		}

		&::after {
			border-bottom: 1px dashed #412814;
			content: "";
			display: inline-block;
			height: 1px;
			@include sp {
				right: -63px;
				width: 55px;
			}
			position: absolute;
			right: -105px;
			top: 50%;
			transform: translateY(-50%);
			width: 95px;
		}

		& .p-action_link {
			@include sp {
				font-size: 1.2rem;
				padding: 20px;
			}
			position: relative;
			&::before {
				background: url(../../assets/image/common/icon-search.svg) no-repeat;
				background-size: contain;
				content: "";
				display: inline-block;
				height: 22px;
				@include sp {
					height: 13px;
					left: 23px;
					width: 13px;
				}
				left: 38px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 23px;
				.red & {
					content: none!important;
				}
			}
		}
	}
}

.about__social-project {
	background-color: #f5f4f2;
	@include sp {
		margin-bottom: 0;
		margin-top: 57px;
		padding: 13.73vw 6.66vw;
		width: 100%;
	}
	@include between {
		padding: 6.55vw 10.888vw;
	}
	@include tablet {
		padding: 6.55vw 10.888vw;
	}
	margin-top: 88px;
	padding: 6.55vw 13.888vw;
	width: 100%;

	&--title {
		letter-spacing: 8px;
		margin-right: -8px;
	}

	&--text {
		color: #000;
		font-size: 2.4rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.6rem;
			margin: 27px auto 0;
			text-align: left;
			width: 100%;
		}
		line-height: 2;
		margin: 44px auto 0;
		text-align: center;
		// width: 41.94vw;
	}

	&--wrap {
		background-color: #fff;
		@include sp {
			margin-top: 28px;
			padding: 6.4vw 4vw;
		}
		margin-top: 42px;
		padding: 50px;
	}

	&--list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 10px;
	}

	&--item {
		border-top: 1px solid #000;
		display: inline-block;
		@include sp {
			padding: 15px 0;
			width: 100%;
		}
		padding: 30px 0;
		width: 31.666vw;

		// &:nth-child(-n + 2) {
		// 	border-top: 1px solid #000;
		// 	@include sp {
		// 		border-top: none;
		// 		&:first-child {
		// 			border-top: 1px solid #000;
		// 		}
		// 	}
		// }

		&:last-child {
			border-bottom: none;
		}

		& .tags {
			@include sp {
				margin-top: 15px;
			}
			margin-top: 25px;
		}

		& .p-tag__item {
			background-color: #f5f4f2;
			@include sp {
				font-size: 1rem;
				margin-top: 10px;
			}
			margin-bottom: 0;
			margin-right: 10px;
			padding: 0 15px;
		}
	}

	&--cat {
		font-size: 2.5rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.3rem;
		}
		letter-spacing: 5px;
	}

	&--image {
		display: flex;
		@include sp {
			overflow: hidden;
		}

		&-01 {
			@include sp {
				height: 48vw;
				object-fit: cover;
				width: 100%;
			}
			width: 50%;
		}

		&-02 {
			@include sp {
				display: block;
				height: auto;
				width: 100%;
			}
			width: 50%;
		}
	}
}

.about__system {
	background-color: #fafafa;
	@include sp {
		padding: 50px 25px;
	}
	@include tablet {
		padding: 5.833vw 3.888vw 0;
	}
	padding: 5.833vw 13.888vw 0;

	&--title {
		letter-spacing: 2px;
		line-height: .7;
	}

	&--text {
		color: #000;
		font-size: 2rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.3rem;
			margin: 28px auto 0;
			text-align: left;
			width: 100%;
		}
		letter-spacing: 1px;
		line-height: 2;
		margin: 47px auto 0;
		max-width: 1023px;
		text-align: center;
		width: 100%;
	}

	&--image {
		display: flex;
		@include sp {
			display: block;
			margin-top: 30px;
		}
		@include tablet {
			justify-content: center;
		}
		justify-content: space-between;
		margin-top: 48px;

		& .system {
			&__list {
				@include font_iroha_600;
				@include sp {
					margin: 4.898rem auto 0;
					max-width: 37.5rem;
					width: 100%;
				}
				position: relative;
				width: 21.111vw; //380px
				&.project-list {
					@include tablet {
						margin: 0 4vw;
					}
				}
			}
			&__temple {
				&--list {
					align-items: center;
					border: dashed 1px #6877ac;
					border-radius: 45px;
					display: flex;
					flex-direction: column;
					height: 100%;
					@include sp {
						flex-direction: row;
						justify-content: space-between;
						padding: 7.14% 3.57% 7.14% 5%;
					}
					padding: 13.157% 10.842%;
				}

				&--title {
					color: #6877ac;
					font-size: 3rem;
					@include fz_vw(30);
					@include sp {
						font-size: 1.8rem;
						letter-spacing: 0;
					}
					letter-spacing: 4px;
					text-align: center;
				}

				&--item {
					// align-items: center;
					// display: flex;
					// flex-direction: column;
					// justify-content: center;
					&-image {
						@include sp {
							width: 33%;
						}
					}

					&-wrap {
						align-items: center;
						display: flex;
						flex-direction: column;
						@include sp {
							flex-direction: column-reverse;
							width: 33%;
						}
						justify-content: center;
					}
					&-plus {
						background: url(../../assets/image/about/plus_img.png) no-repeat
						center center;
						background-size: contain;
						color: #6877ac;
						font-size: 1.6rem;
						height: 106px;
						@include sp {
							flex-direction: column-reverse;
							height: 64px;
							line-height: 64px;
							margin-top: 0;
							max-width: 64px;
							width: 33%;
						}
						line-height: 106px;
						margin-top: -1.111vw;
						text-align: center;
						width: 106px;
					}

					&.temple-text {
						color: #6877ac;
						font-size: 1.7rem;
						@include fz_vw(17);
						@include sp {
							font-size: 1.4rem;
							letter-spacing: -3px;
							margin-top: 0;
						}
						margin-top: 20px;
						text-align: center;
					}

					& .temple-text-small {
						font-size: 1.2rem;
						@include fz_vw(12);
						@include sp {
							font-size: 1rem;
						}
					}
				}

				&--image {
					@include sp {
						display: block;
						margin: 10px auto 0;
						width: 75px;
					}
					margin-top: 20px;
					// max-width: 150px;
					// width: 39.473%;
					width: 10.55vw;
				}

				&--point {
					background: #6877ac;
					border-radius: 14.955vw;
					color: #fff;
					font-size: 2.5rem;
					height: 29.916vw;
					@include fz_vw(25);
					@include sp {
						align-items: center;
						display: flex;
						font-size: 1.4rem;
						height: 15.42%;
						justify-content: center;
						left: 50%;
						line-height: initial;
						right: auto;
						right: auto;
						top: -9.71%;
						transform: translateX(-50%);
						width: 67.14%;
						writing-mode: horizontal-tb;
					}
					left: -2.222vw;
					line-height: 5.833vw;
					position: absolute;
					text-align: center;
					top: 13.055vw;
					width: 5.833vw;
					-ms-writing-mode: tb-rl;
					writing-mode: vertical-lr;

					&::after {
						background: url(../../assets/image/about/arrow_to_left.svg);
						background-size: 100% 7.4vw;
						content: "";
						display: inline-block;
						height: 27.777vw;
						@include sp {
							background: url(../../assets/image/about/arrow_to_down.svg)
							no-repeat;
							background-size: contain;
							height: 54px;
							left: 50%;
							right: auto;
							top: -4.5rem;
							transform: translateX(-50%) rotate(180deg);
							width: 8px;
						}
						left: -4.1666vw;
						position: absolute;
						top: 50%;
						transform: translateY(-50%) rotate(180deg);
						width: 4.1666vw;
						z-index: 5;
					}
				}
			}

			&__project {
				border: Solid #719630 5px;
				border-radius: 45px;
				height: 100%;
				@include sp {
					padding: 7.466vw;
				}
				padding: 13.157% 7.894%;

				&--list {
					@include sp {
						display: flex;
						flex-direction: row-reverse;
						margin-top: 0;
					}
					margin-top: 2.5vw;
				}

				&--title {
					color: #719630;
					font-size: 3rem;
					@include fz_vw(30);
					@include sp {
						font-size: 1.8rem;
					}
					letter-spacing: -2px;
					line-height: 1.2;
					text-align: center;
				}

				&--text {
					font-size: 1.4rem;
					@include fz_vw(14);
					@include tablet {
						line-height: 1.2;
					}
					@include sp {
						font-size: 1.4rem;
						margin-top: 3.4666vw;
					}
					letter-spacing: -2px;
					line-height: 1.5;
					margin-top: 1.111vw;
					text-align: center;
				}

				&--item {
					background-color: #719630;
					border-radius: 5.888vw;
					color: #fff;
					font-size: 2.5rem;
					height: 5.888vw;
					@include fz_vw(25);
					@include sp {
						align-items: center;
						border-radius: 14.13vw;
						display: flex;
						font-size: 1.4rem;
						height: 13.8rem;
						justify-content: center;
						line-height: 36.8vw;
						margin: 4.8vw auto 0;
						width: 5.298rem;
						writing-mode: vertical-lr;
					}
					line-height: 5.888vw;
					margin: 1.388vw auto 0;
					text-align: center;
					width: 15.166vw;
				}
			}

			&__peaple {
				border: dashed #bb4d5e 1px;
				border-radius: 45px;
				height: 100%;
				@include sp {
					padding: 3.7rem 0;
				}
				padding: 13.157% 15.789%;

				&--image {
					display: block;
					@include sp {
						margin: 0 auto 10px;
						max-width: 32.4rem;
						width: 61.866vw;
					}
					margin: 30px auto 0;
					// max-width: 226px;
					// width: 59.473%;
					width: 100%;

					&-wrap {
						@include sp {
							display: flex;
							flex-direction: column-reverse;
						}
					}
				}

				&--point {
					@include sp {
						bottom: -2.7rem;
						display: flex;
						flex-direction: row-reverse;
						justify-content: center;
						left: 50%;
						top: initial;
						transform: translateX(-50%);
						width: 100%;
					}
					position: absolute;
					right: -35px;
					top: 13.055vw;
				}

				&--title {
					color: #bb4d5e;
					font-size: 3rem;
					@include fz_vw(30);
					line-height: 1.5;
					text-align: center;
				}

				&--item {
					background-color: #bb4d5e;
					border-radius: 50%;
					color: #fff;
					font-size: 2.5rem;
					height: 6vw;
					@include fz_vw(25);
					@include sp {
						font-size: 1.4rem;
						height: 5.6rem;
						line-height: 5.6rem;
						margin-top: 0;
						width: 5.6rem;
						&:nth-child(n + 2) {
							margin-right: 2.666vw;
						}
					}
					line-height: 6vw;
					margin-top: 1.388vw;
					position: relative;
					text-align: center;
					width: 6vw;

					&:first-of-type {
						margin-top: 0;
					}

					&::before {
						background: url(../../assets/image/about/arrow_to_left.svg)
						no-repeat;
						background-size: contain;
						content: "";
						display: inline-block;
						height: 1.111vw;
						@include sp {
							background: url(../../assets/image/about/arrow_to_up.svg)
							no-repeat;
							background-size: contain;
							bottom: -4.125rem;
							height: 5.4rem;
							left: 50%;
							top: initial;
							transform: translateX(-50%) scale(1, -1);
							width: 8px;
						}
						position: absolute;
						right: -4.1666vw;
						top: 50%;
						transform: translateY(-50%);
						width: 4.1666vw;
						z-index: 10;
					}
				}
			}
		}
	}
}

.about__howtouse {
	background-color: #fafafa;
	@include sp {
		padding: 0 25px 50px;
	}
	@include tablet {
		padding: 5.833vw 3.888vw 5.833vw;
	}
	padding: 6.11vw 13.888vw 7.66vw;

	&--title {
		letter-spacing: 5px;
		text-align: center;
	}

	&--image {
		display: flex;
		@include sp {
			display: block;
			margin-top: 25px;
		}
		justify-content: space-between;
		margin-top: 60px;

		&-img {
			height: 8.333vw;
			@include sp {
				height: 28.333vw;
				max-height: 10.5rem;
			}
		}

		&-content {
			display: inline;
		}

		&-title {
			font-size: 2.5rem;
			margin-top: 1.111vw;
		}

		&-btn {
			border-radius: 37px;
			display: inline-block;
			font-size: 1.3rem;
			line-height: 26px;
			margin-top: 15px;
		}

		&-box {
			@include sp {
				margin: 0 auto;
				max-width: 37.5rem;
				width: 100%;

				&:nth-of-type(n + 2) {
					margin-top: 25px;
				}
			}
			@include tablet {
				padding-top: 12.414%;
			}
			position: relative;
			text-align: center;
			width: 31.307%; //410px

			& .image-01 {
				background: url(../../assets/image/about/how_to_use01_init.png)
				no-repeat center;
				background-size: contain;
				@include between {
					padding: 28.414% 0 18.414%;
				}
				@include sp {
					padding: 23.414% 0;
				}
				padding: 18.414% 0;

				& .about__howtouse--image {
					&-img {
						// width: 174px;
						// height: 150px;
					}

					&-title {
						color: #bb4d5e;
					}

					&-btn {
						background-color: #bb4d5e;
						color: #fff;
						@include tablet {
							width: 36.926%;
						}
						width: 32.926%;
					}
				}
			}

			& .image-02 {
				background: url(../../assets/image/about/how_to_use02_init.png)
				no-repeat center;
				background-size: contain;
				@include between {
					padding: 28.414% 0 18.414%;
				}
				@include sp {
					padding: 23.414% 0;
				}
				padding: 18.414% 0;
				& .about__howtouse--image {
					&-img {
						// width: 123px;
					}

					&-title {
						color: #6877ac;
					}

					&-btn {
						background-color: #6877ac;
						color: #fff;
						@include tablet {
							width: 36.926%;
						}
						width: 32.926%;
					}
				}
			}

			& .image-03 {
				background: url(../../assets/image/about/how_to_use03_init.png)
				no-repeat center;
				background-size: contain;
				@include between {
					padding: 28.414% 0 18.414%;
				}
				@include sp {
					padding: 23.414% 0;
				}
				padding: 18.414% 0;
				& .about__howtouse--image {
					&-img {
						// width: 165px;
					}

					&-title {
						color: #719630;
					}

					&-btn {
						background-color: #719630;
						color: #fff;
						font-size: 1rem;
						padding: 0 8px;
					}
				}
			}
			&::after {
				background: url(../../assets/image/common/scrollbar.svg) no-repeat;
				background-size: contain;
				bottom: 25px;
				content: "";
				display: inline-block;
				height: 73px;
				left: 50%;
				position: absolute;
				transform: translateX(-50%);
				width: 11px;
			}
		}

		&-item {
			width: 100%;
		}

		&-text {
			font-size: 1.3rem;
			@include font_conqueror_400;
			letter-spacing: 5.2px;
			margin-right: -5.2px;
			margin-top: 50px;
			text-transform: capitalize;
		}
	}
}
.about__system--title.title {
	letter-spacing: 0;
	margin-left: -1em;
}

.about__social-project--title.title {
	margin-left: -.5em;
}
</style>

<template>
  <div class="container">
    <div class="outer">
      <template v-if="!heading">
        <p>Participating Temples</p>
        <h1>参加<span>寺</span>院一覧</h1>
      </template>
      <template v-else>
        <p>Temples</p>
        <h1 v-html="heading" />
      </template>
      <ul class="temples" v-if="!fetching">
        <TempleItem
          v-for="(item, index) in state.temples"
          :key="index"
          :id="item.id"
          :copy="item.temple_website && item.temple_website.catch_copy"
          :locate="item.prefectures + item.municipality"
          :name="item.name"
          :role="
            item.temple_website && item.temple_website.priest_position_name
          "
          :imageSrc="thumbnail(item)"
          :iconImageSrc="
            item.temple_website &&
              item.temple_website.priest_thumbnail &&
              item.temple_website.priest_thumbnail.thumbnail_url
          "
          :iconName="item.temple_website.priest_name"
          :isNew="false"
        />
      </ul>
      <Fetching v-else />
      <Pagination
        v-if="!linkAll"
        :on-click-page="handleClickPage"
        :total="pagination.total"
        :current="pagination.current"
        :perPage="pagination.perPage"
      />
      <LinkButton link="/search?target=temple" v-if="decorate" />
    </div>
  </div>
</template>

<script>
import TempleItem from '@/components/TempleItem.vue';
import LinkButton from '@/components/LinkButton.vue';
import Pagination from '@/components/Pagination';
import { useTemples } from '@/composables/useTemples';
import { getThumbnailUrl } from '../utils/helper';
import { watch } from 'vue';
import Fetching from './Fetching.vue';
export default {
  name: 'TempleList',
  props: {
    limit: {
      type: [String, Number],
    },
    conditions: {
      type: Object,
    },
    heading: {
      type: String,
    },
    decorate: {
      type: Boolean,
      default: true,
    },
    linkAll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { state, getTemples, fetching, pagination } = useTemples();
    getTemples(props.limit, props.conditions);
    const thumbnail = (item) => {
      if (
        item.temple_website &&
        item.temple_website.content &&
        item.temple_website.content.thumbnail &&
        item.temple_website.thumbnail_1
      ) {
        return getThumbnailUrl(item.temple_website.thumbnail_1);
      } else {
        return false;
      }
    };
    watch(props, () => {
      getTemples(props.limit, props.conditions);
    });

    const handleClickPage = (number) => {
      const conditions = {
        ...props.conditions,
      };
      getTemples(props.limit, Object.assign({}, conditions, { page: number }));
    };

    return {
      state,
      thumbnail,
      fetching,
      pagination,
      handleClickPage,
    };
  },
  components: {
    TempleItem,
    LinkButton,
    Fetching,
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 120rem;
}
.container {
  background-color: #fff;
  overflow-x: hidden;
  padding: 9.2rem 5rem 1.5rem 5rem;
  width: 100%;
}
.outer {
  margin: auto;
  max-width: 123rem;
}
p {
  font-family: 'aw-conqueror-didot', serif;
  font-size: 1.6rem;
  letter-spacing: 0.32rem;
  margin-bottom: 5.6rem;
  position: relative;
}
p::after {
  background-color: #000;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  top: 4rem;
  width: 3rem;
}
h1 {
  font-size: 4rem;
  letter-spacing: 3.2rem;
  margin-bottom: 5.2rem;
}
span {
  color: #6a7bb7;
}
.temples {
  display: flex;
  flex-wrap: wrap;
  @include tablet {
    justify-content: space-around;
  }
  @include between {
    justify-content: space-around;
  }
  justify-content: space-between;

  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 37.5rem;
    @include tablet {
     width: 42rem;
    }
    @include between {
      width: 42rem;
    }
  }
}
.temples > * {
  margin-bottom: 10rem;
}
.link-button {
  margin-top: 0;
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
  .temples {
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .container {
    padding: 5rem 3rem 2rem 3rem;
  }
  p {
    font-size: 1.1rem;
    margin-bottom: 2.8rem;
  }
  p::after {
    left: 0;
    top: 2.5rem;
    width: 1.5rem;
  }
  h1 {
    font-size: 2rem;
    letter-spacing: 1.6rem;
    margin-bottom: 2.6rem;
  }
  .temples > * {
    margin-bottom: 2rem;
  }
}
</style>

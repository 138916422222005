<template>
  <DonationPage />
</template>

<script>
import DonationPage from '@/components/DonationPage.vue';

export default {
  name: 'Donation',
  props: {},
  components: {
    DonationPage,
  },
};
</script>

<!-- Add "sdcope" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>

<template>
  <select
    v-if="editMode"
    :value="modelValue"
    id="select"
    @change="$emit('update:modelValue', $event.target.value)"
  >
    <option value="">
      カテゴリ
    </option>
    <option
      v-for="option in catState.categories"
      :key="option.id"
      :value="option.id"
      :selected="option === modelValue"
    >
      {{ option.name }}
    </option>
  </select>
  <div v-else>
    <div v-if="catState.categories" class="view-mode">
      {{ viewCategoryName }}
    </div>
  </div>

</template>

<script>
// import { ref } from "vue";
import { useCommons } from '@/composables/useCommons.js';
import { ref, onMounted, watch } from 'vue';
export default {
  name: 'CategorySelect',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    editMode: {
      type: Boolean,
    }
  },
  data() {
    return {
      selected: document.getElementById('select')
        ? document.getElementById('select').value
        : '',
    };
  },
  setup(props) {
    const { catState } = useCommons();

    let viewCategoryName = ref();
    onMounted(() => {
      console.log('@@@@catState', catState);
      if (!catState.categories || props.editMode) return;

      viewCategoryName.value = (catState.categories).find(
        (v) => v.id === Number(props.modelValue)
      );
      console.log(
        '@@@@catState-- viewCategoryName.value',
        viewCategoryName.value
      );
      viewCategoryName.value = viewCategoryName.value.name;
      return viewCategoryName.value;
    });

    watch(
      () => props.editMode,
      (newEditMode, oldEditMode) => {
        console.log(`${oldEditMode} -> ${newEditMode}`);
        if (!catState.categories || props.editMode) return;

        viewCategoryName.value = catState.categories.find(
          (v) => v.id === Number(props.modelValue)
        );
        console.log(
          '@@@@catState-- viewCategoryName.value',
          viewCategoryName.value
        );
        viewCategoryName.value = viewCategoryName.value.name;
        return viewCategoryName.value;
      }
    );

    // watch(
    //   () => props.articleGroupId,
    //   (newArticleGroupId, oldArticleGroupId) => {
    //     console.log(
    //       `oldArticleGroupId：${oldArticleGroupId} -> newArticleGroupId：${newArticleGroupId}`
    //     );
    //     viewArticlesGroup = props.articlesGroups.find(
    //       (v) => v.id === Number(newArticleGroupId)
    //     );
    //   }
    // );

    return {
      catState,
      viewCategoryName,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="relative inline-block" :class="className">
    <input
      type="text"
      class="searchTerm"
      placeholder="エリア・寺院名・キーワード"
      v-model="keyword"
    />
    <button type="submit" class="searchButton" @click="onClickSearch">
      <img src="@/assets/image/header/icon-search.svg" />
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'SearchForm',
  props: {
    onSearch: {
      type: Function,
    },
    className: {
      type: String
    }
  },
  setup(props) {
    const keyword = ref('');
    const onClickSearch = () => {
      props.onSearch(keyword.value);
    };
    return {
      keyword,
      onClickSearch,
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.inline-block {
  display: inline-block;
}
header {
  font-family: iroha-23kaede-stdn, Avenir, Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12rem;
  position: fixed;
  z-index: 999;
  background-color: #fff;
  width: 100%;
}
.sp-only {
  display: none;
}
.center,
.right {
  display: flex;
}
.right {
  padding-right: 3rem;
}
.relative {
  position: relative;
}
.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
h1 {
  margin-bottom: 0;
  margin-right: 1.5rem;
  padding-left: 12.5rem;
}
h1 img {
  vertical-align: 0.4rem;
  width: 20rem;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}
.hamburger-btn {
  cursor: pointer;
  left: 4rem;
  position: fixed;
  top: 4rem;
  z-index: 50;
}
.hamburger-btn img {
  height: 3.1rem;
  width: 5rem;
}
.menu-list {
  padding: 0 4rem;
}
.accordion-menu {
  background-color: #fff;
  left: 0;
  padding: 1.5rem 0 4rem 0;
  position: fixed;
  text-align: left;
  top: 12rem;
  z-index: 999;
  width: 44rem;
  max-height: calc(100% - 13rem);
  overflow-y: scroll;
}
.list-link {
  color: #000;
  font-size: 1.8rem;
  padding: 0 1.2rem;
}
.list-link .small {
  font-size: 0.6em;
  vertical-align: 0.2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.tags {
  padding-left: 0;
}
.tags li {
  display: inline-block;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
.plus-btn img {
  height: 2rem;
  width: 2rem;
}
.categories {
  margin-top: 2rem;
  border-top: 1px solid #ddd;
  padding-left: 1rem;
}
.tags {
  margin-top: 2rem;
  border-top: 1px solid #ddd;
}
.categories li {
  margin-top: 1.5rem;
}
/*左サイドバー*/
.accordion-menu-enter-active,
.accordion-menu-leave-active {
  transition: opacity 0.2s;
}
.accordion-menu-enter,
.accordion-menu-leave-to {
  opacity: 0;
}
.accordion-menu-leave,
.accordion-menu-enter-to {
  opacity: 1;
}
.borderd-list {
  border-top: 1px solid #ddd;
  line-height: 1;
  padding: 2rem 0;
}
.borderd-list.indent {
  margin-left: 2rem;
}
.borderd-list:last-child {
  border-bottom: 1px solid #ddd;
}
.link-menu,
.link-menu a {
  align-items: center;
  display: flex;
  letter-spacing: 0.3rem;
}
.link-menu {
  margin-left: 3rem;
  white-space: nowrap;
}
.link-menu li:not(:first-child) {
  margin-left: 3rem;
}
.link-menu div {
  background-color: #000;
  height: 1px;
  width: 4rem;
}
.link-menu p {
  color: #000;
  font-size: 1.5rem;
  margin-left: 1.5rem;
}
.right,
.account {
  display: flex;
  align-items: center;
}
.search {
  position: relative;
  padding-left: 3rem;
}
.searchTerm {
  width: 30rem;
  border: none;
  padding: 1.5rem 2.5rem;
  height: 2rem;
  border-radius: 2.5rem;
  outline: none;
  background-color: #f7f6f4;
  font-size: 1.3rem;
  font-family: YuGothic, 'Yu Gothic Medium', 'Yu Gothic', Meiryo, sans-serif;
}
.searchButton {
  position: absolute;
  right: 1.7rem;
  top: 0.7rem;
}
.searchButton img {
  width: 2.8rem;
}
.search-board {
  position: absolute;
  width: 35rem;
  height: 20rem;
  background-color: #fafafa;
  top: 8.35rem;
  z-index: 999;
  left: 6rem;
}
.searchItems {
  padding-left: 0;
  width: 22rem;
  height: 6.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  margin: 3.2rem auto 2.3rem auto;
}
.searchItems li {
  display: inline-block;
}
.searchItems input[type='checkbox'] {
  display: none;
}
/* チェックボックスの代わりを成すラベル */
.searchItems input[type='checkbox'] + label {
  cursor: pointer;
  display: none;
  display: inline-block;
  font-size: 1.3rem;
  padding-left: 2.5rem;
  padding-right: 1rem;
  position: relative;
}
/* ラベルの左に表示させる正方形のボックス□ */
.searchItems input[type='checkbox'] + label::before {
  background-color: #fff;
  border: 1px solid;
  border-color: #000;
  box-sizing: border-box;
  content: '';
  display: block;
  height: 14px;
  left: 0;
  margin-top: -0.7rem;
  position: absolute;
  top: 50%;
  width: 14px;
}
/* チェックが入った時の×点 */
.searchItems input[type='checkbox']:checked + label::after {
  background-image: url('~@/assets/image/header/icon-checked.svg');
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 12px;
  left: 1.5px;
  position: absolute;
  top: 0;
  width: 12px;
}
.selectbox {
  position: relative;
  width: 25rem;
  margin: auto;
}
.selectbox select {
  appearance: none;
  width: 25rem;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  font-size: 1.3rem;
  border: #000 1px solid;
  border-radius: 5rem;
  background: #fff;
}
.selectbox::after {
  content: '';
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  right: 2.5rem;
  top: 42%;
  border-bottom: #000 1px solid;
  border-right: #000 1px solid;
  transform: rotate(45deg) translateY(-30%);
}
.photo {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 2.5rem;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  flex: none;
}
.initial {
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
}
.budge {
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  background-color: #c84d5f;
  border-radius: 50%;
  top: -0.2rem;
  right: -0.2rem;
}
.login-menu {
  margin-left: 3rem;
  display: flex;
}
.login-menu-nav {
  margin: 0 1rem;
  align-items: center;
  text-align: center;
}
.login-menu-nav img {
  margin-bottom: 1rem;
  height: 3rem;
}
.login-menu-nav p {
  white-space: nowrap;
  font-size: 1.2rem;
  letter-spacing: 0.12rem;
}
.account {
  margin-left: 1.5rem;
  position: relative;
}
.account:before {
  content: '';
  //position: absolute;
  //width: 1px;
  //height: 5rem;
  //background-color: #000;
  //left: -4rem;
}
.user-menu-btn {
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 50;
}
.user-menu-btn img {
  width: 1.3rem;
  height: 0.7rem;
}
.user-menu {
  background-color: #fff;
  padding: 1.5rem 0 4rem 0;
  position: fixed;
  right: 0;
  top: 12rem;
  width: 35rem;
  z-index: 999;
}
.login {
  text-align: center;
  margin-left: 5rem;
}
.login img {
  margin-bottom: 1rem;
}
.login p {
  font-size: 1.2rem;
  letter-spacing: 0.12rem;
}
/*右サイドバー*/
.user-menu-enter-active,
.user-menu-leave-active {
  transition: opacity 0.4s;
}
.user-menu-enter,
.user-menu-leave-to {
  opacity: 0;
}
.user-menu-leave,
.user-menu-enter-to {
  opacity: 1;
}
.name {
  font-size: 1.8rem;
  letter-spacing: 0.36rem;
  margin-right: 4rem;
}
.menu-title {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
}
.menu-title {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.menu-title .img-box {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: 0.5rem;
  width: 3.7rem;
}
.menu-title img[alt='ring'] {
  height: 1.73rem;
  width: 1.32rem;
}
.menu-title img[alt='calender'] {
  height: 1.2rem;
  width: 1.63rem;
}
.menu-title img[alt='temple'] {
  height: 1.57rem;
  width: 2.3rem;
}
.menu-title img[alt='pen'] {
  height: 1.73rem;
  width: 1.63rem;
}
.menu-title img[alt='idcard'] {
  height: 1.22rem;
  width: 1.92rem;
}
.menu-title img[alt='logout'] {
  height: 1.53rem;
  width: 1.82rem;
}
.menu-item {
  font-family: YuGothic, 'Yu Gothic Medium', 'Yu Gothic', Meiryo, sans-serif;
  font-size: 1.3rem;
  margin-left: 4.3rem;
  margin-top: 1.2rem;
}
.message-box {
  //height: 11rem;
  margin-left: 4.3rem;
  margin-top: 2.2rem;
  overflow-y: scroll;
  padding-left: 0;
  height: auto;
  max-height: 660px;
}
.message-box li {
  margin-bottom: 1.4rem;
  padding-right: 3rem;
}
.message-box a,
.message-box time {
  display: block;
  cursor: pointer;
}
.message-box time {
  font-family: 'aw-conqueror-didot', serif;
  font-size: 1.1rem;
  letter-spacing: 0.11rem;
  margin-bottom: 0.9rem;
}
.message-box p.notification-title {
  font-family: YuGothic, 'Yu Gothic Medium', 'Yu Gothic', Meiryo, sans-serif;
  font-size: 1.3rem;
  letter-spacing: 0.13rem;
  line-height: 1.6;
}
.message-box .notification-content {
  display: none;
}

/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
  // header{
  //   width: auto;
  // }
  .hamburger-btn {
    left: 3rem;
  }
  h1 {
    padding-left: 11rem;
  }
  .link-menu {
    display: none;
  }
  .search {
    display: none;
  }
  .visible {
    display: block;
    position: fixed;
    top: 12rem;
    width: 90%;
    left: 0;
  }
  .searchTerm {
    width: 95%;
  }
  .sp-search {
    text-align: center;
  }
  .sp-search img {
    margin-bottom: 1rem;
    width: 3.6rem;
    height: 3.6rem;
  }
  .sp-search p {
    font-size: 1.2rem;
    letter-spacing: 0.12rem;
  }
  .login {
    margin-left: 2.5rem;
    margin-top: 0.3rem;
  }
  .login img {
    width: 2.7rem;
    height: 3.4rem;
  }
  .right {
    padding-right: 2rem;
  }
  .search-board {
    top: 20rem;
    position: fixed;
    left: 0;
    width: 100%;
  }
  .searchButton {
    right: 0;
  }
  .searchItems input[type='checkbox']:checked + label::after {
    left: 1px;
    top: 1px;
  }
  .backboard {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
  }
  .accordion-menu,
  .user-menu {
    width: 100%;
  }
  .account:before {
    height: 7.5rem;
  }
  .message-box {
    width: auto;
  }
  .sns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .sns > * {
    margin-right: 4.5rem;
  }
  .sns a:last-child {
    margin-right: 0;
  }
}
</style>

<template>
  <div class="editAreaWrap">
    <component
      v-if="(editMode || initialText || OnUpdate) && !editHtml"
      :data-text="placeholder ? placeholder : 'テキストを入力してください'"
      :key="resetCount"
      :is="tagName"
      :contenteditable="editMode ? 'true' : 'false'"
      v-html="noLink ? initialText : nl2br(autoLink(initialText))"
      v-on:input="OnUpdate"
      v-on:focus="OnFocus"
      v-on:blur="OnBlur"
      v-on:paste="OnPaste"
      :class="bindClasses"
      ref="form"
    />

    <textarea
        v-if="editHtml"
        v-show="editMode"
        v-on:input="OnUpdateTextarea"
        :value="modelValue"
        class="html-textarea"
        placeholder="htmlを入力してください"
    />
    <component
        v-show="!editMode"
        v-if="editHtml"
        :is="tagName"
        :class="bindClasses"
        v-html="modelValue"
    >
    </component>

    <span v-if="str">{{ str }}</span>
    <div v-if="errorState">
      <span v-if="titleFlg">
        <div class="err-message">
          {{ outputErrMessage(errorState) }}
        </div>
      </span>
      <span v-else>
        <div
          v-for="(key, index) in Object.keys(errorState)"
          :key="index"
          class="err-message"
        >
          {{ errorState[key] }}
        </div>
      </span>
    </div>
    <div v-if="errMessage" class="err-message">
      {{ errMessage }}
    </div>
    <span class="err-message number" v-if="err">{{ err }}</span>
  </div>
</template>

<script>
import { nl2br } from '@/utils/nl2br';
import { autoLink, handlepaste } from '@/utils/helper';
import {
  outputErrMessage,
  validationlength,
  validationRequired,
} from '@/utils/validation';
import { computed, ref } from 'vue';

export default {
  name: 'Editable',
  props: {
    modelValue: {
      type: [String, Object, Number],
      required: false,
    },
    tagName: String,
    initialClass: {
      type: String,
      required: false,
    },
    initialText: {
      type: [String, Number],
      required: false,
    },
    typeValidation: {
      type: String,
      required: false,
    },
    titleFlg: {
      type: Boolean,
      required: false,
    },
    require: {
      type: Boolean,
      required: false,
    },
    editMode: {
      type: Boolean,
    },
    noLink: {
      type: Boolean,
      required: false,
    },
    status: {
      type: Number,
    },
    placeholder: {
      type: String,
      required: false,
    },
    str: {
      type: String,
      required: false,
    },
    errorState: {
      type: [String, Number, Object],
      required: false,
    },
    editHtml: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      resetCount: 0,
      err: '',
      errMessage: '',
    };
  },
  setup(props) {
    const focusIn = ref(false);

    const bindClasses = computed(() => {
      const classes = [props.initialClass, 'editable'];
      if (props.errorState) {
        classes.push('err');
      }

      if (focusIn.value) {
        classes.push('focus-in');
      }
      return classes;
    });

    return {
      nl2br,
      autoLink,
      handlepaste,
      bindClasses,
      focusIn,
      outputErrMessage,
    };
  },
  methods: {
    input(str) {
      if (this.typeValidation === 'number') {
        let inputTel = str.match(/^[0-9\-]+$/g); //eslint-disable-line
        if (inputTel === null) {
          this.err = '数値もしくはハイフンのみが入力できます';
        } else {
          this.err = '';
        }
      }
      if (this.err) {
        return;
      }
      if (this.titleFlg) {
        this.errMessage = validationlength(str, 256);
        console.log('str', str);
        console.log('this.errMessage', this.errMessage);
      }
      if (this.require) {
        console.log('〜〜〜〜〜〜〜〜〜〜〜require');
        this.errMessage = validationRequired(str);
        if (!str) {
          console.log('イフ', str);
          return {
            errMessage: 'は必須です',
          };
        }
        console.log('this.errMessage', this.errMessage);
      }

      console.log('str', str);
      this.$emit('update:modelValue', str);
    },
    OnUpdate(event) {
      const target = event.target;
      this.input(target.innerText);
    },
    OnUpdateTextarea(event) {
      const target = event.target;
      this.input(target.value);
    },
    OnPaste(event) {
      event.preventDefault();
      const text = event.clipboardData.getData('text/plain');
      handlepaste(text);
      this.input(this.$refs.form.innerText);
    },
    OnFocus() {
      this.focusIn = true;
    },
    OnBlur() {
      this.focusIn = false;
    },
  },
  watch: {
    modelValue() {
      if (this.focusIn) return;
      this.resetCount++;
    },
  },
};
</script>

<style lang="scss">
.editAreaWrap {
	width: 100%;
}

.editable {
	.edit & {
		border: #333 dotted 1px !important;
		padding: 8px;
		&.err {
			border: #c84d5f dotted 1px !important;
		}
	}
}
.focus-in {
	min-height: 3rem;
	min-width: 10rem;
}
span {
	&.focus-in {
		display: block;
	}
}
.err-message {
	color: #c84d5f;
	font-size: 1.6rem;
	margin: 8px auto 20px;
	text-align: left;
	&.number {
		bottom: 0;
		font-size: 1.6rem !important;
		left: 0;
		position: absolute;
	}
}
.html-textarea{
  width: calc(100% - 16px);
  border: #333 dotted 1px !important;
  background-color: transparent;
  padding: 8px;
}
.p-event__outline-contents {
	&-price {
		display: inline-block;

		.edit & {
			min-width: 215px;
		}
	}
	&-capacity {
		display: inline-block;
		.edit & {
			min-width: 200px;
		}
	}
	&-lowest_people {
		display: inline-block;
		.edit & {
			min-width: 250px;
		}
	}
	&-organizer_name {
		display: inline-block;
		.edit & {
			// min-width: 220px;
			width: 100%;
		}
	}
	&-organizer_mail {
		display: inline-block;
		.edit & {
			// min-width: 340px;
			width: 100%;
		}
	}
}
[contentEditable="true"]:empty:not(:focus):before {
	color: #aaa;
	content: attr(data-text);
}

.sns-block {
	display: block;
	@include sp {
		font-size: 1.1rem;
	}
	line-height: 1.2;
	text-align: left;
}
</style>

<template>
  <template v-if="onClick">
    <a @click="onClick(args)" :class="className">{{ label }}</a>
  </template>
  <template v-else>
    <router-link :to="path" :class="className" v-if="link === 'router-link'">
      {{ label }}
      <span v-if="count" class="new"></span>
    </router-link>
    <a :href="path" :class="className" v-else>
      {{ label }}
      <span v-if="count" class="new"></span>
    </a>
  </template>
</template>
<script>
export default {
  name: 'ActionButton',
  props: {
    label: String,
    id: Number,
    className: String,
    link: String,
    path: String,
    count: Number,
    onClick: Function,
    args: Object
  },
}
</script>
<style scoped lang="scss">
a{
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  padding: 0.7rem 2.0rem;
  background-color: #412814;
  color: #FFF;
  border-radius: 4rem;
  letter-spacing: 0.08rem;
  font-family: $contents_fonts_jp_Gothic;
  border: 0 solid #eeece7;
  position: relative;
}
.blue a{
  background-color: #E5E7EE;
}
.orange a{
  background-color: #e8e2d7;
}
.green a{
  background-color: #e5e7dc;
}
.new{
  position: absolute;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  text-align: center;
  top:-0.3rem;
  right: -0.3rem;
  background-color: #C84D5F;
  color: #fff;
  font-size: 1.1rem;
  border-radius: 50%;
}
.edit-link{
  margin: 2rem 0;
  display: block;
  font-size: 1.5rem;
  width: 100%;
  padding: 1.2rem 0;
  text-align: center;
  background-color: #C84D5F;
  color: #fff;
}
</style>
<template>
  <div class="modal-text">
    <div>
      サブドメイン設定<br class="u-use--sp" />（寺院ページのURLとなります）
    </div>
    <div class="input_area">
      <div class="input_wrap">
        <input type="text" class="input" v-model="inputValue" />
        <span class="input_text">.machitera.net</span>
      </div>
      <button @click.prevent.stop="getText" class="input_btn">
        {{state.loading ? 'チェック中' : 'チェック'}}
      </button>
    </div>
    <div class="caution_wrap">
      <div v-if="result && result.errMessage" class="err">{{ result.errMessage }}</div>
      <div v-else-if="state.message && !state.errFlg" class="ok">{{ state.message }}</div>
      <div v-else-if="state.message && state.errFlg" class="err">{{ state.message }}</div>
      <div v-else class="err">{{ defaultMessage }}</div>
      <p class="caution">
        ※サブドメインは登録順となり、<br />
        すでに利用されているサブドメインは設定できません。
      </p>
      <p class="caution">
        ※サブドメインは第1階層までの設定となります。<br />
        第2階層以上の設定はできません。
      </p>
      <p class="caution">
        buddha1.machitera.net → ◯<br />
        buddha1.buddha2.machitera.net → ×
      </p>
      <p class="caution">
        ※サブドメインには以下の文字列のみ使用できます。<br />
        ・半角英数字（a～z、0～9）<br />
        ・半角のハイフン「-」
      </p>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { validationDomain } from '@/utils/validation';

export default {
  name: 'InputSubdomain',
  props: {
    fnc: {
      type: Function,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    let inputValue = ref('');
    let result = ref(null);
    const defaultMessage ='';
    const getText = () => {
      //バリデーション
      result.value = validationDomain(inputValue.value);
      if(result.value.errMessage){
        return;
      }
      props.fnc(inputValue.value);
    };

    return { inputValue, getText, result, defaultMessage };
  },
};
</script>
<style lang="scss" scoped>
.modal-text {
	text-align: left;
}

.input_area {
	align-items: center;
	display: flex;
	@include sp {
		align-items: center;
		flex-direction: column;
	}
	justify-content: space-between;
	margin-top: 2rem;

	& .input {
		background-color: #fff;
		border: 1px solid #412814;
		border-radius: 40px;
		box-shadow: none;
		color: #412814;
		font-size: 1.6rem;
		@include font_shuei_400;
		@include sp {
			font-size: 1.3rem;
			width: 13rem;
		}
		outline: 0;
		padding: .5rem 1.6rem;
		transition: 50ms border-color ease-in-out;
		width: 23rem;
		&_wrap {
			align-items: flex-end;
			display: flex;
		}
		&_text {
			display: block;
		}
		&_btn {
			background-color: #412814;
			border: 1px #412814 solid;
			border-radius: 40px;
			box-shadow: none;
			color: #fff;
			font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro",
			"Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック",
			"MS PGothic", sans-serif;
			font-size: 1.5rem;
			@include sp {
				font-size: 1.2rem;
				margin-top: 1rem;
				padding: .5rem .8rem;
				width: 11rem;
			}
			outline: 0;
			padding: 1rem 1.6rem;
			transition: 50ms border-color ease-in-out;
		}
	}
}

.caution {
	font-size: 1.3rem;
	margin-top: 1.5rem;
	&_wrap {
		margin-top: 2rem;
	}
}

.err {
	color: #c84d5f;
	font-size: 1.4rem;
	margin-top: 3rem;
	text-align: center;
}
.ok {
  color: green;
  font-size: 1.4rem;
  margin-top: 3rem;
  text-align: center;
}
</style>

<template>
  <div class="p-follow">
    <button
      :disabled="editMode"
      class="p-action_link follow"
      @click="followTemple(templeId)"
    >
      <img
        class="follow-image"
        :src="isLike ? onFollowImage : offFollowImage"
        alt="like"
      />
      <span class="follow-text" v-if="isLike">
        {{ name }}の活動をフォロー中
      </span>
      <span class="follow-text" v-else> {{ name }}の活動をフォローする </span>
    </button>
  </div>
</template>
<script>
import { useAuth } from '@/composables/useAuth';
import {computed} from "vue";
export default {
  name: 'FollowBtn',
  props: {
    name: {
      type: String,
      required: true,
    },
    templeId: {
      type: Number,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { followTemple, isFollowing } = useAuth();
    const onFollowImage = require('@/assets/image/icon_heart_on_brown.svg');
    const offFollowImage = require('@/assets/image/icon_heart_off_brown.svg');

    const isLike = computed(() => {
        return isFollowing(props.templeId);
    });

    return { onFollowImage, offFollowImage, isLike, followTemple };
  },
};
</script>
<style lang="scss" scoped>
.p-follow {
	background-color: #fff;
	border: solid 1px #412814;
	border-radius: 41rem;
	color: #412814;
	font-size: 1.5rem;
	@include sp {
		min-width: 0 !important;
		width: 100%;
	}
	text-align: center;
	width: 41%;
	.l-report & {
		min-width: 46rem;
	}

	.l-project & {
		min-width: 46rem;
	}
	.follow {
		background-size: contain;
		line-height: 3rem;
		position: relative;
		text-align: center;

		&-image {
			backface-visibility: hidden;
			display: inline-block;
			transform: translate3d(0, 0, 0);
			width: 3rem;
			will-change: animation, transform;
			&:hover {
				animation: heartAnimation .2s;
			}
		}

		&-text {
			display: inline-block;
			@include sp {
				line-height: 2.3rem;
			}
			padding-left: 1rem;
		}
	}
}

@keyframes heartAnimation {
	0% {
		transform: scale(.5);
	}
	10% {
		transform: scale(.7);
	}
	30% {
		transform: scale(.9);
	}
	50% {
		transform: scale(1.2);
	}
	80% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vClickOutside from "click-outside-vue3"
import "./utils/font.js"
import ModalSsr from "./components/ModalSsr";

const app = createApp(App)
app.use(vClickOutside).use(router).mount('#app')

const modal = createApp(ModalSsr)
modal.mount('#modal-ssr')

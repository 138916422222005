<template>
  <h2 class="main-title">編集権限のある記事</h2>
  <template v-if="isLoggedIn">
    <div id="project-list">
      <ProjectList :limit="limit" :conditions="conditions" :decorate="false" :editMode="isTemple" />
    </div>
    <div id="event-list">
      <EventList :limit="limit" :conditions="conditions" :decorate="false" :editMode="isTemple" id="event-list"/>
    </div>
    <div id="report-list">
      <ReportList :limit="limit" :conditions="conditions" :decorate="false" :editMode="isTemple" id="report-list"/>
    </div>
  </template>
</template>

<script>
import {useRoute} from "vue-router";
import {onUpdated, computed, reactive, ref, inject} from "vue";
import ProjectList from "../../components/ProjectList";
import EventList from "../../components/EventList";
import ReportList from "../../components/ReportList";

export default {
  name: 'ArticleAdmin',
  props: {

  },
  setup(){
    const route = useRoute();
    console.log("route----", route);
    const { keyword, prefecture, category, tag } = route.query;

    const {isLoggedIn, isTemple } = inject('auth');

    const targets = computed( () => route.query.target ? route.query.target.split(',') : ['project', 'event', 'report']);

    const conditions = reactive({
      keyword: keyword ? keyword : null,
      prefecture: prefecture ? prefecture : null,
      category: category ? category : null,
      tag: tag ? tag : null,
      article_group_id: null,
      editable: true
    });

    const limit = ref(200);

    onUpdated(()=>{
      console.log("route----", route);
      const { keyword, prefecture, category, tag } = route.query;
      conditions.keyword = keyword ? keyword : null;
      conditions.prefecture = prefecture ? prefecture : null;
      conditions.category = category ? category : null;
      conditions.tag = tag ? tag : null;

    });

    return {targets, conditions, limit, isLoggedIn, isTemple}
  },
  components: {
    ProjectList,
    EventList,
    ReportList
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.heading {
	padding-bottom: 4rem;
	text-align: center;
}
.lead {
	color: #a73550;
	font-size: 1.8rem;
	margin: 5rem 0 0;
}
.p-action_block {
	padding: 4rem;
}
.p-action_item.red {
	background-color: #b24b5b;
	border: none;
	color: #fff;
}
.main-img {
	height: 97rem;
	padding-top: 12rem;
	position: relative;
}
.base-point {
	height: 0;
	margin: auto;
	position: relative;
	width: 0;
}
.title {
	left: 8.5rem;
	max-width: initial;
	position: absolute;
	top: 5rem;
	width: 42rem;
	z-index: 99;
}
.copy {
	font-family: "iroha-23kaede-stdn", Avenir, Helvetica, Arial, sans-serif;
	font-size: 2.8rem;
	left: 24rem;
	letter-spacing: 1.1rem;
	line-height: 5rem;
	position: absolute;
	top: 33rem;
	width: 39rem;
	z-index: 30;
}
.bg {
	max-width: initial;
	position: absolute;
	z-index: 1;
}
.bg-shippo-l {
	right: 8rem;
	top: 3rem;
	width: 18.4rem;
}
.bg-hishigata01 {
	left: 17rem;
	top: 1rem;
	width: 4rem;
}
.bg-hishigata02 {
	right: 16rem;
	top: 67rem;
	width: 4rem;
}
.bg-shippo-s01 {
	right: 18rem;
	top: 74rem;
	width: 3.9rem;
}
.bg-shippo-s02 {
	left: 58rem;
	top: 41rem;
}
.bg-uzu {
	background-image: url("~@/assets/image/top/bg-uzu.svg");
	background-position: 50% 100%;
	background-repeat: no-repeat;
	content: "";
	height: 11rem;
	left: 31rem;
	top: 0;
	width: 24rem;
}
.bg-nami01 {
	left: 18rem;
	top: 58rem;
	width: 11rem;
}
.bg-icho-left {
	left: 35rem;
	top: 46rem;
	width: 6rem;
}
.bg-soroban01 {
	left: 70rem;
	top: 24rem;
	width: 10.7rem;
}
.bg-icho-right {
	right: 33rem;
	top: 16rem;
	width: 6rem;
}
.bg-hamon {
	right: 29rem;
	top: 21rem;
	width: 18.4rem;
}
.bg-nami02 {
	right: 46rem;
	top: 34rem;
	width: 11rem;
}
.bg-soroban02 {
	right: 66rem;
	top: 58rem;
	width: 10.7rem;
}
.center-img {
	left: 48%;
	position: absolute;
	top: 51%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index: 20;
}
.center-img img {
	border-radius: 35% 0 35% 0;
	height: 47.6rem;
	object-fit: cover;
	width: 47.6rem;
}
.clip {
	max-width: initial;
	position: absolute;
}
.clip img {
	border-radius: 35% 0 35% 0;
	object-fit: cover;
}
.clip-right01 {
	left: 55rem;
	top: 10rem;
}
.clip-right01, .clip-right01 img {
	height: 15.3rem;
	width: 15.3rem;
}
.clip-right02 {
	left: 41rem;
	top: 49rem;
}
.clip-right02, .clip-right02 img {
	height: 24.5rem;
	width: 24.5rem;
}
.clip-left01 {
	right: 40rem;
	top: 3rem;
}
.clip-left01, .clip-left01 img {
	height: 15.3rem;
	width: 15.3rem;
}
.clip-left02 {
	right: 66rem;
	top: 14rem;
}
.clip-left02, .clip-left02 img {
	height: 22.1rem;
	width: 22.1rem;
}
.clip-left03 {
	right: 34rem;
	top: 49rem;
}
.clip-left03, .clip-left03 img {
	height: 29.7rem;
	width: 29.7rem;
}
.social {
	align-items: center;
	display: flex;
	flex-direction: column;
	left: 2.5rem;
	position: fixed;
	top: 42rem;
}
.social > p {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.3rem;
	letter-spacing: .5rem;
	margin-bottom: 3rem;
	writing-mode: vertical-rl;
}
.social > div {
	display: flex;
	flex-direction: column;
}
.social > div a {
	margin-bottom: 2rem;
}
.scroll {
	align-items: center;
	bottom: -12%;
	display: flex;
	flex-direction: column;
	left: 49%;
	position: absolute;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index: 20;
}
.scroll p {
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
	font-size: 1.3rem;
	letter-spacing: .5rem;
	margin-bottom: 1.5rem;
}
.tag01, .tag02, .tag03, .tag04 {
	position: relative;
}
.tag01 {
	bottom: 80px;
	left: 370px;
}
.tag02 {
	bottom: 10rem;
	left: 20rem;
}
.tag03 {
	bottom: 7rem;
	left: 18rem;
}
.tag04 {
	bottom: 10rem;
	left: 23rem;
}
.main-title {
	font-family: $contents_fonts_jp_iroha;
	font-size: 4.3rem;
	@include sp {
		font-size: 2.4rem;
		padding-top: 5.2rem;
	}
	letter-spacing: 1.2rem;
	margin-bottom: 5.2rem;
	padding-top: 20rem;
	text-align: center;
}
</style>

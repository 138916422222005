import { createRouter, createWebHistory } from 'vue-router'

import Top from '../pages/Top.vue'
import Articles from '../pages/Articles'
import Project from '../pages/Articles/Project/_id'
// import Event from '../pages/Articles/Event/_id'
import Features from "../pages/Articles/Features";
import Event from '../pages/Articles/Event/_id'
import Report from '../pages/Articles/Report/_id'
import About from '../pages/About/'
import PlanningProposal from '../pages/About/PlanningProposal.vue'
import ForTemples from '../pages/About/ForTemples.vue'
import TempleRoot from '../pages/Temples'
import TempleEdit from '../pages/Temples/_id'
import TempleArticles from '../pages/Temples/_idArticles'
import TempleFlow from '../pages/Temples/_idFlow'
import TempleProposed from '../pages/Temples/_idProposed'
import Search from '../pages/Search'
import Followee from '../pages/Followee'
import AdminArticles from '../pages/Articles/Admin'
import PartnerFlow from '../pages/Partners/Flow'
import Donation from '../pages/Donation'

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top,
		meta: { title: 'まち寺プラットフォーム｜まちを元気にするお寺' },
  },
  {
    path: '/',
    name: 'TempleWithSubdomain',
    component: TempleEdit,
		meta: { title: 'まち寺プラットフォーム｜まちを元気にするお寺' },
  },
  {
    path: '/tops/logout',
    name: 'Logout',
    component: Top,
		meta: { title: 'まち寺プラットフォーム｜ログアウト' },
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
		meta: { title: 'まち寺プラットフォーム｜まちを元気にするお寺' },
  },
  {
    path: '/articles/admin',
    name: 'AdminArticles',
    component: AdminArticles,
		meta: { title: 'まち寺プラットフォーム｜まちを元気にするお寺' },
  },
  {
    path: '/partners/flow',
    name: 'PartnerFlow',
    component: PartnerFlow,
		meta: { title: 'まち寺プラットフォーム｜まちを元気にするお寺' },
  },
  {
    path: '/articles/project',
    name: 'Articles',
    component: Articles,
		meta: { title: 'まち寺プラットフォーム｜まちを元気にするお寺' },
  },
  {
    path: '/articles/project/:id',
    name: 'Project',
    component: Project,
    props: true,
    children: [
      {
        path: 'edit',
        name: 'ProjectEdit',
        component: Project
      }
    ]
  },
  {
    path: '/articles/event',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/articles/event/:id',
    name: 'Event',
    component: Event,
    props: true,
    children: [
      {
        path: 'edit',
        name: 'EventEdit',
        component: Event
      }
    ]
  },
  {
    path: '/articles/report',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/articles/report/:id',
    name: 'Report',
    component: Report,
    props: true,
    children: [
      {
        path: 'edit',
        name: 'ReportEdit',
        component: Report
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    props: true,
		meta: { title: 'まち寺プラットフォーム｜まち寺プラットフォームとは？' },
  },
  {
    path: '/about/planning-proposal',
    name: 'PlanningProposal',
    component: PlanningProposal,
    props: true,
		meta: {title: 'まち寺プラットフォーム｜まち寺プラットフォームの寺院会員になる' },
  },
  {
    path: '/about/for-temples',
    name: 'ForTemples',
    component: ForTemples,
    props: true,
		meta: {title: 'まち寺プラットフォーム｜ソーシャルプロジェクトをお寺に企画提案する' },
  },
  {
    path: '/temples/:id',
    name: 'TempleRoot',
    component: TempleRoot,
    props: true,
    children: [
      {
        path: '',
        name: 'Temple',
        component: TempleEdit
      },
      {
        path: 'edit',
        name: 'TempleEdit',
        component: TempleEdit
      },
      {
        path: 'preview',
        name: 'TemplePreview',
        component: TempleEdit,
        mata: {
          preview: true
        }
      },
      {
        path: 'flow',
        name: 'TempleFlow',
        component: TempleFlow,
				meta: { title: 'まち寺プラットフォーム｜進行中の案件' },
      },
      {
        path: 'proposed',
        name: 'TempleProposed',
        component: TempleProposed,
				meta: { title: 'まち寺プラットフォーム｜お寺への提案' },
      },
      {
        path: 'articles',
        name: 'TempleArticles',
        component: TempleArticles,
        mata: {
					title: 'まち寺プラットフォーム｜記事'
        }
      },
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    props: true,
		meta: { title: 'まち寺プラットフォーム｜検索' },
  },
  {
    path: '/followee',
    name: 'Followee',
    component: Followee,
    props: true,
		meta: { title: 'まち寺プラットフォーム｜フォロー中のお寺' },
  },
  {
    path: '/donation',
    name: 'Donation',
    component: Donation,
    props: true,
		meta: { title: 'まち寺プラットフォーム｜ご寄付のお願い' },
    children: [
      {
        path: 'edit',
        name: 'DonationEdit',
        component: Donation
      }
    ]
  },
  {
    path: '/features',
    name: 'Features',
    component: Features,
    props: true,
		meta: { title: 'まち寺プラットフォーム｜特集記事一覧' },
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  // if (savedPosition) {

  //   return savedPosition;
  // }

  if (savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          {
            savedPosition
          })
      }, 100)
    });

  }

  if (to.hash) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          {
            el: to.hash,
            behavior: 'smooth',
            top: 120
          })
      }, 100)
    });
  }

  return { top: 0 }
}

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior
});

const excludeDomain = [
  'localhost',
  'machitera',
  'www'
]

router.beforeEach((to, from, next) => {
  // logout
  if(to.name === 'Logout'){
    localStorage.clear();
    location.href = '/';
  }

  //Sub-domain routing
  const parts = location.hostname.split(".");
  const subdomain = parts.shift();

	//title
	document.title = `${to.meta.title}`;

  // subdomain判定
  if (location.hostname === '13.230.118.19' || location.hostname === '35.77.11.174' || location.hostname === '192.168.1.109' || location.hostname === '192.168.0.16'){
    next()
  }else{
    if(!excludeDomain.includes(subdomain)){
      if(to.name === 'Top'){
        if(from.name === 'TempleWithSubdomain'){
          location.href = process.env.VUE_APP_WEB_URL
        }
        next({name:"TempleWithSubdomain", params: {domain: subdomain}})
      }else if(to.name === 'TempleWithSubdomain'){
        next()
      }else{
        const url = process.env.VUE_APP_WEB_URL + to.path
        if(Object.keys(to.query).length){
          const urlParams = '?' + Object.entries(to.query).map((e) => `${e[0]}=${e[1]}`).join('&');
          location.href = url + urlParams
        }else{
          location.href = url
        }
      }
    }else{
      next()
    }
  }
})
export default router
<template>
  <!-- <div class="control-area__wrapper short">  ナビショート版 -->
  <div class="control-area__wrapper" :class="scrollHeader ? 'menu-fix' : ''">
    <div class="control-area__item type u-use--tablet">
      <img
        src="@/assets/image/header/icon-temple.svg"
        alt="寺院アイコン"
        class="control-area__type-icon"
      />
      {{ controlAreaObj.type }}
    </div>
    <div class="control-area__list">
      <div class="control-area__menu">
        <div class="control-area__item type withoutTablet">
          <img
            src="@/assets/image/header/icon-temple.svg"
            alt="寺院アイコン"
            class="control-area__type-icon"
          />
          <span class="control-area__type-text">{{ controlAreaObj.type }}</span>
        </div>
        <div class="control-area__item mode">
          <button
            :disabled="!mode || controlAreaObj.simple"
            class="control-area__link-wrap"
            :class="mode ? '' : 'active'"
            @click="
              router.push({
                name: controlAreaObj.routePath,
                params: { id: controlAreaObj.id },
              });
              changeModeToView();
              changeMode();
            "
          >
            <span class="control-area__link-text"
              >通常<br class="u-use--between" />モード</span
            >
          </button>
          |
          <button
            :disabled="mode || controlAreaObj.simple"
            class="control-area__link-wrap"
            :class="mode ? 'active' : ''"
            @click="
              router.push({
                name: `${controlAreaObj.routePath}Edit`,
                params: { id: controlAreaObj.id },
              });
              changeModeToEdit();
              changeMode();
            "
          >
            <span class="control-area__link-text"
              >編集<br class="u-use--between" />モード</span
            >
          </button>
        </div>
      </div>
      <ul class="control-area__slot">
        <li
          :class="['control-area__item']"
          v-for="(nav, index) in navigation"
          :key="index"
        >
          <span class="control-area__link-item">
            <template v-if="nav.children">
              <span
                class="control-area__link"
                @click="nav.showChildren = !nav.showChildren"
              >
                <img :src="nav.imagePath" :alt="nav.text" />
                {{ nav.text }}
              </span>
              <div class="child-nav" v-show="nav.showChildren">
                <p
                  v-for="item in nav.children"
                  :key="item.path"
                  class="child-nav__item"
                >
                  <router-link
                    :to="item.path"
                    class="control-area__link"
                    v-if="item.pathType === 'router-link'"
                    @click="clickmode"
                  >
                    {{ item.text }}
                  </router-link>
                  <a :href="item.path" class="control-area__link" v-else @click="clickmode">
                    {{ item.text }}
                  </a>
                </p>
              </div>
            </template>
            <template v-else>
              <router-link
                :to="nav.path"
                class="control-area__link"
                v-if="nav.pathType === 'router-link'"
              >
                <img :src="nav.imagePath" :alt="nav.text" />
                {{ nav.text }}
              </router-link>
              <a :href="nav.path" class="control-area__link" v-else>
                <img :src="nav.imagePath" :alt="nav.text" />
                {{ nav.text }}
              </a>
            </template>
          </span>
        </li>
      </ul>
      <div
        class="control-area__action--container"
        v-show="mode"
        v-if="!controlAreaObj.simple"
      >
        <div class="control-area__action--status">
          {{ controlAreaObj.statusText }}
        </div>
        <div class="control-area__action--wrap">
          <button
            v-for="(button, index) in controlAreaObj.modalItems"
            :key="index"
            :disabled="!mode"
            @click="clickAction(index)"
            :class="[
              'control-area__item',
              'control-area__action',
              button.class + '-wrap',
            ]"
          >
            <span :class="button.class">{{ button.title }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useCommons } from '@/composables/useCommons';

export default {
  name: 'ControlArea',
  props: {
    controlAreaObj: {
      type: Object,
    },
    editMode: {
      type: Boolean,
    },
    changeMode: {
      type: Function,
    },
  },
  data() {
    return {
      menuFix: false,
    };
  },
  setup(props) {
    const router = useRouter();
    const { openModal } = useCommons();
    const { viewControlAreaFlg,modeChangeFlg } = inject('commons');

    viewControlAreaFlg.value = true;

    const clickmode = () => {
      modeChangeFlg.value = true;
    }

    let mode = ref(props.editMode);

    const changeModeToView = () => {
      mode.value = false;
      return mode;
    };

    const changeModeToEdit = () => {
      mode.value = true;
      return mode;
    };

    const clickAction = (index) => {
      openModal(props.controlAreaObj.modalItems[index]);
    };

    const navigation = computed(() => {
      // 2階層ナビ
      if (props.controlAreaObj.nav['edit']) {
        return mode.value
          ? props.controlAreaObj.nav['edit']
          : props.controlAreaObj.nav['normal'];
      } else {
        return props.controlAreaObj.nav;
      }
    });

    //スクロールしたらコントロールエリア固定
    const scrollHeader = ref(false);
    const showScrollTop = () => {
      if (window.scrollY >= 120) {
        scrollHeader.value = true;
      } else {
        scrollHeader.value = false;
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', showScrollTop, { passive: true });
    });

    return {
      router,
      changeModeToView,
      changeModeToEdit,
      mode,
      navigation,
      clickAction,
      scrollHeader,
      clickmode,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.control-area {
	&__wrapper {
		background-color: #f7f6f4;
		height: 100px;
		@include sp {
			height: auto;
      &.menu-fix {
        .control-area__menu{
          left: 0;
          position: fixed;
          top: 0;
          z-index: 10;
        }
      }
		}
		@include pc {
			&.menu-fix {
				left: 0;
				position: fixed;
				top: 0;
				z-index: 10;
			}
		}
		position: static;
		width: 100%;

		&.short {
			@include pc {
				//高さ 縮小 固定
				height: 50px;

				& .control-area__item.type {
					@include pc {
						line-height: 50px;
					}
				}

				& .control-area__link-text {
					line-height: 1.2;
				}

				& .control-area__link-wrap.active .control-area__link-text::after {
					bottom: -6px;
				}

				& .control-area__link img {
					display: none;
				}
			}
			@include tablet {
				height: 80px;
				& .control-area__list {
					height: 50px;
				}

				& .control-area__item:not(.type) {
					height: 50px;
				}

				& .control-area__item.type {
					line-height: 30px;
				}
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		@include pc {
			flex-wrap: nowrap;
		}
		justify-content: space-between;
	}

	&__menu {
		display: flex;
		@include sp {
			width: 100%;
		}
	}

	&__item {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-size: 1.3rem;
		height: 100%;
		@include tablet {
			font-size: 1.1rem;
			width: 100%;
		}
		@include sp {
			font-size: .9rem;
			width: 100%;

			&:last-child {
				& .control-area__link-item {
					border-right: none;
				}
			}
		}
		justify-content: center;
		line-height: 1.2;
		position: relative;
		text-align: center;
		width: 100%;

		&:last-child {
			& .control-area__link-item {
				border-right: none;
			}
		}

		&.type {
			background: #e4e3e0;
			font-size: 1.5rem;
			@include pc {
				align-items: center;
				flex-direction: column;
				font-size: 1.3rem;
				height: 100%;
				justify-content: center;
				line-height: 1.2;
				position: relative;
				text-align: center;
				&.withoutTablet {
					display: flex !important;
				}
				img {
					margin-bottom: 1rem;
					margin-top: .5rem;
					max-height: 3rem;
					width: 3.5rem;
				}
			}
			@include tablet {
				align-items: flex-start;
				font-size: 1.1rem;
				height: 30px;
				line-height: 30px;
				max-width: initial;
				padding-left: 20px;
				width: 100%;
				&.withoutTablet {
					display: none !important;
				}
			}
			@include sp {
				flex-direction: row;
				font-size: 1.1rem;
				height: 35px;
				line-height: 35px;
				max-width: initial;
				padding-left: 4.6vw;
				text-align: left;
				width: 50vw;
				img {
					margin: 0 .5rem 0 0;
					max-height: 2rem;
					width: 2.5rem;
				}
			}
			max-width: 284px;
			width: 15.777vw;
		}

		&.mode {
			flex-direction: row;
			font-size: 1.5rem;
			@include tablet {
				font-size: 1.3rem;
				width: 22.5vw;
			}
			@include sp {
				background: #e4e3e0;
				height: 35px !important;
				justify-content: flex-end;
				max-width: initial;
				width: 50vw;

				& .control-area__link-text {
					font-size: 1.1rem;
				}
			}
			max-width: 260px;
			width: 14.444vw;
		}

		&:not(.type) {
			@include tablet {
				height: 70px;

				&.control-area__action {
					height: auto;
				}
			}
			@include sp {
				height: 100%;

				&.control-area__action {
					display: flex;
					font-size: 1rem;
					height: 3.4rem;
					line-height: 1.2;
					padding: .4rem;

					&.submit-wrap {
						&::before {
							content: none;
						}
					}

					& .submit {
						padding-left: 0;

						&::before {
							content: none;
						}
					}

					& .delete {
						display: block;
						padding-left: 0;
						position: relative;
					}

					&.update-wrap {
						left: 0;

						&::before {
							content: none;
						}
					}

					& .update {
						display: block;
						padding-left: 0;
					}
				}
			}
		}
	}

	&__link {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		@include sp {
			font-size: 1rem;
		}
		justify-content: center;
		width: 100%;

		img {
			@include sp {
				height: 2.1rem;
			}
			margin-bottom: 1rem;
			margin-top: .5rem;
			max-height: 3rem;
			width: auto;
		}

		&-wrap {
			display: block;
      color: #000;
			height: 100%;
			height: 80%;
			@include tablet {
				width: 100%;
			}
			@include sp {
				width: 50%;
			}
			max-width: 125px;
			position: relative;
			width: 8.611vw;

			&:last-child {
				border-right: 1px solid #000;
				@include sp {
					border: none;
				}
			}

			&.active {
				font-weight: bold;

				& .control-area__link-text {
					display: inline-block;
					position: relative;

					&::after {
						background-color: #c84d5f;
						bottom: -15px;
						content: "";
						height: 3px;
						@include sp {
							bottom: -6px;
							height: 2px;
						}
						left: 50%;
						position: absolute;
						transform: translateX(-50%);
						width: 100%;
					}
				}
			}
		}

		&-item {
			border-right: 1px solid #000;
			height: 80%;
			@include tablet {
				font-size: 1.1rem;
			}
			@include sp {
				font-size: .9rem;
				padding: .8rem;
			}
			position: relative;
			width: 100%;
		}
	}

	&__slot {
		display: flex;
		justify-content: space-around;
		width: 100%;
	}

	&__action {
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 2rem;
		cursor: pointer;
		display: inline-block;
		font-family: $contents_fonts_jp_Gothic;
		font-size: 1.5rem;
		// @include tablet {
		// 	font-size: 1.3rem;
		// 	width: 12vw;
		// }
		@include sp {
			background-color: #412814;
			color: #fff;
			font-size: 1.3rem;
			height: 50px;
			max-width: initial;
			width: 50vw;
		}
		margin: 0 .5rem;
		padding: 1rem 2rem 1rem 1.7rem;
		width: auto;

		&--container {
			background-color: rgba(225, 225, 221, 1);
			bottom: 0;
			height: 10rem;
			@include sp {
				bottom: 6.5rem;
        height: 8rem;
			}
			left: 0;
			position: fixed;
			text-align: center;
			width: 100%;
			z-index: 100;
		}

		&--status {
			font-size: 1.4rem;
			padding: 1.8rem 0 1.5rem;
      @include sp {
        font-size: 1.2rem;
        padding: 1.0rem 0 1.0rem;
      }
		}

		&--wrap {
			display: flex;
			@include tablet {
			}
			@include sp {
				bottom: 65px;
				max-width: initial;
				width: 100%;
				z-index: 10;
			}
			justify-content: center;
		}

		&.delete-wrap {
			@include sp {
				left: 0;
			}
		}

		& .delete {
			padding-left: 25px;
			position: relative;

			&::before {
				background: url(../assets/image/icon_delete.svg) no-repeat;
				background-size: contain;
				content: "";
				height: 13px;
				@include sp {
					// background: url(../assets/image/icon_delete_sp.svg) no-repeat;
					content: none;
				}
				left: 2px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 13px;
			}
		}

		&.submit-wrap {
			@include sp {
				right: 0;
				&::before {
					background-color: #fff;
					content: "";
					height: 25px;
					left: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 1px;
				}
			}
		}

		& .submit {
			padding-left: 25px;
			position: relative;

			&::before {
				background: url(../assets/image/icon_save.svg) no-repeat;
				background-size: contain;
				content: "";
				height: 15px;
				@include sp {
					background: url(../assets/image/icon_save_sp.svg) no-repeat;
					top: 3px;
					transform: initial;
				}
				left: 0;
				position: absolute;
				top: 65%;
				transform: translateY(-50%);
				width: 15px;
			}
		}

		&.update-wrap {
			background-color: #b24b5b;
			border: 1px solid #b24b5b;
			color: #fff;
			@include sp {
				left: 50vw;
				&::before {
					background-color: #fff;
					content: "";
					height: 25px;
					left: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 1px;
				}
			}
			padding-left: 25px;
			position: relative;
		}

		& .update {
			padding-left: 25px;
			position: relative;

			&::before {
				background: url(../assets/image/icon_save_white.svg) no-repeat;
				background-size: contain;
				content: "";
				height: 15px;
				@include sp {
					// background: url(../assets/image/icon_save_sp.svg) no-repeat;
					// top: 3px;
					// transform: initial;
					content: none;
				}
				left: 0;
				position: absolute;
				top: 65%;
				transform: translateY(-50%);
				width: 15px;
			}
		}

		&.publish-wrap {
			background-color: #b24b5b;
			border: 1px solid #b24b5b;
			color: #fff;
			@include sp {
				// &::before {
				//   background-color: #fff;
				//   content: '';
				//   height: 25px;
				//   left: 0;
				//   position: absolute;
				//   top: 50%;
				//   transform: translateY(-50%);
				//   width: 1px;
				// }
			}
			padding: 1rem 4rem 1rem 3.7rem;
		}

		& .publish {
			@include sp {
				padding-left: 0;
				visibility: hidden;
			}
			padding-left: 2.5rem;
			position: relative;

			&::before {
				background: url(../assets/image/icon_save.svg) no-repeat;
				background-size: contain;
				content: "";
				height: 15px;
				@include sp {
					background: none;
					// background: url(../assets/image/icon_save_sp.svg) no-repeat;
					// top: 3px;
					// transform: initial;
					content: "公開する";
					top: 50%;
					visibility: initial;
					width: 100%;
				}
				left: 0;
				position: absolute;
				top: 65%;
				transform: translateY(-50%);
				width: 15px;
			}
		}
	}
}

.child-nav {
	background-color: #fff;
	display: block;
	left: 0;
	padding: 40px;
	position: absolute;
	width: 44rem;
	z-index: 10;

	&__item {
		border-top: 1px solid #ddd;
		padding: 20px 0;

		&:last-child {
			border-bottom: 1px solid #ddd;
		}

		& .control-area__link {
			align-items: flex-start;
		}
	}
}
</style>

<template>
  <div v-if="editMode || modelComputed.body" class="editable__wrapper">

    <Editable
        v-if="modelValue.type === 'title'"
        :initialText="initialItem && initialItem.body"
        :editMode="editMode"
        v-model="modelComputed.body"
        :initial-class="'p-contents__title'"
        tagName="h2"
        placeholder="大見出しを入力してください"
    />
    <Editable
        v-if="modelValue.type === 'heading'"
        :initialText="initialItem && initialItem.body"
        :editMode="editMode"
        v-model="modelComputed.body"
        :initial-class="useTitle ? 'p-contents__catch' : 'p-contents__title p-contents__tit'"
        tagName="h3"
        :placeholder="useTitle ? '小見出しを入力してください' : '見出しを入力してください'"
    />
    <Upload
        v-if="modelValue.type === 'image'"
        v-model="modelComputed.body"
        :editMode="editMode"
        :initial-img="initialItem && initialItem.body"
    />
    <Editable
        v-if="modelValue.type === 'body'"
        :initialText="initialItem && initialItem.body"
        :editMode="editMode"
        v-model="modelComputed.body"
        :initial-class="'p-contents__text body'"
        tagName="p"
        placeholder="本文を入力してください"
    />
    <template v-if="modelValue.type === 'youtube'">
      <p v-if="editMode" class="youtube-edit">
        <span>https://www.youtube.com/watch?v=</span>
        <Editable
            :initialText="initialItem && initialItem.body"
            :editMode="true"
            v-model="modelComputed.body"
            initial-class="text-inline-block"
            tagName="span"
            placeholder="youtubeの動画IDを入力してください"
        />
      </p>
      <div class="youtube" v-if="modelComputed.body">
        <iframe
            :src="'https://www.youtube.com/embed/' + modelComputed.body"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
        </iframe>
      </div>
    </template>
    <button v-if="editMode" class="delete-btn" @click="onClickDeleteContent">
      -
    </button>
  </div>
</template>
<script>
import Editable from "./Editable.vue";
import Upload from "./Upload";
import { computed } from "vue";

export default {
  name: "ContentBlock",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    initialItem: {
      type: Object,
      required: false,
    },
    indexKey: {
      required: true,
    },
    handleClickDelete: {
      type: Function,
    },
    editMode: {
      type: Boolean,
    },
    useTitle: {
      type: Boolean
    },
  },
  setup(props, { emit }) {

    const modelComputed = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    const onClickDeleteContent = () => {
      // console.log("props.key", props.indexKey);
      props.handleClickDelete(props.indexKey);
    };

    // console.log("modelComputed", modelComputed, onClickDeleteContent);
    return { modelComputed, onClickDeleteContent };
  },
  methods: {},
  components: {
    Editable,
    Upload,
  },
};
</script>

<style lang="scss" scoped>
.editable__wrapper {
	margin-bottom: 30px;
	position: relative;

	& .upload-wrapper {
		min-height: auto;
	}
}
.p-event__outline-title {
	width: 20%;
}
.p-event__outline-contents {
	margin-left: 2%;
	width: 78%;
}
::v-deep .body {
	min-height: 20rem;
}
.youtube{
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.youtube-edit{
  white-space: nowrap;
  margin-bottom: 2rem;
  >*{
    display: inline;
    text-align: left;
  }
}
::v-deep .text-inline-block{
  display: inline-block !important;
}
.delete-btn {
	background-color: #000;
	border-radius: 50%;
	color: #fff;
	content: "-";
	cursor: pointer;
	display: block;
	display: inline-block;
	font-size: 16px;
	position: absolute;
	right: -8px;
	text-align: center;
	top: -10px;
	width: 17px;
}
</style>
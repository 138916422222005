<template>
  <div class="upload-wrapper" :class="editMode ? 'edit' : ''">
    <div
      v-if="!isImageExist && !loading && editMode"
      class="drop_area"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @dragover.prevent
      @drop.prevent="dropFile"
      :class="{ enter: isEnter }"
    >
      <label
        v-if="!isImageExist"
        class="upload-content-space user-photo default"
        >画像をアップロード
        <input ref="file" type="file" class="file-button" @change="uploadFile" />
      </label>
    </div>

    <div v-if="loading" class="upload-content-space user-photo upload-loading">
      <div class="loader">Loading...</div>
    </div>

    <div v-if="isImageExist && !loading" class="uploaded">
      <label class="upload-content-space user-photo">
        <img :src="modelValue.url" class="fit" />
      </label>

      <button
        v-if="editMode"
        type="button"
        class="delete-button"
        @click="deleteImage"
      >
        画像を変更する
      </button>
    </div>
    <ul v-if="fileErrorMessages.length > 0" class="error-messages">
      <li v-for="(message, index) in fileErrorMessages" :key="index">
        {{ message }}
      </li>
    </ul>
    <span v-if="errorState">
      <div
        v-for="(key, index) in Object.keys(errorState)"
        :key="index"
        class="err-message"
      >
        {{ errorState[key] }}
      </div>
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Upload',
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    editMode: {
      type: Boolean,
    },
    errorState: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      file: null,
      fileErrorMessages: [],
      isEnter: false,
      loading: false,
    };
  },
  setup(props) {
    const isImageExist = computed(() => {
      return props.modelValue ? !!props.modelValue.url : false;
    });
    return {
      isImageExist,
    };
  },
  methods: {
    uploadFile(event) {
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];
      let responseData = {};
      this.loading = true;
      console.log('api-----file', file);

      if (this.checkFile(file)) {
        const formData = new FormData();
        formData.append('file', file);
        const init = {
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }

        // ブラウザでBoundaryをつけるために明示的に削除
        delete init.headers['Content-Type'];
        //キーとURL取得
        fetch('/api/image-upload', init)
            .then((response) => response.json())
            .then((data) => {
              responseData = {
                key: data.data.key,
                thumbnail_url: data.data.thumbnail_url,
                url: data.data.url,
              };
              this.loading = false;
              console.log('responseData', responseData);
              this.$emit('update:modelValue', responseData);
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
      } else {
        this.loading = false;
      }
    },

    async upload(event) { const files = event.target.files || event.dataTransfer.files;
      const file = files[0];
      let responseData = {};
      this.loading = true;
      console.log('api-----file', file);
      if (this.checkFile(file)) {
        const picture = await this.getBase64(file);
        const picturObj = {
          image: picture,
        };

        //キーとURL取得
        fetch('/api/image-upload-json', {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(picturObj),
        })
          .then((response) => response.json())
          .then((data) => {
            responseData = {
              key: data.data.key,
              thumbnail_url: data.data.thumbnail_url,
              url: data.data.url,
            };
            this.loading = false;
            console.log('responseData', responseData);
            this.$emit('update:modelValue', responseData);
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    deleteImage() {
      this.$emit('update:modelValue', {
        key: null,
        thumbnail_url: null,
        url: null,
      });
      this.$refs.file = null;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    checkFile(file) {
      let result = true;
      this.fileErrorMessages = [];
      const SIZE_LIMIT = 5000000; //5MB

      if (!file) {
        result = false;
      }

      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.fileErrorMessages.push(
          'アップロードできるのは jpeg画像ファイル か png画像ファイルのみです。'
        );
        result = false;
      }

      if (file.size > SIZE_LIMIT) {
        this.fileErrorMessages.push(
          'アップロードできるファイルサイズは5MBまでです。'
        );
        result = false;
      }
      return result;
    },
    dragEnter() {
      this.isEnter = true;
      console.log('Enter Drop Area');
    },
    dragLeave() {
      this.isEnter = false;
    },
    dragOver() {
      console.log('DragOver');
    },
    dropFile(event) {
      this.uploadFile(event);
      console.log(event.dataTransfer.files);
      this.isEnter = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-wrapper {
	height: inherit;
	max-width: inherit;
	position: relative;
	width: inherit;
}

.user-photo {
	height: inherit;
	max-width: inherit;
	outline: none;
	width: inherit;
	&.edit {
		cursor: pointer;
	}
}

.user-photo.default {
	align-items: center;
	border-radius: 2px;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-flex;
	font-family: $contents_fonts_jp_Gothic;
	font-weight: 600;
	height: 100%;
	justify-content: center;
	letter-spacing: .3px;
	padding-top: 75px;
	position: relative;
	width: 100%;

	&::before {
		background: url(../assets/image/ico_file_add.png) no-repeat;
		background-size: contain;
		content: "";
		display: inline-block;
		height: 17px;
		margin-right: 15px;
		width: 17px;

		.p-event__outline-title & {
			content: none;
		}
	}

	&::after {
		background: url(../assets/image/add_photo.png) no-repeat;
		background-size: contain;
		content: "";
		display: inline-block;
		height: 40px;
		left: 50%;
		margin-right: 15px;
		position: absolute;
		top: calc(50% - 20px);
		transform: translateX(-50%);
		width: 45px;

		.p-event__outline-title & {
			top: 15px;
		}
	}
}

.user-photo.default:hover {
	opacity: .7;
}

.user-photo.default:active {
	opacity: .7;
}

.user-photo-image {
	display: block;
	max-width: 85px;
}

.fit {
	height: inherit;
	object-fit: cover;
	width: 100%;
}
.user-photo-image:hover {
	opacity: .8;
}

.file-button {
	display: none;
}

.uploaded {
	align-items: center;
	display: flex;
	flex-flow: column;
	height: inherit;
	max-width: inherit;
	width: inherit;
}

.delete-button {
	background-color: #fff;
	border: 1px solid;
	border-radius: 40px;
	bottom: 2.5rem;
	color: #412814;
	line-height: 44px;
	margin: 0;
	padding: 0 2rem;
	position: absolute;
}

.delete-button:hover {
	background-color: #412814;
	color: #fff;
}

.error-messages {
	color: #cf0000;
	font-size: 1.6rem;
	list-style: none;
	margin: .4rem 0 0 0;
	padding: 0 .2rem;
}

.drop_area {
	align-items: center;
	background-color: #d2d2d2;
	color: #fff;
	display: flex;
	font-size: 1.6rem;
	height: 100%;
	justify-content: center;
	margin-bottom: 30px;
	padding: 3rem 0 4rem;
	width: 100%;
}

.enter {
	border: 2px dotted #333;
}

.upload-loading {
	background-color: #d2d2d2;
	height: 100%;
	padding: 3rem 0 4rem;
	// position: absolute;
	width: 100%;
	z-index: 10;
	// min-height: 162px;

	.p-event__outline-contents & {
		margin-bottom: 15px;
	}
}
.upload-loading .loader:before, .upload-loading .loader:after, .upload-loading .loader {
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	border-radius: 50%;
	height: 1.5em;
	width: 1.5em;
}
.upload-loading .loader {
	-webkit-animation-delay: .16s;
	animation-delay: .16s;
	font-size: 10px;
	left: 50%;
	position: absolute;
	text-indent: -9999em;
	top: calc(50% - 1.5em);
	transform: translate(-50%, -50%);
}
.upload-loading .loader:before {
	left: -2.8em;
}
.upload-loading .loader:after {
	-webkit-animation-delay: .32s;
	animation-delay: .32s;
	left: 2.8em;
}
.upload-loading .loader:before, .upload-loading .loader:after {
	content: "";
	position: absolute;
	top: 0;
}
@-webkit-keyframes load7 {
	0%, 80%, 100% {
		box-shadow: 0 1.5em 0 -1.3em #fff;
	}
	40% {
		box-shadow: 0 1.5em 0 0 #fff;
	}
}
@keyframes load7 {
	0%, 80%, 100% {
		box-shadow: 0 1.5em 0 -1.3em #fff;
	}
	40% {
		box-shadow: 0 1.5em 0 0 #fff;
	}
}
</style>

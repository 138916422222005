<template>
  <div v-if="decorate" class="sp-only relative">
    <p class="sub">Machi-Tera <span>R</span>eport</p>
    <img src="@/assets/image/event/line-ribbon02.svg" class="title-line" />
    <div class="bg-pattern">
      <img src="@/assets/image/report/bg-star.svg" class="bg03" />
    </div>
  </div>
  <div :class="['container', decorate && 'decorate']">
    <div class="outer" v-if="decorate">
      <div class="bg-pattern">
        <img src="@/assets/image/report/bg-star.svg" class="bg01 sp-none" />
        <img src="@/assets/image/report/bg-star.svg" class="bg02" />
        <img src="@/assets/image/report/bg-star.svg" class="bg03 sp-none" />
      </div>
      <p class="vertical-index sp-none">Report</p>
      <h1>
        <p class="sub sp-none">Machi-Tera <span>R</span>eport</p>
        <img src="@/assets/image/event/line-ribbon02.svg" class="sp-none" />
        <p class="title">レポート</p>
      </h1>

      <p class="lead" v-html="lead" />
    </div>
    <section class="p-related-articles" v-else-if="!hideTitle">
      <h2 class="p-related-articles__title">
        <span class="p-related-articles__title--ja">レポート一覧</span>
        <span class="p-related-articles__title--en"
          ><span class="color-text">R</span>eport</span
        >
      </h2>
    </section>

    <template v-if="!fetching">
      <div class="flex" v-if="state.articles.length > 0">
        <ReportItem
          v-for="(item, index) in state.articles"
          :key="index"
          :item="item"
          :editMode="editMode"
        />
      </div>
      <p v-else class="no-list">該当記事はありません</p>
<!--
      <div v-if="editMode" class="add-btn__wrapper">
        <a href="/articles/create?type=report" class="add-btn"
          >レポートを追加</a
        >
      </div>
-->
    </template>
    <Fetching v-else />

    <LinkButton link="/search?target=report" v-if="linkAll" />
    <Pagination
      v-else
      :on-click-page="handleClickPage"
      :total="pagination.total"
      :current="pagination.current"
      :perPage="pagination.perPage"
    />
  </div>
</template>

<script>
import ReportItem from "@/components/ReportItem.vue";
import LinkButton from "@/components/LinkButton.vue";
import { useArticles } from "@/composables/useArticles";
import Pagination from "@/components/Pagination";
import Fetching from "@/components/Fetching";
import { watch } from "vue";
export default {
  name: "ReportList",
  props: {
    lead: {
      type: String,
    },
    limit: {
      type: [String, Number],
    },
    conditions: {
      type: Object,
    },
    decorate: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
    },
    linkAll: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ReportItem,
    LinkButton,
    Pagination,
    Fetching,
  },
  setup(props) {
    const { getArticles, state, pagination, fetching } = useArticles();
    getArticles(3, props.limit, props.conditions);

    watch(()=>props.conditions, (to, from) => {
      if(to !== from){
        getArticles(3, props.limit, props.conditions);
      }
    });

    const handleClickPage = (number) => {
      getArticles(
        3,
        props.limit,
        Object.assign({}, props.conditions, { page: number })
      );
    };

    return {
      state,
      handleClickPage,
      pagination,
      fetching,
    };
  },
};
</script>
<style lang="scss" scoped>
.relative {
	position: relative;
}
.sp-only {
	display: none;
}
.flex {
	display: flex;
	flex-wrap: wrap;
	@include tablet {
		justify-content: space-around;
	}
	@include between {
		justify-content: space-around;
	}
	justify-content: space-between;
	margin: 0 auto;
	max-width: 120rem;
	&::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 35.5rem;
	}
}
.container {
	padding: 5rem 2rem;
	width: 100%;
}
.decorate {
	background-color: #fafafa;
	overflow: hidden;
	padding: 9.2rem 5rem 1.5rem 5rem;
}
.outer {
	margin: auto;
	position: relative;
}
.bg-pattern {
	height: 0;
	margin: auto;
	position: relative;
	width: 0;
}
img.bg01, img.bg02, img.bg03 {
	max-width: initial;
	position: absolute;
	width: 14.2rem;
}
.bg01 {
	right: 62rem;
	top: 18rem;
}
.bg02 {
	bottom: -4.5rem;
	right: 28rem;
}
.bg03 {
	left: 48rem;
	top: 5rem;
}
.vertical-index {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.5rem;
	letter-spacing: .6rem;
	position: absolute;
	right: 4rem;
	top: -4rem;
	writing-mode: vertical-rl;
}
.vertical-index:before {
	background-color: #000;
	content: "";
	height: .2rem;
	position: absolute;
	right: -.3rem;
	top: -1.7rem;
	width: 1.5rem;
}
h1 {
	margin: auto;
	max-width: 120rem;
	text-align: center;
	z-index: 30;
}
.sub {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.6rem;
	letter-spacing: 1.12rem;
	margin-bottom: 2rem;
}
.sub span {
	color: #749e1d;
}
.title {
	color: #412814;
	font-family: "iroha-23kaede-stdn", sans-serif;
	font-size: 5.2rem;
	letter-spacing: 1.62rem;
	margin-top: 2.4rem;
}
.lead {
	font-family: dnp-shuei-mincho-pr6, sans-serif;
	font-size: 1.3rem;
	letter-spacing: .52px;
	line-height: 3rem;
	margin-bottom: 4.1rem;
	margin-top: 3.5rem;
	text-align: center;
}
.link-button {
	margin-top: 5.5rem;
}
.add-btn__wrapper {
	text-align: center;
}

.p-related-articles {
	&__title {
		&::before {
			background-color: #749e1d;
		}
	}
}

.no-list {
	letter-spacing: .2rem;
	text-align: center;
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
	.sp-none {
		display: none;
	}

	.sp-only {
		display: block;
	}

	.container {
		padding: 0 3rem 2rem 3rem;
	}

	.title-line {
		background-color: #fafafa;
		position: relative;
		z-index: 9;
	}
	.sp-only.relative {
		background-color: #fafafa;
		overflow: hidden;
		// height: 16rem;
	}
	.sp-only .sub {
		background-color: #fafafa;
		font-size: .95rem;
		letter-spacing: .7rem;
		margin-bottom: 0;
		padding-bottom: 1.6rem;
		padding-top: 5rem;
		text-align: center;
	}
	.title {
		font-size: 3rem;
		letter-spacing: 1rem;
		margin-top: 1.2rem;
	}
	.lead {
		font-size: 1.3rem;
		line-height: 3rem;
		margin-bottom: 2rem;
		margin-top: 1.7rem;
		position: relative;
		text-align: left;
		z-index: 9;
	}

	.flex {
		flex-wrap: nowrap;
		overflow-x: scroll;
    justify-content: space-between;
	}

	.flex::-webkit-scrollbar {
		display: none;
	}

	img.bg02 {
		bottom: -20rem;
		right: 15rem;
		width: 10rem;
		z-index: 1;
	}

	img.bg03 {
		bottom: -1rem;
		left: 12rem;
		top: initial;
		width: 10rem;
	}
	.link-button {
		margin-top: 2rem;
	}
}
</style>
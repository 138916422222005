export function translateIntoJapanese(enText) {
	const translationLists = [
		{ en: "validation.unique", ja: "既に存在しています" },
		{ en: "maxLength", ja: "長すぎます" },
	]

	const translationlist = translationLists.find((v) => v.en === enText);
	let jaText = translationlist["ja"]


	return jaText;
}

export function outputErrMessage(dataErrors) {
	let jaErrorMessage = []
	const errors = Object.entries(dataErrors);
	console.log(errors);
	(errors).map(
		(error) => {
			console.log(error);
			console.log(error[1].value);
			console.log(error[1]);
			// jaErrorName = translateIntoJapanese(error[0])
			jaErrorMessage = translateIntoJapanese(error[0])
			// stateErrMessages.push({ name: jaErrorName, mess: jaErrorMessage })

			// console.log(stateErrMessages);
		}
	)
	return `${jaErrorMessage}`;
}

export function validationlength(text, length) {
	const MAX_LENGTH = length;

	if (!text) {
		return
	} else {
		if ([...text].length > MAX_LENGTH) {
			return `${MAX_LENGTH}文字以内で入力してください。`;
		} else {
			return '';
		}
	}
}

export function validationRequired(text) {
	console.log('validationRequired(text)', text)
	if (!text) {
		return {
			errMessage: "は必須です",
		};
	}
}

export function validationDomain(str) {
  str = (str == null) ? "" : str;
  if (str.match(/^[A-Za-z0-9-]+$/)) {
    return {
      errMessage: null,
    };
  } else {
    return {
      errMessage: "半角英数字ではありません",
    };
  }
}
export const getProfile = (content) => {
  if(!content){
    return false
  }

  if(typeof content !== 'object'){
    return false;
  }

  if(content.items){
    if(Array.isArray(content.items)){
      if(content.items.length > 0){
        if (content.items[0].name){
          return content.items[0]
        }else{
          return false;
        }

      }
    }
  }

  return false
}
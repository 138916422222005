<template>
  <div
    :class="[
      'wrap',
      editMode && 'edit',
      !isAdmin && 'not-admin',
      viewControlAreaFlg && 'with-control-area',
    ]"
  >
    <div class="donation-page__wrap">
      <ControlArea
        :controlAreaObj="control"
        :editMode="editMode"
        :changeMode="changeMode"
        v-if="isAdmin && isCurrentPageSet"
      />
      <div class="donation-page">
        <div class="donation-page__title--image-wrap">
          <img
            class="donation-page__title--image"
            src="@/assets/image/donation/donation_title.png"
            alt="Request for Donation"
          />
        </div>
        <h1 class="donation-page__title">ご寄付のお願い</h1>
        <div class="donation-page__contents">
          <img
            class="donation-page__image"
            src="@/assets/image/donation/mv.jpg"
            alt=""
          />
          <div class="donation-page__cv">
            <h2 class="donation-page__cv--tilte">
              お寺のソーシャルプロジェクトの活性化にご協力ください
            </h2>
            <p class="donation-page__cv--body">
              「まち寺」は、活動の趣旨にご賛同いただいたみなさまからのご寄付により運営されています。全国各地の「お寺のソーシャルプロジェクト」が更に発展し、ひとやまちを元気にしていくため、ぜひともご支援いただけますようお願い申し上げます。
              お預かりした寄付金は、モデルケースづくり、取材費、システム維持費、広報活動費、事務局運営費に利用させていただきます。また、ご寄付いただきました皆様には、一般社団法人寺子屋ブッダの活動レポートを1年に一度お送りさせていただきます。
            </p>
          </div>
        </div>
        <h2 class="donation-page__sub-title">
          ご都合にあったご寄付の方法をお選びください
        </h2>
        <div class="tab__wrap">
          <ul class="tab__list">
            <li class="tab__item blue" :class="isActive === 'blue' && 'active'">
              <div
                class="tab__item-inner"
                @click="isSelect('blue'), (isOpen[0] = !isOpen[0])"
              >
                <div class="tab__sub-title">
                  まち寺サポーター<br class="u-use--pc" />として寄付
                </div>
                <div class="tab__title">
                  <span class="color__font--blue">毎月</span>のご寄付
                </div>
              </div>
              <div
                class="tab__contents--item u-use--sp"
                :class="isOpen[0] && 'isopen'"
              >
                <h3 class="tab__contents--title">
                  まち寺サポーターは、<br />「まち寺」ポータルサイトの運営を継続的に応援していただく会員様です。
                </h3>
                <ul>
                  <li class="tab__contents--list blue">
                    まち寺サポーターの皆様は、「まち寺」内の、サポーター一覧にお名前（ご寄付いただく際に、掲載用に入力いただいたお名前）を掲載させていただきます。
                  </li>
                  <!--                <li class="tab__contents&#45;&#45;list blue">
                    「まち寺」はじめ寺子屋ブッダの活動にご協力いただいている皆様のオンライン交流会にご招待させていただきます。<br />
                    ご意見・ご要望・アイデアをいただき、出来るかぎり運営に生かさせていただきます。
                  </li>-->
                </ul>
                <div class="tab__contents--payment">
                  <h3 class="tab__contents--payment-title">お支払い方法</h3>
                  <div class="tab__contents--payment-body-wrap">
                    <div>
                      <div class="tab__contents--payment-body">
                        下記のクレジットカードがご利用いただけます
                      </div>
                      <img
                        class="tab__contents--payment-image"
                        src="@/assets/image/donation/card_brand.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <form
                    action="https://credit.j-payment.co.jp/gateway/payform.aspx"
                    method="POST"
                  >
                    <select
                      name="iid"
                      id="select01"
                      class="payment-select"
                      @change="onChange"
                    >
                      <option value="">継続支援金額を選択</option>
                      <option value="ms10"
                        >まち寺サポーターとして継続支援（10,000円/月）
                      </option>
                      <option value="ms05"
                        >まち寺サポーターとして継続支援（5,000円/月）
                      </option>
                      <option value="ms03"
                        >まち寺サポーターとして継続支援（3,000円/月）
                      </option>
                      <option value="ms02"
                        >まち寺サポーターとして継続支援（2,000円/月）
                      </option>
                      <option value="ms01"
                        >まち寺サポーターとして継続支援（1,000円/月）
                      </option>
                    </select>
                    <input type="hidden" name="aid" value="121268" />
                    <input type="hidden" name="cod" value="" />
                    <input type="hidden" name="jb" value="CAPTURE" />
                    <!-- <input type="hidden" name="iid" value="machitera-main" /> -->
                    <input type="hidden" name="pt" value="" />
                    <div class="tab__contents--btn">
                      <button
                        type="submit"
                        class="tab__contents--btn-link"
                        :disabled="!isSelected.select01"
                      >
                        毎月のご寄付を申し込む
                      </button>
                    </div>
                    <p
                      style="font-size: 12px; text-align: center; margin-top: 10px;"
                    >
                      ※決済会社のフォームに移動します
                    </p>
                  </form>
                </div>
              </div>
            </li>
            <li
              class="tab__item orange"
              :class="isActive === 'orange' && 'active'"
            >
              <div
                class="tab__item-inner"
                @click="isSelect('orange'), (isOpen[1] = !isOpen[1])"
              >
                <div class="tab__sub-title">
                  自由な金額を<br class="u-use--pc" />自由なタイミングで
                </div>
                <div class="tab__title">
                  <span class="color__font--orange">都度</span>のご寄付
                </div>
              </div>
              <div
                class="tab__contents--item u-use--sp"
                :class="isOpen[1] && 'isopen'"
              >
                <h3 class="tab__contents--title">
                  「都度のご寄付」は<br />自由金額でご寄付いただくことが可能です。
                </h3>
                <ul>
                  <!--                <li class="tab__contents&#45;&#45;list orange">
                    「まち寺」はじめ寺子屋ブッダの活動にご協力いただいている皆様のオンライン交流会にご招待させていただきます。<br />
                    ご意見・ご要望・アイデアをいただき、出来るかぎり運営に生かさせていただきます。
                  </li>-->
                </ul>
                <div class="tab__contents--payment">
                  <h3 class="tab__contents--payment-title">お支払い方法</h3>
                  <div class="tab__contents--payment-body-wrap">
                    <div>
                      <div class="tab__contents--payment-body">
                        下記のクレジットカードがご利用いただけます
                      </div>
                      <img
                        class="tab__contents--payment-image"
                        src="@/assets/image/donation/card_brand.png"
                        alt=""
                      />
                    </div>
                    <!-- <div class="tab__contents--payment-block">
                      <div class="tab__contents--payment-body">
                        口座振替
                      </div>
                      <div class="tab__contents--payment-bank-tit">
                        各種銀行口座（ゆうちょ銀行含む）
                      </div>
                      <div class="tab__contents--payment-bank-text">
                        ※ご登録の住所に手続き用紙をお送りしますので、記入・捺印のうえ10日以内にご返送をお願いします。
                      </div>
                    </div> -->
                  </div>
                  <form
                    class="form-wrap"
                    action="https://credit.j-payment.co.jp/gateway/payform.aspx"
                    method="POST"
                  >
                    <input
                      id="input01"
                      class="form-input"
                      name="am"
                      type="number"
                      value=""
                      min="1000"
                      max="100000"
                      @input="onInput"
                    />円
                    <input type="hidden" name="aid" value="121268" />
                    <input type="hidden" name="cod" value="" />
                    <input type="hidden" name="jb" value="CAPTURE" />
                    <!-- <input type="hidden" name="iid" value="machitera-main" /> -->
                    <input TYPE="hidden" name="tx" value="0" />
                    <input TYPE="hidden" name="sf" value="0" />
                    <input TYPE="hidden" name="pt" value="" />
                    <div class="tab__contents--btn">
                      <button
                        type="submit"
                        class="tab__contents--btn-link"
                        :disabled="!isSelected.input01"
                      >
                        都度のご寄付を申し込む
                      </button>
                    </div>
                  </form>
                  <p
                    style="font-size: 12px; text-align: center; margin-top: 10px;"
                  >
                    ※決済会社のフォームに移動します
                  </p>
                </div>
              </div>
            </li>
            <li
              class="tab__item green"
              :class="isActive === 'green' && 'active'"
            >
              <div
                class="tab__item-inner"
                @click="isSelect('green'), (isOpen[2] = !isOpen[2])"
              >
                <div class="tab__sub-title">
                  一般社団法人寺子屋ブッダの<br
                    class="u-use--pc"
                  />賛助会員として寄付
                </div>
                <div class="tab__title">
                  <span class="color__font--green">年間</span>のご寄付
                </div>
              </div>
              <div
                class="tab__contents--item u-use--sp"
                :class="isOpen[2] && 'isopen'"
              >
                <h3 class="tab__contents--title">
                  寺子屋ブッダ賛助会員としてのご寄付。
                </h3>
                <p class="tab__contents--body">
                  寺子屋ブッダの賛助会員は、「お寺をもっと身近で楽しくて温かい場所にすることで、まちやひとを元気にする」寺子屋ブッダの活動全般を応援いただく会員様です。<br />
                  寺子屋ブッダは、「まち寺」ポータルサイトの運営の他、お寺を地域の健康拠点にする活動「ヘルシーテンプル・コミュニティ」の運営、お寺のソーシャルプロジェクトをすすめる僧侶の学びの場「寺子屋学」などを行っています。これらの活動の、モデルケースづくり、取材費、システム維持費、広報活動費、事務局運営費に使わせていただきます。
                </p>
                <ul>
                  <li class="tab__contents--list green">
                    寺子屋ブッダ賛助会員の皆様は、「まち寺」内の、寄付者一覧にお名前（掲載用に入力いただいた登録名）を掲載させていただきます。
                  </li>
                  <!--                <li class="tab__contents&#45;&#45;list green">
                    「まち寺」にご協力いただいている皆様を特別招待させていただくオンライン交流会にご招待させていただきます。
                  </li>-->
                </ul>
                <div class="tab__contents--payment">
                  <h3 class="tab__contents--payment-title">お支払い方法</h3>
                  <div class="tab__contents--payment-body-wrap">
                    <div>
                      <div class="tab__contents--payment-body">
                        下記のクレジットカードがご利用いただけます
                      </div>
                      <img
                        class="tab__contents--payment-image"
                        src="@/assets/image/donation/card_brand.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <form
                    action="https://credit.j-payment.co.jp/gateway/payform.aspx"
                    method="POST"
                  >
                    <select
                      name="iid"
                      id="select02"
                      class="payment-select"
                      @change="onChange"
                    >
                      <!-- <option value="">年間の支援金額を選択</option>
                    <option value="sk120"
                      >寺子屋ブッダ賛助会員・法人として継続支援（120,000円/年）</option
                    > -->
                      <option value="sk036"
                        >寺子屋ブッダ賛助会員・個人として継続支援（36,000円/年）
                      </option>
                    </select>
                    <input type="hidden" name="aid" value="121268" />
                    <input type="hidden" name="cod" value="" />
                    <input type="hidden" name="jb" value="CAPTURE" />
                    <!-- <input type="hidden" name="iid" value="machitera-main" /> -->
                    <input type="hidden" name="pt" value="" />
                    <div class="tab__contents--btn">
                      <button type="submit" :disabled="!isSelected.select02">
                        <span class="tab__contents--btn-link small"
                          >個人として年間のご寄付を申し込む</span
                        >
                      </button>
                    </div>
                    <p
                      style="font-size: 12px; text-align: center; margin-top: 10px;"
                    >
                      ※決済会社のフォームに移動します
                    </p>
                  </form>
                  <div class="payment-message">
                    ※寺子屋ブッダ賛助会員・法人として継続援助をご希望の方は<a
                      href="/events/contact/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >こちら</a
                    >よりお問い合わせください
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="tab__contents--wrap u-use--pc">
          <ul class="tab__contents--list-wrap">
            <li class="tab__contents--item blue" v-if="isActive === 'blue'">
              <h3 class="tab__contents--title">
                まち寺サポーターは、<br />「まち寺」ポータルサイトの運営を継続的に応援していただく会員様です。
              </h3>
              <ul>
                <li class="tab__contents--list blue">
                  まち寺サポーターの皆様は、「まち寺」内の、サポーター一覧にお名前（ご寄付いただく際に、掲載用に入力いただいたお名前）を掲載させていただきます。
                </li>
                <!--              <li class="tab__contents&#45;&#45;list blue">
                  「まち寺」はじめ寺子屋ブッダの活動にご協力いただいている皆様のオンライン交流会にご招待させていただきます。<br />
                  ご意見・ご要望・アイデアをいただき、出来るかぎり運営に生かさせていただきます。
                </li>-->
              </ul>
              <div class="tab__contents--payment">
                <h3 class="tab__contents--payment-title">お支払い方法</h3>
                <div>
                  <div class="tab__contents--payment-body">
                    下記のクレジットカードがご利用いただけます
                  </div>
                  <img
                    class="tab__contents--payment-image"
                    src="@/assets/image/donation/card_brand.png"
                    alt=""
                  />
                </div>
                <form
                  action="https://credit.j-payment.co.jp/gateway/payform.aspx"
                  method="POST"
                >
                  <select
                    name="iid"
                    id="select03"
                    class="payment-select"
                    @change="onChange"
                  >
                    <option value="">継続支援金額を選択</option>
                    <option value="ms10"
                      >まち寺サポーターとして継続支援（10,000円/月）
                    </option>
                    <option value="ms05"
                      >まち寺サポーターとして継続支援（5,000円/月）
                    </option>
                    <option value="ms03"
                      >まち寺サポーターとして継続支援（3,000円/月）
                    </option>
                    <option value="ms02"
                      >まち寺サポーターとして継続支援（2,000円/月）
                    </option>
                    <option value="ms01"
                      >まち寺サポーターとして継続支援（1,000円/月）
                    </option>
                  </select>
                  <input type="hidden" name="aid" value="121268" />
                  <input type="hidden" name="cod" value="" />
                  <input type="hidden" name="jb" value="CAPTURE" />
                  <!-- <input type="hidden" name="iid" value="machitera-main" /> -->
                  <input type="hidden" name="pt" value="" />
                  <div class="tab__contents--btn">
                    <button
                      type="submit"
                      class="tab__contents--btn-link"
                      :disabled="!isSelected.select03"
                    >
                      毎月のご寄付を申し込む
                    </button>
                  </div>
                  <p
                    style="font-size: 12px; text-align: center; margin-top: 10px;"
                  >
                    ※決済会社のフォームに移動します
                  </p>
                </form>
              </div>
            </li>

            <li
              class="tab__contents--item orange"
              v-else-if="isActive === 'orange'"
            >
              <h3 class="tab__contents--title">
                「都度のご寄付」は<br />自由金額でご寄付いただくことが可能です。
              </h3>
              <ul>
                <!--              <li class="tab__contents&#45;&#45;list orange">
                  「まち寺」はじめ寺子屋ブッダの活動にご協力いただいている皆様のオンライン交流会にご招待させていただきます。<br />
                  ご意見・ご要望・アイデアをいただき、出来るかぎり運営に生かさせていただきます。
                </li>-->
              </ul>
              <div class="tab__contents--payment">
                <h3 class="tab__contents--payment-title">お支払い方法</h3>
                <div class="tab__contents--payment-body-wrap">
                  <div class="tab__contents--payment-block">
                    <div class="tab__contents--payment-body">
                      下記のクレジットカード、銀行振込がご利用いただけます
                    </div>
                    <img
                      class="tab__contents--payment-image"
                      src="@/assets/image/donation/card_brand.png"
                      alt=""
                    />
                  </div>
                  <!-- <div class="tab__contents--payment-block">
                    <div class="tab__contents--payment-body">
                      口座振替
                    </div>
                    <div class="tab__contents--payment-bank-tit">
                      各種銀行口座（ゆうちょ銀行含む）
                    </div>
                    <div class="tab__contents--payment-bank-text">
                      ※ご登録の住所に手続き用紙をお送りしますので、記入・捺印のうえ10日以内にご返送をお願いします。
                    </div>
                  </div> -->
                </div>
                <form
                  class="form-wrap"
                  action="https://credit.j-payment.co.jp/gateway/payform.aspx"
                  method="POST"
                >
                  <input
                    id="input02"
                    class="form-input"
                    name="am"
                    type="number"
                    value=""
                    min="1000"
                    max="100000"
                    @input="onInput"
                  />円
                  <p class="cation">
                    ※1,000円からのご寄付をお願いしております
                  </p>
                  <input type="hidden" name="aid" value="121268" />
                  <input type="hidden" name="cod" value="" />
                  <input type="hidden" name="jb" value="CAPTURE" />
                  <!-- <input type="hidden" name="iid" value="machitera-main" /> -->
                  <input TYPE="hidden" name="tx" value="0" />
                  <input TYPE="hidden" name="sf" value="0" />
                  <input TYPE="hidden" name="pt" value="" />
                  <div class="tab__contents--btn">
                    <button
                      type="submit"
                      class="tab__contents--btn-link"
                      :disabled="!isSelected.input02"
                    >
                      都度のご寄付を申し込む
                    </button>
                  </div>
                  <p class="cation">
                    ※決済会社のフォームに移動します
                  </p>
                </form>
              </div>
            </li>

            <li
              class="tab__contents--item green"
              v-else-if="isActive === 'green'"
            >
              <h3 class="tab__contents--title">
                寺子屋ブッダ賛助会員としてのご寄付。
              </h3>
              <p class="tab__contents--body">
                寺子屋ブッダの賛助会員は、「お寺をもっと身近で楽しくて温かい場所にすることで、まちやひとを元気にする」寺子屋ブッダの活動全般を応援いただく会員様です。<br />
                寺子屋ブッダは、「まち寺」ポータルサイトの運営の他、お寺を地域の健康拠点にする活動「ヘルシーテンプル・コミュニティ」の運営、お寺のソーシャルプロジェクトをすすめる僧侶の学びの場「寺子屋学」などを行っています。これらの活動の、モデルケースづくり、取材費、システム維持費、広報活動費、事務局運営費に使わせていただきます。
              </p>
              <ul>
                <li class="tab__contents--list green">
                  寺子屋ブッダ賛助会員の皆様は、「まち寺」内の、寄付者一覧にお名前（掲載用に入力いただいた登録名）を掲載させていただきます。
                </li>
                <!--              <li class="tab__contents&#45;&#45;list green">
                  「まち寺」にご協力いただいている皆様を特別招待させていただくオンライン交流会にご招待させていただきます。
                </li>-->
              </ul>

              <div class="tab__contents--payment">
                <h3 class="tab__contents--payment-title">お支払い方法</h3>
                <div>
                  <div class="tab__contents--payment-body">
                    下記のクレジットカードがご利用いただけます
                  </div>
                  <img
                    class="tab__contents--payment-image"
                    src="@/assets/image/donation/card_brand.png"
                    alt=""
                  />
                </div>
                <form
                  action="https://credit.j-payment.co.jp/gateway/payform.aspx"
                  method="POST"
                >
                  <select
                    name="iid"
                    id="select04"
                    class="payment-select"
                    @change="onChange"
                  >
                    <option value="">年間の支援金額を選択</option>
                    <!-- <option value="sk120"
                      >寺子屋ブッダ賛助会員・法人として継続支援（120,000円/年）</option
                    > -->
                    <option value="sk036"
                      >寺子屋ブッダ賛助会員・個人として継続支援（36,000円/年）
                    </option>
                  </select>
                  <input type="hidden" name="aid" value="121268" />
                  <input type="hidden" name="cod" value="" />
                  <input type="hidden" name="jb" value="CAPTURE" />
                  <!-- <input type="hidden" name="iid" value="machitera-main" /> -->
                  <input type="hidden" name="pt" value="" />
                  <div class="tab__contents--btn">
                    <button type="submit" :disabled="!isSelected.select04">
                      <span class="tab__contents--btn-link small"
                        >個人として年間のご寄付を申し込む</span
                      >
                    </button>
                  </div>
                  <p
                    style="font-size: 12px; text-align: center; margin-top: 10px;"
                  >
                    ※決済会社のフォームに移動します
                  </p>
                </form>
                <div class="payment-message">
                  ※寺子屋ブッダ賛助会員・法人として継続援助をご希望の方は<a
                    href="/events/contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >こちら</a
                  >よりお問い合わせください
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <section v-if="!loading && (isCurrentPageSet || editMode)">
        <div class="p-contents">
          <div class="p-contents__block l-wrapper">
            <template
              v-for="(list, index) in setState.currentPage.content.items"
              :key="list.hash"
            >
              <ContentBlock
                v-model="setState.currentPage.content.items[index]"
                :initial-item="
                  getItemByHash(
                    initialState.currentPage.content.items,
                    list.hash
                  )
                "
                :indexKey="index"
                :handle-click-delete="deleteContentBlock"
                :editMode="editMode"
                :useTitle="true"
              />
            </template>
            <AddContentBtn
              :handle-click-add="addContentBlock"
              :editMode="editMode"
              :useTitle="true"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { ref, inject, onMounted } from 'vue';
import { usePages } from '@/composables/usePages';
import { useCommons } from '@/composables/useCommons';
import { useRoute, useRouter } from 'vue-router';
import ContentBlock from '@/components/ContentBlock.vue';
import { getItemByHash } from '@/utils/hash';
import AddContentBtn from '@/components/AddContentBtn';
import ControlArea from '@/components/ControlArea';

export default {
  name: 'DonationPage',
  props: {},
  components: {
    ContentBlock,
    AddContentBtn,
    ControlArea,
  },
  data() {
    return {
      editMode: false,
    };
  },
  created() {
    if (this.$route.path.indexOf('edit') != -1) {
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
  methods: {
    changeMode() {
      this.editMode = !this.editMode;
      this.modeChangeFlg = true;
    },
  },
  mounted() {
    if (this.noArticleAuthFlg) {
      this.editMode = false;
    }
  },
  setup() {
    const { isAdmin } = inject('auth');
    const {
      getCurrentPage,
      state,
      setState,
      deleteContentBlock,
      addContentBlock,
      initialState,
      isCurrentPageSet,
      control,
    } = usePages();
    const { loading } = useCommons();
    const { viewControlAreaFlg, modeChangeFlg } = inject('commons');
    const route = useRoute();
    const router = useRouter();
    getCurrentPage('donation');
    let isActive = ref('blue');
    let noArticleAuthFlg = ref(false);
    let isOpen = ref([false, false, false]);
    let isSelected = ref({
      select01: false,
      select02: false,
      select03: false,
      select04: false,
      input01: false,
      input02: false,
    });

    const isSelect = (color) => {
      isActive.value = color;
    };

    const onChange = (event) => {
      event.target.value
        ? (isSelected.value[event.target.id] = true)
        : (isSelected.value[event.target.id] = false);
    };

    const onInput = (event) => {
      event.target.value
        ? (isSelected.value[event.target.id] = true)
        : (isSelected.value[event.target.id] = false);
    };

    onMounted(() => {
      if (!isAdmin.value) {
        const target = 'edit';
        if (route.path.indexOf(target) > 0) {
          noArticleAuthFlg.value = true;
          const newPath = route.path.replace(target, '');
          router.push({
            path: newPath,
          });
        }
      } else {
        viewControlAreaFlg.value = true;
      }
    });

    return {
      isAdmin,
      isActive,
      isSelect,
      isOpen,
      onChange,
      isSelected,
      onInput,
      state,
      setState,
      deleteContentBlock,
      addContentBlock,
      initialState,
      getItemByHash,
      control,
      isCurrentPageSet,
      loading,
      noArticleAuthFlg,
      viewControlAreaFlg,
      modeChangeFlg,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  @include sp {
    padding-top: 8.4rem; //未ログイン時
    &.with-control-area {
      padding-top: 0;
    }
  }
  @include pc {
    &.with-control-area {
      padding-top: 0;
    }
  }
  padding-top: 12rem;
}
.article__content {
  @include sp {
    padding-top: 7rem;
  }
  padding-top: 12rem;

  // &:not(.edit) {
  // 	.edit-mode {
  // 		display: none;
  // 	}
  // }
  // &.edit {
  // 	.view-mode {
  // 		display: none;
  // 	}
  // }
}

.donation-page {
  @include pc {
    max-width: 150rem;
    padding-top: 7rem;
    width: 83.3333%;
  }
  margin: 0 auto;
  &__wrap {
    background-color: #f7f6f4;
    @include sp {
      padding-top: 26.26vw; //未ログイン時
      .with-control-area & {
        padding-top: 0;
      }
    }
  }

  &__title {
    @include pc {
      font-size: 4rem;
    }
    @include sp {
      font-size: 2rem;
    }
    @include font_iroha_600;
    letter-spacing: 8px;
    line-height: 1.25;
    position: relative;
    text-align: center;

    &::after {
      background-color: #c84d5f;
      bottom: -4.5rem;
      content: '';
      height: 1rem;
      @include sp {
        bottom: -2.25rem;
        height: 5px;
        width: 4rem;
      }
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 8rem;
    }

    &--image {
      @include pc {
        width: 29rem;
      }

      &-wrap {
        @include sp {
          margin: 0 auto;
          width: 41.6vw;
        }
        text-align: center;
      }
    }
  }

  &__contents {
    @include pc {
      display: flex;
      justify-content: center;
      margin-bottom: 7rem;
      margin-top: 12rem;
    }
    @include tablet {
      display: block;
    }
    @include sp {
      margin-top: 14.666vw;
      padding: 0 2.5rem;
    }
    padding: 0 5rem;
  }

  &__image {
    border-radius: 50% 0 50% 0;
    height: 100%;
    @include sp {
      width: 100%;
    }
    @include tablet {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    max-width: 58rem;
    width: 32.222vw;
  }

  &__cv {
    // width: 65.1rem;
    @include sp {
      margin-left: 0;
      margin-top: 8vw;
      max-width: initial;
      width: 100%;
    }
    @include tablet {
      margin-left: 0;
      margin-top: 8vw;
      max-width: initial;
      width: 100%;
    }
    margin-left: 3.7222rem;
    max-width: 65.1rem;
    width: 36.166vw;

    &--tilte {
      color: #bb4d5e;
      font-size: 4rem;
      @include fz_vw(40);
      @include font_shuei_400;
      @include sp {
        font-size: 2rem;
        letter-spacing: 0;
      }
      letter-spacing: 8px;
      line-height: 1.63;
    }

    &--body {
      font-size: 1.6rem;
      @include font_shuei_400;
      @include pc {
        letter-spacing: 3.2px;
      }
      @include sp {
        font-size: 1.1em;
        line-height: 1.75;
        margin-top: 1.5rem;
      }
      line-height: 2.5;
      margin-top: 3rem;
      text-align: left;
    }
  }

  &__sub-title {
    font-size: 3rem;
    @include sp {
      font-size: 1.7rem;
      line-height: 1.7;
      margin: 11.06vw auto 7.2vw;
      padding: 0 5rem;
    }
    @include font_shuei_400;
    letter-spacing: 6px;
    line-height: 1.33;
    text-align: center;
  }
}

.tab {
  &__wrap {
    @include pc {
      margin-top: 4rem;
    }
  }

  &__list {
    @include pc {
      display: flex;
      justify-content: center;
    }
  }

  &__item {
    background-color: #e8e6e3;
    cursor: pointer;
    @include pc {
      width: 35rem;
    }
    @include sp {
      background-color: #fff;
      padding: 6.4vw 6.8vw 0;
    }
    position: relative;
    text-align: center;

    &-inner {
      height: 100%;
      padding: 2.8rem 2.88vw 6rem;
      width: 100%;
    }

    &.active {
      background-color: #fff;
    }

    &::before {
      content: '';
      display: inline-block;
      height: 6px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.blue {
      &::before {
        background-color: #7ba4c1;
      }
    }

    &.orange {
      &::before {
        background-color: #cf9954;
      }
    }

    &.green {
      &::before {
        background-color: #9eaf3d;
      }
    }

    &:nth-of-type(n + 2) {
      @include pc {
        margin-left: 20px;
      }
    }
  }

  &__sub-title {
    @include pc {
      font-size: 1.3rem;
    }
    @include sp {
      font-size: 1rem;
      position: relative;
      text-align: left;

      &::after {
        background: url(~@/assets/image/donation/ico_open.png) no-repeat;
        background-size: contain;
        content: '';
        display: inline-block;
        height: 1.1rem;
        position: absolute;
        right: 0;
        top: 1.5rem;
        width: 1.1rem;
      }
    }
    @include font_shuei_400;
    letter-spacing: 2.6px;
    line-height: 1.54;
    text-align: center;
  }

  &__title {
    @include pc {
      font-size: 2.5rem;
      margin-left: -4px;
    }
    @include tablet {
      font-size: 2rem;
    }
    @include sp {
      border-bottom: 1px solid #e8e6e3;
      font-size: 1.8rem;
      margin-left: 0;
      padding-bottom: 5.33vw;
      position: relative;
      text-align: left;
    }
    @include font_iroha_600;
    letter-spacing: 4px;
    line-height: 1.6;
    position: relative;

    &::before {
      background-color: #d0dee7;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 24px;
      @include sp {
        content: none;
      }
      left: 50%;
      position: absolute;
      top: 6rem;
      transform: translateX(-50%);
      width: 24px;
    }

    .blue & {
      &::before {
        background-color: #d0dee7;
      }
    }

    .orange & {
      &::before {
        background-color: #ebd9c2;
      }
    }

    .green & {
      &::before {
        background-color: #dbe1bb;
      }
    }

    &::after {
      background: url(~@/assets/image/donation/arrow.svg) no-repeat;
      content: '';
      display: inline-block;
      height: 30px;
      @include sp {
        content: none;
      }
      left: 50%;
      position: absolute;
      top: 5rem;
      transform: translateX(-50%);
      width: 8px;
    }
  }

  &__contents {
    &--wrap {
      background-color: #fff;
      padding: 9rem 12.7vw;
      width: 100%;
    }

    &--list {
      width: 100%;

      &-wrap {
        margin: 0 auto;
        // width: 68.666%;
        // width: 103rem;
        width: 100%;
        // max-width: 103rem;
      }
    }

    &--title {
      @include font_shuei_400;
      @include pc {
        font-size: 2.5rem;
      }
      @include sp {
        font-size: 1.3rem;
        letter-spacing: 3px;
        margin-bottom: 5.33vw;
        margin-top: 4vw;
      }
      letter-spacing: 5px;
      line-height: 1.8;
      margin-bottom: 4rem;
      text-align: center;
    }

    &--body {
      @include pc {
        font-size: 1.5rem;
        line-height: 2;
        margin-bottom: 4rem;
      }
      @include sp {
        font-size: 1.1rem;
        letter-spacing: 2.2px;
        line-height: 1.82;
        margin-bottom: 5.3vw;
        text-align: left;
      }
      text-align: left;
    }

    &--list {
      @include sp {
        font-size: 1.1rem;
        text-align: left;
      }
      line-height: 2;
      margin-bottom: 1.4rem;
      padding-left: 2rem;
      position: relative;

      &::before {
        background: #7ba4c1;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 1.5rem;
        @include sp {
          height: 1.1rem;
          top: 0.6rem;
          width: 1.1rem;
        }
        left: 0;
        position: absolute;
        top: 20%;
        width: 1.5rem;
      }

      &.orange {
        &::before {
          background: #cf9954;
        }
      }

      &.green {
        &::before {
          background: #9eaf3d;
        }
      }
    }

    &--item {
      @include sp {
        &.u-use--sp {
          display: none;

          &.isopen {
            display: block;
          }
        }
      }
    }

    &--payment {
      background-color: #f7f6f4;
      @include font_shuei_400;
      @include pc {
        margin-top: 5rem;
      }
      @include sp {
        margin-left: -2.5rem;
        margin-top: 8vw;
        padding: 6.66vw;
        width: 100vw;
      }
      padding: 6rem 4vw;

      &-block {
        @include tablet {
          margin: 0 auto;
          text-align: center;
          width: 37.4rem;

          &:last-child {
            margin: 4rem auto 0;
          }
        }
        @include sp {
          width: 100%;
          &:last-child {
            margin: 5.333vw auto 0;
          }
        }
        width: 48%;
      }

      &-title {
        @include pc {
          font-size: 3.6rem;
          margin-bottom: 4.5rem;
        }
        @include sp {
          font-size: 1.75rem;
          margin-bottom: 3.466vw;
        }
        letter-spacing: 5.25px;
        line-height: 1;
        text-align: center;
      }

      &-body {
        background-color: #fff;
        border-radius: 20px;
        @include pc {
          font-size: 1.6rem;
        }
        @include sp {
          font-size: 1.1rem;
          padding: 1.7rem 2rem;
          width: 100%;
        }
        line-height: 1.2;
        margin-bottom: 2.8rem;
        padding: 12px 1.444vw;
        text-align: center;

        &-wrap {
          display: flex;
          @include sp {
            display: block;
          }
          @include tablet {
            display: block;
          }
          justify-content: space-between;

          .orange & {
            display: block;

            & .tab__contents--payment-block {
              width: 100%;
            }
          }
        }

        .blue & {
          @include sp {
            min-width: initial;
            width: 100%;
          }
          @include tablet {
            width: 80%;
          }
          margin: 0 auto 2.9rem;
          min-width: 37rem;
          width: 70%;
        }

        .green & {
          @include sp {
            min-width: initial;
            width: 100%;
          }
          @include tablet {
            width: 80%;
          }
          margin: 0 auto 2.9rem;
          min-width: 37rem;
          width: 70%;
        }

        .orange & {
          @include sp {
            min-width: initial;
            width: 100%;
          }
          @include tablet {
            width: 80%;
          }
          margin: 0 auto 2.9rem;
          min-width: 37rem;
          width: 70%;
        }
      }

      &-bank {
        &-tit {
          font-family: $contents_fonts_jp_Gothic;
          font-size: 1.5rem;
          font-weight: bold;
          letter-spacing: 3px;
          line-height: 2.33;
        }

        &-text {
          font-family: $contents_fonts_jp_Gothic;
          font-size: 1.3rem;
          letter-spacing: 2.6px;
          line-height: 1.92;
          text-align: center;
        }
      }

      &-left {
        @include tablet {
          width: 100%;
        }
        text-align: center;
        width: 26.333vw;
      }

      &-image {
        @include sp {
          width: 80%;
        }
        @include tablet {
          width: 80%;
        }
        width: 21.44vw;

        .blue & {
          display: block;
          @include sp {
            width: 100%;
          }
          margin: 0 auto;
          text-align: center;
          width: 50rem;
        }

        .green & {
          display: block;
          @include sp {
            width: 100%;
          }
          margin: 0 auto;
          text-align: center;
          width: 50rem;
        }

        .orange & {
          display: block;
          @include sp {
            width: 100%;
          }
          margin: 0 auto;
          text-align: center;
          width: 50rem;
        }
      }
    }

    &--year-fee {
      @include tablet {
        margin-top: 3.2rem;
        width: 100%;
      }
      @include sp {
        margin-top: 8.533vw;
        width: 100%;
      }
      width: 17.055vw;

      &-titel {
        @include pc {
          font-size: 1.6rem;
          letter-spacing: 3.2px;
          margin: 1.2rem 0 2.5rem;
        }
        @include sp {
          font-size: 1.1rem;
          letter-spacing: 4.4px;
          margin-top: 6.66vw;
          text-align: left;
        }
      }

      &-list {
        @include pc {
          font-size: 2rem;
          letter-spacing: 4px;
          line-height: 1.75;
        }
        @include sp {
          font-size: 1.3rem;
          letter-spacing: 5.2px;
          line-height: 1.92;
          margin-top: 1rem;
          text-align: left;
        }
        text-align: left;
      }
    }

    &--btn {
      background-color: #b24b5b;
      border-radius: 50px;
      color: #fff;
      display: block;
      font-family: iroha-23kaede-stdn, sans-serif;
      font-size: 2.2rem;
      font-style: normal;
      font-weight: 400;
      height: 8rem;
      // @include fz_vw(25);
      @include sp {
        font-size: 2rem;
        height: 16vw;
        max-height: 120px;
        min-width: initial;
        width: 100%;
      }
      margin: 40px auto 0;
      min-width: 35rem;
      position: relative;
      width: 48.54%;

      &-link {
        color: #fff;
        display: inline-block;
        font-family: iroha-23kaede-stdn, sans-serif;
        font-size: 2.2rem;
        height: 100%;
        @include sp {
          font-size: 2rem;
          line-height: 16vw;
          padding-left: 21.066vw;
          text-align: left;
        }
        line-height: 8rem;
        padding-left: 6.33vw;
        position: relative;
        text-align: left;
        width: 100%;

        &.small {
          display: inline;
          font-size: 1.6rem;
          @include pc {
            padding-left: 4.83vw;
          }
          @include sp {
            padding-left: 2.5rem;
          }
          text-align: center;

          &::before {
            @include pc {
              height: 1.9vw;
              left: 1.7vw;
              width: 3.5rem;
            }
            @include sp {
              left: -1rem;
            }
          }
        }

        &::before {
          background: url(~@/assets/image/about/hands_white.svg) no-repeat;
          background-size: contain;
          content: '';
          display: inline-block;
          height: 1.9vw;
          @include sp {
            height: 5.6vw;
            left: 8.8vw;
            width: 7.466vw;
          }
          left: 2.7vw;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 2.611vw;
        }
      }
    }
  }
}

.payment-message {
  font-size: 1.6rem;
  @include sp {
    font-size: 1.2rem;
  }
  line-height: 1.5;
  margin: 3rem auto 0;
  text-align: center;
}

.payment-select {
  -webkit-appearance: none;
  background-color: #fff;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211px%22%20height%3D%226px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(0%2C%200%2C%200)%22%20d%3D%22M10.208%2C0.428%20L5.646%2C4.990%20L5.728%2C5.072%20L5.332%2C5.468%20L5.250%2C5.385%20L5.168%2C5.468%20L4.772%2C5.072%20L4.855%2C4.990%20L0.292%2C0.428%20L0.688%2C0.032%20L5.250%2C4.594%20L9.812%2C0.032%20L10.208%2C0.428%20Z%22%2F%3E%3C%2Fsvg%3E');
  background-position: right 16px top 50%;
  background-repeat: no-repeat;
  background-size: 11px px 6px;
  border: 1px solid #9a8c82;
  border-radius: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  height: auto;
  @include font_shuei_400;
  @include sp {
    font-size: 1rem;
    width: 100%;
  }
  margin: 3rem auto 0;
  outline: 0;
  padding: 12px 16px;
  padding-right: 28px;
  -webkit-transition: 50ms border-color ease-in-out;
  transition: 50ms border-color ease-in-out;
}

.form-wrap {
  margin: 0 auto;
  text-align: center;
}

.form-input {
  background-color: #fff;
  border: 1px solid #9a8c82;
  border-radius: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #000;
  font-size: 1.6rem;
  margin: 3rem auto 0;
  margin-right: 0.8rem;
  outline: 0;
  padding: 12px 16px;
  -webkit-transition: 50ms border-color ease-in-out;
  transition: 50ms border-color ease-in-out;
  width: 30%;
}

.color__font {
  &--blue {
    color: #7ba4c1;
  }

  &--orange {
    color: #cf9954;
  }

  &--green {
    color: #9eaf3d;
  }
}

.cation {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
:deep {
  .body {
    text-align: center;
  }
  .p-contents__catch {
    text-align: center;
  }
}
</style>

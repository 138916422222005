<template>
  <section class="search-box">
    <SearchArea :searchConditions="searchConditions"/>
  </section>
  <ProjectList :limit="limit" :conditions="conditions" v-if="targets.includes('project')" :decorate="false"/>
  <EventList :conditions="conditions" :limit="limit" v-if="targets.includes('event')" :decorate="false"/>
  <ReportList :conditions="conditions" :limit="limit" v-if="targets.includes('report')" :decorate="false"/>
  <TempleList :conditions="conditions" :limit="limit" v-if="targets.includes('temple')" :decorate="false"/>
</template>

<script>
import ProjectList from '@/components/ProjectList.vue'
import EventList from '@/components/EventList.vue'
import ReportList from '@/components/ReportList.vue'
import TempleList from '@/components/TempleList.vue'
import SearchArea from '@/components/SearchArea.vue'
import {useRoute} from "vue-router";
import {computed, ref, watch} from "vue";

export default {
  name: 'Search',
  props: {

  },
  setup(){
    const route = useRoute();
    console.log("route----", route);
    const { keyword, prefecture, category, tag, event_group_id } = route.query;

    const targets = computed( () => route.query.target ? route.query.target.split(',') : ['project', 'event', 'report', 'temple']);

    const conditions = ref({
      keyword: keyword ? keyword : null,
      prefecture: prefecture ? prefecture : null,
      category: category ? category : null,
      tag: tag ? tag : null,
      event_group_id: event_group_id ? event_group_id : null,
      merge: !event_group_id
    });

    const limit = ref(route.query.limit);


    watch(()=> route.query, (to, from) => {
      if(to !== from){
        const { keyword, prefecture, category, tag, event_group_id } = route.query;
        conditions.value = {
          keyword: keyword ? keyword : null,
          prefecture: prefecture ? prefecture : null,
          category: category ? category : null,
          tag: tag ? tag : null,
          event_group_id: event_group_id ? event_group_id : null,
          merge: !event_group_id
        }

        limit.value = route.query.limit;
      }
    });

    const searchConditions = ref({
      keyword: true,
      prefecture: true,
      targets:[
      { id: 'project', name: 'プロジェクト' },
      { id: 'event', name: 'イベント' },
      { id: 'report', name: 'レポート' },
      { id: 'temple', name: '参加寺院' },
    ],
    })

    return {targets, conditions, limit, searchConditions}
  },
  components: {
    ProjectList,
    EventList,
    ReportList,
    TempleList,
    SearchArea,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-img{
  position: relative;
  height: 97rem;
  padding-top: 12rem;
}
.base-point{
  height: 0;
  width: 0;
  margin: auto;
  position: relative;
}
.title{
  position: absolute;
  max-width: initial;
  width: 42rem;
  z-index: 99;
  top: 5rem;
  left: 8.5rem;
}
.copy{
  font-family: "iroha-23kaede-stdn", Avenir, Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  letter-spacing: 1.1rem;
  line-height: 5rem;
  position: absolute;
  width: 39rem;
  top: 33rem;
  left: 24rem;
  z-index: 30;
}
.bg{
  position: absolute;
  max-width: initial;
  z-index: 1;
}
.bg-shippo-l{
  width: 18.4rem;
  right: 8rem;
  top: 3rem;
}
.bg-hishigata01{
  width: 4rem;
  left: 17rem;
  top: 1rem;
}
.bg-hishigata02{
  width: 4rem;
  right: 16rem;
  top: 67rem;
}
.bg-shippo-s01{
  width: 3.9rem;
  right: 18rem;
  top: 74rem;
}
.bg-shippo-s02{
  top: 41rem;
  left: 58rem;
}
.bg-uzu{
  content: "";
  background-image: url('~@/assets/image/top/bg-uzu.svg');
  width: 24rem;
  height: 11rem;
  top: 0;
  left: 31rem;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.bg-nami01{
  width: 11rem;
  top: 58rem;
  left: 18rem;
}
.bg-icho-left{
  width: 6rem;
  top: 46rem;
  left: 35rem;
}
.bg-soroban01{
  width: 10.7rem;
  top: 24rem;
  left: 70rem;
}
.bg-icho-right{
  width: 6rem;
  right: 33rem;
  top: 16rem;
}
.bg-hamon{
  width: 18.4rem;
  top: 21rem;
  right: 29rem;
}
.bg-nami02{
  width: 11rem;
  top: 34rem;
  right: 46rem;
}
.bg-soroban02{
  width: 10.7rem;
  top: 58rem;
  right: 66rem;
}
.center-img{
  position: absolute;
  top: 51%;
  left: 48%;
  -webkit-transform : translate(-50%,-50%);
  transform : translate(-50%,-50%);
  z-index: 20;
}
.center-img img{
  object-fit: cover;
  width: 47.6rem;
  height: 47.6rem;
  border-radius: 35% 0 35% 0;
}
.clip{
  position: absolute;
  max-width: initial;
}
.clip img{
  border-radius: 35% 0 35% 0;
  object-fit: cover;
}
.clip-right01{
  top: 10rem;
  left: 55rem;
}
.clip-right01, .clip-right01 img{
  width: 15.3rem;
  height: 15.3rem;
}
.clip-right02{
  top: 49rem;
  left: 41rem;
}
.clip-right02, .clip-right02 img{
  width: 24.5rem;
  height: 24.5rem;
}
.clip-left01{
  top: 3rem;
  right: 40rem;
}
.clip-left01, .clip-left01 img{
  width: 15.3rem;
  height: 15.3rem;
}
.clip-left02{
  top: 14rem;
  right: 66rem;
}
.clip-left02, .clip-left02 img{
  width: 22.1rem;
  height: 22.1rem;
}
.clip-left03{
  top: 49rem;
  right: 34rem;
}
.clip-left03, .clip-left03 img{
  width: 29.7rem;
  height: 29.7rem;
}
.social{
  position: fixed;
  top: 42rem;
  left: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.social > p{
  font-family: "aw-conqueror-didot", serif;
  writing-mode: vertical-rl;
  font-size: 1.3rem;
  letter-spacing: 0.5rem;
  margin-bottom: 3rem;
}
.social > div {
  display: flex;
  flex-direction: column;
}
.social > div a{
  margin-bottom: 2rem;
}
.scroll{
  position: absolute;
  bottom: -12%;
  left: 49%;
  -webkit-transform : translate(-50%,-50%);
  transform : translate(-50%,-50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scroll p{
  font-family: "dnp-shuei-mincho-pr6", sans-serif;
  font-size: 1.3rem;
  letter-spacing: 0.5rem;
  margin-bottom: 1.5rem;
}
.tag01, .tag02, .tag03, .tag04{
  position: relative;
}
.tag01{
  bottom: 80px;
  left: 370px;
}
.tag02{
  bottom: 10rem;
  left: 20rem;
}
.tag03{
  bottom: 7rem;
  left: 18rem;
}
.tag04{
  bottom: 10rem;
  left: 23rem;
}
.search-box{
  padding-top: 12rem;
}
</style>

<template>
  <div class="for-temples">
    <section class="mv">
      <img
        class="mv__image mv__image-01 u-use--pc"
        src="@/assets/image/about/mv06.png"
        alt=""
      />
      <div class="mv__title--wrap">
        <h1>
          <span class="mv__title--sub">ソーシャルプロジェクトを</span
          ><span class="mv__title--main">お寺に企画提案する</span>
        </h1>
        <span class="mv__title--btn u-use--pc">
          まち寺パートナー向け
        </span>
      </div>
      <img
        class="mv__image-02 u-use--pc"
        src="@/assets/image/about/mv07.png"
        alt=""
      />
      <div class="mv__sp u-use--sp">
        <img
          class="mv__image mv__image-01 u-use--sp"
          src="@/assets/image/about/mv06.png"
          alt=""
        />
        <button class="mv__title--btn u-use--sp">
          まち寺パートナー向け
        </button>
        <img
          class="mv__image-02 u-use--sp"
          src="@/assets/image/about/mv07.png"
          alt=""
        />
      </div>
    </section>
    <section class="project">
      <h2 class="project__title title">
        お寺との共創・協働で未来をつくろう！
      </h2>
      <p class="project__text">
        全国にお寺は、77,000ヶ寺あると言われています。<br />
        この数は、コンビニエンスストアの1.5倍、小学校の3倍以上に相当します。<br />
        多くのお寺は人が集うためのつくりになっていることから、ハード的価値を活かした寺子屋活動などが行われてきました。<br />
        近年では、人々のココロとカラダの健康に貢献しようという「ヘルシーテンプル」や、<br />
        ひとり親家庭への支援に取り組む「おてらおやつクラブ」など、<br />
        お寺同士が連携して社会課題の解決に向かう活動も増えてきています。
      </p>
      <p class="project__text bold">
        全国にたくさんあるお寺が、それぞれの抱える課題に対して何かひとつ取り組むことができれば、<br />
        それは社会を支える大きな力になるはずです。
      </p>
      <img
        class="project__image"
        src="@/assets/image/about/project02.png"
        alt=""
      />
      <p class="project__text">
        お寺の特筆するべき力は、古くから存在し続けることで培われてきた関係性の価値です。<br />
        特に地域とのつながりや信頼感はお寺独自のものであり、場づくりやコミュニティ形成における大きな基盤となります。<br />
        最近は行政も注目し始めていて、支援を受けたり連携を進めているお寺もあると聞きます。
      </p>
      <p class="project__text">
        もし、あなたの町で力をあわせて解決するべき課題があったり、<br />
        地域の人たちがより良く生きるために貢献できる活動があれば、<br />
        まち寺で「お寺のソーシャルプロジェクト」として提案してみませんか？<br />
        あなたの持ってる理論や実績や人脈をいかしてください！<br />
        全国各地のお寺が、あなたの提案を待っています！
      </p>
    </section>
    <section class="about__social-project">
      <div class="about__social-project--inner">
        <h3 class="about__social-project--title">
          お寺のソーシャルプロジェクト例
        </h3>
        <div class="about__social-project--wrap">
          <ul class="about__social-project--list">
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">ココロとカラダの健康</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=ヘルシーテンプル" class=""
                    >#ヘルシーテンプル</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=寺ヨガ" class=""
                    >#寺ヨガ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=人生会議" class=""
                    >#人生会議</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">学びの場づくり</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=伝統を学ぶ" class=""
                    >#伝統を学ぶ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=書を学ぶ" class=""
                    >#書を学ぶ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=まちのお寺の学校" class=""
                    >#まちのお寺の学校</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">ほっとする場づくり</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=寺カフェ" class=""
                    >#寺カフェ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=お寺ベンチ" class=""
                    >#お寺ベンチ</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">まちの支え合い</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=こども食堂" class=""
                    >#こども食堂</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=フードパントリー" class=""
                    >#フードパントリー</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=コミュニティカフェ" class=""
                    >#コミュニティカフェ</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">まちの魅力化</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=地域イベント" class=""
                    >#地域イベント</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=民泊・宿坊・寺泊" class=""
                    >#民泊・宿坊・寺泊</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=縁結び" class=""
                    >#縁結び</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="about__social-project--item">
              <h3 class="about__social-project--cat">防災・防犯</h3>
              <ul class="tags">
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=防災キャンプ" class=""
                    >#防災キャンプ</router-link
                  >
                </li>
                <li class="tag p-tag__item">
                  <router-link to="/articles/?tag=お寺の防災基地計画" class=""
                    >#お寺の防災基地計画</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="l-wrapper p-action_block long" data-v-fcf7ca16="">
        <div class="p-action_item long" data-v-fcf7ca16="">
         <router-link
            :to="{
              name: 'About',
              hash: '#social-project',
            }"
            class="p-action_link"
          ><span class="p-action_link-text" data-v-fcf7ca16=""
              >お寺のソーシャルプロジェクトについて詳しく知る</span
            ></router-link>
        </div>
      </div>

      <!-- <div class="l-wrapper p-action_block long">
        <div class="p-action_item long">
          <router-link
            :to="{
              name: 'About',
              hash: '#social-project',
            }"
            class="p-action_link"
          >
            <span class="p-action_link-text"
              >お寺のソーシャルプロジェクトについて詳しく知る</span
            >
          </router-link>
        </div>
      </div> -->
    </section>
    <section class="arrange">
      <h2 class="arrange__title title">
        お寺との共創・協働の進め方
      </h2>
      <ul class="arrange__list">
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;1&nbsp;</span>
              <span class="arrange__title-text">一般会員に登録する</span>
            </dt>
            <dd class="arrange__body">
              トップページのヘッダー（スマホの場合は画面下部）にある「登録・ログイン」ボタンから、一般会員申請を行ってください。
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;2&nbsp;</span>
              <span class="arrange__title-text"
                >提案用の企画ページを作成する</span
              >
            </dt>
            <dd class="arrange__body">
              ログインしてヘッダー（スマホの場合は画面下部）の「お寺マーク」アイコンから「お寺への提案」を選択し、新規企画提案を作成します。<br />登録が終わると、まち寺事務局による審査の後、提案者の活動範囲内の寺院会員に情報が一斉公開されます。<br />
              ※お寺での検討状況についてのお問合せにはお答えできかねますので予めご了承ください。
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;3&nbsp;</span>
              <span class="arrange__title-text">お寺とのマッチング</span>
            </dt>
            <dd class="arrange__body">
              提案企画に興味を持ったお寺からメッセージが届きます。<br />メッセージ機能でやり取りした後、できる限り実際に面会して企画の詳細を打ち合わせしてください。
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;4&nbsp;</span>
              <span class="arrange__title-text"
                >プロジェクトページを作成公開する</span
              >
            </dt>
            <dd class="arrange__body">
              詳細を話し合った上で実行することになったらマッチング成立です。<br />プロジェクトページやイベントページを仕上げて、公開します。（お寺サイドから共同編集権限を追加してもらうことも可能です）<br />
              「まち寺」のイベントページを利用すると、まち寺の会員に告知メールが届く仕組みとなっています。更にご自身のSNSで情報をシェアしたり、関係者・友人・商店街・自治会など地域の繋がりある方にお知らせしましょう。<br />
              ※オンラインイベント（無料開催の場合）にも対応しています。
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;5&nbsp;</span>
              <span class="arrange__title-text">レポートを書く</span>
            </dt>
            <dd class="arrange__body">
              イベントを実施したらできる限りレポートを書きましょう。（お寺サイドから共同編集権限を追加してもらうことが可能です）<br />しっかりしたレポートを書くことで、継続参加の意欲や未参加者の興味を湧き立てることができます。
            </dd>
          </dl>
        </li>
        <li class="arrange__item">
          <dl>
            <dt class="arrange__title">
              <span class="arrange__title-num">step&nbsp;6&nbsp;</span>
              <span class="arrange__title-text">支援へのお礼をする</span>
            </dt>
            <dd class="arrange__body">
              プロジェクトに何らかの形で協力してくれた方には必ずお礼のメッセージを送りましょう。<br />イベント参加者には、まち寺プラットフォームからメール送信することが可能です。
            </dd>
          </dl>
        </li>
      </ul>
      <div class="l-wrapper p-action_block">
        <div class="p-action_item red partner">
          <a class="p-action_link" href="/users/login">
            <span class="p-action_link-text templ"
              >まち寺パートナー申請はこちら</span
            >
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ForTemples',
  props: {},
};
</script>

<!-- Add "sdcope" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.title {
	color: #719630;
}

.mv {
	.for-temples & {
		display: flex;
		@include pc {
			.mv__title--btn {
				max-width: 300px;
				width: 100%;
			}

			.mv__image-01 {
				min-width: 12rem;
				width: 11.333vw;
			}

			.mv__image-02 {
				min-width: 12rem;
				width: 11.333vw;
			}
		}
		@include sp {
			display: block;
			// padding-top: 77px;
			padding-top: 120px;
			width: 100vw;

			.mv__title--btn {
				max-width: 220px;
				width: 58.666vw;
			}

			.mv__image-01 {
				height: 18.637vw;
				object-fit: cover;
				width: 21.6vw;
			}

			.mv__image-02 {
				height: 18.637vw;
				object-fit: cover;
				width: 21.6vw;
			}
		}
		justify-content: center;
		padding: 210px 25px 0;
	}

	&__title {
		&--main {
			color: #719630;
			@include fz_vw(75);
			line-height: 1.5;
			margin-bottom: 20px;
		}
		&--btn {
			background-color: #719630;
			border-radius: 30px;
			color: #fff;
			display: inline-block;
			font-size: 2.5rem;
			height: 60px;
			@include fz_vw(25);
			@include font_iroha_600;
			@include sp {
				font-size: 1.2rem;
				height: 30px;
				line-height: 30px;
				margin-top: 13px;
				width: 38.666vw;
			}
			letter-spacing: 2px;
			line-height: 60px;
			text-align: center;
			width: 30.555vw;
		}
	}
}

.arrange__title-num {
	color: #719630;
}

.for-temples .p-action_block .p-action_item.short {
	background-color: #b24b5b;
	border: none;
	color: #fff;
	font-weight: bold;
	margin: 0 auto;
	width: 41%;

	& .p-action_link-text {
		&::before {
			background: url(../../assets/image/about/registration_white.svg) no-repeat;
			background-size: contain;
		}
	}
}

.for-temples .p-action_block .p-action_link.short {
	position: relative;

	&::after {
		border-bottom: 1px dashed #b24b5b;
		content: "";
		display: inline-block;
		height: 1px;
		@include sp {
			right: -63px;
			width: 55px;
		}
		position: absolute;
		right: -105px;
		top: 50%;
		transform: translateY(-50%);
		width: 95px;
	}

	&::before {
		border-bottom: 1px dashed #b24b5b;
		content: "";
		display: inline-block;
		height: 1px;
		@include sp {
			left: -63px;
			width: 55px;
		}
		left: -105px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 95px;
	}
}

.for-temples .p-action_block .p-action_item .p-action_link-text {
	@include sp {
		padding-left: 20px;
	}
	position: relative;
}
.p-action_block {
	@include sp {
		margin: 35px auto 0;
	}
	margin: 67px auto 0;
	padding: 0;
	width: 100%;

	& .p-action_item {
		@include sp {
			margin: 0 auto;
			width: 48vw;
		}
		margin-right: 0;
		position: relative;

		&::before {
			border-bottom: 1px dashed #412814;
			content: "";
			display: inline-block;
			height: 1px;
			@include sp {
				left: -63px;
				width: 55px;
			}
			left: -105px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 95px;
		}

		&::after {
			border-bottom: 1px dashed #412814;
			content: "";
			display: inline-block;
			height: 1px;
			@include sp {
				right: -63px;
				width: 55px;
			}
			position: absolute;
			right: -105px;
			top: 50%;
			transform: translateY(-50%);
			width: 95px;
		}

		&.red {
			& .p-action_link::before {
				content: none;
			}
		}

		& .p-action_link {
			@include sp {
				font-size: 1.2rem;
				margin-left: 1rem;
				padding: 2rem;
			}
			position: relative;
			&::before {
				background: url(../../assets/image/common/icon-search.svg) no-repeat;
				background-size: contain;
				content: "";
				display: inline-block;
				height: 22px;
				@include sp {
					height: 13px;
					left: 23px;
					width: 13px;
				}
				left: 38px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 23px;
				.red & {
					content: none !important;
				}
			}
		}
	}
}
.p-action_block .p-action_item.red {
	background-color: #b24b5b;
	border: none;
	color: #fff;
	font-weight: bold;
	margin: 0 auto;

	&.partner {
		@include sp {
			width: 63vw;
			&::before {
				left: -58px;
				width: 50px;
			}
			&::after {
				right: -58px;
				width: 50px;
			}
		}
	}

	&::before {
		border-bottom: 1px dashed #b24b5b;
	}
	&::after {
		border-bottom: 1px dashed #b24b5b;
	}

	& .p-action_link-text::before {
		background: url(../../assets/image/about/add_temple_white.svg) no-repeat;
	}
}

.about__social-project {
	.for-temples & {
		@include sp {
			margin: 3rem auto 0;
		}
		margin: 8.8rem auto 0;
		max-width: 130rem;
		width: 100%;
	}

	.p-action_block .p-action_item.long {
		margin: 0 auto;
		max-width: 550px;
		position: relative;
		width: 86.4vw;

		&::before {
			border-bottom: 1px dashed #412814;
			content: "";
			display: inline-block;
			height: 1px;
			@include sp {
				content: none;
			}
			left: -105px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 95px;
		}

		&::after {
			border-bottom: 1px dashed #412814;
			content: "";
			display: inline-block;
			height: 1px;
			@include sp {
				content: none;
			}
			position: absolute;
			right: -105px;
			top: 50%;
			transform: translateY(-50%);
			width: 95px;
		}
		& .p-action_link-text::before {
			content: none;
		}
	}
}

.p-action_block {
	& .p-action_item {
		@include sp {
			.for-temples & {
				width: 56.6vw;
			}
		}
	}
}
.p-action_link.short {
	@include sp {
		font-size: .9rem;
	}
}

.p-action_block.long {
	@include sp {
		padding: 3.7rem 0;
	}
	padding: 7.4rem 0 3.7rem;
}
</style>
<style lang="scss" scoped>
.p-action_block {
	background-image: initial;
}
</style>

<template>
  <div class="base-point">
    <div class="bg-uzu sp-only"></div>
  </div>
  <div class="outer" v-if="!fetching && state.articles.length > 0">
    <div class="inner special-feature">
      <div class="base-point02">
        <div class="feature-title">
          <h1>Special <span>F</span>eature</h1>
          <p class="small">特集記事</p>
        </div>
      </div>
      <vueper-slides
        class="no-shadow ex--center-mode"
        :arrows="false"
        bullets-outside
        transition-speed="250"
      >
        <vueper-slide
          v-for="(item, i) in state.articles"
          :key="i"
          class="slide-inside"
        >
          <template #content>
            <div class="vueperslide__content-wrapper">
              <div class="flex">
                <template v-if="item.thumbnail">
                  <div
                    class="left"
                    :style="
                      'background-image: url(' +
                        getThumbnailUrl(item.thumbnail) +
                        ')'
                    "
                  >
                    <!-- <Like :temple-id="item.temple_id"/> -->
                  </div>
                </template>
                <template v-else>
                  <div class="left">
                    <!-- <Like :temple-id="item.temple_id"/> -->
                  </div>
                </template>
                <div class="right">
                  <router-link
                    :to="
                      '/articles/' +
                        articleType[item.article_type - 1] +
                        '/' +
                        item.id
                    "
                  >
                    <div class="label">{{ item.category.name }}</div>
                    <div class="time-stamp">
                      <time :datetime="item.created_at">{{
                        moment(item.created_at, 'YYYY/MM/DD ddd')
                      }}</time>
                    </div>
                    <p class="caption">{{ item.subtitle }}</p>
                    <h2 v-html="nl2br(item.title)" />
                  </router-link>

                  <ul class="tags">
                    <li v-for="tag in item.tags" :key="tag.id" class="white">
                      <Tag :tagName="tag.name" :id="tag.id" />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="youtube-thumbnail" v-if="getYouTubeThumbnail(item.content.items)">
                <a :href="`https://www.youtube.com/watch?v=${getYouTubeThumbnail(item.content.items)}`" target="_blank" rel="noopener noreferrer">
                <img
                  class="youtube-thumbnail__image"
                  :src="
                    `https://img.youtube.com/vi/${getYouTubeThumbnail(
                      item.content.items
                    )}/mqdefault.jpg`
                  "
                  alt="thumbnail"
                />
                </a>
              </div>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
    <div class="bg-belt"></div>
  </div>
</template>

<script>
import { useArticles } from '@/composables/useArticles';
import { getThumbnailUrl } from '@/utils/helper';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import moment from 'moment';
import { nl2br } from '../utils/nl2br';
import Tag from '@/components/Tag';

export default {
  name: 'SpecialFeature',
  props: {
    limit: {
      type: Number,
    },
    conditions: {
      type: Object,
    },
    editMode: {
      type: Boolean,
    },
  },
  components: {
    VueperSlides,
    VueperSlide,
    Tag,
  },
  setup() {
    const { getArticles, state, fetching } = useArticles();
    const articleType = ['project', 'event', 'report'];
    getArticles(null, 3, { feature: true });

    const getYouTubeThumbnail = (items) => {
      const youtubeItems = items.filter((item) => item.type === 'youtube');

      if(youtubeItems.length > 0){
        return youtubeItems[0].body;
      }else{
        return null
      }

    };

    return {
      state,
      getThumbnailUrl,
      articleType,
      fetching,
      nl2br,
      getYouTubeThumbnail,
    };
  },
  methods: {
    moment(date, format) {
      moment.locale('en');
      if (date) {
        return moment(date).format(format);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sp-only {
  display: none;
}
.outer {
  position: relative;
  overflow: hidden;
  @include sp{
    margin-bottom: -2.2rem;
  }
}
.inner {
  width: 84%;
  max-width: 160rem;
  margin: auto;
  padding-top: 10rem;
  padding-bottom: 3.5vw;
  position: relative;
}
.base-point,
.base-point02 {
  height: 0;
  width: 0;
  margin: auto;
  position: relative;
}
.feature-title {
  position: absolute;
  left: 17rem;
  top: 2rem;
  width: 40rem;
}
.vueperslide {
  position: relative;
}
.flex {
  display: flex;
  width: 100%;
  padding-bottom: 7rem;
}
.right,
.ex--center-mode {
  position: relative;
  z-index: 9;
}
.left {
  max-width: 83.6rem;
  flex: 60%;
  height: 43rem;
  border-radius: 22rem 0 22rem 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.vueperslides__bullet {
  margin: 1.5em 1em;
}
.right {
  text-align: left;
  margin-left: 7.8rem;
  flex: 1 1 35%;
  align-self: flex-end;
}
.time-stamp {
  color: #9d7bab;
  font-family: 'aw-conqueror-didot', serif;
  font-size: 1.1rem;
  letter-spacing: 0.11rem;
  margin-bottom: 1.4rem;
}
.caption,
h1,
h2,
.small {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
}
.caption {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  margin-bottom: 1.2rem;
}
h1 {
  font-size: 5rem;
  letter-spacing: 0.5rem;
  margin-bottom: 2rem;
  color: #412814;
}
h1 span {
  color: #9d7bab;
}
.small {
  font-size: 2rem;
  letter-spacing: 0.3rem;
  margin-bottom: 7.5rem;
}
.label {
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-family: 'iroha-23kaede-stdn', sans-serif;
  color: #fff;
  padding: 1rem 6.6rem;
  background-color: #9d7bab;
  display: inline-block;
  margin-bottom: 2.8rem;
}
h2 {
  font-size: 3rem;
  letter-spacing: 0.3rem;
  line-height: 4.5rem;
  margin-bottom: 2rem;
}
.bg-belt {
  z-index: 1;
  height: 50rem;
  width: 100%;
  background-image: url('~@/assets/image/top/bg-shippo-tunagi.png');
  background-repeat: repeat;
  position: absolute;
  top: 27rem;
}
.tags li {
  display: inline-block;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
}
.youtube-thumbnail {
  left: 2rem;
  position: absolute;
  bottom: 2rem;
  z-index: 10;
  @include sp {
    left: 1.2rem;
    bottom: 33rem;
  }
  & .youtube-thumbnail__image {
    width: 20rem;
    @include sp {
      width: 12rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
  .ex--center-mode {
    top: 7rem;
  }
  .flex {
    flex-direction: column;
  }
  .left img {
    height: 28rem;
    border-radius: 11rem 0 11rem 0;
  }
  .bg-uzu {
    position: absolute;
    max-width: initial;
    z-index: 1;
    content: '';
    background-image: url('~@/assets/image/top/bg-uzu.svg');
    background-position: 80% 50%;
    background-size: cover;
    height: 14.3rem;
    right: 9rem;
    bottom: -10rem;
    width: 11.8rem;
  }
  .base-point02 {
    margin: 0;
  }
  .inner {
    padding-top: 4rem;
    padding-bottom: 0;
    z-index: 9;
  }
  .feature-title {
    position: static;
  }
  .left {
    width: 100%;
    height: 25rem;
    border-radius: 12rem 0 13rem 0;
    margin-bottom: 4rem;
    flex: initial;
  }
  .right {
    margin-left: 0;
    flex: initial;
    align-self: flex-start;
  }
  h1 {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
    margin-bottom: 1rem;
  }
  .small {
    font-size: 1rem;
  }
  .label {
    font-size: 1rem;
    padding: 0.5rem 3rem;
    margin-bottom: 2rem;
  }
  .time-stamp {
    font-size: 0.9rem;
  }
  .caption {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 3rem;
  }
  .bg-belt {
    height: 40rem;
    /* top: 22rem; */
  }
}
</style>

import { computed, reactive, readonly, ref } from 'vue';
import { useCommons } from './useCommons';
import { deleteNullProperties, move } from '../utils/helper';

const { loading, postLoading, openModal } = useCommons();

export const useArticles = (id) => {
  // 管理したいステートを定義
  const state = reactive({
    currentArticle: {},
    articles: [],
    articlesGroups: [],
    proposedList: [],
  });

  const origin = {
    currentArticle: {},
    articles: [],
    articlesGroups: [],
    proposedList: [],
  };

  const pagination = reactive({
    total: null,
    current: null,
    perPage: null,
  });

  const templeControl = ref({});
  const control = ref({});

  const fetching = ref(false);

  const error = ref({});
  // const error = ref();

  //typeFlg:: false:一般会員 true:寺院会員
  // const typeFlg = ref(false);

  const createModalItems = (article, typeFlg) => {
    const articleTypeText = ['プロジェクト', 'イベント', 'レポート'];
    const articleType = ['project', 'event', 'report'];
    // console.log('**********createModalItems',article)
    // console.log('**********typeFlg', typeFlg)

    return [
      {
        title: '基本情報',
        text:
          '基本情報の編集画面へ移動します。<br class="u-use--sp">保存していない内容は失われます。よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '編集画面へ移動する',
        class: 'submit',
        function: () => {
          move(
            '/articles/create?type=' +
              articleType[Number(article.article_type) - 1] +
              '&article_id=' +
              article.id +
              '&temple_id=' +
              article.temple_id
          );
        },
        condition: true,
      },
      {
        title: '複製',
        text:
          '新規記事作成画面へ移動して記事を複製します。<br class="u-use--sp">保存していない内容は失われます。よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '複製画面へ移動する',
        class: 'submit',
        function: ()=> {
          move('/articles/create?type=' + articleType[Number(article.article_type) - 1]  + '&copy=true&article_id=' + article.id + '&temple_id=' + article.temple_id)
        },
        condition: true,
      },
      {
        title: '下書きに戻す',
        text:
          'この記事を下書き状態に戻します。<br class="u-use--sp">よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '下書きに戻す',
        class: 'draft',
        function: draftArticle,
        condition:
          article.status === 1 &&
          (article.article_type === 1 || article.article_type === 3),
      },
      {
        title: '参加者を確認',
        text:
            '参加者一覧画面へ移動します。<br class="u-use--sp">保存していない内容は失われます。よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '参加者一覧へ移動する',
        class: 'submit',
        function: ()=> {
          move('/partners/contact_participant/?partner_id=' + article.event_detail.event.partner_id + '&event_detail_id=' + article.event_detail_id)
        },
        condition: article.status === 1 && article.article_type === 2,
      },
      {
        title: '変更を取消',
        text:
          'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を取消しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '取消する',
        class: 'delete',
        function: resetCurrentArticleSubmit,
        condition: true,
      },
      {
        title: '変更を保存',
        text:
          'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を保存しますか？？',
        btnType: 'cancel&submit',
        submitBtnText: '保存する',
        class: 'update',
        function: () => { postSave('/partners/flow')},
        condition: article.status === 1 && !typeFlg
      },
      {
        title: '変更を保存',
        text:
          'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を保存しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '保存する',
        class: 'update',
        function: () => { postSave(`/temples/${article.temple_id}/flow`)},
        condition: article.status === 1 && typeFlg
      },
      {
        title: '下書き保存',
        text:
          'この操作は元に戻すことが出来ません。<br class="u-use--sp">本当に変更を保存しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '保存する',
        class: 'submit',
        function: postArticle,
        condition: article.status === 0,
      },
      {
        title: articleTypeText[Number(article.article_type) - 1] + 'を公開する',
        text:
          '変更を保存して記事を公開します。<br class="u-use--sp">よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '公開する',
        class: 'publish',
        function: publishArticle,
        condition:
          article.status === 0 &&
          (article.article_type === 1 || article.article_type === 3),
      },
      {
        title: articleTypeText[Number(article.article_type) - 1] + 'を公開する',
        text:
          'イベントを公開して参加者の募集を開始します。<br class="u-use--sp">一度公開すると下書きには戻せません。本当に公開しますか？',
        btnType: 'cancel&submit',
        submitBtnText: '募集を開始する',
        class: 'publish',
        function: publishArticle,
        condition: article.status === 0 && article.article_type === 2,
      },
      {
        title: '中止',
        text:
            'このイベントを中止します。<br class="u-use--sp">よろしいですか？',
        btnType: 'cancel&submit',
        submitBtnText: '中止する',
        class: 'delete',
        function: ()=>{
          stopEvent(article.event_detail_id)
        },
        condition: article.status === 1 && article.article_type === 2 && article.event_detail.stop_flg === 0,
      },
    ].filter((item) => item.condition);
  };

  const setTempleControl = (article) => {
    const articleType = {};
    switch (article.article_type) {
      case 1:
        articleType.name = 'プロジェクト';
        articleType.path = 'Project';
        articleType.target = 'project';
        articleType.statusText = article.status === 0 ? '下書き' : '公開中';
        break;
      case 2:
        articleType.name = 'イベント';
        articleType.path = 'Event';
        articleType.target = 'event';
        if (article.status === 0) {
          articleType.statusText = '下書き';
        } else {
          if (article.event_detail.stop_flg === 0) {
            articleType.statusText = '公開中';
          } else {
            articleType.statusText = '中止';
          }
        }
        break;
      case 3:
        articleType.name = 'レポート';
        articleType.path = 'Report';
        articleType.target = 'report';
        articleType.statusText = article.status === 0 ? '下書き' : '公開中';
        break;
      default:
        articleType.name = 'プロジェクト';
        articleType.path = 'Project';
        articleType.target = 'project';
        articleType.statusText = article.status === 0 ? '下書き' : '公開中';
        break;
    }

    templeControl.value = {
      type: '寺院会員メニュー',
      id: article.id,
      routePath: articleType.path,
      statusText:
        'この' +
        articleType.name +
        'は現在【' +
        articleType.statusText +
        '】です。',
      nav: [
        {
          path: '/temples/' + article.temple_id,
          pathType: 'router-link',
          imagePath: require('@/assets/image/common/icon-toppage.svg'),
          text: 'トップページ',
        },
        {
          path: '/temples/edit_web/?temple_id=' + article.temple_id,
          pathType: 'a',
          imagePath: require('@/assets/image/common/icon-information.svg'),
          text: '基本情報',
        },
        {
          imagePath: require('@/assets/image/icon_new.png'),
          text: '記事作成',
          children: [
            {
              path:
                '/temples/' + article.temple_id + '/articles?target=project',
              pathType: 'router-link',
              imagePath: require('@/assets/image/common/icon-project.svg'),
              text: 'プロジェクト（活動理念を書くページ）',
            },
            {
              path: '/temples/' + article.temple_id + '/articles?target=event',
              pathType: 'router-link',
              imagePath: require('@/assets/image/common/icon-event.svg'),
              text: 'イベント（参加者募集をするページ）',
            },
            {
              path: '/temples/' + article.temple_id + '/articles?target=report',
              pathType: 'router-link',
              imagePath: require('@/assets/image/common/icon-report.svg'),
              text: 'レポート（活動の様子を書くページ）',
            },
          ],
        },
        {
          path: '/temples/' + article.temple_id + '/flow',
          pathType: 'router-link',
          imagePath: require('@/assets/image/common/icon-flag.svg'),
          text: '進行中の案件',
        },
        {
          path: '/temples/add_monk/?temple_id=' + article.temple_id,
          pathType: 'a',
          imagePath: require('@/assets/image/common/icon-coeditor.svg'),
          text: '共同編集者',
        },
        {
          path: '/temples/temple_members/?temple_id=' + article.temple_id,
          pathType: 'a',
          imagePath: require('@/assets/image/common/icon-follower.svg'),
          text: 'ご縁リスト',
        },
        {
          path: '/temples/' + article.temple_id + '/proposed',
          pathType: 'router-link',
          imagePath: require('@/assets/image/common/icon-comment.svg'),
          text: 'お寺への提案',
        },
      ],

      modalItems: createModalItems(article, true),
    };
  };

  const setControl = (article) => {
    const articleType = {};
    const partnerId = article.temporary_event_id
      ? article.temporary_event.partner_id
      : null;
    switch (article.article_type) {
      case 1:
        articleType.name = 'プロジェクト';
        articleType.path = 'Project';
        articleType.target = 'project';
        articleType.statusText = article.status === 0 ? '下書き' : '公開中';
        break;
      case 2:
        articleType.name = 'イベント';
        articleType.path = 'Event';
        articleType.target = 'event';
        if (article.status === 0) {
          articleType.statusText = '下書き';
        } else {
          if (article.event_detail.stop_flg === 0) {
            articleType.statusText = '公開中';
          } else {
            articleType.statusText = '中止';
          }
        }
        break;
      case 3:
        articleType.name = 'レポート';
        articleType.path = 'Report';
        articleType.target = 'report';
        articleType.statusText = article.status === 0 ? '下書き' : '公開中';
        break;
      default:
        articleType.name = 'プロジェクト';
        articleType.path = 'Project';
        articleType.target = 'project';
        articleType.statusText = article.status === 0 ? '下書き' : '公開中';
        break;
    }

    control.value = {
      type: '記事 - ' + articleType.name,
      id: article.id,
      routePath: articleType.path,
      statusText:
        'この' +
        articleType.name +
        'は現在【' +
        articleType.statusText +
        '】です。',
      nav: [
        {
          path: '/partners/flow?target=' + articleType.target,
          pathType: 'router-link',
          imagePath: require('@/assets/image/icon_list.png'),
          text: '記事一覧',
        },
        {
          path:
            '/articles/create?type=' +
            articleType.target +
            '&copy=true&article_id=' +
            article.id +
              '&temple_id=' +
              article.temple_id,
          pathType: 'a',
          imagePath: require('@/assets/image/icon_duplicate.png'),
          text: '複製',
        },
        {
          // path: (isLoggedIn && isCurrentArticleSet) &&  "/partners/create_event/?partner_id=" + setState.currentArticle.event_detail.event.partner_id + "&event_detail_id=" + setState.currentArticle.event_detail_id + "&temple_id=" + setState.currentArticle.temple_id,
          path:
            '/articles/create?type=' +
            articleType.target +
            '&article_id=' +
            article.id +
          '&temple_id=' +
              article.temple_id,
          pathType: 'a',
          imagePath: require('@/assets/image/icon_information.png'),
          text: '基本情報',
        },
        {
          path:
            '/partners/contact_participant/?partner_id=' +
            partnerId +
            '&event_detail_id=' +
            article.event_detail_id,
          pathType: 'a',
          imagePath: require('@/assets/image/icon_enlister.png'),
          text: '参加者一覧',
          hidden: article.article_type !== 2,
        },
      ],
      modalItems: createModalItems(article, false),
    };
  };

  //記事グループ
  //取得

  const getArticlesGroups = async (temple_id) => {

    try {
      const data = await fetch(`/api/article-groups?temple_id=${temple_id}`);
      if (!data.ok) {
        // console.log('articlesGroup___!data.ok', data);
        const errModal = {
          title: "エラーが発生しました",
          text: '記事グループの取得ができませんでした。記事編集画面へ戻ります。',
          btnType: 'close',
          submitBtnText: "閉じる"
        }
        loading.value = false;
        openModal(errModal);
        throw Error('No data available');
      }

      const articlesGroups = await data.json();
      state.articlesGroups = JSON.parse(JSON.stringify(articlesGroups)).data;
      origin.articlesGroups = JSON.parse(JSON.stringify(articlesGroups)).data;
      // console.log('origin.articlesGroups', origin.articlesGroups)
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };

  const isArticlesGroupSet = computed(
    () => !!Object.keys(state.articlesGroup).length
  );

  //記事リセット
  const resetCurrentArticle = () => {
    state.currentArticle = JSON.parse(JSON.stringify(origin.currentArticle));
    // console.log('state.currentArticle', state.currentArticle);
  };

  const resetCurrentArticleSubmit = () => {
    postLoading.value = true;
    resetCurrentArticle();
    const resetModal = {
      title: '変更を取消しました',
      text: '',
      btnType: 'close',
      submitBtnText: "閉じる"
    }
    postLoading.value = false;
    openModal(resetModal);
  };

  const setCurrentArticle = (article) => {
    // console.log('hash対応前ーーーcurrentArticle', article);

    const defaultItems = [
      {
        body: '',
        type: 'title',
      },
      {
        body: '',
        type: 'heading',
      },
      {
        body: '',
        type: 'body',
      },
      {
        body: { key: '', thumbnail_url: '', url: '' },
        caption: '',
        type: 'image',
      }
    ]

    if(article.article_type === 2){
      defaultItems.shift()
    }

    // Editableでの初期値対応
    if (article.content) {
      if (!article.content.items) {
        article.content.items = defaultItems
      }
    } else {
      article.content = {
        items: defaultItems
      }
    }
    article.content.items.forEach((item) => {
      withHash(item);
    });

    if (article.profile_content) {
      if (article.profile_content.items) {
        article.profile_content.items.forEach((item) => {
          withHash(item);
        });
      } else {
        article.profile_content.items = [];
      }
    } else {
      article.profile_content = {
        items: [
          {
            name: '',
            thumbnail: {
              key: null,
              thumbnail_url: null,
              url: null,
            },
            title: '',
            body: '',
          },
        ],
      };
    }

    // if (currentArticle.data.profile_content.items.length) {
    //   currentArticle.data.profile_content.items.forEach(item => {
    //     withHash(item)
    //   })
    // } else {
    //   currentArticle.data.profile_content = {
    //     name: "",
    //     thumbnail: {
    //       key: null,
    //       thumbnail_url: null,
    //       url: null
    //     },
    //     items: [
    //       {
    //       body: "",
    //       image: { key: "", thumbnail_url: "", url: "" },
    //       name: "",
    //     }
    //   ]
    //   }
    // }

    state.currentArticle = JSON.parse(JSON.stringify(article));
    origin.currentArticle = JSON.parse(JSON.stringify(article));
    setControl(article);
    setTempleControl(article);

    // console.log('state.currentArticle', state.currentArticle);
    // console.log('origin.currentArticle', origin.currentArticle);
  };

  //記事取得
  const getArticles = async (type = null, limit = 12, conditions = {}) => {
    const params = {
      limit: limit,
      sort: 'created_at',
      direction: 'DESC',
    };

    if (Array.isArray(type)) {
      params.types = type.join(',');
    } else {
      params.type = type;
    }

    Object.assign(params, conditions);
    deleteNullProperties(params);

    const queryParams = new URLSearchParams(params);
    fetching.value = true;
    // console.log('||||params', params)
    try {
      const data = await fetch(`/api/articles?${queryParams}`);

      if (!data.ok) {
        console.log(data);
      }
      const articles = await data.json();

      state.articles = JSON.parse(JSON.stringify(articles)).data;
      origin.articles = JSON.parse(JSON.stringify(articles)).data;
      pagination.total = articles.total;
      pagination.perPage = articles.per_page;
      pagination.current = articles.current_page;

      // console.log("*******articles",articles);
    } catch (err) {
      console.log(err);
    }
    fetching.value = false;
  };

  //記事取得
  const getCurrentArticle = async (articleId) => {
    loading.value = true;
    const idToFetch = articleId ? articleId : id;
    try {
      let data = await fetch(`/api/articles/${idToFetch}`);

      if (!data.ok) {
        loading.value = false;
        throw Error('No data available');
      }
      const currentArticle = await data.json();
      setCurrentArticle(currentArticle.data);
      getArticlesGroups(currentArticle.data.temple_id);
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }

    loading.value = false;
  };

  //記事更新
  const postArticle = async (url) => {
    // console.log('postArticle===state.currentArticle', state.currentArticle);
    postLoading.value = true;
    fetch(`/api/articles/${id}`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state.currentArticle),
    })
      .then((response) => {
        if (!response.ok) {
          console.log('!response.ok', response);

          const errModal = {
            title: 'エラーが発生しました',
            text: '記事の更新ができませんでした。記事編集画面へ戻ります。',
            btnType: 'close',
            submitBtnText: "閉じる"
          }
          postLoading.value = false;
          openModal(errModal);
          return response.json().then((json) => {
            throw json.errors;
          });
        }
        return response.json();
      })
      // .then((response) => response.json())
      .then((data) => {
        postLoading.value = false;

        setCurrentArticle(data.data);

        const successModal = {
          title: '記事を更新しました',
          text: '',
          btnType: 'close',
          submitBtnText: "閉じる",
          path:url
        }
        openModal(successModal);
      })
      .catch((err) => {
        console.log('catch error', err);
        const successModal = {
          title: 'エラーが発生しました',
          text: '記事の更新ができませんでした。記事編集画面へ戻ります。',
          btnType: 'close',
          submitBtnText: "閉じる",
					path: null
        }
        error.value = err;
        postLoading.value = false;
        openModal(successModal);
      });
  };

  // 記事を削除する
  const deleteArticle = async (articleId, redirectPath="") => {
    postLoading.value = true;
    fetch(`/api/articles/${articleId}`, {
      method: 'DELETE'
    })
        .then((response) => {
          if (!response.ok) {
            console.log('!response.ok', response);

            return response.json().then((json) => {
              throw json.errors;
            });
          }
          return response.json();
        })
        // .then((response) => response.json())
        .then(() => {
          // console.log(data)
          postLoading.value = false;

          const successModal = {
            title: '記事を削除しました',
            text: '',
            btnType: 'submit',
            submitBtnText: "閉じる",
            function: ()=>{
              location.href = redirectPath
            }
          }
          openModal(successModal);
        })
        .catch((err) => {
          console.log('catch error', err.message);
          const successModal = {
            title: 'エラーが発生しました',
            text: '記事の削除に失敗しました。',
            btnType: 'close',
            submitBtnText: "閉じる",
          }
          error.value = err;
          postLoading.value = false;
          openModal(successModal);
        });
  };

  // 記事を下書きに戻す
  const draftArticle = async () => {
    state.currentArticle.status = 0;
    postArticle();
  };

  // 記事を保存する

  const postSave = async (url) => {
    await postArticle(url);
  }

  const stopEvent = async (eventDetailId) => {
    postLoading.value = true;
    fetch(`/api/stop-event/${eventDetailId}`, {
      method: 'PUT'
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then(json => {throw json.errors})
        }
        return response.json()
      })
      // .then((response) => response.json())
      .then(() => {
        // console.log(data)
        const successModal = {
          title: "イベントを中止しました",
          text: '',
          btnType: 'submit',
          function: ()=>{
            location.reload()
          },
          submitBtnText: "閉じる",
        }

        postLoading.value = false;
        openModal(successModal);
      })
      .catch((err) => {
        console.log('catch error', err.message);
        const errModal = {
          title: "エラーが発生しました",
          text: 'イベントの中止に失敗しました。',
          btnType: 'close',
          submitBtnText: "閉じる"
        }
        error.value = err;
        postLoading.value = false;
        openModal(errModal);
      });

  };

  // 記事を公開する
  const publishArticle = async () => {
    state.currentArticle.status = 1;
    if (state.currentArticle.article_type === 2) {
      state.currentArticle.event_detail.approve_type_id = 3;
    }
    state.currentArticle.status = 1;
    // console.log('publishEvent===state.currentArticle', state.currentArticle);
    postLoading.value = true;
    fetch(`/api/articles/${id}/publish`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state.currentArticle),
    })
        .then((response) => {
          if (!response.ok) {
            // console.log('!response.ok', response);
            state.currentArticle.status = 0
            if(state.currentArticle.article_type === 2){
              state.currentArticle.event_detail.approve_type_id = 5
            }

            return response.json().then(json => {throw json.errors})
          }
          return response.json()
        })
        // .then((response) => response.json())
        .then((data) => {
          postLoading.value = false;
          setCurrentArticle(data.data)

          const successModal = {
            title: "記事を公開しました",
            text: '',
            btnType: 'close',
            submitBtnText: "閉じる",
          }

          postLoading.value = false;
          openModal(successModal);
      })
      .catch((err) => {
        console.log('catch error', err.message);
        const errModal = {
          title: "エラーが発生しました",
          text: '記事の公開ができませんでした。記事編集画面へ戻ります。',
          btnType: 'close',
          submitBtnText: "閉じる"
        }
        error.value = err;
        postLoading.value = false;
        openModal(errModal);
      });
  };

  //提案企画取得
  const getProposedList = async (type = null, limit = 12, conditions = {}) => {
    let path = 'proposed-list';
    if (type === 'messaged') {
      path = 'proposed-list/messaged';
    } else if (type === 'public') {
      path = 'proposed-list/public';
    }

    const params = {
      limit: limit,
      sort: 'created_at',
      direction: 'DESC',
    };

    Object.assign(params, conditions);
    deleteNullProperties(params);

    const queryParams = new URLSearchParams(params);
    fetching.value = true;
    try {
      const data = await fetch(`/api/${path}?${queryParams}`);

      if (!data.ok) {
        console.log(data);
      }
      const proposedList = await data.json();
      state.proposedList = JSON.parse(JSON.stringify(proposedList)).data;
      origin.proposedList = JSON.parse(JSON.stringify(proposedList)).data;

      // console.log(proposedList);
    } catch (err) {
      console.log(err);
    }
    fetching.value = false;
  };

  const deleteProfileContentBlock = (index) => {
    state.currentArticle.profile_content.items.splice(index, 1);
  };

  const addProfileContentBlock = (newListItem) => {
    state.currentArticle.profile_content.items.push(withHash(newListItem));
    // console.log(
    //   'state.currentArticle.profile_content.items',
    //   state.currentArticle.profile_content.items
    // );
  };

  const deleteContentBlock = (index) => {
    state.currentArticle.content.items.splice(index, 1);
  };

  const addContentBlock = (newListItem) => {
    state.currentArticle.content.items.push(withHash(newListItem));
  };

  const getItemByHash = (items, hash) => {
    return items.find((item) => item.hash === hash);
  };

  const withHash = (object) => {
    object.hash = Math.random()
      .toString(32)
      .substring(2);
    return object;
  };

  const isCurrentArticleSet = computed(
    () => !!Object.keys(state.currentArticle).length
  );

  return {
    state: readonly(state),
    setState: state,
    initialState: origin,
    pagination: readonly(pagination),
    isCurrentArticleSet,
    isArticlesGroupSet,
    error,
    fetching,
    getArticlesGroups,
    getCurrentArticle,
    getProposedList,
    postArticle,
    resetCurrentArticle,
    deleteProfileContentBlock,
    addProfileContentBlock,
    addContentBlock,
    deleteContentBlock,
    getArticles,
    getItemByHash,
    control,
    templeControl,
    resetCurrentArticleSubmit,
    deleteArticle,
    stopEvent
  };
};

<template>
  <div
    class="article__content"
    :class="viewControlAreaFlg ? 'with-control-area' : ''"
  >
    <div :class="editMode ? 'edit' : ''" v-if="isCurrentArticleSet">
      <ControlArea
        :controlAreaObj="
          hasTempleAuth(state.currentArticle.temple_id)
            ? templeControl
            : control
        "
        :editMode="editMode"
        :changeMode="changeMode"
        v-if="
          hasArticleAuth(
            state.currentArticle.id,
            state.currentArticle.temple_id
          )
        "
      />
      <div class="l-event l-edit" v-if="isCurrentArticleSet">
        <div class="p-pagetype">
          <span class="p-pagetype__jp">まち寺イベント</span
          ><span class="p-pagetype__en">Machi-Tera Event</span>
        </div>
        <section>
          <div class="p-mv">
            <div class="l-wrapper p-mv__block">
              <Upload
                :error-state="error.thumbnail ? error.thumbnail : ''"
                v-model="setState.currentArticle.thumbnail"
                v-bind:initial-img="initialState.currentArticle.thumbnail"
                :editMode="editMode"
              />
              <div class="p-share__block">
                <div class="required_wrap u-use--sp">
                  <span v-if="editMode" class="required">*</span>
                  <CategorySelect
                    class="p-cat u-use--sp"
                    v-if="!catState.loading"
                    v-model="setState.currentArticle.category_id"
                    :editMode="editMode"
                  />
                </div>
                <SnsShare
                  :title="initialState.currentArticle.title"
                  :editMode="editMode"
                />
                <p class="p-share__text">Share on</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="l-wrapper">
            <div class="required_wrap u-use--pc">
              <span v-if="editMode" class="required">*</span>
              <CategorySelect
                class="p-cat u-use--pc"
                v-if="!catState.loading"
                v-model="setState.currentArticle.category_id"
                :editMode="editMode"
              />
            </div>
            <h1 class="p-title">
              <Editable
                :error-state="error.subtitle ? error.subtitle : ''"
                v-model="setState.currentArticle.subtitle"
                :initial-text="initialState.currentArticle.subtitle"
                :initial-class="'p-title__sub'"
                tagName="span"
                v-bind:editMode="editMode"
                :title-flg="true"
                placeholder="サブタイトルを入力してください"
              />
              <StatusLabel
                v-if="setState.currentArticle"
                :item="setState.currentArticle"
              />
              <div class="required_wrap">
                <span v-if="editMode" class="required">*</span>
                <Editable
                  :error-state="error.title ? error.title : ''"
                  v-model="setState.currentArticle.title"
                  v-bind:initial-text="initialState.currentArticle.title"
                  :initial-class="'p-title__main'"
                  tagName="span"
                  v-bind:editMode="editMode"
                  :title-flg="true"
                  :require="true"
                  placeholder="タイトルを入力してください"
                />
              </div>
            </h1>
            <div class="p-date__event">
              <span class="p-date__event-place">開催日時</span>
              <span
                class="p-date__event-date"
                v-if="state.currentArticle.event_detail.date"
              >
                {{
                  moment(
                    state.currentArticle.event_detail.date,
                    'YYYY/MM/DD(ddd)'
                  )
                }}
              </span>
              &nbsp;
              <span class="p-date__event-time">
                <span v-if="state.currentArticle.event_detail.start_time">
                  {{
                    moment(
                      state.currentArticle.event_detail.start_time,
                      'HH:mm'
                    )
                  }}
                </span>
                〜
                <span v-if="state.currentArticle.event_detail.end_time">
                  {{
                    moment(state.currentArticle.event_detail.end_time, 'HH:mm')
                  }}
                </span>
              </span>
            </div>
            <div class="p-author">
              <IconBox
                v-if="getProfile(state.currentArticle.profile_content)"
                :imageSrc="
                  getThumbnailUrl(
                    getProfile(state.currentArticle.profile_content).thumbnail
                  )
                "
                :name="getProfile(state.currentArticle.profile_content).name"
                role="企画者・講師・出演者"
                :detail="getProfile(state.currentArticle.profile_content).title"
                :isNonClip="false"
                color="orange"
                :is-single="true"
                :link="true"
                path="#person"
              />
              <template v-if="state.currentArticle.temple.group_flg !== 1 || setState.currentArticle.event_detail.online_flg === 1">
                <router-link :to="'/temples/' + state.currentArticle.temple_id">
                  <div class="p-location">
                    <img
                      class="p-location__img"
                      src="@/assets/image/location_orange.png"
                      alt="author"
                    />
                    <div class="p-location__block">
                      <div class="p-location__tit">会場</div>
                      <div class="p-location__name">
                        <div
                          class="p-location__name"
                          v-if="
                            setState.currentArticle.event_detail.online_flg === 1
                          "
                        >
                          オンラインイベント
                        </div>
                        <div class="p-location__name" v-else>
                          {{ state.currentArticle.temple.name
                          }}<br class="u-use--sp" />（{{
                            state.currentArticle.temple.prefectures
                          }}{{ state.currentArticle.temple.municipality }}）
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </template>
            </div>
            <TagInput
              :error-state="error.Tags ? error.Tags : ''"
              tagBgColor="#e8e2d7"
              tagTextColor="black"
              :customDelimiter="customDelimiter"
              v-model="setState.currentArticle.tags"
              tagsClass="event"
              :editMode="editMode"
            />
          </div>
        </section>
        <section>
          <div class="p-contents">
            <div class="p-contents__block l-wrapper">
              <template
                v-for="(list, index) in setState.currentArticle.content.items"
                :key="list.hash"
              >
                <ContentBlock
                  v-model="setState.currentArticle.content.items[index]"
                  :initial-item="
                    getItemByHash(
                      initialState.currentArticle.content.items,
                      list.hash
                    )
                  "
                  :indexKey="index"
                  :handle-click-delete="deleteContentBlock"
                  :editMode="editMode"
                />
              </template>
              <AddContentBtn
                :handle-click-add="addContentBlock"
                :editMode="editMode"
              />
              <p
                class="p-contents__comment"
                v-if="
                  editMode ||
                    setState.currentArticle.event_detail.temple_comment
                "
              >
                <span class="p-contents__comment-tit">
                  {{ state.currentArticle.temple.name }}
                  からのコメント</span
                >
                <Editable
                  :error-state="
                    error.event_detail && error.event_detail.temple_comment
                      ? error.event_detail.temple_comment
                      : ''
                  "
                  v-model="setState.currentArticle.event_detail.temple_comment"
                  v-bind:initial-text="
                    initialState.currentArticle.event_detail.temple_comment
                  "
                  :initial-class="'p-contents__comment-text'"
                  tagName="span"
                  v-bind:editMode="editMode"
                  placeholder="コメントを入力してください"
                />
              </p>
            </div>
          </div>
        </section>
        <section
          v-if="editMode || getProfile(state.currentArticle.profile_content)"
        >
          <div class="p-outline l-wrapper" id="person">
            <h2 class="p-outline__title">
              企画者・講師・出演者情報<span class="notice" v-if="editMode"
                >一番上の人物のサムネイルが、まち寺トップページなどに表示されます</span
              >
            </h2>

            <div
              v-for="(list, index) in setState.currentArticle.profile_content
                .items"
              :key="list.hash"
              class="p-event__teacher-wrap"
            >
              <ProfileContentBlock
                v-model="setState.currentArticle.profile_content.items[index]"
                :initial-item="
                  getItemByHash(
                    initialState.currentArticle.profile_content.items,
                    list.hash
                  )
                "
                :indexKey="index"
                :handle-click-delete="deleteProfileContentBlock"
                :editMode="editMode"
                :articleType="'event'"
              />
            </div>
            <AddProfileContentBtn
              :handle-click-add="addProfileContentBlock"
              :editMode="editMode"
            />
          </div>
        </section>
        <section>
          <div class="p-outline l-wrapper">
            <h2 class="p-outline__title">イベント概要</h2>
            <dl
              class="p-event__outline"
              v-if="editMode || initialState.currentArticle.title"
            >
              <dt class="p-event__outline-title">タイトル</dt>
              <dd class="p-event__outline-contents">
                {{ state.currentArticle.title }}
              </dd>
            </dl>
            <div class="required_wrap">
              <span v-if="editMode" class="required">*</span>
              <dl class="p-event__outline">
                <dt class="p-event__outline-title">開催日</dt>
                <dd class="p-event__outline-contents">
                  <FlatPickr
                    :error-state="
                      error.event_detail && error.event_detail.date
                        ? error.event_detail.date
                        : ''
                    "
                    v-if="editMode && setState.currentArticle.status === 0"
                    v-model="setState.currentArticle.event_detail.date"
                    :config-no-calendar="false"
                    :configEnableTime="false"
                    v-bind:editMode="editMode"
                    class="edit-mode"
                  ></FlatPickr>
                  <span
                    class="view-mode"
                    v-if="
                      !editMode ||
                        (state.currentArticle.event_detail.date &&
                          setState.currentArticle.status === 1)
                    "
                    >{{
                      moment(
                        state.currentArticle.event_detail.date,
                        'YYYY/MM/DD(ddd)'
                      )
                    }}</span
                  >
                </dd>
              </dl>
            </div>

            <dl
              class="p-event__outline"
              v-if="
                editMode ||
                  (setState.currentArticle.event_detail.end_date &&
                    setState.currentArticle.event_detail.end_date !==
                      setState.currentArticle.event_detail.date)
              "
            >
              <dt class="p-event__outline-title">終了日</dt>
              <dd class="p-event__outline-contents">
                <FlatPickr
                  :error-state="
                    error.event_detail && error.event_detail.end_date
                      ? error.event_detail.end_date
                      : ''
                  "
                  v-if="editMode && setState.currentArticle.status === 0"
                  v-model="setState.currentArticle.event_detail.end_date"
                  :config-no-calendar="false"
                  :configEnableTime="false"
                  :configMinDate="
                    setState.currentArticle.event_detail.date &&
                      setState.currentArticle.event_detail.date
                  "
                  v-bind:editMode="editMode"
                  class="edit-mode"
                ></FlatPickr>
                <span
                  class="view-mode"
                  v-if="
                    !editMode ||
                      (state.currentArticle.event_detail.end_date &&
                        setState.currentArticle.status === 1)
                  "
                  >{{
                    moment(
                      state.currentArticle.event_detail.end_date,
                      'YYYY/MM/DD(ddd)'
                    )
                  }}</span
                >
              </dd>
            </dl>

            <dl class="p-event__outline">
              <dt class="p-event__outline-title">開催時間</dt>
              <dd class="p-event__outline-contents">
                <span
                  class="p-event__outline-block"
                  v-if="
                    editMode ||
                      initialState.currentArticle.event_detail.open_time
                  "
                >
                  入場開始時間:
                  <FlatPickr
                    :error-state="
                      error.event_detail && error.event_detail.open_time
                        ? error.event_detail.open_time
                        : ''
                    "
                    v-if="editMode && setState.currentArticle.status === 0"
                    v-model="setState.currentArticle.event_detail.open_time"
                    :config-no-calendar="true"
                    :configEnableTime="true"
                    class="edit-mode"
                  ></FlatPickr>
                  <span v-else>
                    <span
                      v-if="setState.currentArticle.event_detail.open_time"
                      class="view-mode"
                      >{{
                        moment(
                          state.currentArticle.event_detail.open_time,
                          'HH:mm'
                        )
                      }}</span
                    >
                  </span>
                </span>
                <span
                  class="p-event__outline-block"
                  v-if="
                    editMode ||
                      initialState.currentArticle.event_detail.start_time
                  "
                >
                  開始時間:
                  <FlatPickr
                    :error-state="
                      error.event_detail && error.event_detail.start_time
                        ? error.event_detail.start_time
                        : ''
                    "
                    v-if="editMode && setState.currentArticle.status === 0"
                    v-model="setState.currentArticle.event_detail.start_time"
                    :config-no-calendar="true"
                    :configEnableTime="true"
                    class="edit-mode"
                  ></FlatPickr>
                  <span v-else>
                    <span
                      v-if="setState.currentArticle.event_detail.start_time"
                      class="view-mode"
                      >{{
                        moment(
                          state.currentArticle.event_detail.start_time,
                          'HH:mm'
                        )
                      }}</span
                    >
                  </span>
                </span>
                <span
                  class="p-event__outline-block"
                  v-if="
                    editMode ||
                      initialState.currentArticle.event_detail.end_time
                  "
                >
                  終了時間:
                  <FlatPickr
                    :error-state="
                      error.event_detail && error.event_detail.end_time
                        ? error.event_detail.end_time
                        : ''
                    "
                    v-if="editMode && setState.currentArticle.status === 0"
                    v-model="setState.currentArticle.event_detail.end_time"
                    :config-no-calendar="true"
                    :configEnableTime="true"
                    class="edit-mode"
                  ></FlatPickr>
                  <span v-else>
                    <span
                      v-if="setState.currentArticle.event_detail.end_time"
                      class="view-mode"
                      >{{
                        moment(
                          state.currentArticle.event_detail.end_time,
                          'HH:mm'
                        )
                      }}</span
                    >
                  </span>
                </span>
              </dd>
            </dl>
            <dl class="p-event__outline" v-if="state.currentArticle.temple.group_flg !== 1 || setState.currentArticle.event_detail.online_flg === 1">
              <dt class="p-event__outline-title">開催場所</dt>
              <dd
                class="p-event__outline-contents"
                v-if="setState.currentArticle.event_detail.online_flg !== 1"
              >
                <router-link
                  :to="'/temples/' + state.currentArticle.temple_id"
                  class="link-button-underline"
                >
                  {{ state.currentArticle.temple.name }} </router-link
                ><br />
                〒{{ state.currentArticle.temple.postal_code }}
                {{ state.currentArticle.temple.prefectures }}
                {{ state.currentArticle.temple.municipality }}
                {{ state.currentArticle.temple.address }}
                <a
                  class="p-event__outline-btn btn_1"
                  target="_blank"
                  :href="
                    `https://www.google.com/maps?q=${setState.currentArticle.temple.prefectures}${state.currentArticle.temple.municipality}${state.currentArticle.temple.address}`
                  "
                  >Map</a
                >
                <!-- <button class="p-event__outline-btn btn_1">Map</button> -->
              </dd>
              <dd v-else>
                オンライン
              </dd>
            </dl>
            <div
              class="required_wrap"
              v-if="setState.currentArticle.event_detail.online_flg !== 1"
            >
              <span v-if="editMode" class="required">*</span>
              <dl
                class="p-event__outline"
                v-if="
                  editMode ||
                    initialState.currentArticle.event_detail.meeting_place
                "
              >
                <dt class="p-event__outline-title">集合場所の特記事項</dt>
                <div class="p-event__outline-contents">
                  <Editable
                    :error-state="
                      error.event_detail && error.event_detail.meeting_place
                        ? error.event_detail.meeting_place
                        : ''
                    "
                    v-model="setState.currentArticle.event_detail.meeting_place"
                    v-bind:initial-text="
                      initialState.currentArticle.event_detail.meeting_place
                    "
                    :initial-class="'p-event__outline-contents'"
                    tagName="dd"
                    v-bind:editMode="editMode"
                    placeholder="集合場所の特記事項を入力してください"
                  />
                </div>
              </dl>
            </div>
            <dl
              class="p-event__outline"
              v-if="
                setState.currentArticle.event_detail.online_flg !== 1 &&
                  (editMode || initialState.currentArticle.event_detail.access)
              "
            >
              <dt class="p-event__outline-title">交通アクセス</dt>
              <div class="p-event__outline-contents">
                <Editable
                  :error-state="
                    error.event_detail && error.event_detail.access
                      ? error.event_detail.access
                      : ''
                  "
                  v-model="setState.currentArticle.event_detail.access"
                  v-bind:initial-text="
                    initialState.currentArticle.event_detail.access
                  "
                  tagName="dd"
                  v-bind:editMode="editMode"
                  placeholder="交通アクセス方法を入力してください"
                />
              </div>
            </dl>
            <div class="required_wrap">
              <span v-if="editMode" class="required">*</span>
              <dl
                class="p-event__outline"
                v-if="
                  editMode ||
                    initialState.currentArticle.event_detail.price === 0 ||
                    initialState.currentArticle.event_detail.price
                "
              >
                <dt class="p-event__outline-title">参加料金</dt>

                <dd class="p-event__outline-contents">
                  <span
                    v-if="!editMode || setState.currentArticle.status === 1"
                  >
                    <span
                      v-if="
                        initialState.currentArticle.event_detail.price === 0
                      "
                    >
                      無料
                    </span>
                    <span v-else>{{
                      state.currentArticle.event_detail.price
                    }}</span>
                  </span>
                  <Editable
                    v-else
                    :error-state="
                      error.event_detail && error.event_detail.price
                        ? error.event_detail.price
                        : ''
                    "
                    v-model="setState.currentArticle.event_detail.price"
                    v-bind:initial-text="
                      initialState.currentArticle.event_detail.price
                    "
                    :initial-class="'p-event__outline-contents-price'"
                    tagName="span"
                    v-bind:editMode="editMode"
                    :status="status"
                    placeholder="参加料金を入力してください"
                  ></Editable>
                  <span
                    v-if="initialState.currentArticle.event_detail.price !== 0"
                    >円（税込）</span
                  >
                </dd>
              </dl>
            </div>
            <dl
              class="p-event__outline"
              v-if="
                editMode ||
                  initialState.currentArticle.event_detail.included_price
              "
            >
              <dt class="p-event__outline-title">料金に含まれるもの</dt>
              <div class="p-event__outline-contents">
                <Editable
                  :error-state="
                    error.event_detail && error.event_detail.included_price
                      ? error.event_detail.included_price
                      : ''
                  "
                  v-model="setState.currentArticle.event_detail.included_price"
                  v-bind:initial-text="
                    initialState.currentArticle.event_detail.included_price
                  "
                  :initial-class="'p-event__outline-contents'"
                  tagName="dd"
                  v-bind:editMode="editMode"
                  placeholder="料金に含まれるものを入力してください"
                />
              </div>
            </dl>
            <div class="required_wrap">
              <span v-if="editMode" class="required">*</span>
              <dl
                class="p-event__outline"
                v-if="
                  editMode ||
                    setState.currentArticle.event_detail.reserve_deadline_date
                "
              >
                <dt class="p-event__outline-title">申込締切</dt>
                <FlatPickr
                  :error-state="
                    error.event_detail &&
                    error.event_detail.reserve_deadline_date
                      ? error.event_detail.reserve_deadline_date
                      : ''
                  "
                  v-if="editMode"
                  v-model="
                    setState.currentArticle.event_detail.reserve_deadline_date
                  "
                  :config-no-calendar="false"
                  :config-enable-time="true"
                ></FlatPickr>
                <span v-else>
                  <span class="view-mode">{{
                    moment(
                      state.currentArticle.event_detail.reserve_deadline_date,
                      'YYYY/MM/DD(ddd) HH:mm'
                    )
                  }}</span>
                </span>
              </dl>
            </div>
            <div class="required_wrap">
              <span v-if="editMode" class="required">*</span>
              <dl
                class="p-event__outline"
                v-if="
                  editMode ||
                    initialState.currentArticle.event_detail.cancel_policy
                "
              >
                <dt class="p-event__outline-title">キャンセルポリシー</dt>
                <div class="p-event__outline-contents">
                  <Editable
                    :error-state="
                      error.event_detail && error.event_detail.cancel_policy
                        ? error.event_detail.cancel_policy
                        : ''
                    "
                    v-model="setState.currentArticle.event_detail.cancel_policy"
                    v-bind:initial-text="
                      initialState.currentArticle.event_detail.cancel_policy
                    "
                    :initial-class="'p-event__outline-contents'"
                    tagName="dd"
                    v-bind:editMode="editMode"
                    placeholder="キャンセルポリシーを入力してください"
                  />
                </div>
              </dl>
            </div>
            <div class="required_wrap">
              <span v-if="editMode" class="required">*</span>
              <dl
                class="p-event__outline"
                v-if="
                  editMode || initialState.currentArticle.event_detail.capacity
                "
              >
                <dt class="p-event__outline-title">定員</dt>
                <dd class="p-event__outline-contents">
                  <Editable
                    :error-state="
                      error.event_detail && error.event_detail.capacity
                        ? error.event_detail.capacity
                        : ''
                    "
                    v-model="setState.currentArticle.event_detail.capacity"
                    v-bind:initial-text="
                      initialState.currentArticle.event_detail.capacity
                    "
                    tagName="span"
                    :initial-class="'p-event__outline-contents-capacity'"
                    v-bind:editMode="editMode"
                    placeholder="定員を入力してください"
                    str="名"
                  />
                </dd>
              </dl>
            </div>
            <div class="required_wrap">
              <span v-if="editMode" class="required">*</span>
              <dl
                class="p-event__outline"
                v-if="
                  editMode ||
                    initialState.currentArticle.event_detail.recruit_member
                "
              >
                <dt class="p-event__outline-title">まち寺での募集人数</dt>
                <dd class="p-event__outline-contents">
                  <Editable
                    :error-state="
                      error.event_detail && error.event_detail.recruit_member
                        ? error.event_detail.recruit_member
                        : ''
                    "
                    v-model="
                      setState.currentArticle.event_detail.recruit_member
                    "
                    v-bind:initial-text="
                      initialState.currentArticle.event_detail.recruit_member
                    "
                    tagName="span"
                    :initial-class="'p-event__outline-contents-lowest_people'"
                    v-bind:editMode="editMode"
                    placeholder="募集人数を入力してください"
                    str="名"
                  />
                </dd>
              </dl>
            </div>
            <div class="required_wrap">
              <span v-if="editMode" class="required">*</span>
              <dl
                class="p-event__outline"
                v-if="
                  editMode ||
                    initialState.currentArticle.event_detail.lowest_people
                "
              >
                <dt class="p-event__outline-title">最低遂行人数</dt>
                <dd class="p-event__outline-contents">
                  <Editable
                    :error-state="
                      error.event_detail && error.event_detail.lowest_people
                        ? error.event_detail.lowest_people
                        : ''
                    "
                    v-model="setState.currentArticle.event_detail.lowest_people"
                    v-bind:initial-text="
                      initialState.currentArticle.event_detail.lowest_people
                    "
                    tagName="span"
                    :initial-class="'p-event__outline-contents-lowest_people'"
                    v-bind:editMode="editMode"
                    placeholder="最低遂行人数を入力してください"
                    str="名"
                  />
                </dd>
              </dl>
            </div>
            <dl
              class="p-event__outline"
              v-if="
                editMode || initialState.currentArticle.event_detail.notices
              "
            >
              <dt class="p-event__outline-title">特記事項</dt>
              <div class="p-event__outline-contents">
                <Editable
                  :error-state="
                    error.event_detail && error.event_detail.notices
                      ? error.event_detail.notices
                      : ''
                  "
                  v-model="setState.currentArticle.event_detail.notices"
                  v-bind:initial-text="
                    initialState.currentArticle.event_detail.notices
                  "
                  :initial-class="'p-event__outline-contents'"
                  tagName="dd"
                  v-bind:editMode="editMode"
                  placeholder="特記事項を入力してください"
                />
              </div>
            </dl>
            <dl
              class="p-event__outline"
              v-if="editMode || initialState.currentArticle.organizer.name"
            >
              <dt class="p-event__outline-title">主催者</dt>
              <dd class="p-event__outline-contents">
                <Editable
                  :error-state="
                    error.organizer && error.organizer.name
                      ? error.organizer.name
                      : ''
                  "
                  v-model="setState.currentArticle.organizer.name"
                  v-bind:initial-text="
                    initialState.currentArticle.organizer.name
                  "
                  tagName="span"
                  :initial-class="'p-event__outline-contents-organizer_name'"
                  v-bind:editMode="editMode"
                  placeholder="主催者名を入力してください"
                />
                <span class="p-event__outline-cation"
                  >※このイベントは、主催者様によって企画運営されています。講座の内容については、お問い合わせボタンから主催者様にお問い合わせください。</span
                >
                <a
                  :href="
                    `mailto:${initialState.currentArticle.event_detail.event_contact_mail}`
                  "
                  class="p-event__outline-btn btn_2"
                  v-if="
                    !editMode &&
                      initialState.currentArticle.event_detail
                        .event_contact_mail
                  "
                  >お問い合わせ</a
                >
                <div class="required_wrap">
                  <span v-if="editMode" class="required">*</span>
                  <Editable
                    :error-state="
                      error.event_detail &&
                      error.event_detail.event_contact_mail
                        ? error.event_detail.event_contact_mail
                        : ''
                    "
                    v-if="editMode"
                    v-model="
                      setState.currentArticle.event_detail.event_contact_mail
                    "
                    v-bind:initial-text="
                      initialState.currentArticle.event_detail
                        .event_contact_mail
                    "
                    tagName="span"
                    :initial-class="'p-event__outline-contents-organizer_mail'"
                    v-bind:editMode="editMode"
                    placeholder="問い合わせメールアドレスを入力してください"
                  />
                </div>
              </dd>
            </dl>
          </div>
          <div class="p-action_block" v-if="!editMode">
            <div class="l-wrapper">
              <FollowBtn
                :temple-id="state.currentArticle.temple_id"
                :name="state.currentArticle.temple.name"
                :editMode="editMode"
              />
              <div
                class="p-action_item"
                :class="{'action-book': state.currentArticle.event_detail.stop_flg !== 1 && nextDateCheck(state.currentArticle.event_detail.reserve_deadline_date) && !hasReserved(state.currentArticle.event_detail_id)}"
                v-if="state.currentArticle.event_detail.no_entry_flg !== 1"
              >
                <button
                  :disabled="
                    state.currentArticle.event_detail.stop_flg === 1 ||
                      !nextDateCheck(
                        state.currentArticle.event_detail.reserve_deadline_date
                      ) ||
                      editMode
                  "
                  class="p-action_link"
                  @click="locate(state.currentArticle.event_detail.reserve_url)"
                >
                  <span
                    v-if="state.currentArticle.event_detail.stop_flg === 1"
                    class="p-action_link-text"
                    >このイベントは中止しました</span
                  >
                  <span
                    v-else-if="
                      !nextDateCheck(
                        state.currentArticle.event_detail.reserve_deadline_date
                      )
                    "
                    class="p-action_link-text"
                    >このイベントの募集は終了しました</span
                  >
                  <span
                    v-else-if="
                      hasReserved(state.currentArticle.event_detail_id)
                    "
                    class="p-action_link-text"
                    >予約をキャンセルする</span
                  >
                  <span v-else class="p-action_link-book"
                    >このイベントを予約する</span
                  >
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="p-access"
            v-if="
              !editMode && setState.currentArticle.event_detail.online_flg !== 1
            "
          >
            <div class="l-wrapper">
              <div class="p-access__list">
                <dl class="p-access__item">
                  <dt class="p-access__tit">寺院名</dt>
                  <dd class="p-access__contents">
                    <router-link
                      :to="'/temples/' + state.currentArticle.temple_id"
                    >
                      {{ state.currentArticle.temple.name }}
                    </router-link>
                  </dd>
                  <dt class="p-access__tit">住所</dt>
                  <dd class="p-access__contents">
                    〒{{ state.currentArticle.temple.postal_code }}
                    {{ state.currentArticle.temple.prefectures }}
                    {{ state.currentArticle.temple.municipality }}
                    {{ state.currentArticle.temple.address }}<br />
                    TEL {{ state.currentArticle.temple.tel }}
                  </dd>
                </dl>
                <dl
                  class="p-access__item"
                  v-if="
                    editMode ||
                      state.currentArticle.temple.temple_website.public_access
                  "
                >
                  <dt class="p-access__tit">交通アクセス</dt>
                  <dd class="p-access__contents">
                    {{
                      state.currentArticle.temple.temple_website.public_access
                    }}
                  </dd>
                </dl>
              </div>
            </div>
            <div
              class="p-access__gmap"
              v-if="
                editMode ||
                  setState.currentArticle.event_detail.online_flg !== 1
              "
            >
              <iframe
                :src="
                  `//maps.google.co.jp/maps?q=${setState.currentArticle.temple.prefectures}${state.currentArticle.temple.municipality}${state.currentArticle.temple.address}&output=embed&t=m&z=16&hl=ja`
                "
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                width="600"
                height="450"
              ></iframe>
            </div>
          </div>
        </section>
        <section v-if="setState.articlesGroups.length > 0 || editMode">
          <div class="p-article-group l-wrapper">
            <ArticleGroup
              :error-state="error.article_group ? error.article_group : ''"
              v-model:article-group-id="
                setState.currentArticle.article_group_id
              "
              v-model:article-group="setState.currentArticle.article_group"
              :editMode="editMode"
              :articles-groups="setState.articlesGroups"
              :temple-id="setState.currentArticle.temple_id"
            />
          </div>
        </section>
        <!-- <section v-if="editMode || initialState.currentArticle.profile_content.thumbnail || initialState.currentArticle.profile_content.name || initialState.currentArticle.profile_content.items[0].body || initialState.currentArticle.profile_content.items[0].name "> -->

        <!-- <section v-if="editMode || setState.articlesGroups.length > 0"> -->
      </div>
    </div>
    <ArticleRelatedList
      v-if="setState.currentArticle.article_group_id"
      :limit="6"
      :color="'orange'"
      :conditions="{
        article_group_id: setState.currentArticle.article_group_id,
      }"
      :route-params-id="route.params.id"
      :editMode="editMode"
      :article-id="state.currentArticle.id"
    />
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { useArticles } from '@/composables/useArticles.js';
import { useCommons } from '@/composables/useCommons';
import Editable from '@/components/Editable.vue';
import Upload from '@/components/Upload';
import TagInput from '@/components/TagInput.vue';
import CategorySelect from '@/components/CategorySelect.vue';
import ArticleGroup from '@/components/ArticleGroup.vue';
import FlatPickr from '@/components/FlatPickr.vue';
import ProfileContentBlock from '@/components/ProfileContentBlock.vue';
import SnsShare from '@/components/SnsShare.vue';
import AddProfileContentBtn from '@/components/AddProfileContentBtn.vue';
import ContentBlock from '@/components/ContentBlock.vue';
import AddContentBtn from '@/components/AddContentBtn.vue';
import ControlArea from '@/components/ControlArea.vue';
import { inject, watch, onMounted, ref } from 'vue';
import ArticleRelatedList from '@/components/ArticleRelatedList';
import StatusLabel from '@/components/StatusLabel';
import { getProfile } from '@/utils/profile';
import { getThumbnailUrl, dateCheck, nextDateCheck } from '@/utils/helper';
import { nl2br } from '@/utils/nl2br';
import IconBox from '@/components/IconBox';
import FollowBtn from '@/components/FollowBtn';
export default {
  name: 'Event',
  data() {
    return {
      customDelimiter: [',', ' '],
      editMode: false,
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    let noArticleAuthFlg = ref(false);

    const { id } = route.params;
    const {
      user,
      hasArticleAuth,
      followTemple,
      hasTempleAuth,
      hasReserved,
    } = inject('auth');
    const { viewControlAreaFlg, modeChangeFlg } = inject('commons');
    const { openModal, toNext, catState } = useCommons();
    let { routerPush } = useCommons();
    // let editMode = ref(false);
    const {
      state,
      setState,
      isCurrentArticleSet,
      error,
      initialState,
      getCurrentArticle,
      resetCurrentArticle,
      isArticlesGroupSet,
      deleteProfileContentBlock,
      addProfileContentBlock,
      addContentBlock,
      deleteContentBlock,
      getItemByHash,
      templeControl,
      control,
    } = useArticles(id);

    //記事取得
    getCurrentArticle();
    watch(
      () => route.params.id,
      (newParams, oldParams) => {
        console.log(`${oldParams} -> ${newParams}`);
        newParams && getCurrentArticle(newParams);
      }
    );

    const locate = (url) => {
      if (url) {
        window.open(url, '_blank');
      } else {
        location.href = `/events/reserve_event/?event_detail_id=${setState.currentArticle.event_detail.id}&type=entry`;
      }
    };

    onMounted(() => {
      console.log('_id__error', error);
      watch(
        () => setState.currentArticle.title,
        (newTitle) => {
          newTitle &&
            (document.title = `まち寺プラットフォーム｜${setState.currentArticle.title}`);
        }
      );

      if (
        !hasArticleAuth(state.currentArticle.id, state.currentArticle.temple_id)
      ) {
        const target = 'edit';
        if (route.path.indexOf(target) > 0) {
          noArticleAuthFlg.value = true;
          const newPath = route.path.replace(target, '');
          router.push({
            path: newPath,
          });
        }
      } else {
        viewControlAreaFlg.value = true;
      }
    });

    return {
      state,
      setState,
      user,
      isCurrentArticleSet,
      isArticlesGroupSet,
      error,
      initialState,
      id,
      resetCurrentArticle,
      deleteProfileContentBlock,
      addProfileContentBlock,
      addContentBlock,
      deleteContentBlock,
      templeControl,
      control,
      hasTempleAuth,
      hasArticleAuth,
      getItemByHash,
      openModal,
      toNext,
      getProfile,
      getThumbnailUrl,
      followTemple,
      nl2br,
      route,
      dateCheck,
      nextDateCheck,
      locate,
      hasReserved,
      routerPush,
      catState,
      noArticleAuthFlg,
      viewControlAreaFlg,
      modeChangeFlg,
    };
  },
  created() {
    if (this.$route.path.indexOf('edit') != -1) {
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
  mounted() {
    if (this.noArticleAuthFlg) {
      this.editMode = false;
    }
  },
  methods: {
    moment(date, format) {
      moment.locale('ja');
      return moment(date).format(format);
    },
    changeMode() {
      this.editMode = !this.editMode;
      this.modeChangeFlg = true;
    },
  },
  components: {
    IconBox,
    Editable,
    Upload,
    TagInput,
    CategorySelect,
    ArticleGroup,
    FlatPickr,
    ProfileContentBlock,
    SnsShare,
    AddProfileContentBtn,
    ContentBlock,
    AddContentBtn,
    ControlArea,
    ArticleRelatedList,
    StatusLabel,
    FollowBtn,
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.routerPush && !this.noArticleAuthFlg) {
      const modalItems = {
        title: 'このページを離れますか？',
        text: '行った変更が保存されない可能性があります。',
        btnType: 'cancel&submit&go',
        submitBtnText: 'このページを離れる',
        function: this.resetCurrentArticle,
      };
      this.openModal(modalItems);
      this.$watch(
        'toNext',
        function(newVal, oldVal) {
          console.log(`newVal:${newVal} -> oldVal:${oldVal}`);
          if (newVal) {
            next();
          } else {
            return;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
    } else {
      this.routerPush = false;
      next();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.article__content {
  @include sp {
    &.with-control-area {
      padding-top: 0;
    }
    padding-top: 7rem;
  }
  @include pc {
    &.with-control-area {
      padding-top: 0;
    }
  }
  padding-top: 12rem;

  // &:not(.edit) {
  // 	.edit-mode {
  // 		display: none;
  // 	}
  // }
  // &.edit {
  // 	.view-mode {
  // 		display: none;
  // 	}
  // }
}

.l-event {
  & .p-pagetype {
    & .p-pagetype__jp {
      background-color: #cf9954;
    }

    & .p-pagetype__en {
      background-color: #be6c4c;
    }
  }

  & .p-title {
    @include sp {
      margin-top: 2rem;
    }
  }

  & .p-author {
    @include sp {
      margin-top: 42px;
    }
    margin-top: 70px;
  }

  & .p-author__tit {
    color: #be6c4c;
  }

  & .p-location__tit {
    color: #be6c4c;
  }

  & .p-location__btn {
    background-color: #be6c4c;
  }

  & .p-tag__item {
    background-color: #e8e2d7;
  }

  & .p-contents {
    margin-top: 50px;
  }

  & .p-contents__title {
    border-bottom: 2px solid #6a7bb7;
    color: #6a7bb7;
    position: relative;
  }

  & .p-contents {
    &__block {
      text-align: left;
    }

    &__title {
      border: none;
      color: #be6c4c;
      font-size: 3.5rem;
      @include sp {
        font-size: 2.3rem;
        line-height: 1.5;
      }

      &-no-margin {
        margin-top: 0;
      }
    }

    &__comment {
      background-color: #f0ede8;
      font-size: 1.8rem;
      @include sp {
        font-size: 1.1rem;
        margin-top: 30px;
        padding: 25px 30px;
      }
      line-height: 1.8;
      margin-top: 50px;
      padding: 50px 55px;

      &-tit {
        color: #be6c4c;
        display: block;
        font-size: 2.5rem;
        @include sp {
          font-size: 2.3rem;
          margin-bottom: 15px;
        }
        line-height: 1.2;
        margin-bottom: 30px;
      }

      &-text {
        display: block;
      }
    }
  }

  & .p-outline {
    @include pc {
      border-bottom: 1px solid #000;
    }
    @include sp {
      margin-top: 48px;

      & .p-event__teacher {
        &-wrap {
          &:last-child {
            border-bottom: 1px solid #000;
          }
        }
      }
    }
    margin: 75px auto;

    &__title {
      color: #be6c4c;
      font-size: 2.5rem;
      @include font_shuei_400;
      @include sp {
        font-family: $contents_fonts_jp_Gothic;
        font-size: 2.3rem;
        font-weight: bold;
        margin-bottom: 10px;
      }
      line-height: 1.2;
      margin-bottom: 30px;
    }

    & .p-event__outline {
      border-top: 1px solid #000;
      display: flex;
      font-size: 1.5rem;
      @include sp {
        display: block;
        font-size: 1.2rem;
        justify-content: space-between;
        padding: 15px 0;
        &.p-event__teacher {
          justify-content: start;
        }

        &.p-outline__ph {
          display: block;

          & .p-event__outline-contents {
            width: 100%;
          }
        }
      }
      line-height: 2;
      padding: 20px 0;

      &-title {
        @include sp {
          width: 100%;
        }
        width: 20%;

        & .drop_area {
          margin: 0;
          padding: 0;

          & .user-photo.default {
            font-size: 1.2rem;
            line-height: 1.2;
            padding: 55px 10px 10px;
            text-align: center;
          }
        }

        & .uploaded {
          & .fit {
            border-radius: 50%;
            height: 160px;
            margin: 0 20px;
            width: 160px;
          }

          & .delete-button {
            font-size: 1.2rem;
            line-height: 24px;
            margin-top: 8px;
            padding: 0 7px;
            position: static;
          }
        }
      }

      &-contents {
        @include sp {
          // width: 59.733vw;
          width: 100%;
        }
        width: 80%;

        & .editable {
          @include pc {
            width: 80%;
          }
        }

        & img {
          @include sp {
            margin: 15px 0;
            width: 100%;
          }
          // margin: 35px 0;
          width: 100%;

          &:first-child {
            margin-top: 15px;
          }

          &:last-child {
            margin-bottom: 15px;
          }
        }
      }

      &-block {
        display: block;
      }

      &-cation {
        display: block;
        font-size: 1.1rem;
      }

      &-btn {
        background-color: #be6c4c;
        color: #fff;
        display: inline-block;
        @include sp {
          font-size: 1.3rem;
        }
        text-align: center;

        &.btn_1 {
          border-radius: 12.5px;
          @include font_conqueror_400;
          @include sp {
            line-height: 17px;
            margin-left: 0;
            padding: 0 18px;
          }
          line-height: 25px;
          margin-left: 30px;
          padding: 0 26px;
        }

        &.btn_2 {
          border-radius: 20px;
          display: inline-block;
          @include font_shuei_400;
          @include sp {
            margin-top: 13px;
            padding: 0 26px;
          }
          line-height: 40px;
          margin-top: 20px;
          padding: 0 30px;
        }
      }

      &-upload {
        width: auto;
      }

      &.p-event__teacher {
        padding: 40px 0;
      }
    }

    & .p-event__teacher {
      @include font_shuei_400;
      @include sp {
        font-size: 1.3rem;
      }
      width: 100%;

      & .p-event__outline-title {
        @include sp {
          width: 100%;
        }
        // width: initial;
        width: 200px;
      }

      &-img {
        @include sp {
          width: 35px;
        }
        width: 70px;
      }

      &-title {
        font-size: 1.5rem;
        margin-bottom: 0;

        .edit & {
          margin-bottom: 15px;
        }
      }

      &-name {
        font-size: 3rem;
        margin-bottom: 0;
        .edit & {
          margin-bottom: 15px;
        }
      }

      &-body {
        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }
  }

  & .p-access {
    background-image: url('~@/assets/image/bg.jpg');
    background-repeat: repeat;
    @include sp {
      // margin-top: 45px;
      padding: 28px 0;
    }
    padding: 48px 0 73px;
    position: relative;
    width: 100%;

    &__list {
      display: flex;
      @include sp {
        display: block;
        width: 100%;
      }
    }

    &__item {
      &:first-child {
        @include sp {
          width: 100%;
        }
        width: 40%;
      }

      &:last-child {
        @include sp {
          margin-left: 0;
          margin-top: 24px;
          width: 100%;
        }
        margin-left: 10%;
        width: 50%;
      }
    }

    &__tit {
      font-size: 2rem;
      @include font_shuei_400;
      @include sp {
        font-size: 1.3rem;
        padding-left: 15px;
      }
      line-height: 1.5;
      padding-left: 25px;
      position: relative;

      &::before {
        background-color: #be6c4c;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 20px;
        @include sp {
          height: 13px;
          width: 13px;
        }
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }
    }

    &__contents {
      font-size: 1.5rem;
      @include sp {
        font-size: 1.1rem;
        line-height: 1.8;
      }
      line-height: 2.5;
      margin-bottom: 2rem;

      & a {
        text-decoration: underline;
      }
    }

    & .p-event__outline-contents {
      font-size: 1.5rem;
      @include sp {
        font-size: 1.1rem;
        line-height: 1.8;
      }
      line-height: 2.5;
    }

    &__gmap {
      height: 0;
      @include sp {
        margin-top: 35px;
        padding-bottom: 60%;
      }
      margin-top: 64px;
      overflow: hidden;
      padding-bottom: 25%;
      padding-top: 30px;
      position: relative;

      & iframe,
      object,
      embed {
        height: 450px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    & .p-action_block {
      background: url(../../../assets/image/dots_line_s.png) no-repeat center
        center;
      @include sp {
        margin: 35px auto 0;
      }
      margin: 67px auto 0;
      padding: 0;
      width: 100%;
    }

    & .p-action_item {
      @include sp {
        margin: 0 auto;
        width: 287px;
      }
      margin-right: 0;
      position: relative;

      & .p-action_link-text {
        padding-left: 37px;
        position: relative;

        &::before {
          background: url(../../../assets/image/calendar.png) no-repeat;
          background-size: contain;
          content: '';
          display: inline-block;
          height: 14px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 17px;
        }
      }
      .p-action_link-book & {
        padding-left: 37px;
        position: relative;
        background-color: #b24b5b;
        border: solid 1px #b24b5b;
        color: #fff;

        &::before {
          background: url(../../../assets/image/calendar.png) no-repeat;
          background-size: contain;
          content: '';
          display: inline-block;
          height: 14px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 17px;
        }
      }
    }
  }
}

.p-action_block {
  background-image: url('~@/assets/image/bg.jpg');
  background-repeat: repeat;
  @include sp {
    display: block;
    padding: 37px 45px;
  }
  padding: 77px 0;

  & .l-wrapper {
    display: flex;
    @include sp {
      display: block;
      padding: 0;
      width: 100%;

      & .p-action_link {
        padding: 2.1rem 0;

        & .p-action_item:nth-child(2) .p-action_link-text {
          padding-left: 14.4vw;

          &::before {
            left: 0.6rem;
          }
        }


      }
    }
    justify-content: center;

      & .p-action_item.action-book{
            position: relative;
            background-color: #b24b5b;
            border: solid 1px #b24b5b;
            color: #fff;

            & .p-action_link-book{
              color: #fff;

              &::before {
              background: url(../../../assets/image/header/icon_event_white.svg) no-repeat;
              background-size: contain;
              content: '';
              display: inline-block;
              height: 24px;
              left: -32px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 27px;
            }
            }



        }
  }
}

.l-edit {
  & .p-contents__title {
    margin-bottom: 0;
  }

  & .p-contents__text {
    margin-bottom: 0;
  }

  & .uploaded .delete-button {
    // margin-bottom: 0;
  }
}
</style>
<style lang="scss">
.d-none {
  display: none;
}
.editable-area {
  border: 1px dotted #333;
  padding: 8px;
}

.l-event {
  & .p-cat {
    background-color: #dc9163;
  }

  & .p-select {
    background-color: #dc9163;
  }
}

.add-btn {
  cursor: pointer;
  display: inline-block;
  font-family: $contents_fonts_jp_Gothic;
  font-size: 1.5rem;
  padding: 20px;
  position: relative;
}

.add-btn::before {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  content: '+';
  display: inline-block;
  height: 17px;
  left: 0;
  line-height: 15px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
}

.p-event__outline .link-button {
  text-decoration: underline;
}
.notice {
  font-size: 1.3rem;
  margin-left: 2rem;
}

.p-outline .p-event__teacher {
  @include sp {
    display: block !important;

    & .p-event__outline-title {
      margin-bottom: 15px;
      width: 100%;
    }

    & .p-event__outline-contents {
      font-family: $contents_fonts_jp_Gothic;
      line-height: 2;
      margin-bottom: 15px;
      width: 100%;

      &.p-event__teacher-title {
        font-family: $contents_fonts_jp_Gothic;
        font-size: 1.1em;
        line-height: 1.2;
      }

      &.p-event__teacher-name {
        font-family: $contents_fonts_jp_Gothic;
        font-size: 2rem;
        line-height: 1.5;
      }
    }
  }
}
</style>

<template>
  <a
    v-show="!editMode"
    :href="modelValue"
    class="sns_link"
    :class="!modelValue && 'disabled'"
    target="_blank"
    ><img :src="viewIcon"
  /></a>
  <div
    v-show="editMode"
    class="sns_link"
    :class="!modelValue & !snsEditMode && 'disabled'"
    v-click-outside="onClickOutside"
  >
    <div v-show="snsEditMode" class="sns_edit">
      <span class="sns_edit--balloon">
        <Editable
          v-model="modelComputed"
          :initial-text="initialLink"
          :initial-class="'sns-block'"
          tagName="span"
          v-bind:editMode="editMode"
          :placeholder="placeholder"
          :no-link="true"
        />
      </span>
    </div>
    <img
      @click="editSns"
      class="sns_link--image"
      :class="!modelValue && 'disabled'"
      :src="viewIcon"
    />
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import Editable from './Editable.vue';
import ClickOutside from 'click-outside-vue3';
export default {
  name: 'SnsLink',
  components: { Editable },
  props: {
    title: {
      type: String,
    },
    editMode: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    initialLink: {
      type: String,
    },
    handleUpdate: {
      type: Function,
    },
    placeholder: {
      type: String
    }
  },
  directives: {
    clickOutside: ClickOutside.directive,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const facebookIcon = require('@/assets/image/top/icon-fb.svg');
    const twitterIcon = require('@/assets/image/top/icon-tw.svg');
    const instagramIcon = require('@/assets/image/top/icon-insta.svg');
    const lineIcon = require('@/assets/image/temples/icon-line.svg');
    const hpIcon = require('@/assets/image/temples/icon-hp.svg');
    let viewIcon = ref('');
    onMounted(() => {
      switch (props.type) {
        case 'facebook':
          viewIcon.value = facebookIcon;
          break;
        case 'twitter':
          viewIcon.value = twitterIcon;
          break;
        case 'instagram':
          viewIcon.value = instagramIcon;
          break;
        case 'line':
          viewIcon.value = lineIcon;
          break;
        case 'hp':
          viewIcon.value = hpIcon;
          break;
      }
    });

    let snsEditMode = ref(false);
    const editSns = () => {
      snsEditMode.value = !snsEditMode.value;
    };

    const modelComputed = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const onClickOutside = () => {
      snsEditMode.value = false;
    };

    return {
      facebookIcon,
      twitterIcon,
      instagramIcon,
      lineIcon,
      hpIcon,
      viewIcon,
      editSns,
      snsEditMode,
      modelComputed,
      onClickOutside,
    };
  },
};
</script>

<style lang="scss" scoped>
.disabled {
	display: none;

	.edit & {
		cursor: default;
		display: block;
		opacity: .5;
	}
	.sns_edit {
		opacity: 1 !important;
	}
}

.edit {
	& .sns_link {
		@include sp {
			& .sns_edit {
				transform: initial;
				width: 30rem;
			}

			&:nth-child(2) {
				& .sns_edit {
					left: -3rem;
				}

				& .sns_edit--balloon {
					&::after {
						left: 5rem;
					}
				}
			}

			&:nth-child(4) {
				& .sns_edit {
					left: -8rem;
				}

				& .sns_edit--balloon {
					&::after {
						left: 10rem;
					}
				}
			}

			&:nth-child(6) {
				& .sns_edit {
					left: -13rem;
				}

				& .sns_edit--balloon {
					&::after {
						left: 15rem;
					}
				}
			}

			&:nth-child(8) {
				& .sns_edit {
					left: -18rem;
				}

				& .sns_edit--balloon {
					&::after {
						left: 20rem;
					}
				}
			}

			&:nth-child(10) {
				& .sns_edit {
					left: -24rem;
				}

				& .sns_edit--balloon {
					&::after {
						left: 26rem;
					}
				}
			}
		}
		margin-top: 5rem;
		position: relative;
	}

	& .link_second {
		@include sp {
			& .sns_link {
				&:nth-child(2) {
					& .sns_edit {
						left: -10rem;
					}

					& .sns_edit--balloon {
						&::after {
							left: 12rem;
						}
					}
				}

				&:nth-child(4) {
					& .sns_edit {
						left: -16rem;
					}

					& .sns_edit--balloon {
						&::after {
							left: 18rem;
						}
					}
				}
			}
		}
	}

	& .sns_link--image {
		border: #333 dotted 1px;
		padding: 4px;
	}

	& .sns_edit {
		left: 50%;
		margin-bottom: 1.6rem;
		position: absolute;
		text-align: center;
		top: -60px;
		transform: translateX(-50%);
		width: 35rem;
		z-index: 10;

		.disabled & {
			opacity: 1 !important;
		}

		&--balloon {
			background: #fff;
			border-radius: 16px;
			display: block;
			opacity: 1 !important;
			overflow-wrap: break-word;
			// height: 80px;
			padding: 10px;
			position: relative;
			text-align: center;
			// width: 500px;
			&::after {
				border: solid transparent;
				border-bottom-width: 12px;
				border-color: rgba(0, 153, 255, 0);
				border-left-width: 7px;
				border-right-width: 7px;
				border-top-color: #fff;
				border-top-width: 12px;
				content: "";
				height: 0;
				left: 50%;
				margin-left: -7px;
				pointer-events: none;
				position: absolute;
				top: 100%;
				width: 0;
			}
		}

		&--text {
			border: #333 dotted 1px;
			display: block;
			opacity: 1 !important;
			overflow-wrap: break-word;
			padding: 4px;
			width: 100%;
		}
	}
}

.sns_link {
	@include sp {
		margin-right: 6.66%;
	}
	margin-right: 2.5rem;
}

.sns_link:last-child {
	margin-right: 0;
}
</style>

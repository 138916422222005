<template>
  <div>
    <Header />
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <Footer />
    <Modal/>
    <Loading />
  </div>
</template>

<script>
import {provide} from "vue";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import {useAuth} from "./composables/useAuth";
import {useCommons} from "./composables/useCommons";
import Loading from "./components/Loading";

export default {
  name: 'App',
  components: {
    Loading,
    Header,
    Footer,
    Modal
  },
  setup(){
    const auth = useAuth();
    auth.initialize();

    const commons = useCommons();

    commons.getTags();
    commons.getCategories();


    provide('commons', commons);
    provide('auth', auth);
  }
};
</script>

<style lang="scss">
@import "./assets/scss/import.scss";
#app {
	font-family: $contents_fonts_jp_Gothic;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
button {
	appearance: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
}
</style>

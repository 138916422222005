<template>
  <p :class="['status-text', status.value]">{{ status.label }}</p>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'StatusLabel',
  props: {
    item: {
      type: Object,
      required: true,
    },
    handleEndFlgSet: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const status = computed(() => {
      if (props.item.status === 0) {
        return {
          label: '下書き',
          value: 'draft',
        };
      } else if (props.item.status === 1 && props.item.article_type === 2) {
        const targetDate = new Date(props.item.event_detail.date);
        const now = new Date();
        targetDate.setHours(0)
        targetDate.setMinutes(0)
        targetDate.setSeconds(0)
        targetDate.setMilliseconds(0)
        now.setHours(0)
        now.setMinutes(0)
        now.setSeconds(0)
        now.setMilliseconds(0)
        if (props.item.event_detail.stop_flg === 0) {
          if (targetDate.getTime() === now.getTime()) {
            return { label: '本日開催', value: 'scheduled' };
          }else if(targetDate.getTime() < now.getTime()){
            props.handleEndFlgSet && props.handleEndFlgSet()
						return {
              label: '終了',
              value: 'end'
            }
          }else{
            return { label: '開催前', value: 'scheduled' };
          }
        } else {
          return {
            label: '開催中止',
            value: 'stopped',
          };
        }
      } else {
        return {
          label: '公開中',
          value: 'public',
        };
      }
    });

    return {
      status,
    };
  },
};
</script>
<style scoped>
.status-text {
	border-radius: 2rem;
	color: #fff;
	display: inline-block;
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
	font-size: 1.5rem;
	letter-spacing: .08rem;
	margin: 10px 0;
	padding: .8rem 1.4rem;
}
.status-text.public {
	background-color: #a73550;
}
.status-text.scheduled {
	background-color: #ca933d;
}
.status-text.draft {
	background-color: #3b9e9d;
}
.status-text.stopped {
	background-color: #7eaec5;
}
.status-text.end {
	background-color: #999;
}
</style>

<template>
  <div v-if="decorate" class="sp-only relative">
    <img
      src="@/assets/image/projects/title-line.svg"
      class="sp-only title-line"
    />
    <img src="@/assets/image/projects/bg-triangle.png" class="sp-only bg02" />
  </div>
  <div :class="['container', decorate && 'decorate']">
    <div class="outer" v-if="decorate">
      <div class="bg-pattern">
        <img src="@/assets/image/projects/bg-triangle.png" class="bg01" />
        <img
          src="@/assets/image/projects/bg-triangle.png"
          class="bg02 sp-none"
        />
      </div>
      <p class="vertical-index sp-none">Social Project</p>
      <h1>
        <img src="@/assets/image/projects/title-line.svg" class="sp-none" />
        <div class="title">
          <img src="@/assets/image/projects/title-logo.png" class="logo" />
          <div>
            <p class="sub01">
              <span class="red">ソ</span>ー<span class="blue">シ</span>ャ<span
                class="green"
                >ル</span
              >・<span class="red">プ</span>ロ<span class="blue">ジ</span
              >ェ<span class="green">ク</span>ト
            </p>
            <p class="sub02">Machi-Tera Social Project</p>
          </div>
        </div>
      </h1>
      <p class="lead" v-html="lead" />
    </div>
    <section class="p-related-articles" v-else>
      <h2 class="p-related-articles__title">
        <span class="p-related-articles__title--ja"
          >ソーシャル・プロジェクト一覧</span
        >
        <span class="p-related-articles__title--en"
          >Machi-tera <span class="color-text">S</span>ocial Project</span
        >
      </h2>
    </section>

    <template v-if="!fetching">
      <div class="project-cover flex" v-if="state.articles.length > 0">
        <template v-if="decorate">
          <ProjectItemFull
            v-for="(item, index) in state.articles"
            :key="index"
            :item="item"
            :editMode="editMode"
          />
        </template>
        <template v-else>
          <ProjectItem
              v-for="(item, index) in state.articles"
              :key="index"
              :item="item"
          />
        </template>
      </div>
      <p v-else class="no-list">該当記事はありません</p>

<!--
      <div v-if="editMode" class="add-btn__wrapper">
        <a href="/articles/create?type=project" class="add-btn"
          >プロジェクトを追加</a
        >
      </div>
-->
    </template>
    <Fetching v-else />

    <LinkButton link="/search?target=project" v-if="linkAll" />
    <Pagination
      v-else
      :on-click-page="handleClickPage"
      :total="pagination.total"
      :current="pagination.current"
      :perPage="pagination.perPage"
    />
  </div>
</template>

<script>
import ProjectItem from '@/components/ProjectItem.vue';
import ProjectItemFull from '@/components/ProjectItemFull.vue';
import LinkButton from '@/components/LinkButton.vue';
import { useArticles } from '@/composables/useArticles';
import Pagination from '@/components/Pagination';
import Fetching from '@/components/Fetching';
import { watch } from 'vue';

export default {
  name: 'ProjectList',
  props: {
    lead: {
      type: String,
    },
    limit: {
      type: [String, Number],
    },
    conditions: {
      type: Object,
    },
    decorate: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
    },
    linkAll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProjectItem,
    ProjectItemFull,
    LinkButton,
    Pagination,
    Fetching,
  },
  setup(props) {
    const { getArticles, state, pagination, fetching } = useArticles();
    getArticles(1, props.limit, props.conditions);

    watch(()=>props.conditions, (to, from) => {
      if(to !== from){
        getArticles(1, props.limit, props.conditions);
      }
    });
    const handleClickPage = (number) => {
      getArticles(
        1,
        props.limit,
        Object.assign({}, props.conditions, { page: number })
      );
    };

    return {
      state,
      handleClickPage,
      pagination,
      fetching,
    };
  },
};
</script>
<style lang="scss" scoped>
.sp-only {
	display: none;
}
.relative {
	position: relative;
}
.sp-only.relative {
	overflow-x: hidden;
}
.container {
	padding: 2rem 2rem 5rem;
	width: 100%;
}
.decorate {
	background-color: #fafafa;
	overflow-x: hidden;
	padding: 12.8rem 5rem 1.5rem 5rem;
}
.outer {
	margin: auto;
	position: relative;
}
.bg-pattern {
	height: 0;
	margin: auto;
	position: relative;
	width: 0;
}
img.bg01, img.bg02 {
	max-width: initial;
	position: absolute;
	width: 19.5rem;
}
.bg01 {
	bottom: -9rem;
	right: 58rem;
}
.bg02 {
	left: 52rem;
	top: 24rem;
}
.vertical-index {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.5rem;
	letter-spacing: .6rem;
	position: absolute;
	right: 4rem;
	top: -4rem;
	writing-mode: vertical-rl;
}
.vertical-index:before {
	background-color: #000;
	content: "";
	height: .2rem;
	position: absolute;
	right: -.3rem;
	top: -1.7rem;
	width: 1.5rem;
}
h1 {
	margin: auto;
	max-width: 100rem;
	position: relative;
	text-align: center;
	z-index: 5;
}
h1 > img {
	display: block;
	margin: auto;
	max-width: 100%;
}
.title {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: auto;
	margin-top: 2rem;
}
.logo {
	height: 9rem;
	margin-right: 3.5rem;
	padding-left: 3.5rem;
	width: 23rem;
}
.sub01 {
	color: #412814;
	font-family: "iroha-23kaede-stdn", sans-serif;
	font-size: 4.0rem;
	@include fz_vw(40);
	@include tablet {
		letter-spacing: .42rem;
	}
	letter-spacing: 1.42rem;
}
.sub01 .red {
	color: #c84d5f;
}
.sub01 .blue {
	color: #6a7bb7;
}
.sub01 .green {
	color: #749e1d;
}
.sub02 {
	color: #000;
	font-family: "aw-conqueror-didot", serif;
	font-size: 2.0rem;
	@include fz_vw(20);
	letter-spacing: 1.39rem;
	margin-top: 3rem;
}
.sub03 {
	font-family: "aw-conqueror-didot", serif;
	font-size: 2.7rem;
	letter-spacing: .89rem;
	margin-top: 3rem;
	text-align: left;
}
.sub04 {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.7rem;
	letter-spacing: .89rem;
	text-align: left;
}
.lead {
	font-family: dnp-shuei-mincho-pr6, sans-serif;
	font-size: 2.0rem;
	letter-spacing: .408rem;
	line-height: 4.8rem;
	margin: 5rem auto -1.3rem;
	text-align: center;
}
.no-list {
	letter-spacing: .2rem;
	text-align: center;
}
.add-btn__wrapper {
	text-align: center;
}

.flex {
	display: flex;
	flex-wrap: wrap;
	@include tablet {
		justify-content: space-around;
	}
	@include between {
		justify-content: space-around;
	}
	justify-content: space-between;
	margin: 0 auto;
	max-width: 120rem;


	&::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 35.5rem;
	}
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
	.sp-none {
		display: none;
	}
	.sp-only {
		display: block;
	}
	// .sp-only.relative {
	//   height: 15rem;
	// }
	.container {
		padding: 0 3rem 2rem 3rem;
	}
	.title-line {
		background-color: #fafafa;
		padding-top: 7.2rem;
	}
	.title {
		flex-flow: column;
	}
	.logo {
		height: 4.1rem;
		padding-left: 0;
		width: 10.7rem;
	}
	.sub01 {
		font-size: 2.3rem;
		letter-spacing: .6rem;
		margin-top: 1.2rem;
	}
	.sub02 {
		font-size: 1.1rem;
		letter-spacing: .7rem;
		margin-top: .3rem;
	}
	.lead {
		font-size: 1.3rem;
		line-height: 3rem;
		margin: 2rem auto;
		text-align: left;
	}
	.lead br {
		display: none;
	}
	.project-cover {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: scroll;
    justify-content: space-between;
	}
	.project-cover::-webkit-scrollbar {
		display: none;
	}
	img.bg01 {
		bottom: -4.5rem;
		right: 15rem;
		width: 9.8rem;
	}
	img.bg02 {
		left: 38rem;
		top: 4rem;
		width: 9.8rem;
	}
	.project-cover .outer {
		margin: 0 auto;
	}
}
</style>

<template>
  <ul class="p-share__list">
    <li class="p-share__item">
      <button
        :disabled="editMode"
        class="p-share__item-link"
        @click="toFacebookURL"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          class="p-share__item-img facebook"
          src="@/assets/image/facebook.png"
          alt="facebook"
        />
      </button>
    </li>
    <li class="p-share__item">
      <button
        :disabled="editMode"
        class="p-share__item-link"
        @click="toTwitterURL"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          class="p-share__item-img twitter"
          src="@/assets/image/twitter.png"
          alt="twitter"
        />
      </button>
    </li>
    <li class="p-share__item">
      <button
        :disabled="editMode"
        class="p-share__item-link"
        @click="toNoteURL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          class="p-share__item-img note"
          src="@/assets/image/note.png"
          alt="note"
        />
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SnsShare",
  components: {},
  props: {
    title: {
      type: String,
    },
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  setup() {},
  methods: {
    toFacebookURL() {
      location.href = this.facebookURL;
    },
    toTwitterURL() {
      location.href = this.twitterURL;
    },
    toNoteURL() {
      location.href = this.noteURL;
    },
  },
  computed: {
    url() {
      return location.href;
    },
    getTitle(props) {
      const snsTitle = encodeURIComponent(props.title);
      return snsTitle;
    },
    twitterURL() {
      return `https://twitter.com/intent/tweet?text=${this.getTitle}&url=${this.url}`;
    },
    facebookURL() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
    },
    noteURL() {
      return `https://note.com/intent/post?url=${this.url}`;
    },
  },
};
</script>

<style lang="scss">
.p-share {
	&__block {
		display: flex;
		@include sp {
			align-items: center;
			justify-content: space-between;
			margin-top: 10px;
			position: static;
		}
		position: absolute;
		right: -117px;
		top: 0;
	}
	&__list {
		display: flex;
		flex-direction: column;
		@include sp {
			flex-direction: initial;
			justify-content: flex-end;
		}
	}
	&__item {
		border-radius: 50%;
		height: 60px;
		@include sp {
			height: 30px;
			width: 30px;
		}
		width: 60px;
		&:nth-child(1) {
			background-color: #3c5a96;
		}
		&:nth-child(2) {
			background-color: #29a3ef;
			@include sp {
				margin-left: 10px;
				margin-top: 0;
			}
			margin-top: 15px;
		}
		&:nth-child(3) {
			background-color: #3ec8b3;
			@include sp {
				margin-left: 10px;
				margin-top: 0;
			}
			margin-top: 15px;
		}
		&-link {
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: space-evenly;
			width: 100%;
		}
		&-img {
			&.facebook {
				height: 27px;
				@include sp {
					height: 13px;
					width: 7px;
				}
				width: 15px;
			}
			&.twitter {
				height: 20px;
				@include sp {
					height: 10px;
					width: 12px;
				}
				width: 25px;
			}
			&.note {
				height: 26px;
				@include sp {
					height: 12px;
					width: 11px;
				}
				width: 23px;
			}
		}
	}
	&__text {
		font-size: 1.3rem;
		@include font_conqueror_400;
		@include sp {
			display: none;
		}
		margin-left: 20px;
		writing-mode: vertical-rl;
	}
}
</style>
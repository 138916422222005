<template>
  <div v-if="editMode" class="add-btn__wrapper">
    <button class="add-btn" @click="addtitleBtn" v-if="useTitle">大見出しを追加</button>
    <button class="add-btn" @click="addheadingBtn">見出しを追加</button>
    <button class="add-btn" @click="addtextBtn">本文を追加</button>
    <button class="add-btn" @click="addimageBtn">画像を追加</button>
    <button class="add-btn" @click="addyoutubeBtn">youtubeを追加</button>
  </div>
</template>
<script>
export default {
  name: "AddContentBtn",
  props: {
    handleClickAdd: {
      type: Function,
    },
    editMode: {
      type: Boolean,
    },
    useTitle: {
      type: Boolean
    }
  },
  setup(props) {
    const addtitleBtn = () => {
      const newListItem = {
        body: "",
        type: "title",
      };
      props.handleClickAdd(newListItem);
    };

    const addheadingBtn = () => {
      const newListItem = {
        body: "",
        type: "heading",
      };
      props.handleClickAdd(newListItem);
    };

    const addtextBtn = () => {
      const newListItem = {
        body: "",
        type: "body",
      };
      props.handleClickAdd(newListItem);
    };

    const addimageBtn = () => {
      const newListItem = {
        body: { key: "", thumbnail_url: "", url: "" },
        caption: "",
        type: "image",
      };
      props.handleClickAdd(newListItem);
    };
    const addyoutubeBtn = () => {
      const newListItem = {
        body: "",
        type: "youtube",
      };
      props.handleClickAdd(newListItem);
    };
    return { addtitleBtn, addtextBtn, addimageBtn, addheadingBtn, addyoutubeBtn };
  },
};
</script>
<style scoped>
.add-btn__wrapper {
	display: block;
}
</style>
<template>
  <div
      :class="[
      'temple-page',
      editMode && 'edit',
      viewControlAreaFlg && 'with-control-area',
    ]"
      v-if="isCurrentTempleSet"
  >
    <ControlArea
        :controlAreaObj="control"
        :editMode="editMode"
        :changeMode="changeMode"
        v-if="hasTempleAuth(setState.currentTemple.id) && !preview"
    />
    <div class="relative">
      <div class="container-temple">
        <div class="outer">
          <div class="sns sp-only">
            <div class="name-card">
              <div
                  class="photo"
                  :style="
                  'background-image: url(' +
                    setState.currentTemple.temple_website.priest_thumbnail
                      .thumbnail_url +
                    ')'
                "
              ></div>
              <div class="name-card-right">
                <p class="role">
                  {{
                    setState.currentTemple.temple_website.priest_position_name
                  }}
                </p>
                <p class="full-name">
                  {{ setState.currentTemple.temple_website.priest_name }}
                </p>
                <p class="en-name">
                  {{ setState.currentTemple.temple_website.priest_name_en }}
                </p>
              </div>
            </div>
            <div class="link">
              <SnsLink
                  :type="'facebook'"
                  v-model="setState.currentTemple.facebook_url"
                  :initial-link="initialState.currentTemple.facebook_url"
                  :editMode="editMode"
                  placeholder="寺院のFacebookページURL"
              />
              <SnsLink
                  :type="'twitter'"
                  v-model="setState.currentTemple.twitter_url"
                  :initial-link="initialState.currentTemple.twitter_url"
                  :editMode="editMode"
                  placeholder="寺院のTwitterアカウントURL"
              />
              <SnsLink
                  :type="'instagram'"
                  v-model="setState.currentTemple.instagram_url"
                  :initial-link="initialState.currentTemple.instagram_url"
                  :editMode="editMode"
                  placeholder="寺院のInstagramアカウントURL"
              />
              <SnsLink
                  :type="'line'"
                  v-model="setState.currentTemple.pintarest_url"
                  :initial-link="initialState.currentTemple.pintarest_url"
                  :editMode="editMode"
                  placeholder="寺院のLineアカウントURL"
              />
              <SnsLink
                  :type="'hp'"
                  v-model="setState.currentTemple.homepage_url"
                  :initial-link="initialState.currentTemple.homepage_url"
                  :editMode="editMode"
                  placeholder="寺院のホームページURL"
              />
            </div>
          </div>
          <div class="right">
            <div class="required_wrap copy-cover">
              <span v-if="editMode" class="required right">*</span>
              <Editable
                  v-model="setState.currentTemple.temple_website.catch_copy"
                  v-bind:initial-text="
                  initialState.currentTemple.temple_website.catch_copy
                "
                  tagName="p"
                  :initial-class="'catchcopy'"
                  v-bind:editMode="editMode"
                  placeholder="お寺のキャッチコピー"
              />
            </div>
            <div class="data">
              <div class="name">
                <div class="temple-name">
                  <p class="sub">{{ setState.currentTemple.sub_name }}</p>
                  <h1>{{ setState.currentTemple.name }}</h1>
                  <p class="en" v-if="setState.currentTemple.name_en">
                    {{ setState.currentTemple.name_en }}
                  </p>
                </div>
                <div class="locate">
                  <img src="@/assets/image/temples/icon-locate-r.svg"/>
                  <div class="address">
                    <p>
                      {{
                        setState.currentTemple.prefectures
                      }}{{ setState.currentTemple.municipality }}
                    </p>
                    <p class="en" v-if="setState.currentTemple.address_en">
                      {{ setState.currentTemple.address_en }}
                    </p>
                  </div>
                </div>
              </div>
              <!--name-->
              <div class="sns sp-none">
                <div class="name-card">
                  <div
                      class="photo"
                      :style="
                      'background-image: url(' +
                        setState.currentTemple.temple_website.priest_thumbnail
                          .thumbnail_url +
                        ')'
                    "
                  ></div>
                  <div class="name-card-right">
                    <p class="role">
                      {{
                        setState.currentTemple.temple_website
                            .priest_position_name
                      }}
                    </p>
                    <p class="full-name">
                      {{ setState.currentTemple.temple_website.priest_name }}
                    </p>
                    <p class="en-name">
                      {{ setState.currentTemple.temple_website.priest_name_en }}
                    </p>
                  </div>
                </div>
                <div class="link">
                  <SnsLink
                      :type="'facebook'"
                      v-model="setState.currentTemple.facebook_url"
                      :initial-link="initialState.currentTemple.facebook_url"
                      :editMode="editMode"
                      placeholder="寺院のFacebookアカウントURL"
                  />
                  <SnsLink
                      :type="'twitter'"
                      v-model="setState.currentTemple.twitter_url"
                      :initial-link="initialState.currentTemple.twitter_url"
                      :editMode="editMode"
                      placeholder="寺院のTwitterアカウントURL"
                  />
                  <SnsLink
                      :type="'instagram'"
                      v-model="setState.currentTemple.instagram_url"
                      :initial-link="initialState.currentTemple.instagram_url"
                      :editMode="editMode"
                      placeholder="寺院のInstagramアカウントURL"
                  />
                  <SnsLink
                      :type="'line'"
                      v-model="setState.currentTemple.pintarest_url"
                      :initial-link="initialState.currentTemple.pintarest_url"
                      :editMode="editMode"
                      placeholder="寺院のLineアカウントURL"
                  />
                  <SnsLink
                      :type="'hp'"
                      v-model="setState.currentTemple.homepage_url"
                      :initial-link="initialState.currentTemple.homepage_url"
                      :editMode="editMode"
                      placeholder="寺院のホームページURL"
                  />
                </div>
              </div>
              <!--name-->
            </div>
            <!--data-->
          </div>
          <!--right-->
          <div class="left">
            <Upload
                v-model="setState.currentTemple.temple_website.thumbnail_1"
                :initial-img="
                initialState.currentTemple.temple_website.thumbnail_1
              "
                :editMode="editMode"
            />
          </div>
        </div>
        <!--outer-->
      </div>
      <div class="bg-belt"></div>
      <div class="base-point">
        <img src="@/assets/image/top/bg-hamon.svg" class="hamon"/>
        <img src="@/assets/image/top/bg-uzu.svg" class="uzu"/>
      </div>
    </div>
    <nav>
      <ul class="bottom-nav">
        <li>
          <router-link :to="{ hash: '#project-list' }"
          >プロジェクト
          </router-link
          >
        </li>
        <li>
          <router-link :to="{ hash: '#event-list' }">イベント</router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#report-list' }">レポート</router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#about' }">お寺について</router-link>
        </li>
        <li
            v-if="setState.currentTemple.temple_website.priest_name || editMode"
        >
          <router-link :to="{ hash: '#person' }">ひと</router-link>
        </li>
        <li
            v-if="
            editMode || setState.currentTemple.temple_website['gallery_1'].url
          "
        >
          <router-link :to="{ hash: '#gallery' }">ギャラリー</router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#overview' }"
          >寺院概要・アクセス
          </router-link
          >
        </li>
      </ul>
      <a class="button" @click="followTemple(initialState.currentTemple.id)">
        <!--        <img-->
        <!--          src="@/assets/image/temples/bracket-left.svg"-->
        <!--          alt="bracket-left"-->
        <!--          class="sp-none"-->
        <!--        />-->
        <img
            src="@/assets/image/icon_heart_off_brown.svg"
            alt="heart"
            v-if="!following"
        />
        <img src="@/assets/image/icon_heart_on_brown.svg" alt="heart" v-else/>
        <p>
          <span class="sp-none">このお寺を</span><br class="sp-none"/>フォロー
        </p>
        <!--        <img-->
        <!--          src="@/assets/image/temples/bracket-right.svg"-->
        <!--          alt="bracket-right"-->
        <!--          class="sp-none"-->
        <!--        />-->
      </a>
    </nav>

    <div v-if="setState.currentTemple.group_flg === 1" class="notes">
      <div class="l-wrapper">
        <div
            class="container-notes"
            v-if="setState.currentTemple.temple_website.content.notes || editMode"
        >
          <Editable
              v-model="setState.currentTemple.temple_website.content.notes"
              :initial-text="
              initialState.currentTemple.temple_website.content.notes
            "
              tagName="p"
              v-bind:editMode="editMode"
              placeholder="コンテンツを入力してください"
              :editHtml="true"
          />
        </div>
        <div class="container-add-group" v-if="editMode">
          <div class="add-group">グループに寺院を追加</div>
          <GroupTempleIdInput
              :editMode="editMode"
              :customDelimiter="customDelimiter"
              v-model="
              setState.currentTemple.temple_website.content.groupTempleIds
            "
          />
        </div>
      </div>
    </div>

    <div id="project-list">
      <ProjectList :limit="3" :conditions="conditions" :edit-mode="editMode"/>
    </div>
    <div id="event-list">
      <EventList
          :conditions="eventConditions"
          :limit="9"
          id="event"
          initial-time-direction="future"
          :edit-mode="editMode"
      />
    </div>
    <div id="report-list">
      <ReportList
          :conditions="conditions"
          :limit="9"
          id="report"
          :edit-mode="editMode"
      />
    </div>

    <div class="container-article">
      <div class="article-title" id="about">
        <div class="article-image">
          <Upload
              v-model="setState.currentTemple.temple_website.content.thumbnail"
              :initial-img="
              initialState.currentTemple.temple_website.content.thumbnail
            "
              :editMode="editMode"
              v-if="
              setState.currentTemple.temple_website.content.thumbnail ||
                editMode
            "
          />
          <img
              v-else
              :src="setState.currentTemple.temple_website.thumbnail_1.url"
              class="fit"
          />
          <p class="catch">
            <Editable
                v-model="setState.currentTemple.temple_website.heading_text"
                v-bind:initial-text="
                initialState.currentTemple.temple_website.heading_text
              "
                tagName="span"
                v-bind:editMode="editMode"
                placeholder="お寺紹介文の見出しを入力してください"
            />
          </p>
          <p class="lead">
            <Editable
                v-model="setState.currentTemple.temple_website.sub_heading_text"
                v-bind:initial-text="
                initialState.currentTemple.temple_website.sub_heading_text
              "
                tagName="span"
                v-bind:editMode="editMode"
                placeholder="文中で特に強調したい言葉を入力してください"
            />
          </p>
        </div>
      </div>
      <template
          v-if="
          Object.keys(setState.currentTemple.temple_website.content).length ||
            editMode
        "
      >
        <div class="container-body">
          <template
              v-for="(list, index) in setState.currentTemple.temple_website
              .content.items"
              :key="list.hash"
          >
            <ContentBlock
                v-model="
                setState.currentTemple.temple_website.content.items[index]
              "
                :initial-item="
                getItemByHash(
                  initialState.currentTemple.temple_website.content.items,
                  list.hash
                )
              "
                :indexKey="index"
                :handle-click-delete="deleteContentBlock"
                :editMode="editMode"
                :class="'letter'"
            />
          </template>
          <AddContentBtn
              :handle-click-add="addContentBlock"
              :editMode="editMode"
          />
          <!--      <div class="introduce">-->
          <!--        <p>-->
          <!--          正榮山・妙海寺（しょうえいざん・みょうかいじ）は室町時代初期延文4年（1359）に日蓮宗の本山 茂原藻原寺 第五世 日海上人が、勝浦の出水に開創されたお寺です。<br>-->
          <!--          江戸時代明暦2年（1656）に領主植村土佐守が浄土宗のお寺香花院創設に際し建築の都合上、移転を余議なくされ慧妙院日心上人の（植村玄藩の父）甚労により新官に移転し現在にいたります。-->
          <!--        </p>-->
          <!--      </div>-->
        </div>
        <!--container-body-->
      </template>
      <div
          class="about-outer"
          v-if="
          setState.currentTemple.temple_website.priest_name ||
            setState.currentTemple.temple_website.free_name ||
            editMode
        "
          id="person"
      >
        <div class="bg-white"></div>
        <div class="relative">
          <h1 class="red-line-title">
            {{
              setState.currentTemple.name
            }}{{ initialState.currentTemple.group_flg === 0 ? 'の”ひと”' : '' }}
          </h1>
          <div class="about-body">
            <div class="required_wrap">
              <span v-if="editMode" class="required about-photo">*</span>
              <div class="about-photo">
                <Upload
                    v-model="
                    setState.currentTemple.temple_website.priest_thumbnail
                  "
                    :initial-img="
                    initialState.currentTemple.temple_website.priest_thumbnail
                  "
                    :editMode="editMode"
                />
              </div>
            </div>
            <div class="about-right">
              <div class="role">
                <div class="required_wrap">
                  <span v-if="editMode" class="required">*</span>
                  <Editable
                      v-model="
                      setState.currentTemple.temple_website.priest_position_name
                    "
                      :initial-text="
                      initialState.currentTemple.temple_website
                        .priest_position_name
                    "
                      tagName="span"
                      :initial-class="'inline-block'"
                      v-bind:editMode="editMode"
                      placeholder="役職名"
                  />
                </div>
                <div class="required_wrap">
                  <span v-if="editMode" class="required">*</span>
                  <Editable
                      v-model="setState.currentTemple.temple_website.priest_name"
                      :initial-text="
                      initialState.currentTemple.temple_website.priest_name
                    "
                      tagName="span"
                      :initial-class="'inline-block'"
                      v-bind:editMode="editMode"
                      placeholder="僧籍名"
                  />
                </div>
              </div>
              <div class="required_wrap">
                <span v-if="editMode" class="required">*</span>
                <p
                    class="role-en"
                    v-if="
                    setState.currentTemple.temple_website.priest_name_en ||
                      editMode
                  "
                >
                  <Editable
                      v-model="
                      setState.currentTemple.temple_website.priest_name_en
                    "
                      :initial-text="
                      initialState.currentTemple.temple_website.priest_name_en
                    "
                      tagName="span"
                      :initial-class="'inline-block'"
                      v-bind:editMode="editMode"
                      placeholder="僧籍名英語表記"
                  />
                </p>
              </div>
              <div class="link link_second">
                <SnsLink
                    :type="'facebook'"
                    v-model="
                    setState.currentTemple.temple_website.priest_facebook_url
                  "
                    :initial-link="
                    initialState.currentTemple.temple_website
                      .priest_facebook_url
                  "
                    :editMode="editMode"
                    placeholder="FacebookのアカウントURL"
                />
                <SnsLink
                    :type="'instagram'"
                    v-model="
                    setState.currentTemple.temple_website.priest_instagram_url
                  "
                    :initial-link="
                    initialState.currentTemple.temple_website
                      .priest_instagram_url
                  "
                    :editMode="editMode"
                    placeholder="InstagramのアカウントURL"
                />
              </div>
              <div class="required_wrap">
                <span v-if="editMode" class="required">*</span>
                <Editable
                    v-model="setState.currentTemple.temple_website.priest_profile"
                    :initial-text="
                    initialState.currentTemple.temple_website.priest_profile
                  "
                    :initial-class="'profile'"
                    tagName="p"
                    placeholder="プロフィールを入力してください"
                    v-bind:editMode="editMode"
                />
              </div>
            </div>
          </div>
          <!--about-body-->
          <div
              class="about-body"
              v-if="setState.currentTemple.temple_website.free_name || editMode"
          >
            <div class="about-photo">
              <Upload
                  v-model="setState.currentTemple.temple_website.free_thumbnail"
                  :initial-img="
                  initialState.currentTemple.temple_website.free_thumbnail
                "
                  :editMode="editMode"
              />
            </div>
            <div class="about-right">
              <p class="role">
                <Editable
                    v-model="setState.currentTemple.temple_website.position_name"
                    :initial-text="
                    initialState.currentTemple.temple_website.position_name
                  "
                    :initial-class="'inline-block'"
                    tagName="span"
                    v-bind:editMode="editMode"
                    placeholder="役職名"
                />

                <Editable
                    v-model="setState.currentTemple.temple_website.free_name"
                    :initial-text="
                    initialState.currentTemple.temple_website.free_name
                  "
                    :initial-class="'inline-block'"
                    tagName="span"
                    v-bind:editMode="editMode"
                    placeholder="担当者名"
                />
              </p>
              <p
                  class="role-en"
                  v-if="
                  setState.currentTemple.temple_website.free_name_en || editMode
                "
              >
                <Editable
                    v-model="setState.currentTemple.temple_website.free_name_en"
                    :initial-text="
                    initialState.currentTemple.temple_website.free_name_en
                  "
                    tagName="span"
                    :initial-class="'inline-block'"
                    v-bind:editMode="editMode"
                    placeholder="担当者英語表記"
                />
              </p>
              <div class="link link_second">
                <SnsLink
                    :type="'facebook'"
                    v-model="
                    setState.currentTemple.temple_website.free_facebook_url
                  "
                    :initial-link="
                    initialState.currentTemple.temple_website.free_facebook_url
                  "
                    :editMode="editMode"
                    placeholder="FacebookのアカウントURL"
                />
                <SnsLink
                    :type="'instagram'"
                    v-model="
                    setState.currentTemple.temple_website.free_instagram_url
                  "
                    :initial-link="
                    initialState.currentTemple.temple_website.free_instagram_url
                  "
                    :editMode="editMode"
                    placeholder="InstagramのアカウントURL"
                />
                <!--                <a><img src="@/assets/image/top/icon-insta.svg"></a>-->
                <!--                <a><img src="@/assets/image/temples/icon-line.svg"></a>-->
                <!--                <a><img src="@/assets/image/temples/icon-at.svg"></a>-->
              </div>
              <Editable
                  v-model="setState.currentTemple.temple_website.free_profile"
                  :initial-text="
                  initialState.currentTemple.temple_website.free_profile
                "
                  :initial-class="'profile'"
                  placeholder="プロフィールを入力してください"
                  tagName="p"
                  v-bind:editMode="editMode"
              />
            </div>
          </div>
          <!--about-body-->
        </div>
        <div
            class="greeting"
            v-if="
            setState.currentTemple.temple_website.priest_greeting || editMode
          "
        >
          <div class="greeting-outer">
            <h2>ごあいさつ</h2>
            <div class="grad-wrap">
              <input
                  id="trigger1"
                  class="grad-trigger sp-only"
                  type="checkbox"
              />
              <label class="grad-btn sp-only" for="trigger1">
                <p>Open</p>
                <img src="@/assets/image/header/button-plus.svg"/>
              </label>
              <Editable
                  v-model="setState.currentTemple.temple_website.priest_greeting"
                  :initial-text="
                  initialState.currentTemple.temple_website.priest_greeting
                "
                  :initial-class="'greeting-body'"
                  tagName="p"
                  placeholder="お寺の活動目的などを入力してください"
                  v-bind:editMode="editMode"
              />
              <!--          <p class="greeting-lead">～「寄り添う」お寺を目指して～</p>-->
            </div>
          </div>
        </div>
      </div>
      <!--about-outer-->
    </div>
    <div
        class="photo-garally"
        v-if="setState.currentTemple.temple_website['gallery_1'].url || editMode"
        id="gallery"
    >
      <h1 class="red-line-title">フォトギャラリー</h1>
      <template v-if="!editMode">
        <vueper-slides
            ref="vueperslides1"
            :touchable="false"
            fade
            arrows-outside
            :autoplay="false"
            :bullets="false"
            @slide="
            $refs.vueperslides2.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
            fixed-height="80rem"
            class="main-slide"
        >
          <vueper-slide
              v-for="i in 5"
              :key="i"
              :image="
              setState.currentTemple.temple_website['gallery_' + String(i)].url
            "
          />
          <template #arrow-left>
            <img
                src="@/assets/image/temples/arrow-left.svg"
                class="arrow-left"
            />
          </template>
          <template #arrow-right>
            <img
                src="@/assets/image/temples/arrow-right.svg"
                class="arrow-right"
            />
          </template>
        </vueper-slides>

        <vueper-slides
            class="no-shadow thumbnails"
            ref="vueperslides2"
            @slide="
            $refs.vueperslides1.goToSlide($event.currentSlide.index, {
              emit: false,
            })
          "
            :visible-slides="5"
            fixed-height="13rem"
            :bullets="false"
            :touchable="false"
            :gap="2.5"
            :arrows="false"
        >
          <vueper-slide
              v-for="i in 5"
              :key="i"
              :image="
              setState.currentTemple.temple_website['gallery_' + String(i)].url
            "
              @click="$refs.vueperslides2.goToSlide(i - 1)"
          />
        </vueper-slides>
      </template>
      <template v-else>
        <vueper-slides
            :touchable="false"
            fade
            arrows-outside
            :autoplay="false"
            :bullets="false"
            fixed-height="80rem"
            class="main-slide"
        >
          <vueper-slide
              v-for="i in 5"
              :key="i"
              :image="
              setState.currentTemple.temple_website['gallery_' + String(i)].url
            "
          />
          <template #arrow-left>
            <img
                src="@/assets/image/temples/arrow-left.svg"
                class="arrow-left"
            />
          </template>
          <template #arrow-right>
            <img
                src="@/assets/image/temples/arrow-right.svg"
                class="arrow-right"
            />
          </template>
        </vueper-slides>

        <ul class="gallery-uploads">
          <li v-for="index in 5" :key="index" class="gallery-upload">
            <Upload
                v-model="
                setState.currentTemple.temple_website[
                  'gallery_' + String(index)
                ]
              "
                :initial-img="
                initialState.currentTemple.temple_website[
                  'gallery_' + String(index)
                ]
              "
                :editMode="editMode"
            />
          </li>
        </ul>
      </template>
    </div>
    <div v-else class="space"></div>
    <div class="inquiry" id="overview">
      <p>お気軽にお問い合わせください</p>
      <div class="phone-number" v-if="setState.currentTemple.tel">
        <img src="@/assets/image/temples/icon-phone.svg"/>
        <Editable
            v-model="setState.currentTemple.tel"
            :initial-text="initialState.currentTemple.tel"
            tagName="p"
            v-bind:editMode="editMode"
            placeholder="お問い合わせ用の電話番号を入力してください"
        />
      </div>
      <a
          :href="'mailto:' + setState.currentTemple.temple_website.temple_mail"
          v-if="setState.currentTemple.temple_website.temple_mail"
      >
        <img src="@/assets/image/temples/icon-mail.svg"/>
        <p>メールでのお問い合わせ</p>
      </a>
    </div>
    <div class="access-outer">
      <div class="access-inner">
        <div class="access">
          <table>
            <tbody>
            <tr>
              <th>正式名称</th>
              <td>
                {{ setState.currentTemple.sub_name }}
                {{ setState.currentTemple.name }}
              </td>
            </tr>
            <tr>
              <th>
                {{
                  setState.currentTemple.temple_website.priest_position_name
                }}
              </th>
              <td>{{ setState.currentTemple.temple_website.priest_name }}</td>
            </tr>
            <tr v-if="setState.currentTemple.group_flg !== 1">
              <th>宗派</th>
              <td>{{ setState.currentTemple.sect_name }}</td>
            </tr>
            <tr>
              <th>住所</th>
              <td>
                〒{{ setState.currentTemple.postal_code }}
                {{
                  setState.currentTemple.prefectures
                }}{{
                  setState.currentTemple.municipality
                }}{{ setState.currentTemple.address }}
              </td>
            </tr>
            <tr v-if="setState.currentTemple.tel || editMode">
              <th>電話番号</th>
              <td>
                <Editable
                    v-model="setState.currentTemple.tel"
                    :initial-text="initialState.currentTemple.tel"
                    tagName="span"
                    placeholder="お問い合わせ用の電話番号を入力してください"
                    v-bind:editMode="editMode"
                />
              </td>
            </tr>
            <tr
                v-if="
                  setState.currentTemple.temple_website.temple_mail || editMode
                "
            >
              <th>メールアドレス</th>
              <td>
                <Editable
                    v-model="setState.currentTemple.temple_website.temple_mail"
                    v-if="editMode"
                    :initial-text="
                      initialState.currentTemple.temple_website.temple_mail
                    "
                    tagName="span"
                    placeholder="お問い合わせ用のメールアドレスを入力してください"
                    v-bind:editMode="editMode"
                />
                <a
                    :href="
                      `mailto:${setState.currentTemple.temple_website.temple_mail}`
                    "
                    class="email_btn"
                    v-if="
                      !editMode &&
                        setState.currentTemple.temple_website.temple_mail
                    "
                >お問い合わせ</a
                >
              </td>
            </tr>
            <tr v-if="setState.currentTemple.homepage_url || editMode">
              <th>ホームページ</th>
              <td>
                <Editable
                    v-model="setState.currentTemple.homepage_url"
                    :initial-text="initialState.currentTemple.homepage_url"
                    tagName="span"
                    placeholder="ホームページURLを入力してください"
                    v-bind:editMode="editMode"
                />
              </td>
            </tr>
            </tbody>
          </table>
          <div class="traffic">
            <h5>アクセス</h5>
            <ul>
              <li
                  v-if="
                  setState.currentTemple.temple_website.public_access ||
                    editMode
                "
              >
                <h6>
                  ●公共交通機関でお越しの場合<span
                    class="required-icon"
                    v-if="editMode"
                >※</span
                >
                </h6>
                <Editable
                    v-model="setState.currentTemple.temple_website.public_access"
                    :initial-text="
                    initialState.currentTemple.temple_website.public_access
                  "
                    placeholder="公共交通機関でのアクセス方法を入力してください"
                    tagName="p"
                    v-bind:editMode="editMode"
                />
              </li>
              <li
                  v-if="
                  setState.currentTemple.temple_website.car_access || editMode
                "
              >
                <h6>●お車でお越しの場合</h6>
                <Editable
                    v-model="setState.currentTemple.temple_website.car_access"
                    :initial-text="
                    initialState.currentTemple.temple_website.car_access
                  "
                    tagName="p"
                    placeholder="車でのアクセス方法を入力してください"
                    v-bind:editMode="editMode"
                />
              </li>
              <li
                  v-if="
                  setState.currentTemple.temple_website.other_access || editMode
                "
              >
                <h6>●その他</h6>
                <Editable
                    v-model="setState.currentTemple.temple_website.other_access"
                    :initial-text="
                    initialState.currentTemple.temple_website.other_access
                  "
                    placeholder="その他のアクセス方法を入力してください"
                    tagName="p"
                    v-bind:editMode="editMode"
                />
              </li>
              <li
                  v-if="
                  setState.currentTemple.temple_website.temple_parking ||
                    editMode
                "
              >
                <h6>●駐車場台数</h6>
                <Editable
                    v-model="setState.currentTemple.temple_website.temple_parking"
                    :initial-text="
                    initialState.currentTemple.temple_website.temple_parking
                  "
                    tagName="p"
                    placeholder="駐車可能台数を入力してください"
                    v-bind:editMode="editMode"
                />
              </li>
            </ul>
          </div>
        </div>
        <!--access-->
      </div>
    </div>
    <TempleList
        :limit="200"
        :conditions="{
        templeIds:
          initialState.currentTemple.temple_website.content.groupTempleIds,
      }"
        v-if="
        initialState.currentTemple.group_flg === 1 &&
          initialState.currentTemple.temple_website.content.groupTempleIds &&
          initialState.currentTemple.temple_website.content.groupTempleIds.length > 0
      "
        :decorate="false"
    />
  </div>
  <div v-if="error && error.code === 404">
    <p class="not-found">ページが見つかりませんでした</p>
  </div>
</template>

<script>
import ProjectList from '@/components/ProjectList.vue';
import EventList from '@/components/EventList.vue';
import ReportList from '@/components/ReportList.vue';
import Editable from '@/components/Editable.vue';
import Upload from '@/components/Upload.vue';
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import {useTemples} from '@/composables/useTemples';
import {useCommons} from '@/composables/useCommons';
import {useRoute, useRouter} from 'vue-router';
import {nl2br} from '@/utils/nl2br';
import ContentBlock from '@/components/ContentBlock.vue';
import {getItemByHash} from '@/utils/hash';
import AddContentBtn from '@/components/AddContentBtn.vue';
import ControlArea from '@/components/ControlArea.vue';
import GroupTempleIdInput from '@/components/GroupTempleIdInput.vue';
// import AddTempleIdsContentBtn from '@/components/AddTempleIdsContentBtn.vue';
import SnsLink from '../../components/SnsLink.vue';
import TempleList from '../../components/TempleList';
import {
  onBeforeMount,
  ref,
  inject,
  onUpdated,
  watch,
  computed,
  onMounted,
} from 'vue';

export default {
  name: 'Temple',
  props: {
    domain: {
      type: String,
    },
  },
  components: {
    ProjectList,
    EventList,
    ReportList,
    VueperSlides,
    VueperSlide,
    ContentBlock,
    AddContentBtn,
    ControlArea,
    Editable,
    Upload,
    SnsLink,
    // AddTempleIdsContentBtn,
    GroupTempleIdInput,
    TempleList,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {id, domain} = route.params;
    let noArticleAuthFlg = ref(false);

    const preview = ref(route.path.indexOf('preview') !== -1);
    const {
      setState,
      initialState,
      getCurrentTemple,
      isCurrentTempleSet,
      error,
      deleteContentBlock,
      addContentBlock,
      control,
      resetCurrentTemple,
    } = useTemples(id, domain);
    const {user, hasTempleAuth, followTemple, isFollowing} = inject('auth');
    const {viewControlAreaFlg, modeChangeFlg} = inject('commons');

    const following = computed(() => {
      if (isCurrentTempleSet.value) {
        return isFollowing(initialState.currentTemple.id);
      } else {
        return false;
      }
    });

    const {openModal, toNext} = useCommons();

    const editMode = ref(false);

    const conditions = {
      templeId: id && Number(id),
      templeDomain: domain,
    };

    const eventConditions = {
      templeId: id && Number(id),
      templeDomain: domain,
      merge: true,
    };

    const changeMode = () => {
      editMode.value = !editMode.value;
      modeChangeFlg.value = true;
    };

    console.log('!!!editMode.value', editMode.value);
    onBeforeMount(() => {
      editMode.value = route.path.indexOf('edit') !== -1;
    });

    getCurrentTemple();

    onUpdated(() => {
      preview.value = route.path.indexOf('preview') !== -1;
    });

    watch(
        () => route.params.id,
        (newParams, oldParams) => {
          console.log(`${oldParams} -> ${newParams}`);
          newParams && getCurrentTemple(newParams);
        }
    );

    onMounted(() => {
      watch(
          () => setState.currentTemple.name,
          (newTitle) => {
            newTitle &&
            (document.title = `まち寺プラットフォーム｜${setState.currentTemple.name}`);
          }
      );

      if (!hasTempleAuth(setState.currentTemple.id)) {
        const target = 'edit';
        if (route.path.indexOf(target) > 0) {
          noArticleAuthFlg.value = true;
          changeMode();
          const newPath = route.path.replace(target, '');
          router.push({
            path: newPath,
          });
        }
      } else {
        viewControlAreaFlg.value = true;
      }
    });

    const customDelimiter = ref([',', ' ']);

    return {
      user,
      setState,
      initialState,
      getItemByHash,
      isCurrentTempleSet,
      deleteContentBlock,
      addContentBlock,
      error,
      hasTempleAuth,
      changeMode,
      eventConditions,
      conditions,
      editMode,
      nl2br,
      preview,
      control,
      openModal,
      toNext,
      resetCurrentTemple,
      route,
      followTemple,
      following,
      noArticleAuthFlg,
      viewControlAreaFlg,
      modeChangeFlg,
      getCurrentTemple,
      customDelimiter,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.route.path.indexOf('edit') === -1 || this.noArticleAuthFlg) {
      next();
    } else {
      if (this.editMode) {
        const modalItems = {
          title: 'このページを離れますか？',
          text: '行った変更が保存されない可能性があります。',
          btnType: 'cancel&submit&go',
          submitBtnText: 'このページを離れる',
          function: this.resetCurrentTemple,
        };
        this.openModal(modalItems);
        this.$watch(
            'toNext',
            function (newVal, oldVal) {
              console.log(`newVal:${newVal} -> oldVal:${oldVal}`);
              if (newVal) {
                next();
              } else {
                return;
              }
            },
            {
              deep: true,
              immediate: true,
            }
        );
      } else {
        next();
      }
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.temple-page {
  @include pc {
    &.with-control-area {
      padding-top: 0;
    }
  }
  padding-top: 12rem;
}
</style>
<style lang="scss" scoped>
.sp-only {
  display: none;
}

.relative {
  overflow-x: hidden;
  position: relative;
}

.container-temple {
  padding: 2rem 10rem 11rem 10rem;
  width: 100%;
}

::v-deep .catchcopy,
.name,
.address {
  writing-mode: vertical-rl;
}

.outer,
.right,
.name,
.link {
  display: flex;
}

.outer {
  flex-direction: row-reverse;
  justify-content: center;
  margin-top: 3rem;
  position: relative;
  z-index: 9;
}

.right {
  flex-direction: row-reverse;
  margin-top: 2rem;
}

.name {
  flex-direction: column;
}

::v-deep .catchcopy,
.sub,
.temple-name {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
}

.en,
.en-name {
  font-family: 'aw-conqueror-didot', serif;
}

.copy-cover {
  // margin-left: 7rem;
  writing-mode: vertical-rl;
}

::v-deep .catchcopy {
  color: #c84d5f;
  font-size: 4rem;
  letter-spacing: 0.8rem;
  line-height: 6rem;
  margin-left: 7rem;
  position: relative;
}

::v-deep .catchcopy::after {
  bottom: 17rem;
  content: url('~@/assets/image/temples/nami-tate.svg');
  left: -6rem;
  position: absolute;
}

.data {
  margin-top: 6.3rem;
}

.sub {
  font-size: 3.5rem;
  letter-spacing: 0.4rem;
  margin-left: 3.2rem;
}

.temple-name {
  margin-left: 7.7rem;
  position: relative;
}

.temple-name h1 {
  font-size: 7.5rem;
  letter-spacing: 0.5rem;
  margin-left: 2.5rem;
}

.temple-name .en {
  font-size: 2.5rem;
  letter-spacing: 0.5rem;
}

.temple-name::after {
  background-color: #c84d5f;
  content: '';
  height: 18rem;
  left: -4.8rem;
  position: absolute;
  top: 0;
  width: 1rem;
}

.locate {
  align-items: center;
  display: flex;
}

.locate img {
  height: 3.8rem;
  width: 2.8rem;
}

.address {
  font-size: 2.5rem;
  letter-spacing: 0.4rem;
  margin-top: 1.5rem;
}

.address .en {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  margin-right: 1.5rem;
}

.sns {
  margin-top: 7.5rem;

  .edit & {
    max-width: 40rem;
  }
}

.name-card {
  display: flex;
  justify-content: center;
}

.name-card-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.name-card .photo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 7rem;
  margin-right: 1.4rem;
  width: 7rem;
}

.name-card .role {
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
}

.name-card .full-name {
  font-size: 2.5rem;
  letter-spacing: 0.4rem;
}

.name-card .en-name {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
}

.link {
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  .edit & {
    flex-wrap: wrap;
    margin-right: 0.8rem;
  }
}

.link a {
  margin-right: 2.5rem;
}

.link a:last-child {
  margin-right: 0;
}

.left {
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 35rem 0 35rem 0;
  height: 70rem;
  margin-right: 5.5rem;
  overflow: hidden;
  max-width: 105rem;
  width: 100%;
}

.bg-belt {
  background-image: url('~@/assets/image/temples/bg-pattern-nami.png');
  background-repeat: repeat;
  bottom: 4.5rem;
  height: 28rem;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.base-point {
  height: 0;
  margin: auto;
  position: relative;
  width: 0;
}

.hamon,
.uzu {
  max-width: initial;
  position: absolute;
  z-index: 3;
}

.hamon {
  bottom: 61rem;
  height: 22.7rem;
  left: 61rem;
  width: 25.5rem;
}

.uzu {
  bottom: 0;
  height: 35.7rem;
  right: 58rem;
  width: 37rem;
}

nav,
.bottom-nav,
.button {
  align-items: center;
  display: flex;
}

nav {
  font-size: 1.5rem;
  justify-content: center;
  letter-spacing: 0.15rem;
  padding-bottom: 6rem;
}

.bottom-nav > * {
  position: relative;
}

.bottom-nav a {
  margin-left: 1.6rem;
  padding: 0.5rem 1.8rem;
}

.bottom-nav li:last-child {
  margin-right: 14.8rem;
}

.bottom-nav li::after {
  content: '／';
  position: absolute;
}

.bottom-nav li:last-child::after {
  display: none;
}

img[alt='bracket-left'] {
  margin-right: 2.5rem;
}

img[alt='bracket-right'] {
  margin-left: 2.5rem;
}

img[alt='hand'] {
  width: 3.5rem;
}

img[alt='heart'] {
  width: 3.5rem;
}

.button p {
  font-family: 'iroha-23kaede-stdn', sans-serif;
  margin-left: 1.3rem;
  white-space: nowrap;
}

.email_btn {
  background-color: #be6c4c;
  border: none;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  font-family: dnp-shuei-mincho-pr6, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
}

/* ------------------------- */
/* ------ bottom part ------ */
/* ------------------------- */

.article-title {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
  padding-bottom: 8rem;
  padding-top: 13rem;
  position: relative;
}

.article-image {
  background-position: center center;
  background-repeat: no-repeat;
  height: 73.5rem;
  margin: auto;
  max-width: 110rem;
  position: relative;
}

.catch,
.lead {
  position: absolute;
  writing-mode: vertical-rl;
}

.catch {
  color: #c84d5f;
  font-size: 5.5rem;
  height: 70rem;
  letter-spacing: 2rem;
  line-height: 1.5;
  position: absolute;
  right: -5rem;
  top: -4rem;
}

::v-deep .catch span {
  background: linear-gradient(to left, transparent 0%, #fff 0%);
  padding: 0 0.5rem;
}

.lead {
  height: 45rem;
  left: -5rem;
  position: absolute;
  top: 35rem;
}

::v-deep .lead span {
  background: linear-gradient(to left, transparent 0%, #c84d5f 0%);
  color: #fff;
  font-size: 2.2rem;
  letter-spacing: 1rem;
  line-height: 2.2;
  padding: 0 0.5rem 0;
}

.container-body {
  margin: auto;
  margin-bottom: 15.5rem;
  max-width: 100rem;
}

.notes {
  background-color: #f7f6f4;
}

.container-notes {
  margin: auto;
  // max-width: 100rem;
  padding: 15.5rem 0;
}

.container-add-group {
  padding: 3rem 0;
}

.add-group {
  text-align: center;
  display: block;
  font-family: '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴ Pro',
  'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'MS Pゴシック', 'MS PGothic',
  sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.letter {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
  line-height: 3.5rem;
  /* width: 67.5rem; */
  margin-bottom: 5rem;
}

.introduce {
  background-color: #f0ede8;
  padding: 4.7rem 6.2rem;
}

.introduce p {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  line-height: 3rem;
}

.about-outer {
  background-image: url('~@/assets/image/temples/bg-pattern-nami.png');
  background-repeat: repeat;
  padding-bottom: 11rem;
  position: relative;
  width: 100%;
}

.bg-white {
  background-color: #fff;
  height: 22rem;
  position: absolute;
  top: 5rem;
  width: 100%;
}

.red-line-title {
  background: #fff;
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
  font-size: 5.5rem;
  letter-spacing: 0.7rem;
  position: relative;
  text-align: center;
}

.red-line-title::after {
  background-color: #c84d5f;
  bottom: -3rem;
  content: '';
  height: 0.5rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 10rem;
}

.about-outer .red-line-title {
  margin-bottom: 12rem;
}

.about-body {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 120rem;
}

.about-body + .about-body {
  margin-top: 5rem;
}

.about-photo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 30rem;
  margin-right: 5.7rem;
  width: 40rem;
}

.about-right .link {
  justify-content: flex-start;
  margin-bottom: 3.6rem;
}

.about-right {
  width: 74rem;
}

.about-right .role {
  display: flex;
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
  font-size: 3.5rem;
  letter-spacing: 0.4rem;
  margin-bottom: 2.2rem;
}

.about-right .role-en {
  font-family: 'aw-conqueror-didot', serif;
  font-size: 1.8rem;
  letter-spacing: 0.12rem;
}

::v-deep .inline-block {
  display: inline-block;
  margin-right: 2rem;
}

::v-deep .profile,
::v-deep .greeting-body {
  font-size: 1.5rem;
  line-height: 2;
  min-height: 10rem;
  text-align: left;
}

.greeting {
  margin-top: 8.5rem;
}

.greeting-outer {
  background-color: #fff;
  margin: auto;
  max-width: 120rem;
  padding: 6rem 10rem;
  text-align: center;
}

.greeting h2,
.greeting-lead {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
  margin-bottom: 4.5rem;
}

.greeting h2 {
  font-size: 4.5rem;
  letter-spacing: 0.5rem;
}

.greeting-lead {
  color: #c84d5f;
  font-size: 2.5rem;
}

.photo-garally {
  padding: 12.5rem 10rem 11.5rem;
}

/* ---- Vueper Slides ---- */

.main-slide {
  margin: 10rem auto 5rem auto;
  max-width: 120rem;
}

.thumbnails {
  margin: auto;
  max-width: 120rem;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease-in-out;
}

.thumbnails .vueperslide--active {
  border-color: #000;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

/* .vueperslide--visible{
  margin-right: 2.8%;
} */
.arrow-left,
.arrow-right {
  height: 6rem;
  width: 6rem;
}

.vueperslides__arrows--outside .vueperslides__arrow--prev {
  left: -5.5em;
}

.vueperslides__arrows--outside .vueperslides__arrow--next {
  right: -5.5em;
}

/* ---------------------- */

.inquiry {
  background-image: url('~@/assets/image/temples/bg-pattern-nami.png');
  background-repeat: repeat;
  padding: 6rem 4rem;
  width: 100%;
}

.inquiry,
.phone-number,
.inquiry a {
  align-items: center;
  display: flex;
  justify-content: center;
}

.inquiry p {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
}

.inquiry > p {
  font-size: 1.8rem;
  margin-right: 7rem;
}

::v-deep .inquiry .phone-number p {
  font-family: 'aw-conqueror-didot', serif;
  font-size: 5rem;
  letter-spacing: 0.3rem;
  margin-right: 6.7rem;
}

.phone-number img {
  height: 2.9rem;
  margin-right: 2rem;
  width: 2.9rem;
}

.inquiry a {
  background-color: #fff;
  border: 1px black solid;
  border-radius: 4rem;
  padding: 3rem 6rem;
}

.inquiry a img {
  height: 1.6rem;
  margin-right: 2.2rem;
  width: 2.4rem;
}

.inquiry a p {
  font-size: 2rem;
  letter-spacing: 0.3rem;
}

.access-outer {
  background-color: #fafafa;
  padding: 10rem 4rem;
}

.access-inner {
  margin: auto;
  max-width: 120rem;
}

.access {
  align-items: flex-start;
  display: flex;
}

.access table,
.traffic {
  flex: 1 1 50%;
}

.access table,
.traffic h5 {
  font-family: 'dnp-shuei-mincho-pr6', sans-serif;
}

.access table {
  /* min-width: 40rem; */
  margin-right: 8.5rem;
}

.access tr {
  border-top: 1px solid black;
  font-size: 1.8rem;
  height: 8rem;
}

.access tr:last-child {
  border-bottom: 1px solid black;
}

.access th,
.access td {
  line-height: 1.5;
  padding: 3rem 5rem 3rem 0;
  text-align: left;
}

/* .access td{
  padding-right: 10rem;
} */
.traffic:not(h5) {
  font-size: 1.5rem;
  line-height: 2;
}

.traffic li {
  margin-bottom: 5rem;
}

.traffic li:last-child {
  margin-bottom: 0;
}

.traffic h5 {
  font-size: 1.8rem;
  line-height: 1;
  margin-bottom: 2.8rem;
}

.traffic .margin {
  display: inline-block;
  margin-top: 4.6rem;
}

.gallery-uploads {
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5rem;
}

.gallery-upload {
  height: 15rem;
  margin: 0 0.5rem 1rem;
  position: relative;
  width: 22rem;
}

.fit {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.space {
  height: 2rem;
}

.not-found {
  font-family: $contents_fonts_jp_iroha;
  font-size: 2.2rem;
  padding: 25rem 0 18rem;
  text-align: center;
  vertical-align: middle;
}

.child-nav {
  display: flex;
}

.child-nav p {
  margin: 0 20px;
  padding-top: 25px;
  white-space: nowrap;
  z-index: 1000;
}

.required_wrap {
  position: relative;
}

.required {
  color: #c84d5f;
  display: inline-block;
  font-size: 30px;
  left: -15px;
  position: absolute;
  top: 0;
}

.required.right {
  color: #c84d5f;
  display: inline-block;
  font-size: 30px;
  left: auto;
  position: absolute;
  right: -15px;
  top: 0;
}

.required.about-photo {
  color: #c84d5f;
  display: inline-block;
  font-size: 30px;
  left: 45px;
  position: absolute;
  right: auto;
  top: 0;
}

/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
  .edit nav .button {
    display: none;
  }
  .sp-none {
    display: none;
  }
  .sp-only {
    display: block;
  }
  .temple-page {
    padding-top: 7rem;

    &.with-control-area {
      padding-top: 0;
    }
  }
  .container-temple {
    padding: 2rem 3rem 4rem 3rem;
  }
  .outer {
    flex-direction: column-reverse;
    margin-top: 0;
  }
  .left {
    background-size: cover;
    border-radius: 8rem 0 8rem 0;
    height: auto;
    width: 100%;
  }
  .right {
    justify-content: center;
    margin-top: 0;
  }
  .copy-cover {
    flex: 10%;
  }
  ::v-deep .catchcopy {
    font-size: 1.8rem;
    letter-spacing: 0.4rem;
    line-height: 3rem;
    margin-left: 0;
    margin-top: 2rem;
  }
  ::v-deep .catchcopy::after {
    bottom: -3rem;
    left: -3.5rem;
    transform: scale(0.7);
  }
  .sub {
    font-size: 2rem;
    margin-left: 1.6rem;
  }
  .data {
    flex: 1 1 70%;
    margin-top: 2rem;
  }
  .name {
    margin: auto;
  }
  .temple-name {
    margin-left: 6rem;
  }
  .temple-name h1 {
    font-size: 3.2rem;
  }
  .temple-name .en {
    font-size: 1.5rem;
  }
  .temple-name::after {
    height: 9rem;
    left: -3rem;
    width: 0.5rem;
  }
  .address {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .hamon {
    bottom: 28rem;
    left: 5rem;
    width: 12.7rem;
  }
  .uzu {
    bottom: 2rem;
    right: 8rem;
    width: 18.5rem;
  }
  .locate img {
    height: 2.4rem;
    width: 1.75rem;
  }
  .bg-belt {
    bottom: 2.5rem;
    height: 13rem;
  }
  .sns.sp-only {
    margin-top: 1.5rem;
    text-align: center;
  }
  .name-card {
    display: inline-flex;
    justify-content: center;
    text-align: left;
  }
  .name-card .photo {
    height: 5rem;
    margin-right: 1rem;
    width: 5rem;
  }
  .name-card .role {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  .name-card .full-name {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
  }
  .name-card .en-name {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  .link {
    margin-top: 2rem;
  }
  .link a {
    margin-right: 1.8rem;
    width: 2rem;
  }
  nav {
    display: block;
    padding-bottom: 2rem;
  }
  .bottom-nav {
    flex-wrap: nowrap;
    height: 2.5rem;
    overflow-x: scroll;
  }
  .bottom-nav a {
    font-size: 1rem;
    margin-left: 1rem;
    padding: 0.5rem 1.2rem;
  }
  .bottom-nav::-webkit-scrollbar {
    display: none;
  }
  .bottom-nav > * {
    white-space: nowrap;
  }
  .bottom-nav li:last-child {
    margin-right: 0;
  }
  .bottom-nav li::after {
    font-size: 1rem;
    top: 0.4rem;
  }
  .button {
    background-color: #fff;
    border-radius: 3rem 0 0 3rem;
    bottom: 8rem;
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.1);
    flex-direction: column-reverse;
    padding: 1.7rem 2rem 1.7rem 2.5rem;
    /* width: 15rem; */
    /* height: 17rem; */
    position: fixed;
    right: 0;
    z-index: 50;
  }
  .button p {
    line-height: 1.5;
    margin-bottom: 0.7rem;
    margin-left: 0;
  }
  img[alt='hand'] {
    width: 5rem;
  }
  // .catch, .lead {
  //  top: auto;
  //   height: 40rem;
  // }
  .catch {
    // bottom: initial;
    // right: 15%;
    font-size: 1.75rem;
    height: 30rem;
    letter-spacing: 0.8rem;
    line-height: 1.8;
    right: 0;
    top: -1rem;
  }
  .lead {
    height: 25rem;
    left: 0;
    // bottom: initial;
    // left: 25%;
    top: 1rem;
  }
  // .catch, .lead{
  //   top: 12rem;
  // }
  ::v-deep .lead span {
    font-size: 1.3rem;
  }
  .article-title {
    padding-bottom: 0;
    padding-top: 0;
  }
  .article-image {
    background-size: cover;
    height: 26rem;
    width: 100%;
  }
  .container-body {
    margin: 0 3rem;
  }
  .letter {
    font-size: 1.3rem;
  }
  ::v-deep .body {
    min-height: 0;
  }
  .red-line-title {
    font-size: 3rem;
    letter-spacing: 0.4rem;
  }
  .red-line-title::after {
    bottom: -2rem;
    height: 0.25rem;
    width: 5rem;
  }
  .bg-white {
    height: 14rem;
    top: 2rem;
  }
  .about-photo {
    height: 15rem;
    margin-right: 0;
    width: 20rem;
  }
  .about-outer .red-line-title {
    margin-bottom: 6rem;
  }
  .about-outer {
    padding-bottom: 4rem;
  }
  .introduce {
    padding: 3.5rem 4rem;
  }
  .about-body {
    align-items: center;
    flex-direction: column;
  }
  .about-right {
    margin-top: 2rem;
    text-align: center;
    width: 85%;
  }
  .about-right .role {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }
  .about-right .role div:last-child {
    flex: 1 1 50%;
  }
  .about-right .link {
    justify-content: center;
    margin-bottom: 1.8rem;
    margin-top: 1.2rem;
  }
  ::v-deep .profile,
  ::v-deep .greeting-body {
    font-size: 1.1rem;
  }
  ::v-deep .greeting {
    margin: 3rem;
  }
  .greeting-outer {
    padding: 3rem;
  }
  .greeting h2 {
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }
  ::v-deep .greeting-body {
    height: 35rem; /*隠した状態の高さ*/
    overflow: hidden;
    position: relative;
  }
  ::v-deep .greeting-body::before {
    bottom: 0;
    /*height: 40px; グラデーションで隠す高さ*/
    content: '';
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }
  ::v-deep .grad-trigger {
    display: none; /*チェックボックスは常に非表示*/
  }
  ::v-deep .grad-trigger:checked + .grad-btn {
    display: none; /*チェックされていたら、grad-btnを非表示にする*/
  }
  ::v-deep .grad-trigger:checked ~ .greeting-body {
    height: auto; /*チェックされていたら、高さを戻す*/
  }
  ::v-deep .grad-trigger:checked ~ .greeting-body::before {
    display: none; /*チェックされていたら、grad-itemのbeforeを非表示にする*/
  }
  .grad-btn {
    align-items: center;
    background: #fff;
    bottom: 4rem;
    color: #000;
    cursor: pointer;
    display: flex;
    font-family: 'aw-conqueror-didot', serif;
    font-size: 1.3rem;
    justify-content: center;
    left: 0;
    letter-spacing: 0.2rem;
    margin: 0 3rem;
    padding: 1.5rem 0;
    position: absolute;
    right: 0;
    text-align: center;
    transition: 0.2s ease;
    z-index: 2;
  }
  .grad-btn img {
    height: 1.3rem;
    margin-left: 1rem;
    width: 1.3rem;
  }
  .grad-btn::before {
    background-color: #000;
    content: '';
    height: 0.5px;
    left: 3rem;
    position: absolute;
    top: 0;
    width: calc(100% - 6rem);
  }
  .photo-garally {
    padding: 6rem 3rem 8rem 3rem;
  }
  .main-slide {
    height: 22rem !important;
    margin-bottom: 2rem;
    margin-top: 5rem;
  }
  .thumbnails {
    height: 4rem !important;
  }
  .inquiry {
    flex-direction: column;
    padding: 3rem;
  }
  .inquiry > p {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
  .phone-number img {
    height: 1.5rem;
    margin-right: 1rem;
    width: 1.5rem;
  }
  ::v-deep .inquiry .phone-number p {
    font-size: 2.1rem;
    margin-right: 0;
  }
  .phone-number {
    background-color: #fff;
    border: 1px black solid;
    border-radius: 5rem;
    margin-bottom: 1.5rem;
    padding: 1.6rem 4rem;
  }
  .inquiry a {
    padding: 2rem 3rem;
  }
  .inquiry a img {
    height: 1.1rem;
    margin-right: 1rem;
    width: 1.7rem;
  }
  .inquiry a p {
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
  }
  .arrow-left,
  .arrow-right {
    height: 3rem;
    width: 3rem;
  }
  .access-outer {
    padding: 4rem 3rem;
  }
  .access {
    flex-direction: column;
  }
  .access table {
    margin-right: 0;
    max-width: initial;
    width: 100%;
  }
  .access tr {
    font-size: 1.3rem;
    height: auto;
  }
  .access th,
  .access td {
    padding: 2rem 0.5rem;
  }
  .access th {
    min-width: 7rem;
  }
  .traffic {
    margin-top: 4em;
  }
  .traffic h5 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  .traffic li {
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }
  .traffic .margin {
    margin-top: 2.5rem;
  }
  ::v-deep .fit {
    height: inherit;
  }
  .about-photo ::v-deep .fit {
    height: inherit;
  }
  .space {
    height: 0;
  }
}
</style>

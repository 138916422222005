<template>
  <div v-if="decorate" class="top sp-only relative">
    <p class="sub">Machi-Tera <span>E</span>vent</p>
    <img src="@/assets/image/event/line-ribbon02.svg" class="title-line" />
    <div class="bg-pattern">
      <img src="@/assets/image/event/bg-shippo-l-color.svg" class="bg01" />
    </div>
  </div>
  <div :class="['container', decorate && 'decorate']">
    <div class="outer" v-if="decorate">
      <div class="bg-pattern">
        <img
          src="@/assets/image/event/bg-shippo-l-color.svg"
          class="bg01 sp-none"
        />
        <img src="@/assets/image/event/bg-shippo-l-color.svg" class="bg02" />
        <img
          src="@/assets/image/event/bg-shippo-l-color.svg"
          class="bg03 sp-none"
        />
      </div>
      <p class="vertical-index sp-none">Event</p>
      <h1>
        <p class="sub sp-none">Machi-Tera <span>E</span>vent</p>
        <img src="@/assets/image/event/line-ribbon02.svg" class="sp-none" />
        <p class="title">イベント</p>
      </h1>
      <p class="lead" v-html="lead" />
    </div>
    <section class="p-related-articles" v-else>
      <h2 class="p-related-articles__title">
        <span class="p-related-articles__title--ja">イベント一覧</span>
        <span class="p-related-articles__title--en"
          ><span class="color-text">E</span>vent</span
        >
      </h2>
    </section>

    <template v-if="!fetching">
      <div class="flex" v-if="state.articles.length > 0">
        <EventItem
          v-for="(item, index) in state.articles"
          :key="index"
          :item="item"
          :merged="conditions.merge"
          :timeDirection="timeDirection"
          :editMode="editMode"
        />
      </div>
      <p v-else class="no-list">該当記事はありません</p>

<!--
      <div v-if="editMode" class="add-btn__wrapper">
        <a href="/articles/create?type=event" class="add-btn">イベントを追加</a>
      </div>
-->
    </template>
    <Fetching v-else />

    <LinkButton link="/search?target=event" v-if="linkAll" />
    <Pagination
      v-else
      :on-click-page="handleClickPage"
      :total="pagination.total"
      :current="pagination.current"
      :perPage="pagination.perPage"
    />
    <LinkButton
      :onClick="changeTimeDirection"
      :label="
        timeDirection === 'future' ? '過去イベントを見る' : '開催予定イベント'
      "
      v-if="!linkAll"
    />
  </div>
</template>

<script>
import EventItem from '@/components/EventItem.vue';
import LinkButton from '@/components/LinkButton.vue';
import { useArticles } from '@/composables/useArticles';
import Pagination from '@/components/Pagination';
import Fetching from '@/components/Fetching';
import { watch, ref } from 'vue';
export default {
  name: 'EventList',
  props: {
    lead: {
      type: String,
    },
    limit: {
      type: [String, Number],
    },
    conditions: {
      type: Object,
    },
    decorate: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
    },
    initialTimeDirection: {
      type: String,
      default: 'future',
    },
    linkAll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EventItem,
    LinkButton,
    Pagination,
    Fetching,
  },
  setup(props) {
    const { getArticles, state, pagination, fetching } = useArticles();
    const timeDirection = ref(props.initialTimeDirection);
    const conditions = {
      ...props.conditions,
      sort: 'EventDetails.date',
      direction: timeDirection.value === 'future' ? 'ASC' : 'DESC',
      timeDirection: timeDirection.value,
    };
    getArticles(2, props.limit, conditions);

    watch(
      () => props.conditions,
      (to, from) => {
        if (to !== from) {
          const conditions = {
            ...props.conditions,
            sort: 'EventDetails.date',
            direction: timeDirection.value === 'future' ? 'ASC' : 'DESC',
            timeDirection: timeDirection.value,
          };
          getArticles(2, props.limit, conditions);
        }
      }
    );

    watch(
      () => state.articles,
      (to, from) => {
        if (to !== from) {
          if (to.length === 0 && timeDirection.value === 'future') {
            timeDirection.value = 'past';
            const conditions = {
              ...props.conditions,
              sort: 'EventDetails.date',
              direction: timeDirection.value === 'future' ? 'ASC' : 'DESC',
              timeDirection: timeDirection.value,
            };
            getArticles(2, props.limit, conditions);
          }
        }
      }
    );

    const changeTimeDirection = () => {
      timeDirection.value =
        timeDirection.value === 'future' ? 'past' : 'future';
      const conditions = {
        ...props.conditions,
        sort: 'EventDetails.date',
        direction: timeDirection.value === 'future' ? 'ASC' : 'DESC',
        timeDirection: timeDirection.value,
      };
      getArticles(2, props.limit, conditions);
    };

    const handleClickPage = (number) => {
      const conditions = {
        ...props.conditions,
        sort: 'EventDetails.date',
        direction: timeDirection.value === 'future' ? 'ASC' : 'DESC',
        timeDirection: timeDirection.value,
      };
      getArticles(
        2,
        props.limit,
        Object.assign({}, conditions, { page: number })
      );
    };

    return {
      state,
      handleClickPage,
      pagination,
      fetching,
      changeTimeDirection,
      timeDirection,
    };
  },
};
</script>
<style lang="scss" scoped>
.relative {
	position: relative;
}
.sp-only {
	display: none;
}
.flex {
	display: flex;
	flex-wrap: wrap;
	@include tablet {
		justify-content: space-around;
	}
	@include between {
		justify-content: space-around;
	}
	justify-content: space-between;
	margin: 0 auto;
	max-width: 120rem;


	&::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 35.5rem;
	}
}
.container {
	padding: 5rem 2rem;
	width: 100%;
}
.decorate {
	background-color: #f5f4f2;
	overflow-x: hidden;
	padding: 9.2rem 5rem 1rem 5rem;
}
.outer {
	margin: auto;
	position: relative;
}
.bg-pattern {
	height: 0;
	margin: auto;
	position: relative;
	width: 0;
}
img.bg01, img.bg02, img.bg03 {
	max-width: initial;
	position: absolute;
	width: 16.5rem;
}
.bg01 {
	bottom: -17rem;
	right: 58rem;
}
.bg02 {
	left: 18rem;
	top: 20rem;
}
.bg03 {
	left: 58rem;
	top: -5rem;
}
.vertical-index {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.5rem;
	letter-spacing: .6rem;
	position: absolute;
	right: 4rem;
	top: -4rem;
	writing-mode: vertical-rl;
}
.vertical-index:before {
	background-color: #000;
	content: "";
	height: .2rem;
	position: absolute;
	right: -.3rem;
	top: -1.7rem;
	width: 1.5rem;
}
h1 {
	margin: auto;
	max-width: 120rem;
	text-align: center;
	z-index: 30;
}
.sub {
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.6rem;
	letter-spacing: 1.12rem;
	margin-bottom: 2rem;
}
.sub span {
	color: #be6c4c;
}
.title {
	color: #412814;
	font-family: "iroha-23kaede-stdn", sans-serif;
	font-size: 5.2rem;
	letter-spacing: 1.62rem;
	margin-top: 2.4rem;
}
.lead {
	font-family: dnp-shuei-mincho-pr6, sans-serif;
	font-size: 1.3rem;
	letter-spacing: .52px;
	line-height: 3rem;
	margin-bottom: 4.1rem;
	margin-top: 3.5rem;
	text-align: center;
}
.link-button {
	margin-top: 5.5rem;
}
.add-btn__wrapper {
	text-align: center;
}
.p-related-articles {
	&__title {
		&::before {
			background-color: #dc9163;
		}
	}
}

.no-list {
	letter-spacing: .2rem;
	text-align: center;
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
	.sp-none {
		display: none;
	}
	.sp-only {
		display: block;
	}
	.container {
		padding: 0 3rem 2rem 3rem;
	}
	.top {
		background-color: #f5f4f2;
	}
	.title-line {
		background-color: #f5f4f2;
		position: relative;
		z-index: 9;
	}
	.sp-only .sub {
		background-color: #f5f4f2;
		font-size: .95rem;
		letter-spacing: .7rem;
		margin-bottom: 0;
		padding-bottom: 1.6rem;
		padding-top: 7rem;
		text-align: center;
		z-index: 9;
	}
	img.bg01 {
		bottom: -2rem;
		right: 10rem;
		width: 13rem;
		z-index: 1;
	}
	img.bg02 {
		left: 11rem;
		top: 0;
		width: 13rem;
		z-index: 1;
	}
	.title {
		font-size: 3rem;
		letter-spacing: 1rem;
		margin-top: 1.2rem;
	}
	.lead {
		font-size: 1.3rem;
		line-height: 3rem;
		margin-bottom: 2rem;
		margin-top: 1.7rem;
		position: relative;
		text-align: left;
		z-index: 9;
	}
	.flex {
		flex-wrap: nowrap;
		overflow-x: scroll;
    justify-content: space-between;
	}
	.flex::-webkit-scrollbar {
		display: none;
	}
	.link-button {
		margin-top: 2rem;
		width: 20rem;
	}
}
</style>

<template>
  <div
    class="article__content"
    :class="viewControlAreaFlg ? 'with-control-area' : ''"
  >
    <div v-if="isCurrentArticleSet" :class="editMode ? 'edit' : ''">
      <ControlArea
        :controlAreaObj="
          hasTempleAuth(state.currentArticle.temple_id)
            ? templeControl
            : control
        "
        :editMode="editMode"
        :changeMode="changeMode"
        v-if="
          hasArticleAuth(
            state.currentArticle.id,
            state.currentArticle.temple_id
          )
        "
      />
      <div class="l-report">
        <div class="p-pagetype">
          <span class="p-pagetype__jp">まち寺レポート</span
          ><span class="p-pagetype__en">Machi-Tera Report</span>
        </div>
        <section>
          <div class="p-mv">
            <div class="l-wrapper p-mv__block">
              <Upload
                :error-state="error.thumbnail ? error.thumbnail : ''"
                v-model="setState.currentArticle.thumbnail"
                v-bind:initial-img="initialState.currentArticle.thumbnail"
                :editMode="editMode"
              />
              <div class="p-share__block">
                <div class="required_wrap u-use--sp">
                  <span v-if="editMode" class="required">*</span>
                  <CategorySelect
                    class="p-cat u-use--sp"
                    v-if="!catState.loading"
                    v-model="setState.currentArticle.category_id"
                    :editMode="editMode"
                  />
                </div>
                <SnsShare
                  :title="initialState.currentArticle.title"
                  :editMode="editMode"
                />
                <p class="p-share__text">Share on</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="l-wrapper">
            <div class="required_wrap u-use--pc">
              <span v-if="editMode" class="required">*</span>
              <CategorySelect
                class="p-cat u-use--pc"
                v-if="!catState.loading"
                v-model="setState.currentArticle.category_id"
                :editMode="editMode"
              />
            </div>
            <div class="p-date">
              {{ moment(state.currentArticle.modified_at, 'YYYY.MM.DD') }} 更新
            </div>
            <h1 class="p-title">
              <Editable
                :error-state="error.subtitle ? error.subtitle : ''"
                v-model="setState.currentArticle.subtitle"
                v-bind:initial-text="initialState.currentArticle.subtitle"
                :initial-class="'p-title__sub'"
                tagName="span"
                v-bind:editMode="editMode"
                placeholder="サブタイトルを入力してください"
              />
              <div class="required_wrap">
                <span v-if="editMode" class="required">*</span>
                <Editable
                  :error-state="error.title ? error.title : ''"
                  v-model="setState.currentArticle.title"
                  v-bind:initial-text="initialState.currentArticle.title"
                  :initial-class="'p-title__main'"
                  tagName="span"
                  v-bind:editMode="editMode"
                  placeholder="タイトルを入力してください"
                />
              </div>
            </h1>
            <template
              v-if="
                editMode || getProfile(state.currentArticle.profile_content)
              "
            >
              <div class="p-author">
                <IconBox
                  v-if="getProfile(state.currentArticle.profile_content)"
                  :imageSrc="
                    getThumbnailUrl(
                      getProfile(state.currentArticle.profile_content).thumbnail
                    )
                  "
                  :name="getProfile(state.currentArticle.profile_content).name"
                  :detail="
                    getProfile(state.currentArticle.profile_content).title
                  "
                  :isNonClip="false"
                  :is-single="true"
                  :link="true"
                  path="#person"
                />
              </div>
            </template>
            <TagInput
              :error-state="error.tags ? error.tags : ''"
              tagBgColor="#e8e2d7"
              tagTextColor="black"
              :customDelimiter="customDelimiter"
              v-model="setState.currentArticle.tags"
              tagsClass="report"
              :editMode="editMode"
            />
          </div>
        </section>
        <section>
          <div class="p-contents">
            <div class="p-contents__block l-wrapper">
              <p
                class="p-contents__summary"
                v-if="setState.currentArticle.content.summary"
              >
                {{ setState.currentArticle.content.summary }}
              </p>
              <template
                v-for="(list, index) in setState.currentArticle.content.items"
                :key="list.hash"
              >
                <ContentBlock
                  v-model="setState.currentArticle.content.items[index]"
                  :initial-item="
                    getItemByHash(
                      initialState.currentArticle.content.items,
                      list.hash
                    )
                  "
                  :indexKey="index"
                  :handle-click-delete="deleteContentBlock"
                  :editMode="editMode"
                  :useTitle="true"
                />
              </template>
              <AddContentBtn
                :handle-click-add="addContentBlock"
                :editMode="editMode"
                :useTitle="true"
              />
            </div>
          </div>
        </section>
        <section
          v-if="editMode || getProfile(state.currentArticle.profile_content)"
        >
          <div class="p-outline l-wrapper" id="person">
            <h2 class="p-outline__title">
              執筆者情報<span class="notice" v-if="editMode"
                >一番上の人物のサムネイルが、まち寺トップページなどに表示されます</span
              >
            </h2>

            <div
              v-for="(list, index) in setState.currentArticle.profile_content
                .items"
              :key="list.hash"
            >
              <ProfileContentBlock
                v-model="setState.currentArticle.profile_content.items[index]"
                :initial-item="
                  getItemByHash(
                    initialState.currentArticle.profile_content.items,
                    list.hash
                  )
                "
                :indexKey="index"
                :handle-click-delete="deleteProfileContentBlock"
                :editMode="editMode"
                :articleType="'report'"
              />
            </div>

            <AddProfileContentBtn
              :handle-click-add="addProfileContentBlock"
              :editMode="editMode"
            />
          </div>
        </section>
        <section>
          <div class="p-article-group l-wrapper">
            <ArticleGroup
              :error-state="error.article_group ? error.article_group : ''"
              v-model:article-group-id="
                setState.currentArticle.article_group_id
              "
              v-model:article-group="setState.currentArticle.article_group"
              :editMode="editMode"
              :articles-groups="setState.articlesGroups"
              :temple-id="setState.currentArticle.temple_id"
            />
          </div>
        </section>
        <ArticleRelatedList
          v-if="setState.currentArticle.article_group_id"
          :limit="6"
          :color="'green'"
          :conditions="{
            article_group_id: setState.currentArticle.article_group_id,
          }"
          :route-params-id="route.params.id"
          :editMode="editMode"
          :article-id="state.currentArticle.id"
        />
        <section>
          <div class="p-action">
            <ul class="p-action_wrap">
              <div class="l-wrapper p-action_block">
                <FollowBtn
                  :temple-id="state.currentArticle.temple_id"
                  :name="state.currentArticle.temple.name"
                  :editMode="editMode"
                />
                <!-- <li class="p-action_item">
                <a class="p-action_link" href="#"
                  ><span class="p-action_link-text">支援する</span></a
                >
              </li> -->
              </div>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { useArticles } from '@/composables/useArticles.js';
import { useCommons } from '@/composables/useCommons';
import Editable from '@/components/Editable.vue';
import Upload from '@/components/Upload';
import TagInput from '@/components/TagInput.vue';
import CategorySelect from '@/components/CategorySelect.vue';
import ArticleGroup from '@/components/ArticleGroup.vue';
import SnsShare from '@/components/SnsShare.vue';
import ContentBlock from '@/components/ContentBlock.vue';
import AddContentBtn from '@/components/AddContentBtn.vue';
import ControlArea from '@/components/ControlArea.vue';
import ArticleRelatedList from '@/components/ArticleRelatedList';
import ProfileContentBlock from '@/components/ProfileContentBlock.vue';
import AddProfileContentBtn from '@/components/AddProfileContentBtn.vue';
import { getProfile } from '@/utils/profile';
import { getThumbnailUrl } from '@/utils/helper';
import IconBox from '@/components/IconBox';
import FollowBtn from '@/components/FollowBtn';
import { inject, watch, onMounted, ref } from 'vue';

export default {
  name: 'Report',
  data() {
    return {
      customDelimiter: [',', ' '],
      editMode: false,
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let noArticleAuthFlg = ref(false);

    const { id } = route.params;
    // let editMode = ref(false);
    const { openModal, toNext, catState } = useCommons();
    let { routerPush } = useCommons();
    const { viewControlAreaFlg, modeChangeFlg } = inject('commons');
    const {
      state,
      setState,
      isCurrentArticleSet,
      error,
      initialState,
      getCurrentArticle,
      resetCurrentArticle,
      deleteProfileContentBlock,
      addProfileContentBlock,
      addContentBlock,
      deleteContentBlock,
      getItemByHash,
      control,
      templeControl,
    } = useArticles(id);

    //記事取得
    getCurrentArticle();

    const { followTemple, user, hasArticleAuth, hasTempleAuth } = inject(
      'auth'
    );

    watch(
      () => route.params.id,
      (newParams, oldParams) => {
        console.log(`${oldParams} -> ${newParams}`);
        newParams && getCurrentArticle(newParams);
      }
    );

    onMounted(() => {
      watch(
        () => setState.currentArticle.title,
        (newTitle) => {
          newTitle &&
            (document.title = `まち寺プラットフォーム｜${setState.currentArticle.title}`);
        }
      );

      if (
        !hasArticleAuth(state.currentArticle.id, state.currentArticle.temple_id)
      ) {
        const target = 'edit';
        if (route.path.indexOf(target) > 0) {
          noArticleAuthFlg.value = true;
          const newPath = route.path.replace(target, '');
          router.push({
            path: newPath,
          });
        }
      } else {
        viewControlAreaFlg.value = true;
      }
    });

    return {
      state,
      setState,
      isCurrentArticleSet,
      error,
      initialState,
      resetCurrentArticle,
      deleteProfileContentBlock,
      addProfileContentBlock,
      addContentBlock,
      deleteContentBlock,
      getItemByHash,
      control,
      templeControl,
      getProfile,
      getThumbnailUrl,
      hasArticleAuth,
      hasTempleAuth,
      user,
      followTemple,
      route,
      openModal,
      toNext,
      routerPush,
      catState,
      noArticleAuthFlg,
      viewControlAreaFlg,
      modeChangeFlg,
    };
  },
  created() {
    if (this.$route.path.indexOf('edit') !== -1) {
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
  mounted() {
    if (this.noArticleAuthFlg) {
      this.editMode = false;
    }
  },
  methods: {
    moment(date, format) {
      moment.locale('en');
      return moment(date).format(format);
    },
    changeMode() {
      this.editMode = !this.editMode;
      this.modeChangeFlg = true;
    },
  },
  components: {
    Editable,
    Upload,
    TagInput,
    CategorySelect,
    ArticleGroup,
    SnsShare,
    ContentBlock,
    AddContentBtn,
    ControlArea,
    ArticleRelatedList,
    ProfileContentBlock,
    AddProfileContentBtn,
    IconBox,
    FollowBtn,
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.routerPush && !this.noArticleAuthFlg) {
      const modalItems = {
        title: 'このページを離れますか？',
        text: '行った変更が保存されない可能性があります。',
        btnType: 'cancel&submit&go',
        submitBtnText: 'このページを離れる',
        function: this.resetCurrentArticle,
      };
      this.openModal(modalItems);
      this.$watch(
        'toNext',
        function(newVal, oldVal) {
          console.log(`newVal:${newVal} -> oldVal:${oldVal}`);
          if (newVal) {
            next();
          } else {
            return;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
    } else {
      this.routerPush = false;
      next();
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.l-report {
	& .p-pagetype {
		& .p-pagetype__jp {
			background-color: #9eaf3d;
		}

		& .p-pagetype__en {
			background-color: #749e1d;
		}
	}

	& .p-cat {
		background-color: #3fbcac;
	}

	::v-deep .p-tag__item {
		background-color: #e5e7dc;
	}

	::v-deep .p-contents__title {
		border-bottom: 2px solid #749e1d;
		color: #749e1d;
		line-height: 1.5;
		position: relative;
	}

	& .p-author__block {
		@include sp {
			width: 100%;
		}
	}

	& .p-location__btn {
		background-color: #749e1d;
	}

	& .p-author__company-sub {
		display: block;
	}

	.p-action_block {
		background-image: none;
	}
	.p-action_wrap {
		background-image: none;
	}
}
.p-outline {
	border-bottom: 1px solid #000;
	@include sp {
		margin: 48px auto;
	}
	margin: 75px auto;
}

.p-outline__title {
	color: #749e1d;
	font-family: dnp-shuei-mincho-pr6, sans-serif;
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 400;
	@include sp {
		font-family: $contents_fonts_jp_Gothic;
		font-weight: bold;
	}
	margin-bottom: 30px;
}
.notice {
	font-size: 1.3rem;
	margin-left: 2rem;
}
</style>

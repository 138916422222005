<template>
  <template v-if="total">
    <ul>
      <li><a v-if="current !== 1" @click="onClickPage(current - 1)">Previous</a></li>
      <li><a v-if="total > 2" @click="onClickPage(1)" :class="[current === 1 && 'current']">1</a></li>
      <li v-for="(number, index) in totalComputed" :key="number" :class="[(index === 0 && number !== 2) && 'before-dot', (index === totalComputed.length - 1 && number !== total - 1) && 'after-dot']">
        <a @click="onClickPage(number)"
           :class="[current === number && 'current']">{{ number }}</a>
      </li>
      <li><a v-if="total > 2" @click="onClickPage(total)" :class="[current === total && 'current']">{{ total }}</a></li>
      <li><a v-if="current !== total" @click="onClickPage(current + 1)">Next</a></li>
    </ul>
  </template>
</template>
<script>
import {computed} from "vue";

export default {
  name: 'Pagination',
  props: {
    current: Number,
    total: Number,
    perPage: Number,
    onClickPage: Function
  },
  setup(props) {
    const totalComputed = computed(() => {
      if(props.total > 2){
        const numbers = [...Array(props.total - 2).keys()].map(i => i + 2)
        if (props.total < 11) {
          return numbers;
        } else {
          if (props.current < 5){
            return numbers.slice(0, 8)
          }else if (props.total - props.current < 5){
            return numbers.slice(-8)
          }else{
            return numbers.filter(number => Math.abs(props.current - number) < 5)
          }
        }
      }else{
        return []
      }
    });
    return {
      totalComputed
    }
  }
}
</script>
<style lang="scss" scoped>
ul {
  display: flex;
  justify-content: center;
  padding: 3rem;
  flex-wrap: wrap;
}

ul li {
  padding: 1rem 0;
  white-space: nowrap;
  &.before-dot:before{
    content: '...';
  }
  &.after-dot:after{
    content: '...';
  }
}

a {
  display: inline-block;
  font-size: 2.2rem;
  letter-spacing: 0.11rem;
  color: #000;
  font-family: $contents_fonts_jp_conqueror;
  margin: 0 1rem;
  cursor: pointer;
}

.current {
  font-weight: bold;
}
</style>
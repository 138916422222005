<template>
  <div class="wrap">
    <router-link :to="'/articles/report/'+item.id">
      <template v-if="item.thumbnail">
        <div class="thumbnail" :style="'background-image: url(' + getThumbnailUrl(item.thumbnail) + ')'">
          <Label
              v-if="item.category_id"
              :id="item.category_id"
              :category="item.category.name"
              color="blue-green"
          />
          <Like :temple-id="item.temple_id"/>
        </div>
      </template>
      <template v-else>
        <div class="thumbnail">
          <Label
              v-if="item.category_id"
              :id="item.category_id"
              :category="item.category.name"
              color="green"
          />
          <Like :temple-id="item.temple_id"/>
        </div>
      </template>
    </router-link>
    <div>
      <router-link :to="'/articles/report/'+item.id">
        <div class="time-stamp">
          <time :datetime="item.modified_at">{{ moment(item.modified_at, "YYYY/MM/DD ddd") }}</time>
        </div>
        <p class="caption">{{ item.subtitle }}</p>
        <h1 v-html="nl2br(item.title)"/>
      </router-link>
      <div class="icon-box">
        <IconBox
            v-if="getProfile(item.profile_content)"
            :imageSrc="getThumbnailUrl(getProfile(item.profile_content).thumbnail)"
            role="執筆者"
            :name="getProfile(item.profile_content).name"
            :detail="getProfile(item.profile_content).title"
            :isNonClip="false"
            :link="true"
            :path="'/articles/report/'+item.id+'/#person'"
            color="green"
        />
        <IconBox
            v-else
            :id="item.temple_id"
            :imageSrc="item.temple.thumbnail && item.temple.thumbnail.thumbnail_url"
            role="執筆者"
            :name="item.temple.sub_name + item.temple.name"
            :detail="item.temple.prefectures + item.temple.municipality"
            :isNonClip="false"
            :link="true"
            :path="'/temples/'+item.temple_id"
            color="green"
        />
      </div>
      <ul class="tags">
        <li v-for="tag in item.tags" :key="tag.id" class="orange">
          <Tag :tagName="tag.name" :id="tag.id"/>
        </li>
      </ul>

      <ActionButton
          v-if="editMode"
          label="編集・管理"
          className="edit-link"
          :path="'/articles/report/' + item.id + '/edit'"
      />
    </div>
  </div>
</template>
<script>
import IconBox from '@/components/IconBox.vue'
import Tag from "@/components/Tag";
import Label from "@/components/Label";
import Like from "@/components/Like";
import ActionButton from "./ActionButton";
import moment from "moment";
import {getProfile} from "@/utils/profile";
import {getThumbnailUrl} from "@/utils/helper";
import {nl2br} from "../utils/nl2br";

export default {
  name: 'ReportItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    }
  },
  components: {
    IconBox,
    Tag,
    Label,
    Like,
    ActionButton
  },
  setup(){
    return {
      getProfile,
      getThumbnailUrl,
      nl2br
    }
  },
  data() {
    return {
      tags: [
        {id: 1, tagName: "クラウドファウンディング"},
        {id: 2, tagName: "旅するおむすび屋"},
      ]
    }
  },
  methods: {
    moment(date, format) {
      moment.locale("en");
      if (date) {
        return moment(date).format(format);
      }
    }
  },
}
</script>
<style scoped>
.wrap {
	margin-bottom: 3rem;
	width: 35rem;
}
.thumbnail {
	background-color: #7ba960;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 22rem;
	position: relative;
	width: 35rem;
}

.time-stamp {
	color: #749e1d;
	font-family: "aw-conqueror-didot", serif;
	font-size: 1.1rem;
	letter-spacing: .11rem;
	margin-bottom: 1.4rem;
	margin-top: 2.8rem;
}

.caption, h1 {
	font-family: "dnp-shuei-mincho-pr6", sans-serif;
}

.caption {
	font-size: 1.5rem;
	letter-spacing: .15rem;
	margin-bottom: 1.7rem;
}

h1 {
	font-size: 2.5rem;
	letter-spacing: .25rem;
	line-height: 3rem;
	margin-bottom: 3rem;
	width: 35rem;
}

.icon-box {
	margin-bottom: 2.6rem;
	margin-top: 2rem;
	width: 35rem;
}

.icon-box > a {
	flex-wrap: nowrap;
	margin-right: 0;
}

.tags li {
	display: inline-block;
	margin-bottom: .8rem;
	margin-right: .8rem;
}
@media only screen and (max-width:768px) {
	.wrap .thumbnail {
		height: 17.5rem;
		width: 25rem;
		z-index: 9;
	}
	h1 {
		font-size: 1.9rem;
		line-height: 1.3;
		margin-bottom: 1rem;
		width: auto;
	}
	.caption {
		font-size: 1.1rem;
		margin-bottom: 1rem;
		margin-top: 0;
	}
	.date {
		font-size: 1rem;
	}
	.time-stamp {
		font-size: .9rem;
		margin-bottom: .8rem;
		margin-top: 1.8rem;
	}
	.wrap {
		margin-left: 1rem;
		margin-right: 3rem;
		width: 25rem;
	}
}
</style>
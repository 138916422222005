<template>
  <section class="p-related-articles" :class="color">
    <h2 class="p-related-articles__title">
      <span class="p-related-articles__title--ja">関連記事</span>
      <span class="p-related-articles__title--en"
        >Related <span class="color-text">A</span>rticles</span
      >
    </h2>
    <template v-if="!fetching">
      <div class="flex" v-if="state.articles.filter((article) => Number(article.id) !== Number(articleId)
          ).length > 0">
        <template
          v-for="(item, index) in state.articles.filter(
            (article) => Number(article.id) !== Number(articleId)
          )"
          :key="index"
        >
          <ProjectItem v-if="item.article_type === 1" :item="item" />
          <EventItem v-if="item.article_type === 2" :item="item" />
          <ReportItem v-else-if="item.article_type === 3" :item="item" />
        </template>
      </div>
      <p v-else>該当記事はありません</p>
    </template>
    <Fetching v-else />
  </section>
</template>

<script>
import EventItem from '@/components/EventItem.vue';
import { useArticles } from '@/composables/useArticles';
import { watch } from 'vue';
import ReportItem from './ReportItem';
import ProjectItem from './ProjectItem';
import Fetching from './Fetching.vue';

export default {
  name: 'ArticleRelatedList',
  props: {
    limit: {
      type: Number,
    },
    conditions: {
      type: Object,
    },
    editMode: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    articleId: {
      type: String,
    },
  },
  components: {
    ReportItem,
    EventItem,
    ProjectItem,
    Fetching,
  },
  setup(props) {
    const { getArticles, state, fetching } = useArticles();
    getArticles([1, 2, 3], props.limit, props.conditions);
    watch(
      () => props.conditions.article_group_id,
      (to, from) => {
        if (to !== from) {
          getArticles([1, 2, 3], props.limit, props.conditions);
        }
      }
    );

    return {
      state,
      fetching,
    };
  },
};
</script>
<style lang="scss">
.p-related-articles {
	@include sp {
		margin: 60px auto;

		.container & {
			padding: 0;
		}
	}
	margin: 80px auto;
	max-width: 120rem;
	padding: 0 2.5rem;

	&__title {
		@include sp {
			padding-left: 1.6rem;
		}
		margin-bottom: 4rem;
		padding-left: 2rem;
		position: relative;

		&::before {
			background-color: #6877ac;
			content: "";
			display: inline-block;
			height: 7rem;
			@include sp {
				height: 5rem;
				width: .25rem;
			}
			left: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: .5rem;
		}

		&--ja {
			display: block;
			font-size: 2.5rem;
			@include font_iroha_600;
			@include sp {
				font-size: 1.7rem;
				letter-spacing: 3.2px;
				margin-bottom: 1.5rem;
			}
			letter-spacing: 7.5px;
			margin-bottom: 25px;
		}
		&--en {
			display: block;
			font-size: 1.4rem;
			@include font_conqueror_400;
			@include sp {
				font-size: 1.1rem;
				letter-spacing: 8.4px;
			}
			letter-spacing: 9.8px;
		}
	}
}
</style>
<style lang="scss" scoped>
.flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 120rem;
	&::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 35.5rem;
	}
}

.color-text {
	.orange & {
		color: #dc9163;
	}

	.green & {
		color: #749e1d;
	}

	.blue & {
		color: #6a7bb7;
	}
}
.p-related-articles {
	&__title {
		&::before {
			.orange & {
				background-color: #dc9163;
			}

			.green & {
				background-color: #749e1d;
			}

			.blue & {
				background-color: #6a7bb7;
			}
		}
	}
}
</style>

<template>
  <p :class="[color,'category']">{{ category }}</p>
</template>
<script>
export default {
  name: 'Label',
  props: {
    id: Number, 
    category: String
  },
  setup(props){
    let color = ""
    switch (props.id){
      case 1:
        color = "yellow"
        break
      case 2:
        color = "navy"
        break
      case 3:
        color = "green"
        break
      case 4:
        color = "orange"
        break
      case 5:
        color = "red"
        break
      case 7:
        color = "blue-green"
        break
      case 9:
        color = "light-blue"
        break
      case 6:
        color = "purple"
        break
      default:
        color = "light-blue"
        break
    }

    return {
      color
    }
  }
}
</script>
<style scoped>
p{
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.03rem;
    font-family: "iroha-23kaede-stdn", sans-serif;
    color: #fff;
    padding: 0.7rem 0.4rem;
    background-color: #DC9163;
    display: inline-block;
    position: absolute;
    left: -1rem;
    top: 1rem;
}
.green{
    background-color: #7BA960;
}
.purple{
    background-color: #9D7BAB;
}
.yellow{
    background-color: #C3A83D;
}
.orange{
    background-color: #DC9163;
}
.red{
    background-color: #DF645E;
}
.blue-green{
    background-color: #3FBCAC;
}
.navy{
    background-color: #6078A9;
}
.light-blue{
    background-color: #68B4CD;
}
@media only screen and (max-width:768px){
    p{
        font-size: 1rem;
        padding: 0.4rem 0.3rem;
        left: -0.5rem;
        top: 0.5rem;
    }
}

</style>
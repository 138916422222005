<template>
  <router-view></router-view>
</template>

<script>
// import {useTemples} from "@/composables/useTemples"
// import {useRoute} from "vue-router"
// import ControlArea from "@/components/ControlArea.vue";
// import {onBeforeMount, ref, inject, onUpdated} from "vue";

export default {
  name: 'TempleRoot',
  props: {
    domain: {
      type: String
    }
  },
  components: {
    // ControlArea,
  },
  setup() {
    return {
    }

  },
}
</script>
import { ref, reactive, toRefs } from 'vue';

// 管理したいステートを定義（グローバル）
const catState = reactive({
  categories: {},
  tags: {},
  loading:false,
});

const loading = ref(null);
const postLoading = ref(false);

let showModal = ref(null);
let modalObj = toRefs(reactive(null));
let toNext = ref(false);
let routerPush =ref(false);

const viewControlAreaFlg = ref(false)
const modeChangeFlg = ref(false)
const pageTopFlg = ref(false)

export const useCommons = () => {

  const error = ref(null)

  //カテゴリー
  const getCategories = async () => {
    try {
      catState.loading = true;
      let data = await fetch('/api/categories');
      if (!data.ok) {
        throw Error("No data available");
      }
      const categories = await data.json()
      catState.categories = categories.data;
      catState.loading = false;
    } catch (err) {
      error.value = err.message;
      catState.loading = false;
      console.log(error.value);
    }
  };

  //タグ
  const getTags = async () => {
    try {
      let data = await fetch('/api/tags/popular');
      if (!data.ok) {
        throw Error("No data available");
      }
      const tags = await data.json()
      catState.tags = tags.data;
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };

  //モダール
  const openModal = (obj) => {
    // console.log('openModal----obj', obj)
    showModal.value = true;
    modalObj.value = obj;

    return toNext.value;
  }

  const closeModal = (str) => {
    showModal.value = false;
    toNext.value= str ;
    return toNext.value;
  }

  return {
    catState,
    loading,
    postLoading,
    showModal,
    modalObj,
    toNext,
    getCategories,
    getTags,
    openModal,
    closeModal,
    routerPush,
    viewControlAreaFlg,
    modeChangeFlg,
    pageTopFlg
  }
}


<template>
  <header :class="['header', viewControlAreaFlg && 'with-control-area']">
    <div>
      <div class="left" v-click-outside="onClickOutsideGnav">
        <!--ハンバーガーメニューのボタン-->
        <div class="hamburger-btn" @click="activeBtn = !activeBtn">
          <img
            v-show="!activeBtn"
            src="@/assets/image/header/button-navmenu.svg"
          />
          <img
            v-show="activeBtn"
            src="@/assets/image/header/button-close.svg"
          />
        </div>
        <!--サイドバー-->
        <transition name="accordion-menu">
          <div class="accordion-menu" v-show="activeBtn">
            <ul class="menu-list">
              <li class="borderd-list" @click="activeBtn = false">
                <router-link class="list-link" to="/about"
                >まち寺プラットフォームとは</router-link
                >
              </li>
              <li class="borderd-list" @click="activeBtn = false">
                <router-link
                  class="list-link small"
                  to="/search?target=project&limit=12"
                  >お寺のソーシャルプロジェクトを探す</router-link
                >
              </li>
              <li
                class="borderd-list indent"
                @click="categoryList = !categoryList"
              >
                <div class="flex">
                  <p class="list-link">分野から探す</p>
                  <div class="plus-btn">
                    <img src="@/assets/image/header/button-plus.svg" />
                  </div>
                </div>
                <ul class="categories" v-show="categoryList">
                  <li
                    v-for="category in catState.categories"
                    :key="category.id"
                  >
                    <Category :categoryName="category.name" :id="category.id" />
                  </li>
                </ul>
              </li>
              <li class="borderd-list indent" @click="tagList = !tagList">
                <div class="flex">
                  <p class="list-link">キーワードで探す</p>
                  <div class="plus-btn">
                    <img src="@/assets/image/header/button-plus.svg" />
                  </div>
                </div>
                <ul class="tags" v-show="tagList">
                  <li v-for="tag in catState.tags" :key="tag.id">
                    <Tag :tagName="tag.name" :id="tag.id" />
                  </li>
                </ul>
              </li>
              <li class="borderd-list" @click="activeBtn = false">
                <router-link class="list-link" to="/about/planning-proposal"
                  >寺院会員になる</router-link
                >
              </li>
              <li class="borderd-list" @click="activeBtn = false">
                <router-link class="list-link" to="/about/for-temples"
                  >お寺に企画提案する</router-link
                >
              </li>
              <li class="borderd-list" @click="activeBtn = false">
                <router-link to="/donation" class="list-link"
                  >ご寄付のお願い</router-link
                >
              </li>
              <li class="borderd-list" @click="activeBtn = false">
                <a class="list-link" href="/events/contact/">お問い合わせ</a>
              </li>
              <li class="borderd-list">
                <a
                  class="list-link"
                  href="https://www.tera-buddha.net/"
                  target="_blank"
                  ><img
                    src="@/assets/image/header/icon-card-arrow.svg"
                    width="28"
                    class="link-official"
                  /><span class="small">[運営]</span>一般社団法人寺子屋ブッダ</a
                >
              </li>
            </ul>
            <div class="sp-only sns">
              <a
                href="https://www.facebook.com/TerakoyaBuddha/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/image/top/icon-fb.svg"
                  width="28"
                  height="28"
                />
              </a>
              <!-- <a href="">
                <img
                  src="@/assets/image/top/icon-tw.svg"
                  width="28"
                  height="23"
                />
              </a>
              <a href="">
                <img
                  src="@/assets/image/top/icon-insta.svg"
                  width="26"
                  height="26"
                />
              </a> -->
            </div>
          </div>
        </transition>
      </div>
      <div class="center">
        <h1>
          <router-link to="/"
            ><img src="@/assets/image/header/logo.png"
          /></router-link>
        </h1>
        <ul class="link-menu">
          <li>
            <router-link to="/features">
              <div></div>
              <p>特集記事一覧</p>
            </router-link>
          </li>
          <li>
            <router-link to="/search?target=temple">
              <div></div>
              <p>登録寺院一覧</p>
            </router-link>
          </li>
          <li>
            <router-link to="/donation">
              <div></div>
              <p>ご寄付のお願い</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <div class="relative" v-click-outside="onClickOutside">
        <div @click="toggleSearchBar" class="sp-search sp-only">
          <img src="@/assets/image/header/icon-search.svg" />
          <p>検索</p>
        </div>
        <div :class="['search', 'backboard', isSearchVisible && 'visible']">
          <div :class="['search', isSearchVisible && 'visible']">
            <input
              type="text"
              class="searchTerm"
              placeholder="エリア・寺院名・キーワード"
              @focus="onFocus"
              v-model="keyword"
            />
            <button type="submit" class="searchButton" @click="onClickSearch">
              <p class="sp-only">検索</p>
              <img
                src="@/assets/image/header/icon-search.svg"
                class="sp-none"
              />
            </button>
          </div>
          <div class="search-board" v-show="searchMenu">
            <ul class="searchItems">
              <li v-for="(target, index) in targets" :key="index">
                <input
                  type="checkbox"
                  :id="target.id"
                  v-model="selectedTarget"
                  :value="target.id"
                />
                <label :for="target.id">{{ target.name }}</label>
              </li>
            </ul>
            <div class="selectbox">
              <select name="area" v-model="area">
                <option value="" selected>エリアを選択してください</option>
                <option
                  :value="prefecture.prefName"
                  v-for="prefecture in prefectures"
                  :key="prefecture.prefCode"
                  >{{ prefecture.prefName }}</option
                >
              </select>
            </div>
            <button
              type="submit"
              class="searchButtonPc sp-none"
              @click="onClickSearch"
            >
              <p>検索</p>
            </button>
          </div>
        </div>
      </div>
      <div v-if="isLoggedIn" class="login-menu">
        <div
          id="notification"
          class="login-menu-nav"
          @click="toggleNotificationMenu"
          title="お知らせ"
        >
          <a class="relative nav-news">
            <img
              class="nav-news-img u-use--pc"
              src="@/assets/image/header/icon_news.svg"
              alt="ring"
            />
            <img
              class="nav-news-img u-use--sp"
              src="@/assets/image/header/icon_news_white.svg"
              alt="ring"
            />
            <p>お知らせ</p>
            <span
              class="budge"
              v-if="
                user.notifications.filter(
                  (notification) => notification.checked === 0
                ).length > 0
              "
            ></span>
          </a>
        </div>
        <div class="login-menu-nav" title="フォロー">
          <router-link to="/followee">
            <img
              class="nav-favorite u-use--pc"
              src="@/assets/image/header/icon_heart.svg"
              alt="heart"
              height="20"
            />
            <img
              class="nav-favorite u-use--sp"
              src="@/assets/image/header/icon_heart_white.svg"
              alt="heart"
              height="20"
            />
            <p>フォロー</p>
          </router-link>
        </div>
        <div
          id="edit"
          class="login-menu-nav"
          @click="toggleEditMenu"
          title="寺院ページ編集"
        >
          <a>
            <template v-if="user.monk_type_id === 1">
              <img
                class="nav-edit u-use--pc"
                src="@/assets/image/header/icon_edit.svg"
                alt="temple"
                height="20"
              />
              <img
                class="nav-edit u-use--sp"
                src="@/assets/image/header/icon_edit_white.svg"
                alt="temple"
                height="20"
              />
            </template>
            <template v-else>
              <img
                class="nav-edit-temple u-use--pc"
                src="@/assets/image/header/icon_temple_edit.svg"
                alt="temple"
                height="20"
              />
              <img
                class="nav-edit-temple u-use--sp"
                src="@/assets/image/header/icon_temple_edit_white.svg"
                alt="temple"
                height="20"
              />
            </template>
            <p v-if="user.monk_type_id === 1">提案・編集</p>
            <p v-else>寺院ページ</p>
          </a>
        </div>
        <div
          id="entryEvent"
          class="login-menu-nav"
          @click="toggleEntryEventMenu"
          title="参加イベント編集"
        >
          <a>
            <img
              class="nav-event u-use--pc"
              src="@/assets/image/header/icon_event.svg"
              alt="temple"
              height="20"
            />
            <img
              class="nav-event u-use--sp"
              src="@/assets/image/header/icon_event_white.svg"
              alt="temple"
              height="20"
            />
            <p>参加イベント</p>
          </a>
        </div>
        <div class="account">
          <div
            id="account"
            class="account-inner"
            @click="toggleUserMenu"
            title="アカウントメニュー"
          >
            <img
              class="nav-account u-use--pc"
              src="@/assets/image/header/icon_account.svg"
              alt="account"
            />
            <img
              class="nav-account u-use--sp"
              src="@/assets/image/header/icon_account_white.svg"
              alt="account"
            />
            <p>アカウント</p>
            <p class="pc-none user-name">
              {{ user.name_sei }} {{ user.name_mei }}様
            </p>
            <!-- <div class="user-menu-btn">
            <img src="@/assets/image/header/button-down-arrow.svg" />
          </div> -->
          </div>
        </div>
        <!--サイドバー-->
        <transition name="notification-menu">
          <div
            id="notificationMenu"
            class="user-menu wide"
            v-show="notificationMenu"
          >
            <ul class="menu-list">
              <BorderedList>
                <template v-slot:parent>
                  <div class="flex">
                    <div class="menu-title">
                      <div class="img-box">
                        <img
                          src="@/assets/image/header/icon-ring.svg"
                          alt="ring"
                        />
                      </div>
                      <span>まち寺からのお知らせ</span>
                    </div>
                    <div class="plus-btn">
                      <img src="@/assets/image/header/button-plus.svg" />
                    </div>
                  </div>
                </template>
                <template v-slot:child>
                  <ul class="message-box">
                    <li
                      v-for="(notification, index) in user.notifications"
                      :key="index"
                    >
                      <NotificationItem
                        :notification="notification"
                        :on-click-read="readNotification"
                      />
                    </li>
                  </ul>
                </template>
              </BorderedList>
            </ul>
          </div>
        </transition>
        <transition name="edit-menu">
          <div id="editMenu" class="user-menu" v-show="editMenu">
            <ul class="menu-list">
              <li class="borderd-list" v-if="user.id === 10000">
                <a class="menu-title" href="/users/eventList/?type=plan">
                  <div class="img-box">
                    <img
                      src="@/assets/image/header/icon-temple.svg"
                      alt="temple"
                    />
                  </div>
                  <span>管理者パネル</span>
                </a>
              </li>
              <template v-if="user.monk_type_id === 1">
                <BorderedList>
                  <template v-slot:parent>
                    <div class="flex">
                      <div class="menu-title">
                        <div class="img-box">
                          <img
                            src="@/assets/image/header/icon-pen.svg"
                            alt="pen"
                          />
                        </div>
                        <span>お寺への提案</span>
                      </div>
                      <div class="plus-btn">
                        <img src="@/assets/image/header/button-plus.svg" />
                      </div>
                    </div>
                  </template>
                  <template v-slot:child>
                    <ul>
                      <li class="menu-item">
                        <a
                          v-if="user.name_sei"
                          :href="
                            '/partners/create_proposed/?partner_id=' +
                              user.partner.partner_id
                          "
                          >ー 新規企画提案作成</a
                        >
                        <span
                          v-else
                          class="menu-item__disable"
                          :href="
                            '/partners/create_proposed/?partner_id=' +
                              user.partner.partner_id
                          "
                          >ー 新規企画提案作成</span
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                          v-if="user.name_sei"
                          :to="
                            '/partners/flow/?target=proposed&partner_id=' +
                              user.partner.partner_id
                          "
                          >ー 提案中＆下書き保存</router-link
                        >
                        <span
                          v-else
                          class="menu-item__disable"
                          :to="
                            '/partners/flow/?target=proposed&partner_id=' +
                              user.partner.partner_id
                          "
                          >ー 提案中＆下書き保存</span
                        >
                      </li>
                    </ul>
                  </template>
                </BorderedList>
                <BorderedList>
                  <template v-slot:parent>
                    <div class="flex">
                      <div class="menu-title">
                        <div class="img-box">
                          <img
                            src="@/assets/image/header/icon-pen.svg"
                            alt="pen"
                          />
                        </div>
                        <span>共同編集権限のあるページ</span>
                      </div>
                      <div class="plus-btn">
                        <img src="@/assets/image/header/button-plus.svg" />
                      </div>
                    </div>
                  </template>
                  <template v-slot:child>
                    <ul>
                      <li class="menu-item">
                        <router-link
                          :to="
                            '/partners/flow/?partner_id=' +
                              user.partner.partner_id
                          "
                          >ー 一覧</router-link
                        >
                      </li>
                      <!--                      <li class="menu-item">
                        <router-link
                            :to="
                            '/partners/flow/?target=project&partner_id=' +
                              user.partner.partner_id
                          "
                          >ー プロジェクト</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                            :to="
                            '/partners/flow/?target=event&partner_id=' +
                              user.partner.partner_id
                          "
                          >ー イベント</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                            :to="
                            '/partners/flow/?target=report&partner_id=' +
                              user.partner.partner_id
                          "
                          >ー レポート</router-link
                        >

                      </li>-->
                    </ul>
                  </template>
                </BorderedList>

                <li class="borderd-list" v-if="user.temple">
                  <router-link
                      class="menu-title"
                      :to="'/temples/' + user.temple.temple_id + '/edit'"
                  >
                    <div class="img-box">
                      <img
                          src="@/assets/image/header/icon-temple.svg"
                          alt="temple"
                      />
                    </div>
                    <span>{{ user.temple.name }}寺院ページ編集</span>
                  </router-link>
                </li>
              </template>

              <template v-else-if="user.monk_type_id === 3">
                <template v-if="user.temple.temple_website_id">
                  <li class="borderd-list">
                    <a
                      class="menu-title"
                      :href="user.temple.domain ? 'https://' + user.temple.domain + '.machitera.net' : '/temples/' + user.temple.temple_id + '/preview'"
                    >
                      <div class="img-box">
                        <img
                          src="@/assets/image/header/icon-temple.svg"
                          alt="temple"
                        />
                      </div>
                      <span>{{ user.temple.name }}寺院ページ表示</span>
                    </a>
                  </li>
                  <li class="borderd-list">
                    <router-link
                      class="menu-title"
                      :to="'/temples/' + user.temple.temple_id + '/edit'"
                    >
                      <div class="img-box">
                        <img
                          src="@/assets/image/header/icon-temple.svg"
                          alt="temple"
                        />
                      </div>
                      <span>{{ user.temple.name }}寺院ページ編集</span>
                    </router-link>
                    <ul>
                      <li class="menu-item">
                        <router-link
                          :to="'/temples/' + user.temple.temple_id + '/edit'"
                          @click="clickmode"
                          >ー 寺院ページトップ</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                          :to="
                            '/temples/' +
                              user.temple.temple_id +
                              '/articles?target=project'
                          "
                          @click="clickmode"
                          >ー プロジェクト作成</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                          :to="
                            '/temples/' +
                              user.temple.temple_id +
                              '/articles?target=event'
                          "
                          @click="clickmode"
                          >ー イベント作成</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                          :to="
                            '/temples/' +
                              user.temple.temple_id +
                              '/articles?target=report'
                          "
                          @click="clickmode"
                          >ー レポート作成</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                          :to="'/temples/' + user.temple.temple_id + '/flow'"
                          @click="clickmode"
                          >ー 進行中の案件</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <router-link
                          :to="
                            '/temples/' + user.temple.temple_id + '/proposed'
                          "
                          @click="clickmode"
                          >ー お寺への提案</router-link
                        >
                      </li>
                      <li class="menu-item">
                        <a
                          :href="
                            '/temples/temple_members/?temple_id=' +
                              user.temple.temple_id
                          "
                          >ー ご縁リスト</a
                        >
                      </li>
                      <li class="menu-item">
                        <a
                          :href="
                            '/temples/add_monk/?temple_id=' +
                              user.temple.temple_id
                          "
                          >ー 共同編集者</a
                        >
                      </li>
                      <li class="menu-item">
                        <a
                          :href="
                            '/temples/edit_web/?temple_id=' +
                              user.temple.temple_id
                          "
                          >ー 基本情報</a
                        >
                      </li>
                    </ul>
                  </li>
                </template>
                <li class="borderd-list" v-else>
                  <a
                    class="menu-title"
                    :href="
                      '/temples/edit_web/?temple_id=' + user.temple.temple_id
                    "
                  >
                    <div class="img-box">
                      <img
                        src="@/assets/image/header/icon-temple.svg"
                        alt="temple"
                      />
                    </div>
                    <span>寺院ページ作成</span>
                  </a>
                </li>
              </template>

              <template v-else-if="user.monk_type_id === 2">
                <li class="borderd-list" v-if="user.monk_request_date">
                  <a class="menu-title">
                    <div class="img-box">
                      <img
                        src="@/assets/image/header/icon-temple.svg"
                        alt="temple"
                      />
                    </div>
                    <span style="color: #999999">寺院ページ作成（申請中）</span>
                  </a>
                </li>
                <li class="borderd-list" v-else>
                  <a class="menu-title" href="/users/edit_monk">
                    <div class="img-box">
                      <img
                        src="@/assets/image/header/icon-temple.svg"
                        alt="temple"
                      />
                    </div>
                    <span>寺院登録申請</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </transition>
        <transition name="entryEvent-menu">
          <div id="entryEventMenu" class="user-menu" v-show="entryEventMenu">
            <ul class="menu-list">
              <BorderedList>
                <template v-slot:parent>
                  <div class="flex">
                    <div class="menu-title">
                      <div class="img-box">
                        <img
                          src="@/assets/image/header/icon-calender.svg"
                          alt="calender"
                        />
                      </div>
                      <span>参加イベント</span>
                    </div>
                    <div class="plus-btn">
                      <img src="@/assets/image/header/button-plus.svg" />
                    </div>
                  </div>
                </template>
                <template v-slot:child>
                  <ul>
                    <li class="menu-item">
                      <a href="/users/eventList/?type=plan">ー 開催前</a>
                    </li>
                    <li class="menu-item">
                      <a href="/users/eventList/?type=past">ー 終了</a>
                    </li>
                  </ul>
                </template>
              </BorderedList>
            </ul>
          </div>
        </transition>
        <transition name="user-menu">
          <div id="userMenu" class="user-menu" v-show="userMenu">
            <ul class="menu-list">
              <BorderedList>
                <template v-slot:parent>
                  <div class="flex">
                    <div class="menu-title">
                      <div class="img-box">
                        <img
                          src="@/assets/image/header/icon-idcard.svg"
                          alt="idcard"
                        />
                      </div>
                      <span>アカウント</span>
                    </div>
                    <div class="plus-btn">
                      <img src="@/assets/image/header/button-plus.svg" />
                    </div>
                  </div>
                </template>
                <template v-slot:child>
                  <ul>
                    <li class="menu-item">
                      <a href="/users/edit">ー アカウント情報変更</a>
                    </li>
                    <li class="menu-item">
                      <a href="/users/pass_edit">ー パスワード変更</a>
                    </li>
                    <li class="menu-item">
                      <a href="/users/retire_user">ー 退会する</a>
                    </li>
                  </ul>
                </template>
              </BorderedList>
              <li class="borderd-list">
                <a class="menu-title" href="/users/logout">
                  <div class="img-box">
                    <img
                      src="@/assets/image/header/icon-logout.svg"
                      alt="logout"
                    />
                  </div>
                  <span>ログアウト</span>
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <div v-else class="login">
        <a href="/users/login">
          <img src="@/assets/image/header/icon-login.svg" alt="login" />
          <p>登録・ログイン</p>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { useRoute } from 'vue-router';
import Tag from '@/components/Tag';
import Category from '@/components/Category';
import BorderedList from '@/components/BorderedList';
import NotificationItem from '@/components/NotificationItem';
import ClickOutside from 'click-outside-vue3';
import { inject } from 'vue';

export default {
  name: 'Header',
  components: {
    Tag,
    Category,
    BorderedList,
    NotificationItem,
  },
  props: {
    msg: String,
  },
  setup() {
    const { catState, viewControlAreaFlg, modeChangeFlg, pageTopFlg } = inject(
      'commons'
    );
    const { user, isLoggedIn, readNotification } = inject('auth');
    const route = useRoute();

    const clickmode = () => {
      modeChangeFlg.value = true;
    };

    return {
      catState,
      user,
      isLoggedIn,
      readNotification,
      viewControlAreaFlg,
      modeChangeFlg,
      pageTopFlg,
      route,
      clickmode,
    };
  },
  watch: {
    $route() {
      this.reset();
      if (!this.modeChangeFlg & (this.route.fullPath.indexOf('#app') === -1)) {
        this.viewControlAreaFlg = false;
      }
      this.modeChangeFlg = false;
    },
    isLoggedIn: function() {
      window.addEventListener('click', this.closeUserMenu);
      window.addEventListener('click', this.closeNotificationMenu);
      window.addEventListener('click', this.closeEditMenu);
      window.addEventListener('click', this.closeEntryEventMenu);
    },
  },
  data() {
    return {
      activeBtn: false,
      userMenu: false,
      entryEventMenu: false,
      editMenu: false,
      notificationMenu: false,
      searchMenu: false,
      categoryList: false,
      tagList: false,
      isSearchVisible: false,
      prefectures: [],
      area: '',
      keyword: '',
      target: [],
      targets: [
        { id: 'project', name: 'プロジェクト' },
        { id: 'event', name: 'イベント' },
        { id: 'report', name: 'レポート' },
        { id: 'temple', name: '参加寺院' },
      ],
      selectedTarget: [],
    };
  },
  mounted() {
    window.addEventListener('click', this.closeUserMenu);
    window.addEventListener('click', this.closeNotificationMenu);
    window.addEventListener('click', this.closeEditMenu);
    window.addEventListener('click', this.closeEntryEventMenu);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeUserMenu);
    window.removeEventListener('click', this.closeNotificationMenu);
    window.removeEventListener('click', this.closeEntryEventMenu);
    window.removeEventListener('click', this.closeEditMenu);
  },
  methods: {
    reset() {
      this.searchMenu = false;
      this.isSearchVisible = false;
      this.userMenu = false;
      this.entryEventMenu = false;
      this.activeBtn = false;
      this.editMenu = false;
      this.notificationMenu = false;
      this.categoryList = false;
      this.tagList = false;
      this.isSearchVisible = false;
    },
    toggleNotificationMenu() {
      this.notificationMenu = !this.notificationMenu;
      this.userMenu = false;
      this.editMenu = false;
      this.entryEventMenu = false;
    },
    toggleUserMenu() {
      this.notificationMenu = false;
      this.userMenu = !this.userMenu;
      this.editMenu = false;
      this.entryEventMenu = false;
    },
    toggleEntryEventMenu() {
      this.notificationMenu = false;
      this.entryEventMenu = !this.entryEventMenu;
      this.userMenu = false;
      this.editMenu = false;
    },
    toggleEditMenu() {
      this.notificationMenu = false;
      this.entryEventMenu = false;
      this.userMenu = false;
      this.editMenu = !this.editMenu;
    },
    closeUserMenu(event) {
      if (!this.$el.querySelector('#userMenu').contains(event.target)) {
        if (!this.$el.querySelector('#account').contains(event.target)) {
          this.userMenu = false;
        }
      }
    },
    closeNotificationMenu(event) {
      if (!this.$el.querySelector('#notificationMenu').contains(event.target)) {
        if (!this.$el.querySelector('#notification').contains(event.target)) {
          this.notificationMenu = false;
        }
      }
    },
    closeEditMenu(event) {
      if (!this.$el.querySelector('#editMenu').contains(event.target)) {
        if (!this.$el.querySelector('#edit').contains(event.target)) {
          this.editMenu = false;
        }
      }
    },
    closeEntryEventMenu(event) {
      if (!this.$el.querySelector('#entryEventMenu').contains(event.target)) {
        if (!this.$el.querySelector('#entryEvent').contains(event.target)) {
          this.entryEventMenu = false;
        }
      }
    },
    onFocus() {
      this.searchMenu = true;
      this.isSearchVisible = true;
    },
    onClickOutside() {
      this.searchMenu = false;
      this.isSearchVisible = false;
    },
    onClickOutsideGnav() {
      this.activeBtn = false;
    },
    toggleSearchBar() {
      this.isSearchVisible = !this.isSearchVisible;
      this.searchMenu = !this.searchMenu;
    },
    onClickSearch() {
      this.$router.push({
        path: '/search',
        query: {
          target: this.selectedTarget.join(','),
          keyword: this.keyword,
          prefecture: this.area,
        },
      });
    },
    fetchArea() {
      const $this = this;
      const api = {
        url: 'https://opendata.resas-portal.go.jp/api/v1/prefectures',
        key: 'q8yfxZ0o5WpA2pw81aQemjvI0RtY8aAVWWUkZWOR',
      };
      fetch(api.url, {
        method: 'GET',
        headers: {
          'X-API-KEY': api.key,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          $this.prefectures = data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  directives: {
    clickOutside: ClickOutside.directive,
  },
  created() {
    this.fetchArea();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-family: iroha-23kaede-stdn, Avenir, Helvetica, Arial, sans-serif;
  height: 12rem;
  @include sp {
    height: 7rem;
    top: 0;
  }
  &.with-control-area {
    position: static;
  }
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.sp-only {
  display: none;
}
.center,
.right {
  display: flex;
}
.right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  // padding-right: 3rem;
  padding-right: 1.666vw;
  width: 100%;
}
.relative {
  display: block;
  position: relative;
}
.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
h1 {
  @include sp {
    padding-left: 15.7333vw;
  }
  margin-bottom: 0;
  margin-right: 1.5rem;
  margin-right: 0.833vw;
  // padding-left: 12.5rem;
  padding-left: 6.944vw;
}
h1 img {
  @include sp {
    vertical-align: bottom;
    width: 11.5rem;
  }
  max-width: 20rem;
  vertical-align: 0.4rem;
  width: 11.111vw;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}
.hamburger-btn {
  cursor: pointer;
  @include sp {
    left: 4.4vw;
    top: 3rem;
  }
  // left: 4rem;
  left: 2.222vw;
  position: absolute;
  top: 4rem;
  z-index: 50;
}
.hamburger-btn img {
  height: 3.1rem;
  @include sp {
    height: 1.5rem;
    width: 2.5rem;
  }
  max-width: 5rem;
  width: 2.777vw;
}
.menu-list {
  @include sp {
    padding: 0 2rem;
  }
  padding: 0 4rem;
  background-color: #fff;
}
.accordion-menu {
  background-color: #fff;
  @include sp {
    height: auto;
    max-height: initial;
    top: 7rem;
    width: 100vw;
  }
  left: 0;
  max-height: calc(100% - 13rem);
  padding: 1.5rem 0 4rem 0;
  position: fixed;
  overflow-y: scroll;
  text-align: left;
  top: 12rem;
  width: 44rem;
  z-index: 999;

  .with-control-area & {
    position: absolute;
  }
}
.list-link {
  color: #000;
  font-size: 1.8rem;
  @include sp {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.2;
    padding: 0 0 0 1rem;
  }
  letter-spacing: 0.02em;
  padding: 0 1.2rem;
}
.link-official {
  width: 2.8rem;
}
.list-link .small {
  font-size: 0.6em;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  vertical-align: 0.2rem;
}
.tags {
  padding-left: 0;
}
.tags li {
  display: inline-block;
  margin-right: 0.5rem;
  margin-top: 1rem;
}
.plus-btn img {
  height: 2rem;
  width: 2rem;
}
.categories {
  border-top: 1px solid #ddd;
  margin-top: 2rem;
  padding-left: 1rem;
}
.tags {
  border-top: 1px solid #ddd;
  margin-top: 2rem;
}
.categories li {
  margin-top: 1.5rem;
}
/*左サイドバー*/
.accordion-menu-enter-active,
.accordion-menu-leave-active {
  transition: opacity 0.2s;
}
.accordion-menu-enter,
.accordion-menu-leave-to {
  opacity: 0;
}
.accordion-menu-leave,
.accordion-menu-enter-to {
  opacity: 1;
}
.borderd-list {
  border-top: 1px solid #ddd;
  @include sp {
    padding: 1.5rem 0;
  }
  line-height: 1;
  padding: 2rem 0;
}
.borderd-list.indent {
  margin-left: 2rem;
}
.borderd-list:last-child {
  border-bottom: 1px solid #ddd;
}
.link-menu,
.link-menu a {
  align-items: center;
  display: flex;
  letter-spacing: 0.2rem;
}
.link-menu {
  @include tablet {
    display: none;
  }
  margin-left: 1.666vw;
  white-space: nowrap;
}
.link-menu li:not(:first-child) {
  margin-left: 1vw;
}
.link-menu div {
  background-color: #000;
  height: 1px;
  max-width: 4rem;
  width: 1vw;
}
.link-menu p {
  color: #000;
  font-size: 1.5rem;
  @include between {
    font-size: 1.2rem;
  }
  @include tablet {
    @include fz_vw(15);
  }
  margin-left: 0.3vw;
}
.right,
.account {
  align-items: center;
  display: flex;
}
.search {
  padding-left: 1.3vw;
  position: relative;

  &.backboard {
    padding-left: 0;
  }
}
.searchTerm {
  background-color: #f7f6f4;
  border: none;
  border-radius: 2.5rem;
  display: block;
  font-family: YuGothic, 'Yu Gothic Medium', 'Yu Gothic', Meiryo, sans-serif;
  font-size: 1.3rem;
  height: 2rem;
  @include tablet {
    font-size: 1.6rem;
    padding: 0.8333vw 3rem 0.8333vw 1.3888vw;
    width: 25vw;
  }
  @include between {
    font-size: 1rem;
  }
  max-width: 30rem;
  min-width: 14rem;
  outline: none;
  padding: 0.8333vw 1.3888vw;
  width: 16.666vw;
}
.searchButton {
  @include tablet {
    top: 45.57%;
  }
  position: absolute;
  right: 0.9444vw;
  top: 50%;
  transform: translateY(-50%);
}
.searchButton img {
  max-width: 2.8rem;
  min-width: 1.4rem;
  width: 1.555vw;
}
.searchButtonPc {
  align-items: center;
  background-color: #432b17;
  border-radius: 0.5rem;
  bottom: 2.5rem;
  color: #fff;
  display: flex;
  font-size: 1.4rem;
  font-weight: bold;
  left: 13rem;
  letter-spacing: 0.2rem;
  padding: 0.7rem 3rem;
  position: absolute;
  z-index: 9999;
}
.search-board {
  background-color: #f7f6f4;
  height: 25rem;
  @include tablet {
    left: auto;
    right: 5px;
  }
  left: 0rem;
  position: absolute;
  top: 6.1rem;
  width: 35rem;
  z-index: 999;
}
.searchItems {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  height: 6.5rem;
  justify-content: space-between;
  margin: 3.2rem auto 2.3rem auto;
  padding-left: 0;
  width: 22rem;
}
.searchItems li {
  display: inline-block;
}
.searchItems input[type='checkbox'] {
  display: none;
}
/* チェックボックスの代わりを成すラベル */
.searchItems input[type='checkbox'] + label {
  cursor: pointer;
  display: none;
  display: inline-block;
  font-size: 1.3rem;
  padding-left: 2.5rem;
  padding-right: 1rem;
  position: relative;
}
/* ラベルの左に表示させる正方形のボックス□ */
.searchItems input[type='checkbox'] + label::before {
  background-color: #fff;
  border: 1px solid;
  border-color: #000;
  box-sizing: border-box;
  content: '';
  display: block;
  height: 14px;
  left: 0;
  margin-top: -0.7rem;
  position: absolute;
  top: 50%;
  width: 14px;
}
/* チェックが入った時の×点 */
.searchItems input[type='checkbox']:checked + label::after {
  background-image: url('~@/assets/image/header/icon-checked.svg');
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 12px;
  left: 1.5px;
  position: absolute;
  top: 0;
  width: 12px;
}
.selectbox {
  margin: auto;
  position: relative;
  width: 25rem;
}
.selectbox select {
  appearance: none;
  background: #fff;
  border: #000 1px solid;
  border-radius: 5rem;
  box-sizing: border-box;
  font-size: 1.3rem;
  padding: 1.5rem 2rem;
  width: 25rem;
}
.selectbox::after {
  border-bottom: #000 1px solid;
  border-right: #000 1px solid;
  content: '';
  display: block;
  height: 0.7rem;
  pointer-events: none;
  position: absolute;
  right: 2.5rem;
  top: 42%;
  transform: rotate(45deg) translateY(-30%);
  width: 0.7rem;
}
.photo {
  background-color: #ddd;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  flex: none;
  height: 2.222vw;
  @include sp {
    height: 3.7rem;
    margin-left: 5.333vw;
    margin-right: 4vw;
    width: 3.7rem;
  }
  max-width: 4rem;
  position: relative;
  width: 2.222vw;
}
.initial {
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
}
.budge {
  background-color: #c84d5f;
  border-radius: 50%;
  height: 1.3rem;
  @include sp {
    height: 0.9rem;
    width: 0.9rem;
  }
  position: absolute;
  right: -0.2rem;
  top: 0.3rem;
  width: 1.3rem;
}
.login-menu {
  align-items: center;
  display: flex;
  @include sp {
    background-color: #412814;
    bottom: 0;
    height: 65px;
    justify-content: space-between;
    left: 0;
    margin-left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
  }
  margin: 0 1vw;
}
.login-menu-nav {
  align-items: center;
  cursor: pointer;
  @include sp {
    align-items: center;
    border-right: 1px solid #fff;
    display: flex;
    height: 60%;
    justify-content: center;
    margin: 0;
    width: 20%;
  }
  @include between {
    margin: 0 0.255vw;
  }
  margin: 0 0.555vw;
  text-align: center;

  & a {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 5rem;
    @include sp {
      padding-top: 0.8rem;
    }
    justify-content: space-between;
    position: relative;
  }

  & .nav-news-img {
    height: 3rem;
    @include between {
      height: 2.5rem;
    }
    @include sp {
      height: 2rem;
    }
  }

  & .nav-edit-temple {
    height: 3.5rem;
    @include sp {
      height: 2.5rem;
    }
    @include between {
      height: 3rem;
    }
  }

  & .nav-event {
    height: 2.8rem;
    @include sp {
      height: 1.8rem;
    }
    @include between {
      height: 2.3rem;
    }
  }

  & .nav-favorite {
    height: 2.8rem;
    @include sp {
      height: 1.8rem;
    }
    @include between {
      height: 2.3rem;
    }
  }
}
.login-menu-nav img {
  height: 3rem;
  @include sp {
    height: 1.8rem;
  }
}
.login-menu-nav p {
  color: #412814;
  font-feature-settings: 'palt';
  font-size: 1.2rem;
  @include sp {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  @include between {
    font-size: 1rem;
  }
  white-space: nowrap;
}
.account {
  cursor: pointer;
  @include sp {
    margin-left: 0;
    width: 20%;

    &-inner {
      align-items: center;
      display: flex;
      padding-top: 0.8rem;
      width: 100%;
    }
  }
  margin-left: 0.555vw;
  position: relative;
  text-align: center;
  &:hover {
    opacity: 0.6;
  }
  &-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: space-between;
    position: relative;
  }

  & p {
    color: #412814;
    font-feature-settings: 'palt';
    font-size: 1.2rem;
    @include between {
      font-size: 1rem;
    }
    @include sp {
      color: #fff;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    white-space: nowrap;
  }

  & .nav-account {
    height: 3rem;
    @include sp {
      height: 2rem;
    }
    @include between {
      height: 2.5vw;
    }
  }
}
.account:before {
  content: '';
  //position: absolute;
  //width: 1px;
  //height: 5rem;
  //background-color: #000;
  //left: -4rem;
}
.user-name {
  display: none;
}
.user-menu-btn {
  cursor: pointer;
  position: absolute;
  right: -2rem;
  top: 30%;
  z-index: 50;
}
.user-menu-btn img {
  height: 0.7rem;
  width: 1.3rem;
}
.user-menu {
  background-color: #fff;
  padding: 1.5rem 0 4rem 0;
  position: absolute;
  right: 0;
  top: 11rem;
  width: 35rem;
  z-index: 999;
}
.user-menu.wide {
  width: 50vw;
  min-width: 60rem;
}
.user-name {
  font-size: 0.9rem;
}
.login {
  margin-left: 2.777vw;
  text-align: center;
}
.login img {
  margin-bottom: 1rem;
  width: 20px;
}
.login p {
  font-size: 1.2rem;
  @include sp {
    font-size: 0.9rem;
  }
  letter-spacing: 0.12rem;
}
/*右サイドバー*/
.user-menu-enter-active,
.user-menu-leave-active {
  transition: opacity 0.4s;
}
.user-menu-enter,
.user-menu-leave-to {
  opacity: 0;
}
.user-menu-leave,
.user-menu-enter-to {
  opacity: 1;
}
.name {
  font-size: 1.8rem;
  letter-spacing: 0.36rem;
  margin-right: 4rem;
}
.menu-title {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
}
.menu-title {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.menu-title .img-box {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: 0.5rem;
  width: 3.7rem;
}
.menu-title img[alt='ring'] {
  height: 1.73rem;
  width: 1.32rem;
}
.menu-title img[alt='calender'] {
  height: 1.2rem;
  width: 1.63rem;
}
.menu-title img[alt='temple'] {
  height: 1.57rem;
  width: 2.3rem;
}
.menu-title img[alt='pen'] {
  height: 1.73rem;
  width: 1.63rem;
}
.menu-title img[alt='idcard'] {
  height: 1.22rem;
  width: 1.92rem;
}
.menu-title img[alt='logout'] {
  height: 1.53rem;
  width: 1.82rem;
}
.menu-title img[alt='heart'] {
  height: 1.63rem;
  width: 1.93rem;
}
.menu-item {
  font-family: YuGothic, 'Yu Gothic Medium', 'Yu Gothic', Meiryo, sans-serif;
  font-size: 1.3rem;
  margin-left: 4.3rem;
  margin-top: 1.2rem;
  &__disable {
    color: #a3a3a3;
  }
}
.message-box {
  height: auto;
  margin: 2.2rem 0 2.2rem 4.3rem;
  max-height: 60rem;
  overflow-y: scroll;
  padding-left: 0;
}
.message-box li {
  margin-bottom: 1.4rem;
  padding-right: 3rem;
}
.message-box a,
.message-box time {
  cursor: pointer;
  display: block;
}
.message-box time {
  font-family: 'aw-conqueror-didot', serif;
  font-size: 1.1rem;
  letter-spacing: 0.11rem;
  margin-bottom: 0.9rem;
}
.message-box p.notification-title {
  font-family: YuGothic, 'Yu Gothic Medium', 'Yu Gothic', Meiryo, sans-serif;
  font-size: 1.3rem;
  letter-spacing: 0.13rem;
  line-height: 1.6;
}
.message-box .notification-content {
  display: none;
}

/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
  // header{
  //   width: auto;
  // }
  .sp-none {
    display: none;
  }

  .user-name {
    display: none;
  }

  .link-menu {
    display: none;
  }
  .search {
    display: none;
    padding-top: 20px;

    &.visible {
      width: 100%;
    }
  }
  .visible {
    display: block;
    left: 0;
    position: fixed;
    top: 7rem;
    width: 90%;
    z-index: 1000;
  }
  .searchTerm {
    box-sizing: border-box;
    height: 35px;
    line-height: 35px;
    margin: 0 auto;
    padding: 1.5rem 2.5rem;
    width: 300px;
  }
  .sp-search {
    text-align: center;
  }
  .sp-search img {
    height: 3.6rem;
    @include sp {
      height: 1.7rem;
      width: 1.7rem;
    }
    margin-bottom: 1rem;
    width: 3.6rem;
  }
  .user-menu.wide {
    width: 100%;
    min-width: 0;
  }
  .sp-search p {
    font-size: 0.9rem;
    letter-spacing: 0.12rem;
  }
  .login img {
    height: 1.75rem;
    width: 1.4rem;
  }
  .right {
    height: 7.14vw;
    padding-right: 2rem;
  }
  .search-board {
    left: 0;
    position: fixed;
    top: 15rem;
    width: 100%;
  }
  .searchButton {
    align-items: center;
    background-color: #432b17;
    border-radius: 0.5rem;
    color: #fff;
    display: flex;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding: 0.7rem 3rem;
    right: 38%;
    top: 300px;
  }
  .searchItems input[type='checkbox']:checked + label::after {
    left: 1px;
    top: 1px;
  }
  .backboard {
    background-color: #fff;
    height: 100%;
    left: 0;
    padding-top: 20px;
    position: fixed;
    width: 100%;
  }
  .user-menu {
    bottom: 6rem;
    max-height: calc(100% - 13rem);
    top: auto;
    width: 100vw;
    position: fixed;
  }
  .account:before {
    height: 7.5rem;
  }
  .message-box {
    overflow-wrap: break-word;
    width: auto;
  }

  .message-box li {
    width: 100%;
  }

  .sns {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .sns > * {
    margin-right: 4.5rem;
  }
  .sns a:last-child {
    margin-right: 0;
  }
}
</style>

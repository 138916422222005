<template>
  <div class="outer">
    <div class="inner">
      <a :href="defaultLink">
        <template v-if="item.thumbnail">
          <div class="thumbnail" :style="'background-image: url(' + item.thumbnail + ')'">
            <Label
                v-if="item.category_id"
                :id="Number(item.category_id)"
                :category="item.category_name"
                color="green"
            />
            <div class="wrap">
              <div class="article-type">
                【企画提案】
              </div>
              <p :class="['status-text', status.value]">{{status.label}}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="thumbnail">
            <Label
                v-if="item.category_id"
                :id="Number(item.category_id)"
                :category="item.category_name"
                color="green"
            />
            <div class="wrap">
              <div class="article-type">
                【企画提案】
              </div>
              <p :class="['status-text', status.value]">{{status.label}}</p>
            </div>
          </div>
        </template>
      </a>
      <div class="right">
        <p class="deadline">◎応募期限 : {{item.deadline_date }}<span v-if="item.deadline_week">（{{item.deadline_week}}）</span></p>
        <p class="caption" v-if="item.subtitle">{{ item.image_description }}</p>
        <h1 v-html="item.event_title" />
        <div class="icon-box"
          v-if="getProfile(item.profile_content)">
          <IconBox
            :imageSrc="getThumbnailUrl(getProfile(item.profile_content).thumbnail)"
            :name="getProfile(item.profile_content).name"
            :role="role"
            :detail="getProfile(item.profile_content).title"
            :isNonClip="false"
          />
        </div>
        <ul class="buttons">
          <li v-for="(action, index) in actions" :key="index">
            <ActionButton
              :label="action.label"
              :path="action.path"
              :className="action.className"
              link="a"
              :count="action.count"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import IconBox from '@/components/IconBox.vue';
import ActionButton from "@/components/ActionButton";
import Label from '@/components/Label';
import moment from 'moment';
import {getProfile} from "@/utils/profile";
import {getThumbnailUrl} from "@/utils/helper";
import {computed} from "vue";


export default {
  name: 'ProposedItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    templeId: {
      type: Number
    }
  },
  setup(props) {
    const role = computed(()=>{
      return "提案者"
    });

    const status = computed(()=>{
      if(props.item.private_flg === "1") {
        return {
          label: "下書き",
          value: 'draft'
        }
      }

      const targetDate = new Date(props.item.deadline_date);
      const now = new Date();
      if(targetDate < now){
        return {
          label: "募集終了",
          value: "end"
        }
      }

      if(props.item.type === "messaged"){
        return {
          label: "企画検討中",
          value: 'proposed'
        }
      }else{
        return {
          label: "提案中",
          value: 'proposed'
        }
      }
    });
    const actions = computed(()=>{
      const actionsFull = [
        {
          label: "企画詳細を見る",
          path: "/temples/proposed_view/?temple_id=" +props.templeId+ "&temporary_event_id=" + props.item.id,
          type: "public"
        },
        {
          label: "詳細を見る",
          path: "/temples/proposed_view/?temple_id=" +props.templeId+ "&temporary_event_id=" + props.item.te_id,
          type: "messaged"
        },
        {
          label: "提案者にメッセージ",
          path: "/temples/message_detail?temple_id="+props.templeId+"&temporary_event_id="+props.item.id+"&partner_id="+props.item.partner_id,
          type: "public-contact"
        },
        {
          label: "プレビュー",
          path: "/partners/proposed_view/?partner_id=" +props.item.partner_id+ "&temporary_event_id=" + props.item.id,
          type: "proposed"
        },
        {
          label: "編集",
          path: "/partners/create_proposed/?partner_id=" +props.item.partner_id+ "&temporary_event_id=" + props.item.id,
          type: "proposed"
        },
        {
          label: "提案者と連絡",
          path: "/temples/message_detail?temple_id="+props.item.temple_id+"&temporary_event_id="+props.item.te_id+"&partner_id="+props.item.partner_id,
          type: "messaged"
        },
        {
          label: "寺院と連絡",
          path: "/partners/message_thread?temporary_event_id="+props.item.id+"&partner_id="+props.item.partner_id,
          count: props.item.message_count,
          type: "proposed-contact"
        },
        {
          label: "削除",
          path: "/partners/delete_temporary_event?temporary_event_id="+props.item.id+"&partner_id="+props.item.partner_id,
          type: "proposed"
        },
      ];

      return actionsFull.filter(action=> {
        if(action.type === props.item.type){
          return true
        }else{
          if(action.type === "proposed-contact"){
            if(props.item.type === "proposed"){
              return !!props.item.message_id
            }else{
              return false
            }
          }
          if(action.type === "public-contact"){
            if(props.item.type === "public"){
              return status.value.value === 'proposed'
            }else{
              return false
            }
          }
        }
        return false
      })
    });
    const defaultLink = computed(()=>{
      if(props.item.type === 'proposed'){
        return "/partners/proposed_view/?partner_id=" +props.item.partner_id+ "&temporary_event_id=" + props.item.id
      }else{
        return "/temples/proposed_view/?temple_id=" +props.templeId+ "&temporary_event_id=" + props.item.id
      }
    });
    return {
      role,
      actions,
      getProfile,
      getThumbnailUrl,
      status,
      defaultLink
    };
  },
  components: {
    ActionButton,
    IconBox,
    Label,
  },
  methods: {
    moment(date, format) {
      moment.locale('ja');
      if (date) {
        return moment(date).format(format);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outer{
  position: relative;
  padding-bottom: 8rem;

  @include sp{
    width: 31.6rem;
    margin: 0 .8rem;
  }
}
.outer::after{
  content: "";
  width: 10rem;
  border-bottom: 1px solid #000;
  left:calc(50% - 5rem);
  bottom: 4rem;
  position: absolute;
}
.inner {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 120rem;
}
.wrap{
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.article-type {
  background-color: #a73550;
  color: #fff;
  width: 50%;
  text-align: center;
  font-weight: bold;
  padding:1rem 0;
}

.status-text.end{
  color: #fff;
  background-color: #999;
}
.status-text{
  background-color: #f7f6f4;
  width: 50%;
  border-radius: 0;
  margin: 0;
  padding:1rem 0;
  text-align: center;
  color: #000;
}
.thumbnail {
  background-color: #dff0d8;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24.5rem;
  margin-right: 5rem;
  position: relative;
  width: 37.1rem;
}
.label {
  color: #fff;
  display: inline-block;
  font-family: "iroha-23kaede-stdn", sans-serif;
  font-size: 1.5rem;
  left: auto;
  letter-spacing: .03rem;
  padding: .7rem .4rem;
  position: relative;
  text-align: center;
  bottom: auto;
  top: auto;
  margin-bottom: 1rem;
}
.right {
  max-width: 60rem;
  width: 33.333vw;
}
.deadline{
  font-size: 1.5rem;
  font-family: "dnp-shuei-mincho-pr6", sans-serif;
  letter-spacing: 0.1rem;
  padding:0.5rem 0;
}
.time-stamp {
  color: #000;
  font-family: "dnp-shuei-mincho-pr6", sans-serif;
  font-size: 1.2rem;
  letter-spacing: .11rem;
  margin-bottom: .4rem;
}
.time-stamp.project {
  color: #6a7bb7;
}
.time-stamp.event {
  color: #be6c4c;
}
.time-stamp.report {
  color: #749e1d;
}
.time-stamp.proposed {
  color: #a73550;
}
.caption, h1 {
  font-family: "dnp-shuei-mincho-pr6", sans-serif;
}
.caption {
  font-size: 1.5rem;
  letter-spacing: .15rem;
  margin-bottom: 1.2rem;
}
h1 {
  font-size: 3rem;
  letter-spacing: .3rem;
  line-height: 4.5rem;
  margin-bottom: 2rem;
}
.icon-box {
  display: flex;
  margin-bottom: .8rem;
}
.buttons li {
  display: inline-block;
  margin-bottom: .8rem;
  margin-right: .8rem;
}
/* ------------------------- */
/* ------ Smart phone ------ */
/* ------------------------- */

@media only screen and (max-width: 768px) {
  .inner {
    flex-direction: column;
  }
  .thumbnail{
    height: 17rem;
  }
  .thumbnail, .right {
    margin-top: 2rem;
    margin-right: 0;
    width: auto
  }
  .caption {
    font-size: 1.1rem;
  }
  h1 {
    font-size: 1.9rem;
    line-height: 1.3;
  }
}
</style>

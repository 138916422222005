<template>
  <router-link class="link-button" :to="link" v-if="link">
      <span>一覧へ</span>
      <img src="@/assets/image/projects/line-arrow-right.svg">
  </router-link>
  <a @click="onClick" class="link-button" v-else>
    <span>{{label}}</span>
    <img src="@/assets/image/projects/line-arrow-right.svg">
  </a>
</template>
<script>
export default {
  name: 'LinkButton',
  props: {
   link: {
     type: String
   },
    onClick:{
     type: Function
    },
    label: {
     type: String
    }
  }
}
</script>
<style scoped>
.link-button{
  display: block;
  margin: 8.5rem auto;
  width: 30rem;
  height: 8rem;
  background-color: #fff;
  border-radius: 30rem;
  border: 1px solid #412814;
  text-align: center;
  line-height: 8rem;
  position: relative;
  cursor: pointer;
}
.link-button span{
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Meiryo, sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.6rem;
  color: #412814;
}
.link-button img{
  width: 2.8rem;
  height: 0.75rem;
  position: absolute;
  top: 3.6rem;
  right: 3.4rem;
}
.link-button:before, .link-button:after{
  content: '';
  position: absolute;
  height: 1px;
  width: 9.3rem;
  background-image: linear-gradient(to right, #412814, #412814 1.6px, transparent 1.6px, transparent 3px);
  background-size: 5px 1px;
  background-position: top;
  background-repeat: repeat-x;
  top: 4rem;
}
.link-button:before{
  left: -10rem;
}
.link-button:after{
  right: -10rem;
}
@media only screen and (max-width:768px){
  .link-button{
    margin: 4.2rem auto;
    width: 15rem;
    height: 4rem;
    border-radius: 15rem;
    line-height: 3.6rem;
  }
  .link-button span{
    font-size: 0.9rem;
    letter-spacing: 0.3rem;
  }
  .link-button:before, .link-button:after{
    height: 1px;
    width: 6rem;
    background-image: linear-gradient(to right, #412814, #412814 0.8px, transparent 0.8px, transparent 1.5px);
    background-size: 4px 1px;
    top: 2rem;
  }
  .link-button:before{
    left: -6.5rem;
  }
  .link-button:after{
    right: -6.5rem;
  }
  .link-button img{
    width: 1.7rem;
    height: 0.45rem;
    top: 1.8rem;
    right: 1.7rem;
  }
}
</style>
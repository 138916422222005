<template>
  <button v-if="editMode" class="add-btn" @click="addContentBtn">
    人物を追加
  </button>
</template>
<script>
export default {
  name: 'AddProfileContentBtn',
  props: {
    handleClickAdd: {
      type: Function,
    },
    editMode: {
      type: Boolean,
    },
  },
  setup(props) {
    const addContentBtn = () => {
      const newListItem = {
        name: "",
        thumbnail: {
          key: null,
          thumbnail_url: null,
          url: null,
        },
        title: "",
        body: "",
      };
      props.handleClickAdd(newListItem);
    };
    return { addContentBtn };
  },
};
</script>
<style scoped></style>
